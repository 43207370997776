import {FetchResult, useMutation} from "@apollo/client";
import gql from "graphql-tag";
import {ApolloError, DocumentNode} from "@apollo/client/core";

const usePatientsMutations = () => {
  return {
    addPatient: useAddPatient(),
    deletePatient:  useDeletePatient(),
  };
}
export default usePatientsMutations

const AddPatientMutation = gql`
  mutation AddPatients($patient: PatientInsertInput!) {
    addedPatient: insertOnePatient(data: $patient) {
      _id
      patientId
      birthDate
      created
      email
      gender
      name
      nameKana
      tel
      isTemp
      updated
      created
    }
  }
`;

const PatientFieldsFragment = gql`
  fragment PatientFields on Patient {
      _id
      patientId
      birthDate
      created
      email
      gender
      name
      nameKana
      tel
      isTemp
      updated
      created
  }
`;

const UpdatePatientMutation = (): DocumentNode => gql`
  mutation UpdatePatient($id: String!, $set: PatientUpdateInput!) {
    updatedData: updateOnePatient(query: { patientId: $id }, set: $set) {
        _id
        isTemp
        patientId
        name
    }
  }
`

export const useUpdatePatient = (completed?: () => void) => {
  const [updateDataMutation, { error }] = useMutation(UpdatePatientMutation(), { onCompleted: completed });
  const updateData = async (id: string | number, set: { [key: string]: any }): Promise<{ updatedData: { [key: string]: any }, error: ApolloError | undefined }> => {
    const { data } = await updateDataMutation({
      variables: { id: id, set: set },
    });
    const updatedData: { [key: string]: any } = data?.updatedData || {}
    return { updatedData: updatedData, error };
  };
  return updateData;
}

const useAddPatient = () => {
  const [addPatientMutation, { data, loading, error }] = useMutation(AddPatientMutation, {
    update: (cache, { data: { addedPatient } }) => {
      cache.modify({
        fields: {
          patients: (existingpatients = []) => [
            ...existingpatients,
            cache.writeFragment({
              data: addedPatient,
              fragment: PatientFieldsFragment,
            }),
          ],
        },
      });
    },
  });
  const addPatient = async (patient: any) => {
    // @ts-ignore
    const { addedPatient }: FetchResult<{ addedPatient: any }> = await addPatientMutation({
      variables: {
        patient: {
          _id: Math.random().toString(36).substring(2, 10),
          created: new Date(),
          updated: new Date(),
          ...patient,
        },
      },
    });
    return addedPatient;
  };
  return addPatient;
}


const DeletePatientMutation = gql`
mutation DeletePatient($Id: String!) {
    deletedPatient: deleteOnePatient(query: { patientId: $Id }) {
      _id
      name
    }
  }
`;

const useDeletePatient = () => {
  const [deletePatientMutation] = useMutation(DeletePatientMutation);
  const deletePatient = async (patient: any) => {
    // @ts-ignore
    const { deletePatient } = await deletePatientMutation({
      variables: { Id: patient.patientId },
    });
    return deletePatient;
  };
  return deletePatient;
}
