import showMessage from "components/showMessage"

/**
 * sequence collectionに登録されたaccessionNoを抽出し、同時に1カウントアップする
 * @param db
 * @param length
 */
export const getSequenceNo = async (db?: Realm.Services.MongoDBDatabase | null, length?: Number | null) => {
  try {
    let seqNo:number
    const filter = { productId: 'accessionNo' }
    const update = { $inc: { accessionNo: length || 1 } }
    const result = await db?.collection("sequence").findOneAndUpdate(filter, update)
    seqNo = result.accessionNo
    return seqNo
  } catch (e) {
    if (e instanceof Error) await showMessage(`アクセッション番号の取得時にエラーが発生しました。\r\n${e.message}`, { error: true })
  }
  return
}
