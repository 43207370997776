import { useMongoDB } from "contexts/MongoDBContext";
import { useRealmApp } from "contexts/RealmApp";
import React, { ChangeEvent, MouseEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import showMessage from "./showMessage";
import { XMarkIcon } from "@heroicons/react/24/outline";

export interface FileChooserParams {
    title?: string;
    bookingID?: string,
    files?: FileItem[],
    callback?: (files: FileItem[], error: FileError[]) => void,
    saveButton?: boolean,
    max?: number,
    size?: number
}
interface FileData {
    id: string,
    name: string,
    type?: string,
    file?: File,
    updated?: Boolean
}
export interface FileError {
    id: string,
    name: string,
    message: string
}
export interface Files {
    id: string,
    bookingID?: string,
    name: string,
    type: string,
    data: string,
    updated: Date
}
export interface FileHandler {
    saveFiles(bookingIDprop?: string): Promise<FileItem[]>;
    deleteFiles(bookingIDprop?: string): Promise<void>;
}

/**
 * File chooser. Multiple files are allowed.
 * Use saveFiles function to save from parent component. Prop is optional bookingID.
 * title: Displaying label
 * bookingID: If set, used for bookingID field of saved file.  Provide this when saveButton is active.
 * files: Existing files as FileItem[]
 * callback: Callback function when saveFiles finished. FileItem[], FileError[] is given as prop
 * saveButton: If true, save button is activated
 * max: maximum file numbers.  Respect files property length if exceeds max
 * size: maximum file size as MB
 */
const FileChooser = forwardRef<FileHandler, FileChooserParams>(({ title = "診療情報提供", bookingID, files, callback, saveButton, max = 3, size = 4 }, ref) => {
    const app = useRealmApp()
    const { db } = useMongoDB()
    const [insideProcessing, setInsideProcessing] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<FileData[]>((files || []).map(v => ({ id: v.fileID, name: v.name })));
    const clickCount = useRef(0);
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => { setSelectedFiles((files || []).map(v => ({ id: v.fileID, name: v.name }))) }, [files])

    const handleClick = async (e: MouseEvent<HTMLButtonElement|SVGSVGElement>) => {
        const isDelete = e.currentTarget.tagName === 'svg'
        e.stopPropagation()
//        clickCount.current = e.detail;
        const index = Number(e.currentTarget.dataset.index)
        if (isDelete) {
            if (await showMessage("ファイルを削除します。よろしいですか？", { confirm: true })) {
                removeFile(index)
            }
        } else {
            showFile(index)
        }
    };

    // Function to handle file selection
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const addedFiles = e.target.files;
        const updatedSelectedFiles = [...selectedFiles];

        if (Array.from(addedFiles || []).some(v => v.size > size * 1024 * 1024)) {
            await showMessage(`サイズが${size}MB以上のファイルはアップロードできません`, { error: true })
            return
        }

        Array.from(addedFiles || []).forEach(v => {
            if (!updatedSelectedFiles.some(file => file.name === v.name)) {
                updatedSelectedFiles.push({ id: app.createId(), name: v.name, type: v.type, file: v, updated: true }); // Add unique files to the array
            }
        })
        setSelectedFiles(updatedSelectedFiles);
    };

    const showFile = async (index: number) => {
        const showingFile = selectedFiles[index]
        let blob: any = showingFile.file
        if (!showingFile.file) {
            let fileDoc
            let base64Data
            try {
                setInsideProcessing(true)
                fileDoc = await db?.collection("files").findOne({ "_id": showingFile.id })
                base64Data = fileDoc?.data
                setInsideProcessing(false)
            } catch (e) {
                setInsideProcessing(false)
                await showMessage("ファイル読み込みエラー", { error: true })
                return
            }
            if (!base64Data || !fileDoc.type) return

            const byteCharacters = atob(base64Data.split(",")[1]);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            blob = new Blob([byteArray], { type: fileDoc.type });
            //       blob = new Blob([byteArray], { type: 'application/octet-stream' });
        }
        if (!blob) return

        // Example Base64-encoded image and PDF data with MIME types
        //   const base64ImageData = 'iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAZElEQVR42mL8//8/AzUBEwM1g3DKxKcDIDFKxKcBIODMRYMEqwkYA3jICDUBAxygqgzMY8kMy3BaVAZgFgTAyMK3DCcxiMXQgHMQDMDYbWzARmgFyMDNS8LzA0M3IGMDAxLzAxswFyMDAxM/IMMDAwswJyDAswMHIzIQxrHBkYG8AADK8zj08JhnAAAAAElFTkSuQmCC';
        //   const base64PDFData = 'JVBERi0xLjQKJdP0zOEKMSAwIG9iago8PC9UeXBlIC9QYWdlCi9QYXJlbnQgMSAwIFIKL1Jlc291cmNlcyAyIDAgUgovQ29udGVudHMgNCAwIFI+PgplbmRvYmoKNCAwIG9iago8PC9GaWx0ZXIgL0ZsYXRlRGVjb2RlIC9MZW5ndGggMjEyCi9MZW5ndGggMjUyCi9TdWJ0eXBlIC9UeXBlMQovVHlwZSAvRm9udAovU3VidHlwZSAvSW1hZ2UKL01lZGlhQm94IFswIDAgNjEyLjAwMDAgNzgxLjAwMDBdCi9CaXRzUGVyQ29tcG9uZW50IDgKc3RyZWFtCnicnVU5MwFKwT2BgA3e6GxgbIAxJtIMXZJqIyEgWVQggJshFJaBYzE1BEIyAnlmL7eDp0ZZ7Tz7f+v5G3X3+dfZb3+l/z+2/+WuBc7ZtTn6cfed/nb97zf+hn/92/t9wVNT1zAAAMAWiU5lz9GgAAABddFJl+1w4n+6d+x9wVC8zQAAAGBXahqOryIAAAADiUJq6IhIAAAADmUJy+IiEgAAAAFnUmfoiIyEAAAAJdSZ+kCEyAAAAAp1Jm+gmEwAAAAE6VTx6b6YTAUAAAAXCUy2RvhMFAAAAeEJ9Sl72FAAAAOEh2RPbOQQEAAABwEdZ4t1FJb0MzEQwzAYAAABc7tRODPSATMAAAAt/BsD6NjW9EGsAfYAAAD/vVX6D6Kvzwfx2hDbADf+9Z7/Xno//dD8cf64fIP+t98o23/TkLf4gPz/zwd/9g/e+16+DD/b+x/+G3sC79v93/gL/v/Z2ATMP/aL6/A/D/aX8n/YH+Gf/rfzMDWd6/zV9+90G/ZD/ZD9z/g8N9gP9t/jw6/P4/S/evvYv4F/uv8X/0T/SX/s/vb9';

        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
        URL.revokeObjectURL(url);
    }

    // Function to remove a file from the selection
    const removeFile = (index: number) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1); // Remove file from array
        setSelectedFiles(updatedFiles);
    };

    const deleteFiles = async (bookingID: string) => {
        try {
            await db?.collection("files").deleteMany({ bookingID: bookingID })
        } catch (e) {
            await showMessage(`ファイル削除エラー。操作に支障はあありません\r\n` + (files || []).map(v => v.name).join(`\r\n`), { error: true })
        }
    }

    const handleSave = () => saveFiles()

    const saveFiles = async (bookingIDprop?: string) => {
        console.log("saveCalled")
        setInsideProcessing(true)
        const filesSuccess: FileItem[] = []
        const filesError: FileError[] = []
        const updatingFiles = selectedFiles.filter(v => v.file && v.updated)
        const filesUnchanged: FileItem[] = selectedFiles.filter(v => !v.file && !v.updated).map(v => ({ fileID: v.id, name: v.name }))
        for (const v of updatingFiles) {
            const reader = new FileReader()
            reader.readAsDataURL(v.file!);
            await new Promise<void>((resolve, reject) => {
                reader.onload = () => resolve()
                reader.onerror = () => reject()
            });
            if (reader.result) {
                let error = ""
                try {
                    let binary: any = reader.result;
                    const settingValue: KV = {
                        name: v.name,
                        type: v.type,
                        data: binary,
                        updated: new Date()
                    }
                    if (bookingID || bookingIDprop) settingValue.bookingID = String(bookingID || bookingIDprop)
                    await db?.collection("files").updateOne({ "_id": v.id }, { $set: settingValue }, { upsert: true })
                    filesSuccess.push({ fileID: v.id, name: v.name })
                } catch (e) {
                    error = e instanceof Error ? e.message : "Exception caught with no error object"
                    filesError.push({ id: v.id, name: v.name, message: error });
                }
            } else {
                const error = reader.error?.message || "File reading exception caught with no error object"
                filesError.push({ id: v.id, name: v.name, message: error });
            }
        }
        setInsideProcessing(false)
        if (filesError.length) await showMessage(`ファイルアップロードエラー\r\n` + filesError.map(v => `${v.name}:\r\n${v.message}`).join(`\r\n`), { error: true })
        else { // Deleting deleted files from DB
            const selectedIDs = selectedFiles.map(v => v.id)
            const deletingFiles = (files || []).filter(v => !selectedIDs.includes(v.fileID))
            if (deletingFiles.length) {
                try {
                    await db?.collection("files").deleteMany({ _id: { $in: deletingFiles.map(v => v.fileID) } })
                } catch (e) {
                    await showMessage(`旧ファイル削除エラー。操作に支障はあありません\r\n` + deletingFiles.map(v => v.name).join(`\r\n`), { error: true })
                }
            }
        }
        const updatedFiles = [...filesUnchanged, ...filesSuccess]
        callback && callback(updatedFiles, filesError)
        return updatedFiles
    }

    useImperativeHandle(ref, () => ({
        saveFiles,
        deleteFiles
    }))

    return <div className={"mb-2 flex flex-col gap-2" + (insideProcessing ? " pointer-events-none" : "")}>
        <div className="flex gap-2">
            <input type="file" value="" onChange={handleFileChange} style={{ display: 'none' }} ref={inputRef} />
            <span className="text-white">{title}</span>
            {(selectedFiles.length < Math.max(max, files?.length || 0)) && <button className="px-2 text-white bg-theme-600 hover:bg-theme-500 rounded" onClick={() => inputRef.current?.click()} disabled={insideProcessing}>追加</button>}
            {saveButton && <button className="px-2 text-white bg-theme-600 hover:bg-theme-500 rounded" onClick={handleSave} disabled={insideProcessing}>保存</button>}
        </div>
        <div className="flex gap-2 bg-white overflow-x-scroll">
            {insideProcessing ? <img className="w-6 h-6" src={process.env.PUBLIC_URL + "/images/Rolling-1s-96px.svg"} alt="Loading..." />
                : selectedFiles.map((file, index) => (
                    <button className="m-1 pl-2 flex items-center bg-theme-100" key={index} data-index={index} onClick={handleClick} disabled={insideProcessing}>
                        <span>{file.name}</span><div><XMarkIcon data-index={index} onClick={handleClick} className="w-4 h-4" /></div>
                    </button>
                ))}
        </div>
    </div>
})
export default FileChooser