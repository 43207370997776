import { ApolloError, DocumentNode, gql } from "@apollo/client/core";
import { useMutation } from "@apollo/client/react";

const getDockUpdateMutation = (): DocumentNode => gql`
  mutation UpdateDock($id: ObjectId!, $set: DockUpdateInput!) {
    updatedData: updateOneDock(query: { _id: $id }, set: $set) {
        _id
        book_id
        reception_date
        book_sub_id
        user_id
        patient_id
        birthdate
        patient_name_kana
        book_parent_id
        book_status
        course_name
        memo
        options {
          option_name
        }
        user_name
        update_status
        course_code
        patient_name
        ucc_book_token
        gender
        reception_time
        tel
        course_type_code
        course_type
        completed
        course_contents {
          modality
          modality_body1
          modality_body2
          modality_contrast
        }
    }
  }
`;

export const useUpdateDock = (completed?: () => void) => {
  const [updateDockMutation, { error }] = useMutation(getDockUpdateMutation(), { onCompleted: completed });
  /**
   * Function to update data
   * @param id _id of document to update
   * @param set New data to set
   * @returns Mutation variables {updateData, error}
   */
  const updateData = async (id: string | number, set: { [key: string]: any }): Promise<{ updatedData: { [key: string]: any }, error: ApolloError | undefined }> => {
    const { data } = await updateDockMutation({
      variables: { id: id, set: set },
    });
    const updatedData: { [key: string]: any } = data?.updatedData || {}
    return { updatedData: updatedData, error };
  };
  return updateData;
}
