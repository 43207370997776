import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useRef, useState } from "react"
import FullCalendar from "@fullcalendar/react"
import { DatesSetArg, DayHeaderContentArg, EventClickArg, EventContentArg } from "@fullcalendar/core"
import dayGridPlugin from "@fullcalendar/daygrid"
import scrollGridPlugin from "@fullcalendar/scrollgrid"
import jaLocale from '@fullcalendar/core/locales/ja'
import { getColor, InputClass } from "contexts/style"
import { useMongoDB } from "contexts/MongoDBContext"
import { getDateString } from "contexts/dateUtils"
import { Loading } from "components/Loading"
import { useFindVisits } from "contexts/useMongoQuery"
import { useReactiveVar } from "@apollo/client"
import { filterStates } from "graphql/RealmApolloProvider"
import { DOCK_TYPES, INTERNAL_TYPE } from "contexts/enviroments"
import VisitForm from "components/FormVisit"
import DailyNoticeForm from "components/DailyNoticeForm"
import useDailyNotices from "graphql/useDailyNotices"

const SearchForm = ({
    dateRange,
    handleDateChange,
}: {
    dateRange: { start: Date, end: Date | null };
    handleDateChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
    return <div className="h-24 sm:h-14 bg-theme-200 p-2">
        <div className="flex flex-wrap justify-center items-center">
            <label className="pl-4 pr-1">日付</label><input className={InputClass} type="date" name="date"
                value={getDateString(dateRange.start)}
                onChange={handleDateChange} />
            <div className="py-2">
                <label className={`ml-4 mr-1 px-2 py-1 text-white bg-${getColor(0)}`}>外来</label>
                <label className={`mx-1 px-2 py-1 text-white bg-${getColor(1)}`}>外注</label>
                <label className={`mx-1 px-2 py-1 text-white bg-${getColor(2)}`}>通常ドック</label>
                <label className={`mx-1 px-2 py-1 text-white bg-${getColor(6)}`}>CMC・VIP</label>
            </div>
        </div>
    </div>
}

const getTimes = (date: Date | null) => date ? `${String(date.getHours())}:${String(date.getMinutes()).padStart(2, '0')}` : ''

const EventContent = (eventContent: EventContentArg) => <div className={`fc-event-main-frame w-full cursor-pointer text-white bg-${getColor(eventContent.event.extendedProps.bookingType, !Boolean(eventContent.event.extendedProps.bookingStatus || true))}`}>
    <div className="fc-event-time">{getTimes(eventContent.event.start)} - {getTimes(eventContent.event.end)}　{eventContent.event.title}</div>
    <div className="fc-event-title-container">
        <div className="px-2">{DOCK_TYPES.includes(eventContent.event.extendedProps.bookingType) ? (eventContent.event.extendedProps.courseName || "") : ""}</div>
        <div className="px-2">{eventContent.event.extendedProps.patientName || ""}</div>
        <div className="px-2">{eventContent.event.extendedProps.bookingType === INTERNAL_TYPE ? (eventContent.event.extendedProps.clinicName || "") : ""}</div>
        {eventContent.event.extendedProps.ivCheckin && <div className="px-2">IV {eventContent.event.extendedProps.ivCheckin}</div>}
    </div>
</div>

const Schedule = () => {
    const { db } = useMongoDB()
    const values: KV = useReactiveVar(filterStates)
    const filter = values.bookschedule || {
        dateRange: { start: new Date(), end: null },
    }
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [dailyModalTimestamp, setDailyModalTimestamp] = useState(0)
    const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null | undefined>()
    const [sortedEvents, setSortedEvents] = useState<CalendarEvent[]>([])
    const dateRange = filter.dateRange
    // const dates = Array<number>(8).fill(dateRange.start.getTime()).map((v, i) => v + 86400000 * i)
    const { loading, data: events, refetch: refetchFull } = useFindVisits(db, dateRange)
    const refetch = useCallback(async () => {
        await refetchFull(db, dateRange);
        if (selectedEvent) setSelectedEvent(events?.find(e => e.id === selectedEvent.id))
    }, [refetchFull, db, dateRange])

    const { loading: dailyNoticeLoading, data: dailyNoticeData, error: dailyNoticeError, updateData: updateDailyNotice } = useDailyNotices({ start: dateRange.start, end: dateRange.end })

    // events?.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
    React.useEffect(() => {
        if (events) {
            const sorted = [...events].sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
            setSortedEvents(sorted);
        }
    }, [events]);

    // const getDateIndex = (unixtime: number) => dates.findIndex((v, i) => unixtime >= v && unixtime < dates[i+1])
    // const getTimeFromTimeString = (time:string, index:number) => {
    //     const parts = time.split(':')
    //     return dates[index] + Number(parts[0]) * 3600000 + Number(parts[1]) * 60000
    // }
    // const summarizedEvents = events?.filter(event => event.extendedProps.bookingType !== 4).reduce<CalendarEvent[]>((summarized, event) => {
    //     const dateIndex = getDateIndex(event.start.getTime())
    //     let datum = summarized.find(v => v.extendedProps.patientID === event.extendedProps.patientID && getDateIndex(v.start.getTime()) === dateIndex)
    //     if (!datum) { 
    //         summarized.push({
    //             ...event,
    //             start: (event.extendedProps.checkin &&  event.start.getTime() > getTimeFromTimeString(event.extendedProps.checkin, dateIndex)) ? new Date(getTimeFromTimeString(event.extendedProps.checkin, dateIndex)) : event.start,
    //             extendedProps: {
    //                 ...event.extendedProps,
    //                 bookingGroup: [event.id]
    //             }
    //         })
    //         return summarized
    //     } else if (datum.extendedProps.bookingGroup) {
    //         datum.extendedProps.bookingGroup.push(event.id)
    //     }
    //     if (event.start.getTime() < datum.start.getTime()) datum.start = event.start
    //     if (event.extendedProps.checkin &&  datum.start.getTime() > getTimeFromTimeString(event.extendedProps.checkin, dateIndex)) datum.start = new Date(getTimeFromTimeString(event.extendedProps.checkin, dateIndex))
    //     if (event.end.getTime() > datum.end.getTime()) datum.end = event.end
    //     if (!datum.extendedProps.ivCheckin && event.extendedProps.ivCheckin) datum.extendedProps.ivCheckin = event.extendedProps.ivCheckin;
    //     // Aggregate comment and file.  If not exist, add processing event's data
    //     if (!datum.extendedProps.comment && event.extendedProps.comment) datum.extendedProps.comment = event.extendedProps.comment;
    //     (event.extendedProps.files || []).forEach((v, i) => {
    //         if (!datum) return // dummy to avoid datum may be undefined warning
    //         if (!datum.extendedProps.files) datum.extendedProps.files = [v] 
    //         else if (!datum.extendedProps.files.find(file => file.fileID === v.fileID)) datum.extendedProps.files.push(v)
    //     })
    //     return summarized
    // }, [])

    const calendarRef = useRef<FullCalendar | null>(null)

    const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const calendar = calendarRef.current?.getApi()
        calendar?.gotoDate(e.currentTarget.value)
    }
    // get fullcalendar dates and set dateRange state
    const handleDatesSet = (arg: DatesSetArg) => {
        let startDate = new Date(arg.start)
        startDate.setHours(0)
        let endDate = new Date(arg.end)
        endDate.setHours(0)
        filterStates({
            ...filterStates,
            bookschedule: {
                ...filter,
                dateRange: { start: startDate, end: endDate },
            }
        })
    }

    const handleEventClick = async (e: EventClickArg) => {
        e.el.blur()
        setIsModalOpen(true)
        setSelectedEvent(events?.find(v => v.id === e.event.id))
    }

    useEffect(() => { if (!isModalOpen) setSelectedEvent(undefined) }, [isModalOpen])

    const handleDailyNoticeClick = (e: MouseEvent<HTMLDivElement>) => {
        const timestamp = e.currentTarget.dataset.timestamp
        setDailyModalTimestamp(Number(timestamp))
    }

    const DayHeader = (arg: DayHeaderContentArg) => {
        const timestamp = new Date(arg.date).getTime()
        return <div className="cursor-pointer" data-timestamp={timestamp} onClick={handleDailyNoticeClick}>{arg.text}<div className="px-1 bg-theme-200 max-w-xs">{dailyNoticeData?.find(data => data._id === timestamp)?.notice || ''}</div></div>
    }

    return <>
        {(loading || dailyNoticeLoading) && <Loading full />}
        <SearchForm
            dateRange={dateRange}
            handleDateChange={handleDateChange}
        />
        <DailyNoticeForm timestamp={dailyModalTimestamp} setTimestamp={setDailyModalTimestamp} data={dailyNoticeData} update={updateDailyNotice} />
        <FullCalendar
            ref={calendarRef}
            locale={jaLocale}
            plugins={[scrollGridPlugin, dayGridPlugin]}
            initialView="dayGridWeek"
            headerToolbar={{
                start: 'prev,next today',
                center: 'title',
                right: ''
            }}
            eventColor="lightgray"
            dayHeaderContent={DayHeader}
            initialDate={dateRange.start}
            dayMinWidth={144}
            datesSet={handleDatesSet}
            events={sortedEvents || undefined}
            eventContent={EventContent}
            eventClick={handleEventClick}
            eventOrder="start,end"
            eventOrderStrict={true}
        />
        <VisitForm data={selectedEvent} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} refetch={refetch} />
    </>
}

export default Schedule
