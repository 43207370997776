import {useEffect, useMemo, useRef, useState} from "react"
import showMessage from "../components/showMessage";
import { useReactiveVar } from "@apollo/client";
import { filterStates } from "./RealmApolloProvider";

let mongoCollectionWatch: AsyncGenerator<Realm.Services.MongoDB.ChangeEvent<any>> | null = null

const translateEvent = (v: KV, title: string = "公開枠"): CalendarOpeningEvent => {
  return ({
    id: v._id, title: title, week: v.weekday, start: v.start, end: v.end, resourceId: v.modality || v.modalityID, extendedProps: {
      id: v._id, modality: v.modality, start: v.start, end: v.end, week: v.weekday, close: v.close, doctor: v.doctor, doctorID: v.doctorID
    }
  })
}

export const useFindOpeningTimes = (db?: Realm.Services.MongoDBDatabase | null, isModalOpen?: boolean, load?: boolean) => {
  const [result, setResult] = useState<{ data?: CalendarOpeningEvent[] | null, loading?: boolean, error?: Error | null, refetch?: () => Promise<void> }>({
    data: null,
    loading: false,
    error: null,
  })
  const eventsReference = useRef<CalendarOpeningEvent[] | null | undefined>([])
  eventsReference.current = result.data


  const updateEvent = (change: Realm.Services.MongoDB.ChangeEvent<any>) => {
    switch (change.operationType) {
      case "update":
      case "replace": {
        const { documentKey, fullDocument } = change;
        const index = (eventsReference.current || []).findIndex(v => v.id === documentKey._id)
        if (index > -1) {
          if (fullDocument.canceledAt) setResult({ loading: false, data: eventsReference.current?.filter((v, i) => i !== index), error: null })
          else setResult({ loading: false, data: eventsReference.current?.map((v, i) => i === index ? translateEvent(fullDocument) : v), error: null })
        } else { setResult({ loading: false, data: [...(eventsReference.current || []), translateEvent(fullDocument)], error: null }) }
        break;
      }
      case "delete": {
        const { documentKey } = change;
        const index = (eventsReference.current || []).findIndex(v => v.id === documentKey._id)
        if (index > -1) { setResult({ loading: false, data: eventsReference.current?.filter((v, i) => i !== index), error: null }) }
        break;
      }
      default:
    }
  }


  useEffect(() => {
    async function fetchData() {
      if (!db) {
        setResult({
          loading: false,
          data: null,
          error: null,
        });
        return
      }
      setResult({
        loading: true,
        data: null,
        error: null,
      });
      try {
        const data: KV = await db.collection("opening_times").find({ weekday: { $in: [1,2,3,4,5,6,7] } })
        setResult({
          data: data.map((v: KV) => translateEvent(v)),
          loading: false,
          error: null,
          refetch: fetchData
        });
      } catch (err) {
        setResult({
          data: null,
          loading: false,
          error: (err instanceof Error) ? err : null,
        });
      }

      try {
        if (mongoCollectionWatch) {
          mongoCollectionWatch.return(null)
          mongoCollectionWatch = null
        }
        // mongoCollectionWatch = db.collection("opening_times").watch({ filter: { $or: [{ operationType: 'delete' }, { weekday: { $in: [1,2,3,4,5,6,7] } }] } });
        // try {
        //   for await (const change of mongoCollectionWatch) {
        //     try {
        //       updateEvent(change)
        //     } catch (err) {
        //       if (err instanceof Error) await showMessage(`予約データ読み込み中にエラーが発生しました。\r\n ${(err as Error).message}`, { error: true })
        //       console.log(`Error updating changed booking data.  ${(err as Error)?.message}`)
        //     }
        //   }
        // } catch (err) {
        //   //error happens on reload.  ignore
        // }
      } catch (err) {
        if (err instanceof Error) await showMessage(`予約データのウオッチ設定中にエラーが発生しました。\r\n ${(err as Error).message}`, { error: true })
      }

    }
    fetchData();
    return () => {
      try {
        if (mongoCollectionWatch) {
          mongoCollectionWatch.return(null)
          mongoCollectionWatch = null
        }
      } catch (err) {
        alert(`Error removing watching stream.  ${(err as Error).message}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, isModalOpen, load]) // initially db is null
  return result
}


export const useFindOpeningDailyTimes = (db?: Realm.Services.MongoDBDatabase | null, isModalOpen?: boolean, load?: boolean) => {
  const values: KV = useReactiveVar(filterStates)
  const dateRange = useMemo(() => values?.externalDaily || { start: new Date(), end: null }, [values])

  const [result, setResult] = useState<{ data?: CalendarOpeningEvent[] | null, loading?: boolean, error?: Error | null, refetch?: () => Promise<void> }>({
    data: null,
    loading: false,
    error: null,
  })
  const eventsReference = useRef<CalendarOpeningEvent[] | null | undefined>([])
  eventsReference.current = result.data


  const updateEvent = (change: Realm.Services.MongoDB.ChangeEvent<any>) => {
    switch (change.operationType) {
      case "update":
      case "replace": {
        const { documentKey, fullDocument } = change;
        const index = (eventsReference.current || []).findIndex(v => v.id === documentKey._id)
        if (index > -1) {
          if (fullDocument.canceledAt) setResult({ loading: false, data: eventsReference.current?.filter((v, i) => i !== index), error: null })
          else setResult({ loading: false, data: eventsReference.current?.map((v, i) => i === index ? translateEvent(fullDocument) : v), error: null })
        } else { setResult({ loading: false, data: [...(eventsReference.current || []), translateEvent(fullDocument)], error: null }) }
        break;
      }
      case "delete": {
        const { documentKey } = change;
        const index = (eventsReference.current || []).findIndex(v => v.id === documentKey._id)
        if (index > -1) { setResult({ loading: false, data: eventsReference.current?.filter((v, i) => i !== index), error: null }) }
        break;
      }
      default:
    }
  }


  useEffect(() => {
    async function fetchData() {
      if (!db) {
        setResult({
          loading: false,
          data: null,
          error: null,
        });
        return
      }
      setResult({
        loading: true,
        data: null,
        error: null,
      });
      try {
        const data: KV = await db.collection("opening_times").find({ weekday: { $in: [99] }, start: { $gte: dateRange.start, $lt: dateRange.end } })
        const data2: KV = await db.collection("bookings").find({ modalityID: { $in: ['MRI', 'PET-CT_1', 'PET-CT_2']}, bookingStatus: { $ne: 5 }, start: { $gte: dateRange.start, $lt: dateRange.end } })
        setResult({
          data: data.map((v: KV) => translateEvent(v)).concat(data2.map((v: KV) => ({ ...translateEvent(v, '予約済'),  display: "background" }))),
          loading: false,
          error: null,
          refetch: fetchData
        });
      } catch (err) {
        setResult({
          data: null,
          loading: false,
          error: (err instanceof Error) ? err : null,
        });
      }

      try {
        if (mongoCollectionWatch) {
          mongoCollectionWatch.return(null)
          mongoCollectionWatch = null
        }
        // mongoCollectionWatch = db.collection("opening_times").watch({ filter: { $or: [{ operationType: 'delete' }, { weekday: { $in: [99] } }] } });
        // try {
        //   for await (const change of mongoCollectionWatch) {
        //     try {
        //       updateEvent(change)
        //     } catch (err) {
        //       if (err instanceof Error) await showMessage(`予約データ読み込み中にエラーが発生しました。\r\n ${(err as Error).message}`, { error: true })
        //       console.log(`Error updating changed booking data.  ${(err as Error)?.message}`)
        //     }
        //   }
        // } catch (err) {
        //   //error happens on reload.  ignore
        // }
      } catch (err) {
        if (err instanceof Error) await showMessage(`予約データのウオッチ設定中にエラーが発生しました。\r\n ${(err as Error).message}`, { error: true })
      }

    }
    fetchData();
    return () => {
      try {
        if (mongoCollectionWatch) {
          mongoCollectionWatch.return(null)
          mongoCollectionWatch = null
        }
      } catch (err) {
        alert(`Error removing watching stream.  ${(err as Error).message}`)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, isModalOpen, load, dateRange]) // initially db is null
  return result
}