import React, {ChangeEvent, useEffect, useRef, useState} from 'react'
import jaLocale from "@fullcalendar/core/locales/ja"
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid"
import scrollGridPlugin from "@fullcalendar/scrollgrid"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import FullCalendar from "@fullcalendar/react"
import { DateSelectArg, EventClickArg, EventDropArg } from "@fullcalendar/core"
import { OpeningForm } from "components/OpeningForm"
import { filterStates } from "graphql/RealmApolloProvider"
import { useReactiveVar } from "@apollo/client"
import { useFindOpeningTimes } from "graphql/useFindOpeningTimes"
import { useMongoDB } from "contexts/MongoDBContext"
import OpeningEventContent from "components/OpeningEventContent"
import { Loading } from "components/Loading"
import showMessage from "components/showMessage"
import { useUpdateOpeningTime } from "graphql/useOpeningTimesMutations"
import { PurpleButtonClass } from "contexts/style"
import { Link } from 'react-router-dom'

const getTimeUnit = (value: string) => `${value === "60" ? "01:00" : "00:" + ("0" + value).slice(-2)}:00`
const baseDate = '2023-07-17' // 月曜から取得したいので、この日を基準とする

export const ExternalOpening = () => {
  const { db } = useMongoDB()
  const calendarRef = useRef<FullCalendar | null>(null)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [start, setStart] = useState<Date | null>(null)
  const [end, setEnd] = useState<Date | null>(null)
  const [modality, setModality] = useState<string | null>(null)
  const [id, setId] = useState<string | null>(null)
  const [updated, setUpdated] = useState<boolean>(false)
  const [load, setLoad] = useState<boolean>(false)
  const { loading, data:events, refetch } = useFindOpeningTimes(db, isModalOpen, load)
  const updateOpeningMutation = useUpdateOpeningTime()

  const baseResources = [
    { id: 'PET-CT_1', title: 'PET-CT_1' },
    { id: 'PET-CT_2', title: 'PET-CT_2' },
    { id: 'MRI', title: 'MRI' },
  ]
  const handleEventClick = async (e: EventClickArg) => {
    setIsModalOpen(true)
    setStart(e.event.extendedProps.start)
    setEnd(e.event.extendedProps.end)
    setModality(e.event.extendedProps.modality)
    setUpdated(true)
    setId(e.event.extendedProps.id)
  }
  const handleModalSelect = async (selectionInfo: DateSelectArg) => {
    const timeDifference = selectionInfo.end.getTime() - selectionInfo.start.getTime() // ミリ秒単位の時間差を計算
    setIsModalOpen(true)
    setStart(selectionInfo.start)
    setEnd(selectionInfo.end)
    setModality(selectionInfo.resource?._resource.id ?? null)
  }

  const values: KV = useReactiveVar(filterStates)
  const filter = values.calendar || { dateRange: { start: new Date(), end: null }, timeUnit: "5", resources: undefined, monthView: false}

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let resources: { [key: string]: string }[] = []
    baseResources.forEach(resource => {
      if ((document.getElementById(resource.id || '') as HTMLInputElement).checked) resources.push(resource)
    })
    filterStates({...filterStates(), calendar: {...filter, resources: resources }})
  }

  const handleDrop = async (eventDrop:EventDropArg) => {
    if (!await showMessage(`公開枠の時刻を移動しますか？`, {confirm:true})) {
      setLoad(true)
      return
    }
    // 公開枠を更新する
    const milliseconds = eventDrop.delta.milliseconds
    const target_id = eventDrop.event.extendedProps.id
    const opening_time: KV = {
      _id: target_id,
      modality: eventDrop.event.extendedProps.modality,
      weekday: eventDrop.event.extendedProps.week,
      start: new Date(eventDrop.event.extendedProps.start.getTime() + milliseconds),
      end: new Date(eventDrop.event.extendedProps.end.getTime() + milliseconds),
    }
    await updateOpeningMutation(target_id, opening_time)
  }

  // loadがONになったら、元に戻す
  useEffect(() => {
    setLoad(false)
  },[load])

  const SearchForm = ({ resources, handleChange, baseResources }: {
    resources: KV[];
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    baseResources: { [key: string]: string }[]
  }) => {
    return <div className="h-24 bg-theme-200 p-2">
      <div className="flex justify-center items-center">
        <div>
          <span className="text-green-800 font-bold text-2xl">【週次】公開枠設定</span><br />
          外部予約可能モダリティはMRI, PET-CT(1号機), PET-CT(2号機)です。

        </div>
        <Link to={'/external/daily'} className={PurpleButtonClass} >日別公開枠設定はこちら</Link>
      </div>
    </div>
  }

  return <>
    {loading ? <Loading full /> : <></>}
    <OpeningForm
        id={id || null}
        updated={updated}
        setUpdated={setUpdated}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        start={start}
        end={end}
        setEnd={setEnd}
        modality={modality}
        refetch={refetch}
    />
    <SearchForm
        resources={baseResources}
        handleChange={handleChange} baseResources={baseResources}
    />
    {isModalOpen ? <div/> :

        <FullCalendar
            ref={calendarRef}
            locale={jaLocale}
            plugins={[resourceTimeGridPlugin, scrollGridPlugin, dayGridPlugin, interactionPlugin]}
            views={{
              resourceTimeGridSevenDay: {
                type: 'resourceTimeGrid',
                duration: {days: 6},
                buttonText: '週'
              }
            }}
            dayHeaderFormat={{
              weekday: 'short'
            }}
            headerToolbar={{
              start: '',
              center: '',
              end: ''
            }}
            editable={true}
            eventDurationEditable={false}
            eventResourceEditable={true}
            eventOverlap={false}
            eventDrop={handleDrop}
            eventColor="lightgray"
            selectable={true}
            allDaySlot={false}
            dayMinWidth={96}
            slotMinTime="09:00:00"
            slotMaxTime="18:00:00"
            initialDate={new Date(baseDate)} // 月曜から土曜を表示させる
            initialView={"resourceTimeGridSevenDay"}
            slotDuration={getTimeUnit("5")}
            slotLabelFormat={(arg) => arg.date.minute ? String(arg.date.minute) : (String(arg.date.hour) + ":00")}//.toTimeString() : "0000"}
            slotLabelInterval={getTimeUnit("5")}
            datesAboveResources
            resources={baseResources}
            select={handleModalSelect}
            eventClick={handleEventClick}
            events={events ? events : undefined}
            eventContent={OpeningEventContent}
        />
    }
  </>
}

export default ExternalOpening