import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react"
import { ArrowPathIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import { Loading } from "components/Loading"
import { DatePicker } from "components/UIParts"
import { JSTMonthDate, getMinutesFromMidnight, nextDate, today, getDateAfterDays } from "contexts/dateUtils"
import { CheckClass, IconClass, InputClass, SmallButtonClass } from "contexts/style"
import { gql, useQuery } from "@apollo/client"
import { useRealmApp } from "contexts/RealmApp"
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline"
import { ExternalEntryForm } from "components/ExternalEntryForm"

const openingDataQuery = gql`query GetOpeningTimes($query: Opening_timeQueryInput!) {
    opening_times(query: $query, sortBy: START_ASC) {
        _id
        start
        end
        modality
        weekday
        close
    }
  }
`
const bookingsDataQuery = gql`query GetBookingsShort($query: BookingQueryInput!) {
    bookings(query: $query, sortBy: START_ASC) {
      _id
      bookingStatus
      ivCheckin
      start
      end
      modalityID
      bookingType
    }
  }
  `
interface OpeningTimes {
    _id: string;
    start: string;
    end: string;
    modality: string;
    weekday: number;
    close: boolean | null;
}

interface Booking {
    modalityID: string;
    ivCheckin?: number | null;
    weekday: number;
    startTime: number;
    endTime: number;
}
const SLOT_ROWS = 45
const SLOT_DUR = 10
const SLOT_START_MINUTES = 540
const MODALITY_ROWS = 90
const MODALITY_DUR = 5
const MODALITY_START_MINUTES = 540
const IV_DUR = 10
const RECEPTION_DUR = 20
/** Allowed inerval time between two bookings */
const INTERVAL = { min: 0, max: 30 }
/** Minimum starting time of PET in minutes after IV(not IV start time base) */
const IV_START_MIN = { 頭: 30, 全身: 60 }
const IV_END_MAX = { 頭: 60, 全身: 120 }
const INTERVAL_ARRAY = Array((INTERVAL.max - INTERVAL.min) / MODALITY_DUR).fill(0).map((_, i) => i + (INTERVAL.min / MODALITY_DUR))
const MODALITIES = ["MRI", "PET-CT_1", "PET-CT_2"]

// Slot minutes from midnight
const slotMinutes = Array(SLOT_ROWS).fill(0).map((_, i) => SLOT_START_MINUTES + i * SLOT_DUR)
// Modality slot minutes from midnight
const modalityMinutes = Array(MODALITY_ROWS).fill(0).map((_, i) => MODALITY_START_MINUTES + i * MODALITY_DUR)
// Slot time label of H:MM
const slotLabels = slotMinutes.map(v => `${Math.floor(v / 60)}:${("0" + String(v % 60)).slice(-2)}`)
const modalityDaySlots = () => Array(MODALITY_ROWS).fill(null).map((_) => [...MODALITIES, "IV"].reduce<KV>((a, r) => ({ ...a, [r]: r === "IV" ? true : null }), {}))
// Modality slot availability for each modality and IV. Key-value object of nested array of days and times([day][time]).  true if available, null if untouched
const modalitySlots = Array(7).fill(0).map(() => modalityDaySlots())

/**
 * Make starting time index array of PET based on IV starting timeIndex(=0) considering maximum ending time 
 * @param duraiton duration of modality usage as minutes
 * @param body body1
 * @param fix If true, fix to minimum starting time
 * @returns 
 */
const petStartIndexArray = (duration: number, body: string = "全身", fix?:boolean) => {
    const minIndex = (IV_DUR + IV_START_MIN[body === "頭" ? "頭" : "全身"]) / MODALITY_DUR
    const maxIndex = (IV_DUR + IV_END_MAX[body === "頭" ? "頭" : "全身"]) / MODALITY_DUR - duration / MODALITY_DUR
    return Array(fix ? 1 : (maxIndex - minIndex + 1)).fill(0).map((_, i) => i + minIndex)
}

/**
 * Set false to modalitySlots if booked(including IV). "modalitySlots" is nested slot arraty.
 * @param booking One booking data
 * @param weekdays weekday(1-7) array based on the beginning date in displaying order(ex. [3,4,5,6,7,1,2]) 
 */
const fillSlotFromBooking = (booking: Booking, weekdays: number[]) => {
    const slotCount = Math.round((booking.endTime - booking.startTime) / MODALITY_DUR)
    const indexDay = weekdays.indexOf(booking.weekday)
    const indexTime = (booking.startTime - MODALITY_START_MINUTES) / MODALITY_DUR
    Array(slotCount).fill(0).map((_, i) => i).forEach(i => {
        if (indexTime + i > -1 && indexTime + i < modalityMinutes.length) modalitySlots[indexDay][indexTime + i][booking.modalityID] = false
    })
    if (booking.ivCheckin) {
        const ivIndexTime = (booking.ivCheckin - MODALITY_START_MINUTES) / MODALITY_DUR;
        [0, 1].forEach(i => {
            if (ivIndexTime + i > -1 && ivIndexTime + i < modalityMinutes.length) modalitySlots[indexDay][ivIndexTime + i].IV = false
        })
    }
}

/**
 * Set true to modalitySlots if open and false if close specifically. "modalitySlots" is nested slot arraty.
 * @param datum One opening time data
 * @param weekdays weekday(1-7) array based on the beginning date in displaying order(ex. [3,4,5,6,7,1,2]) 
 * @param daily True if processing daily setting
 * @param close True if processing close setting
 */
const fillSlotFromOpening = (datum: OpeningTimes, weekdays: number[], daily?:boolean, close?:boolean) => {// opening times are based on actual usage time
    if ((daily && datum.weekday < 8) || (!daily && datum.weekday > 7)) return
    if ((close && !datum.close) || (!close && datum.close)) return
    const start = new Date(datum.start)
    const [startTime, endTime] = [getMinutesFromMidnight(start), getMinutesFromMidnight(new Date(datum.end))]
    const slotCount = Math.round((endTime - startTime) / MODALITY_DUR)
    const weekday = (datum.weekday < 8 ? datum.weekday : start.getDay()) || 7
    const indexDay = weekdays.indexOf(weekday)
    const indexTime = (startTime - MODALITY_START_MINUTES) / MODALITY_DUR
    if (indexDay !== -1) {
        Array(slotCount).fill(0).map((_, i) => i).forEach(i => {
            if (datum.close) {
                if (indexTime + i > -1 && indexTime + i < modalityMinutes.length) modalitySlots[indexDay][indexTime + i][datum.modality] = false
            }
            if (indexTime + i > -1 && indexTime + i < modalityMinutes.length && modalitySlots[indexDay][indexTime + i][datum.modality] === null) modalitySlots[indexDay][indexTime + i][datum.modality] = true
        })
    }
}




const Schedule = () => {
    const app = useRealmApp()

    const [date, setDate] = useState(getDateAfterDays(2))
    const [modality, setModality] = useState([{ ID: "PET-CT_1", body1: "PET-CT_全身", body2: "全身", contrast: "単純", delay: false }])
    /** Duration for modalities */
    const [duration, setDuration] = React.useState<number[]>([0])
    const [clickTime, setClickTime] = React.useState<string>("")
    const [clickIndex, setClickIndex] = React.useState<number>(0)
    const [clickDate, setClickDate] = React.useState<Date>(new Date())
    // Slot availability nested array of days and times([day][time])
    const [slots, setSlots] = useState(Array(7).fill(0).map<(boolean | null)[]>(() => Array(SLOT_ROWS).fill(null)))

    const { loading, data: openingRawData, refetch: refetchOpeningData } = useQuery(openingDataQuery, { variables: { query: { OR: [{ weekday_lt: 8 }, { start_gt: date, start_lt: nextDate(date, 7) }] } } })
    const { loading: bookingLoading, data: bookingRawData, refetch: refetchBookingData } = useQuery(bookingsDataQuery, { variables: { query: { start_gt: date, start_lt: nextDate(date, 7), modalityID_in: MODALITIES, bookingStatus_ne: 5 } } })
    // vCheckin is calculated to minutes from midnight
    const bookingData: Booking[] = (bookingRawData?.bookings || []).map((booking: KV): Booking => ({ modalityID: booking.modalityID, ivCheckin: booking.ivCheckin?.split(":").reduce((a: number, r: string, i: number) => a + Number(r) * (i ? 1 : 60), 0), weekday: new Date(booking.start).getDay() || 7, startTime: getMinutesFromMidnight(new Date(booking.start)), endTime: getMinutesFromMidnight(new Date(booking.end)) })) || []
    // weekday(1-7) array based on the beginning date in displaying order(ex. [3,4,5,6,7,1,2]) 
    const weekdays = Array(7).fill(date.getDay() || 7).map<number>((v, i) => v + i > 7 ? v + i - 7 : v + i)

    // For Modal Option
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

    /**
     * Check whether slot is available
     * @param duration modality duration as minutes
     * @param indexDay day index(0-6)
     * @param indexTime modality slot time index
     * @param modality ["MRI", "PET-CT_1", "IV-PET", "PET", "CT", "IV"]
     * @returns true if available
     */
    const checkAvailability = (duration: number, indexDay: number, indexTime: number, modality: string, body?: string): boolean => {
        // If MRI, Mon booking is until Fri.  If PET, Mon booking is until Thu, Tue booking is until Fri
        const days = indexDay - (modality === "MRI" ? 0 : 1) - ((modality === "MRI" ? [1] : [1,2]).includes(weekdays[indexDay]) ? 2 : 0)
        if (new Date(date.getTime() + 86400000 * days).getTime() < new Date().getTime() || [0,6,7].includes(weekdays[indexDay])) return false
        /**
         * Check whether actual specific modality is available or not
         * @param modalityID Actual modalityID or IV
         * @returns true if available
         */
        const checkOneModalityAvailability = (modalityID: string) => Array(duration / MODALITY_DUR).fill(0).every((_, i) => {
            if (indexTime + i < 0 || indexTime + i > modalityMinutes.length - 1) return false
            return Boolean(modalitySlots[indexDay][indexTime + i][modalityID])
        })
        let modalityCode = modality
        if (modality === "PET-CT_1") {
            if (body?.startsWith("CT")) modalityCode = "CT"
            else modalityCode = "IV-PET"
        }
        switch (modalityCode) {
            case "IV-PET": // Check both IV schedule and PET(1,2) schedule
                // First, check IV's availability starting at indexTime
                const isIVAvailable = checkAvailability(IV_DUR, indexDay, indexTime, "IV")
                if (!isIVAvailable) return false
                // Second, check PET availability after change.
                return petStartIndexArray(duration, body, true).some(petIndex => checkAvailability(duration, indexDay, indexTime + petIndex, "PET"))
            case "PET":
            case "CT":
                return checkOneModalityAvailability("PET-CT_1") || checkOneModalityAvailability("PET-CT_2") // If either PET_CT 1 or 2 is available, return true 
            default: return checkOneModalityAvailability(modality)
        }
    }

    /**
     * Calc availability of every slot.  Called when duration changed.
     */
    const recalcAvailability = () => {
        const newSlots = Array(7).fill(0).map((_, indexDay) => Array(SLOT_ROWS).fill(null).map((_, indexSlot) => {
            const indexTime = (slotMinutes[indexSlot] + RECEPTION_DUR - MODALITY_START_MINUTES) / MODALITY_DUR
            const modality0Duration = duration[0] / MODALITY_DUR
            const availability0 = checkAvailability(duration[0], indexDay, indexTime, modality[0].ID, modality[0].body1)
            if (!availability0 || modality.length === 1) return availability0
            const availability1 = INTERVAL_ARRAY.some(intervalIndex => checkAvailability(duration[1], indexDay, indexTime + modality0Duration + intervalIndex, modality[1].ID, modality[1].body1))
            if (availability1) return availability1
            const availability0Revderse = checkAvailability(duration[1], indexDay, indexTime, modality[1].ID, modality[1].body1)
            if (!availability0Revderse) return availability0Revderse
            const modality1Duration = duration[1] / MODALITY_DUR
            return INTERVAL_ARRAY.some(intervalIndex => checkAvailability(duration[0], indexDay, indexTime + modality1Duration + intervalIndex, modality[0].ID, modality[0].body1))
        })
        )
        setSlots(newSlots)
    }

    const updateModality = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const keys = e.currentTarget.name.split("_")
        const value = e.currentTarget.value
        const checked = keys[1] === "delay" ? Boolean((e.currentTarget as HTMLInputElement).checked) : false
        const index = Number(keys[2])
        switch (keys[1]) {
            case "ID": setModality(prev => prev.map((item, i) => i === index ? { ID: value, body1: "", body2: "", contrast: "", delay: false } : item)); break;
            case "body1": setModality(prev => prev.map((item, i) => i === index ? { ...item, body1: value, body2: "", contrast: "", delay: false } : item)); break;
            case "body2": setModality(prev => prev.map((item, i) => i === index ? { ...item, body2: value, contrast: "", delay: false } : item)); break;
            case "contrast": setModality(prev => prev.map((item, i) => i === index ? { ...item, contrast: value, delay: false } : item)); break;
            case "delay": setModality(prev => prev.map((item, i) => i === index ? { ...item, delay: checked } : item)); break;
        }
        if (keys[1] === "ID" && index === 0 && modality.length > 1 && value !== "MRI") {
            setDuration([0])
            setModality([{ ID: value, body1: "", body2: "", contrast: "", delay: false }])
        }
    }

    const selectResourceInformation = modality.map(item => app.resources.find(resource => String(resource['id']) === item.ID) || {})

    const Modality = ({ index = 0 }: { index?: number }) => <span>
        <select id={"modality_ID_" + index} name={"modality_ID_" + index} value={modality[index].ID} onChange={updateModality} className={InputClass}>
            {!index && <option value="MRI">MRI</option>}
            {index && <option value="">未選択</option>}
            <option value="PET-CT_1">PET-CT</option>
        </select>
    </span>



    const ModalityBody1 = ({ index = 0 }: { index?: number }) => <span className="ml-3">
        <select id={"modality_body1_" + index} name={"modality_body1_" + index} value={modality[index].body1} className={InputClass} onChange={updateModality}>
            <option key="0" value=""> - 部位1 - </option>
            {Object.keys(selectResourceInformation[index]).length &&
                Object.keys(selectResourceInformation[index].body).map((key) => <option key={key} value={key}>{key}</option>)
            }
        </select>
    </span>

    const ModalityBody2 = ({ index = 0 }: { index?: number }) => <span className="ml-3">
        <select id={"modality_body2_" + index} name={"modality_body2_" + index} value={modality[index].body2} className={InputClass} onChange={updateModality}>
            <option key="0" value=""> - 部位2 - </option>
            {(Object.keys(selectResourceInformation[index]).length && modality[index].body1 in selectResourceInformation[index].body) &&
                Object.keys(selectResourceInformation[index].body[modality[index].body1]).map((key) => <option key={key} value={key}>{key}</option>)
            }
        </select>
    </span>

    const Contrast = ({ index = 0 }: { index?: number }) => <span className="ml-3">
        <select id={"modality_contrast_" + index} name={"modality_contrast_" + index} value={modality[index].contrast} className={InputClass} onChange={updateModality}>
            <option key="0" value=""> - 造影剤指示 -  </option>
            {(Object.keys(selectResourceInformation[index]).length && modality[index].body1 in selectResourceInformation[index].body) && modality[index].body2 in selectResourceInformation[index].body[modality[index].body1] &&
                Object.keys(selectResourceInformation[index].body[modality[index].body1][modality[index].body2]).map((key) => <option key={key} value={key}>{key}</option>)
            }
        </select>
    </span>

    const Delay = ({ index = 0 }: { index?: number }) => (modality[index].ID === 'PET-CT_1' && modality[index].body1 === '全身' && modality[index].body2 === "全身") ? <div className="mx-5">
        <input type="checkbox" id={"modality_delay_" + index} name={"modality_delay_" + index} checked={modality[index].delay} onChange={updateModality} className={CheckClass} value="delay" />
        <label className="ml-1">Delay</label>
    </div> : <></>

    const handleDateMove = (e: MouseEvent<HTMLButtonElement>) => {
        switch (e.currentTarget.name) {
            case "previousDay":
                setDate(new Date(date.getTime() - 86400000))
                break
            case "today":
                setDate(today())
                break
            case "nextDay":
                setDate(new Date(date.getTime() + 86400000))
                break
            default:

        }
    }
    const handleClick = (e: MouseEvent) => {
        if (modality.length === 1) {
            setDuration([...duration, 0])
            setModality([...modality, { ID: "", body1: "", body2: "", contrast: "", delay: false }])
        } else {
            setDuration([duration[0]])
            setModality([modality[0]])
        }
    }

    const openBookingModal = (e: any) => {
        const [hours, minutes] = String(e.target.dataset.time).split(':').map(Number)

        const newDate = new Date(date)
        newDate.setDate(newDate.getDate() + Number(e.target.dataset.index))
        newDate.setHours(hours)
        newDate.setMinutes(minutes)
        setClickTime(e.target.dataset.time)
        setClickIndex(e.target.dataset.index)
        setIsModalOpen(true)
        setClickDate(newDate)
    }

    const refetch = () => {
        refetchOpeningData()
        refetchBookingData()
    }

    useEffect(() => { // Clear modalitySlots and set booked status(false)
        modalitySlots.forEach((_, i) => modalitySlots[i] = modalityDaySlots())
        openingRawData?.opening_times?.forEach((datum: OpeningTimes) => fillSlotFromOpening(datum, weekdays, false))
        openingRawData?.opening_times?.forEach((datum: OpeningTimes) => fillSlotFromOpening(datum, weekdays, false, true))
        openingRawData?.opening_times?.forEach((datum: OpeningTimes) => fillSlotFromOpening(datum, weekdays, true))
        openingRawData?.opening_times?.forEach((datum: OpeningTimes) => fillSlotFromOpening(datum, weekdays, true, true))
        bookingData.forEach(booking => fillSlotFromBooking(booking, weekdays));
        if (duration.every(v => Boolean(v))) recalcAvailability()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openingRawData, bookingRawData])

    useEffect(() => { // Recalc modality duratin if detail has changed
        modality.forEach((eachModlity, index) => {
            if (eachModlity.body1 && eachModlity.body2 && eachModlity.contrast && selectResourceInformation?.[index]?.body) {
                setDuration(prev => prev.map((item, i) => i === index ? Number(selectResourceInformation[index].body[eachModlity.body1][eachModlity.body2][eachModlity.contrast]) + (eachModlity.delay ? 20 : 0) : item));
            } else {
                setDuration(prev => prev.map((item, i) => i === index ? 0 : item))
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modality, app.resources])

    useEffect(() => {
        if (duration.every(v => Boolean(v))) recalcAvailability()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration])

    return <>
        <ExternalEntryForm
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            modality={modality}
            date={clickDate}
            duration={duration}
        />
        {isModalOpen ? <div /> :
            <div className="relative h-full grid grid-rows-list">
                <div className="bg-theme-200 p-2 relative">
                    <div className="flex flex-wrap justify-center items-center gap-2">
                        <button className={IconClass} type="button" onClick={() => refetch()}><ArrowPathIcon /></button>
                        <button type="button" className={SmallButtonClass} name="previousDay" onClick={handleDateMove}>
                            <ChevronLeftIcon className="w-5 h-5" /></button>
                        <DatePicker name="startDate" value={date} setValue={(n, v) => setDate(new Date(v))} />
                        <button type="button" className={SmallButtonClass} name="nextDay" onClick={handleDateMove}>
                            <ChevronRightIcon className="w-5 h-5" /></button>
                        <button type="button" className={SmallButtonClass} name="today" onClick={handleDateMove}>本日
                        </button>
                        <div className="flex flex-col justify-start px-2">
                            <div className="flex items-center p-1 text-theme-800 bg-theme-300">
                                <button
                                    className={`ml-4 mr-1 border border-theme-800 ${modality.length > 1 || modality[0].ID !== "MRI" ? "invisible" : ""}`}
                                    onClick={handleClick}><PlusIcon className="w-4 h-4" /></button>
                                <span className="pr-4">検査１</span>
                                <Modality />
                                <ModalityBody1 />
                                <ModalityBody2 />
                                <Contrast />
                                <Delay />
                                <span className="px-4">{duration && `検査時間：${duration[0]}分`}</span>
                            </div>
                            {modality.length === 2 &&
                                <div className="flex items-center p-1 text-theme-800 bg-theme-300">
                                    <button className="ml-4 mr-1 border border-theme-800" onClick={handleClick}>
                                        <MinusIcon className="w-4 h-4" /></button>
                                    <span className="pr-4">検査２</span>
                                    <Modality index={1} />
                                    <ModalityBody1 index={1} />
                                    <ModalityBody2 index={1} />
                                    <Contrast index={1} />
                                    <Delay index={1} />
                                    <span className="px-4">{duration && `検査時間：${duration[1]}分`}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="bg-white"></div>
                <div className="px-2 pb-2 h-full overflow-scroll flex flex-col z-0">
                    {(loading || bookingLoading) ? <Loading full /> : !duration.reduce((a, r) => a * r, 1) ?
                        <div className="p-4 text-center text-theme-800 font-bold">検査内容を選択してください</div> :
                        <table
                            className="mx-auto border border-gray-200 sm:rounded-b-md text-center text-sm text-gray-500 divide-y">
                            <thead className="divide-y">
                                <tr className="divide-x">
                                    <th className="w-28 h-12">利用日</th>
                                    {Array(7).fill(0).map((_, i) => (
                                        <th
                                            className="w-28"
                                            key={"date" + i}
                                            data-name="date"
                                            data-value={i}
                                            scope="col"
                                        >
                                            {JSTMonthDate(new Date(date.getTime() + 86400000 * i))}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y">
                                {slotLabels.map((datum, timeIndex) => (
                                    <tr key={datum} data-id={datum} data-detail="detail"
                                        className={`text-gray-900 divide-x`}>
                                        <td>{datum}</td>
                                        {Array(7).fill(0).map((_, dateIndex) => <React.Fragment
                                            key={datum + "=" + dateIndex}>{slots[dateIndex][timeIndex] ?
                                                <td data-index={dateIndex} data-time={datum}
                                                    data-name={`${dateIndex % 2 ? 'MRI' : 'PET-CT'}`}
                                                    className="text-xl font-bold text-theme-600 cursor-pointer hover:bg-theme-100"
                                                    onClick={openBookingModal}>◯</td>
                                                : <td className="text-xl text-gray-500 bg-gray-100">ー</td>
                                            }</React.Fragment>)}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        }
    </>
}

export default Schedule