import React, { MouseEvent, useEffect, useRef } from "react"
import { createRoot } from "react-dom/client"
import { ButtonClass } from "contexts/style"

/**
 * Disable tab select for form element and href
 *
 * @param {boolean} disable
 */
const disableTabIndex = (disable:boolean) => {
    var universe = document.querySelectorAll('input, button, select, textarea, a[href]')
    var list:HTMLElement[] = Array.prototype.filter.call(universe, item => (item instanceof HTMLElement) && !item.dataset?.focustab);
    if (disable) for (const item of list) { item.tabIndex = -1 }
    else for (const item of list) { item.removeAttribute("tabindex") }
}

/** Be sure to add div whose id is modal-container to app(index.js or tsx) and declare modalContainer to interface Window at types.d.ts */
const showMessage = (message:string, options:{keep?: boolean, error?:boolean, confirm?:boolean} = {}) => {
    const cleanup = () => {
        window.modalContainer?.unmount()
        disableTabIndex(false)
    }
    return new Promise((resolve, reject) => {
        try {

            window.modalContainer = createRoot(document.getElementById('modal-container')!)
            window.modalContainer.render(<MessageBox message={message} options={options} resolve={resolve} cleanup={cleanup} />)
            disableTabIndex(true)
        } catch (e) {
            reject (e)
            disableTabIndex(false)
        }
    })
}

const MessageBox = ({ message, options, resolve, cleanup }: { message:string, options:{keep?: boolean, error?:boolean, confirm?:boolean}, resolve:(value:unknown)=>void, cleanup:()=>void }) => {
    const okButton = useRef<any>(null);
    const cancelButton = useRef<any>(null);
    const handleClick = (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.currentTarget.dataset.name === "cancel") {
            resolve(false)
        } else {
            resolve(true)
        }
        cleanup()
    }
    const timer = useRef<number | undefined>()
    useEffect(() => {
        if (timer.current) {
            window.clearTimeout(timer.current)
            timer.current = undefined
        }
        if (message && !options.keep && !options.error && !options.confirm) {
            timer.current = window.setTimeout(() => {
                resolve(true)
                cleanup()
            }, 2000)
        }
        okButton.current?.focus()
//        document.addEventListener('focus', focus, true)
        return () => {
            if (timer.current) {
                window.clearTimeout(timer.current)
                timer.current = undefined
            }
        }
    }, [])

    return <div data-name="back" className="fixed z-50 left-0 top-0 w-screen h-screen bg-transparent flex justify-center items-center" onClick={(options.confirm || options.error) ? undefined : handleClick}>
            <div data-name="box" className="w-96 p-4 bg-gray-100 shadow-lg rounded-2xl dark:bg-gray-800" onClick={handleClick}>
                <div className="w-full h-full text-center">
                    <div className="flex flex-col justify-between h-full">
                        {options.error ? <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="m-auto w-8 h-8 text-red-500" viewBox="0 0 1792 1792">
                            <path d="M1024 1375v-190q0-14-9.5-23.5t-22.5-9.5h-192q-13 0-22.5 9.5t-9.5 23.5v190q0 14 9.5 23.5t22.5 9.5h192q13 0 22.5-9.5t9.5-23.5zm-2-374l18-459q0-12-10-19-13-11-24-11h-220q-11 0-24 11-10 7-10 21l17 457q0 10 10 16.5t24 6.5h185q14 0 23.5-6.5t10.5-16.5zm-14-934l768 1408q35 63-2 126-17 29-46.5 46t-63.5 17h-1536q-34 0-63.5-17t-46.5-46q-37-63-2-126l768-1408q17-31 47-49t65-18 65 18 47 49z">
                            </path>
                        </svg> :
                            <svg className="w-12 h-12 m-auto mt-4 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7">
                                </path>
                            </svg>
                        }
                        <p className="px-6 py-2 text-gray-600 dark:text-gray-100 text-left whitespace-pre-wrap">{message}</p>
                        <div className="flex items-center justify-between w-full gap-4 mt-8">
                            <button type="button" data-focustab="ninside" data-name="ok" className={ButtonClass.replace("w-28", "w-full")} ref={okButton} onClick={handleClick}>
                                    OK
                                </button>
                            { options.confirm &&  <button type="button" data-focustab="inside" data-name="cancel" className="py-2 px-4  bg-white hover:bg-gray-100 focus:ring-theme-500 text-theme-500 w-full transition ease-in duration-200 text-center text-sm shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg " ref={cancelButton} onClick={handleClick}>
                                    キャンセル
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
}

export default showMessage