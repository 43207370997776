import { ApolloError } from "@apollo/client";
import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { datesAreOnSameDay, getDateString } from "contexts/dateUtils";
import { CheckClass, InputClass } from "contexts/style";
import React, { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent, SetStateAction, useEffect, useRef, useState } from "react";

export const ErrorMessage = ({children}:{children:JSX.Element|ApolloError|string}) => <div className="w-full h-full flex justify-center items-start p-12"><span className="flex p-4 gap-2 text-base text-red-500 bg-red-100">
    <ExclamationCircleIcon className="w-6 h-6 text-red-500" />{
        (typeof children === 'string' || children instanceof String) ? <pre>{children}</pre> : children instanceof ApolloError ? <pre>{`データベースエラーが発生しています。以下のメッセージを管理者にご連絡ください。\r\n${children.message}`}</pre> : children
    }
</span></div>

export const FilterCheck: React.FunctionComponent<{ label: string; name: string; value: boolean; setValue: (n: string, v: any) => void }> = ({ label, name, value, setValue }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(name, e.currentTarget.checked)
    }
    return <>
        <input
            className={CheckClass}
            type="checkbox"
            name={name}
            checked={value}
            onChange={handleChange}
        />
        <label htmlFor={name} className="pl-1 pr-4">{label}</label>
    </>
}


export const FilterInput: React.FunctionComponent<{ label: string; name: string; type?: string; value: string; setValue: (n: string, v: any) => void }> = ({ label, name, type = "text", value, setValue }) => {
    const [inputValue, setInputValue] = useState(value)
    const inputRef = useRef<HTMLInputElement>(null)
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const changedValue = e.currentTarget.value
        setInputValue(changedValue)
        let hasValue
        try {
            hasValue = inputRef.current?.matches(':autofill')
        } catch (err) {
            try {
                hasValue = inputRef.current?.matches(':-webkit-autofill')
            } catch (er) {
                hasValue = false;
            }
        }
        if (hasValue && value !== changedValue) setValue(name, changedValue || "")
    }
    const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && value !== e.currentTarget.value) setValue(name, e.currentTarget.value || "")
    }
    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        if (value !== e.currentTarget.value) setValue(name, e.currentTarget.value || "")
    }
    return <div className="flex items-center gap-2">
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">{label}</label>
        <input
            type={type}
            name={name}
            className="block shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500  border-gray-300"
            ref={inputRef}
            value={inputValue}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            onBlur={handleBlur}
        />
    </div>
}

export const DatePicker: React.FunctionComponent<{ name: string; value: Date; setValue: (n: string, v: any) => void }> = ({ name, value, setValue }) => {
    const [activeDate, setActiveDate] = useState(new Date(value.getFullYear(), value.getMonth(), 1));
    const [showPicker, setShowPicker] = useState(false)

    const handleClick = (e: MouseEvent<HTMLDivElement|HTMLInputElement>) => {
        setValue(name, new Date(activeDate.getFullYear(), activeDate.getMonth(), Number(e.currentTarget.innerHTML)))
        setShowPicker(false)
    }

    const handleChange = (e:ChangeEvent<HTMLInputElement>) => {
    }

    const toggleDatePicker = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setShowPicker(!showPicker)
    }

    const goToNextMonth = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setActiveDate(new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 1))
    }

    const goToPrevMonth = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setActiveDate(new Date(activeDate.getFullYear(), activeDate.getMonth() - 1, 1))
    }

    return <div className="relative">
        <input className={InputClass + " w-28"} type="text" name={name} value={getDateString(value)} onClick={toggleDatePicker} onChange={handleChange} />

        {showPicker && <>
            <div className="fixed top-0 left-0 w-full h-full z-40 bg-transparent" onClick={toggleDatePicker}></div>
            <div className="absolute top-full left-0 w-72 z-50 bg-white text-center drop-shadow-lg">
                <div className="text-white bg-theme-600 flex justify-between items-center">
                    <div className="p-2 hover:bg-theme-400 cursor-pointer" onClick={goToPrevMonth}>&lt;</div>
                    <div className="p-2">{`${activeDate.getFullYear()}年 ${activeDate.getMonth() + 1}月`}</div>
                    <div className="p-2 hover:bg-theme-400 cursor-pointer" onClick={goToNextMonth}>&gt;</div>
                </div>

                <div className="grid grid-cols-7 p-px gap-px bg-theme-600">
                    {["日", "月", "火", "水", "木", "金", "土"].map((v, i) => <div key={`weekday-${i}`} className={`w-10 bg-white ${v === "日" ? "text-red-500" : v === "土" ? "text-blue-500" : ""}`}>{v}</div>)}
                    {Array.from(Array(activeDate.getDay())).map((v, i) => <div key={`fill-pre-${i}`} className={`bg-white`}></div>)}
                    {
                        Array.from(Array(new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 0).getDate()).keys()).map(i =>
                            <div key={`day-${i + 1}`} className={`w-10 ${datesAreOnSameDay(new Date(activeDate.getFullYear(), activeDate.getMonth(), i + 1), value) ? "text-white bg-theme-800" : "bg-white hover:bg-theme-200 cursor-pointer"}`} onClick={handleClick}>{String(i + 1)}</div>
                        )
                    }
                    {Array.from(Array(6 - new Date(activeDate.getFullYear(), activeDate.getMonth() + 1, 0).getDay())).map((v, i) => <div key={`fill-aft-${i}`} className={`bg-white`}></div>)}
                </div>
            </div>
        </>}
    </div>
}

export const MultipleListPicker = ({ listItems, pickedItems, setPickedItems }:{ listItems:{ id:string, name:string }[], pickedItems:{ id:string, name:string }[], setPickedItems: React.Dispatch<SetStateAction<{ id:string, name:string }[]>> }) => {
  const [inputValue, setInputValue] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [filteredItems, setFilteredItems] = useState<{ id:string, name:string }[]>([]);
  const composing = useRef(false)
  const mouseMove = useRef(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const ulRef = useRef<HTMLUListElement>(null)

  const handleInputChange = (e:ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setInputValue(value)
    // Filter candidates based on the input value
    const filtered = value ? listItems.filter(v => 
      v.name.includes(value) && !pickedItems.find(dest => dest.id === v.id)
    ) : []
    setFilteredItems(filtered)
  }

  const handleFilteredItemClick = (e:MouseEvent<HTMLLIElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const i = Number(e.currentTarget.dataset.index)
    if (!Number.isInteger(i)) return
    selectItem(i)
  }

  const clearSelectionList = () => {
    setFilteredItems([])
    setSelectedIndex(0)
  }

  const selectItem = (i:number) => {
    inputRef.current?.focus()
    if (!pickedItems.find(v => v.id === filteredItems[i].id)) {
        setPickedItems([...pickedItems, filteredItems[i]])
        setInputValue('')
        clearSelectionList()
    }
  }

  const handleKeyDown = (e:KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowUp':
        e.preventDefault()
        e.stopPropagation()
        mouseMove.current = false
        setSelectedIndex((prevIndex) => {
            const index = Math.max(prevIndex - 1, 0)
            if (ulRef.current) {
                const rowHeight = Number(window.getComputedStyle(ulRef.current).fontSize.replace('px', '')) * 1.5
                ulRef.current.scrollTop = Math.min(ulRef.current.scrollTop, index * rowHeight)
            }
            return index
        });
        break;
      case 'ArrowDown':
        e.preventDefault()
        e.stopPropagation()
        mouseMove.current = false
        setSelectedIndex((prevIndex) => {
            const index = Math.min(prevIndex + 1, filteredItems.length - 1)
            if (ulRef.current) {
                const rowHeight = Number(window.getComputedStyle(ulRef.current).fontSize.replace('px', '')) * 1.5
                ulRef.current.scrollTop = Math.max(ulRef.current.scrollTop, (index - 4) * rowHeight)
            }
            return index
        });
        break;
      case 'Enter':
        if (!composing.current && filteredItems.length) {
            e.preventDefault()
            e.stopPropagation()
            selectItem(selectedIndex)
        }
        break;
      case 'Escape':
        clearSelectionList()
        break;
      default:
        break;
    }
  }

  const handleMouseMove = (e:MouseEvent<HTMLLIElement>) => {
    mouseMove.current = true
  }

  const handleMouseOver = (e:MouseEvent<HTMLLIElement>) => {
    if (!mouseMove.current) return
    const index = Number(e.currentTarget.dataset.index)
    setSelectedIndex(index)
    mouseMove.current = false
  }

  const handleRemoveItem = (e:MouseEvent<HTMLButtonElement>) => {
    const index = Number(e.currentTarget.dataset.index)
    const updatedItems = [...pickedItems]
    updatedItems.splice(index, 1)
    setPickedItems(updatedItems)
    filteredItems.length && clearSelectionList()
  };

  const handleBlur = (e:FocusEvent) => {
    clearSelectionList()
  }

  return (
    <div className="flex-1 px-2 py-1 flex flex-wrap items-center gap-2 rounded-md text-black bg-white">
        {pickedItems.map((v, index) => (
        <div key={index} className="px-2 py-0 flex items-center gap-1 bg-theme-100 rounded-2xl">
            {v.name}
            <button className="bg-none border-none" type="button" data-index={index} onClick={handleRemoveItem}><XMarkIcon className="w-4 h-4"/></button>
        </div>
        ))}

        <div className="relative flex-grow items-center rounded-md bg-theme-500" onBlur={handleBlur}>
            <input
                className="w-full p-0 border-none !ring-0"
                type="text"
                placeholder="通知先"
                value={inputValue}
                onChange={handleInputChange}
                onCompositionStart={() => composing.current = true}
                onCompositionEnd={() => composing.current = false}
                onKeyDown={handleKeyDown}
                ref={inputRef}
            />
            <div className="h-0">
            {filteredItems.length > 0 && (
                <ul ref={ulRef} className="list-none bg-white border border-gray-500 cursor-pointer leading-normal max-h-[7.5em] overflow-y-scroll z-10">
                    {filteredItems.map((v, index) => (
                        <li className={"m-0 px-2 py-0" + (index === selectedIndex ? " bg-theme-200" : "")} key={index} data-index={index} onMouseMove={handleMouseMove} onMouseOver={handleMouseOver} onMouseDown={(e)=>e.preventDefault()} onClick={handleFilteredItemClick}>
                        {v.name}
                        </li>
                    ))}
                </ul>
            )}
            </div>
        </div>
    </div>
  )
}
