import React, { ChangeEvent, FormEvent } from 'react'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import showMessage from './showMessage'
import { emailRegex } from 'contexts/functions'


export default function SignIn({ signin, reset }: { signin: (email: string, password: string) => Promise<void>, reset: (email: string, password: string) => Promise<void> }) {
  const [email, setEmail] = React.useState('')
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
  const [password, setPassword] = React.useState('')
  const [resetting, setResetting] = React.useState(false)
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
  const handleFlip = () => setResetting(!resetting)
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    resetting ? handleResetPassword() : signin(email, password)
  }
  const handleResetPassword = async () => {
    if (!email) {
      await showMessage("メールアドレスが入力されていません", { error: true })
      return null
    }
    if (!email.match(emailRegex)) {
      await showMessage("不正なメールアドレスです", { error: true })
      return null
    }
    try {
      await reset(email, "passes")
      await showMessage("入力されたメールアドレスに認証メールを送付しました。メール内のリンクをクリックすると新パスワードが有効になります")

    } catch (e) {
      await showMessage(`無効なユーザーか、パスワード変更リンクの送信に失敗しました。`, { error: true })
    }
  }
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-6">
        <div>
          <h2 className='mt-6 text-center text-xl font-extrabold text-gray-900'>セントラルクリニック世田谷</h2>
          <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>検査予約システム ログイン</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit} >
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email-address"
                required
                className={`appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 ${resetting ? 'rounded-md' : 'rounded-t-md'} focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm`}
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            {!resetting && <div>
              <label htmlFor="password" className="sr-only">
                パスワード
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                placeholder={resetting ? 'パスワード(再入力)' : 'パスワード'}
                value={password}
                onChange={handlePasswordChange}
              />
            </div>}
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-theme-500 group-hover:text-theme-400" aria-hidden="true" />
              </span>
              {resetting ? 'パスワード再設定メール送信' : 'ログイン'}
            </button>
          </div>
        </form>
        <div className="cursor-pointer" onClick={handleFlip}>{resetting ? 'ログイン' : 'パスワード再設定'}</div>
      </div>
    </div>
  )
}