export const ButtonClass = "inline-flex justify-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
export const ButtonClassLong = "inline-flex justify-center m-3 py-2 px-4 w-40 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
export const FixButtonClass = "inline-flex justify-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"

export const YellowButtonClass = "inline-flex justify-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
export const GrayButtonClass = "inline-flex justify-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
export const LightGrayButtonClass = "inline-flex justify-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
export const RedButtonClass = "inline-flex justify-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
export const SmallButtonClass = "inline-flex justify-center px-1 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-theme-500"
export const YellowLeftButtonClass = "inline-flex justify-center p-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-theme-600 hover:bg-theme-700 bg-yellow-500 hover:bg-yellow-700 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-theme-500"

export const PurpleButtonClass = "bg-purple-500 p-1 text-white rounded-lg"

export const InputClass = "block shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 focus:ring-theme-500 focus:border-theme-500  border-gray-300"
export const InputGrayClass = "block text-black shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 focus:ring-theme-500 focus:border-theme-500  border-gray-300"
export const InputClassLong = "block shadow-sm sm:text-sm rounded-md w-60 disabled:bg-gray-100 focus:ring-theme-500 focus:border-theme-500  border-gray-300"
export const CheckClass = "focus:ring-theme-500 h-4 w-4 text-theme-600 border-gray-300 rounded"
export const areaClass = "block shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 focus:ring-theme-500 focus:border-theme-500  border-gray-300 w-full"
export const errorMessageClass = "mx-5 my-10 p-5 rounded-md font-medium bg-white text-red-600"
export const modalBasicIndent = "text-white mt-6 underline flex items-center"
export const modalBasicText = "text-white flex items-center"
export const modalBasicTextMini = "text-white sm:text-sm flex items-center"
export const InputClassBox = "shadow-sm sm:text-sm rounded-md w-60 disabled:bg-gray-100 focus:ring-theme-500 focus:border-theme-500  border-gray-300"
export const modalBlackText = "text-black flex items-center"
export const modalBlackTextMini = "text-black sm:text-sm flex items-center"

export const IconClass = "w-6 h-6 text-white bg-theme-600 hover:bg-theme-700 rounded-md p-1"

/**
 * Event Color
 * @param bookingType 0(外来),1(外注),2(ドック),3(外部予約　外注と同色),4(Close),6(CMC/PETチケット/VIP)
 * @returns Color as tailwind excluding "text-""
 */
export const getColor = (bookingType?: Number, light?: boolean) => {
  switch (bookingType) {
    case 0: return light ? "blue-300" : "blue-500"
    case 1: return light ? "teal-300" : "teal-500"
    case 2: return light ? "orange-300" : "orange-500"
    case 3: return light ? "teal-300" : "teal-500"
    case 4: return light ? "gray-300" : "gray-500"
    case 5: return light ? "lime-300" : "lime-500"
    case 6: return light ? "pink-300" : "pink-500"
    default: return "indigo-500"
  }
}

/**
 * Status Color
 * @param bookingStatus 0(仮),1(確定),2(IV),3(開始),4(完了),5(取消)
 * @returns Color as tailwind excluding "text-""
 */
 export const getStatusColor = (bookingStatus?: Number, light?: boolean) => {
  switch (bookingStatus) {
    case 0: return light ? "gray-300" : "gray-500"
    case 1: return light ? "cyan-300" : "cyan-500"
    case 2: return light ? "pink-300" : "pink-500"
    case 3: return light ? "amber-300" : "amber-500"
    case 4: return light ? "lime-300" : "lime-500"
    case 5: return light ? "red-300" : "red-500"
    default: return light ? "theme-300" : "theme-500"
  }
}
export const statusButtonClass = (bookingStatus?: Number) => `inline-flex justify-center m-3 py-2 px-4 w-28 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-${getStatusColor(bookingStatus)} hover:brightness-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${getStatusColor(bookingStatus)}`


/**
 * モーダルStyle
 */
export const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    backgroundColor: 'rgb(22,101,52)',
  },
  overlay: {
    zIndex: 40
  }
};

export const modalStylesMobile = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    height: '95%',
    backgroundColor: 'rgb(22,101,52)',
  },
  overlay: {
    zIndex: 40
  }
};

export const classNames = (...classes:string[]) => {
  return classes.filter(Boolean).join(' ')
}
