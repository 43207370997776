import { JSTTime } from "contexts/dateUtils";
import { INTERNAL_TYPE } from "contexts/enviroments";
import options from "contexts/options.json";
import React from "react"

const Key = ({children}:{children:string}) => <span className="bg-lime-200 p-1">{children}</span>
const Value = ({children}:{children:string | number | undefined}) => <span className="bg-lime-50 p-1">{children}</span>

export const TooltipExam = ({element, event} : {element?: HTMLElement; event?: CalendarEvent}) => {
    return !event ? null : 
    <div 
        style={{left:((element?.getBoundingClientRect().right || 0) + 10), top:`calc(${(element?.getBoundingClientRect().top || 0)}px - 3rem`}} 
        className="absolute z-50 border border-lime-800 bg-lime-800 grid grid-cols-2 gap-px text-sm text-lime-800 shadow-md"
    >
        <Key>予約種別</Key><Value>{options.bookingType[event.extendedProps.bookingType || 0]}</Value>
        <Key>ステータス</Key><Value>{options.bookingStatus[event.extendedProps.bookingStatus || 0]}</Value>
        <Key>患者ID</Key><Value>{event.extendedProps.patientID}</Value>
        <Key>患者名</Key><Value>{event.extendedProps.patientName}</Value>
        <Key>医師名</Key><Value>{event.extendedProps.doctor}</Value>
        <Key>種別</Key><Value>{event.extendedProps.interviewType}</Value>
        {event.extendedProps.bookingType === INTERNAL_TYPE && <><Key>病院名</Key><Value>{event.extendedProps.clinicName}</Value></>}
        {event.extendedProps.ivCheckin && <><Key>IV</Key><Value>{event.extendedProps.ivCheckin}</Value></>}
        <Key>診療方法</Key><Value>{event.extendedProps.appointmentMethod || ""}</Value>
        <Key>開始</Key><Value>{JSTTime(event.start)}</Value>
        <Key>終了</Key><Value>{JSTTime(event.end)}</Value>
    </div>}

export const Tooltip = ({element, event} : {element?: HTMLElement; event?: CalendarEvent}) => { 
    return !event ? null : 
    <div 
        style={{left:((element?.getBoundingClientRect().right || 0) + 10), top:`calc(${(element?.getBoundingClientRect().top || 0)}px - 3rem`}} 
        className="absolute z-50 border border-lime-800 bg-lime-800 grid grid-cols-2 gap-px text-sm text-lime-800 shadow-md"
    >
        <Key>予約種別</Key><Value>{options.bookingType[event.extendedProps.bookingType || 0]}</Value>
        <Key>ステータス</Key><Value>{options.bookingStatus[event.extendedProps.bookingStatus || 0]}</Value>
        <Key>患者ID</Key><Value>{event.extendedProps.patientID}</Value>
        <Key>患者名</Key><Value>{event.extendedProps.patientName}</Value>
        {event.extendedProps.bookingType === INTERNAL_TYPE && <><Key>病院名</Key><Value>{event.extendedProps.clinicName}</Value></>}
        {event.extendedProps.ivCheckin && <><Key>IV</Key><Value>{event.extendedProps.ivCheckin}</Value></>}
        <Key>開始</Key><Value>{JSTTime(event.start)}</Value>
        <Key>終了</Key><Value>{JSTTime(event.end)}</Value>
    </div>}
export default Tooltip