import React from 'react'

export const sendPostRequest = async (to: string[], subject: string, body: string) => {
  const url = String(process.env.REACT_APP_MAIL_HOST)

  const data = {
    to: to,
    cc: [],
    subject: subject,
    body: body,
  }

  fetch(url, {
    method: 'POST',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    body: JSON.stringify(data),
  })
      .then(response => response)
      .then(data => {
        console.log('Response:', data)
      })
      .catch(error => {
        console.error('Error:', error)
      })
}