import { DOCK_TYPES } from './../../contexts/enviroments';
import { CMC_TYPE, DOCK_TYPE, INTERNAL_TYPE, SpecialCourses } from 'contexts/enviroments'
import options from 'contexts/options.json';
import ExcelJS, { Borders } from 'exceljs'
import { saveAs } from 'file-saver'

const FIRST_MINUTES_ROW = 4// header + beginning 1 adjust = 2
const SLOT_MINUTES = 5
const SLOT_MILLISECONDS = SLOT_MINUTES * 60000
const [BEGIN_HOUR, BEGIN_MINUTE] = [9, 0]
const [END_HOUR, END_MINUTE] = [18, 0]
const [BEGIN_MINUTES, BEGIN_MILLISECONDS] = [BEGIN_HOUR * 60 + BEGIN_MINUTE, (BEGIN_HOUR * 60 + BEGIN_MINUTE) * 60000]
const [END_MINUTES, END_MILLISECONDS] = [END_HOUR * 60 + END_MINUTE, (END_HOUR * 60 + END_MINUTE) * 60000]
const minutes = Array.from(Array((END_MINUTES - BEGIN_MINUTES) / SLOT_MINUTES)).map((v, i) => BEGIN_MINUTES + i * SLOT_MINUTES)
const timeLabels = minutes.map(v => ` ${!(v % 30) ? Math.floor(v / 60) : ''}${!(v % 30) ? ':' : ''}${('0' + (v % 60)).slice(-2)}`)
const getBackground = (obj:Resource) => {
    if (!obj.extendedProps.bookingType) return 'DDFFDDFF'
    switch (obj.extendedProps.bookingType) {
        case INTERNAL_TYPE: return 'DDDDFFFF'
        case CMC_TYPE: return 'FFFFDDDD'
        case DOCK_TYPE: 
            if (SpecialCourses.some(course => obj.extendedProps.courseName?.includes(course))) return 'FFFFDDDD' //TEMPORARY WHILE OLD DATA REMAIN
            return 'FFFED7AA'
        default: return 'FFEEEEEE'
    }
}
interface Resource {
    id: string;//患者ID
    title?: string;//患者名
    extendedProps: { 
        bookingType?: number;
        courseName?: string;
        clinicName?: string;
        checkin?: string;//hh:mm
    }
}

const fullBorder:Borders = {
    top: {style:'thin', color:{argb:"FF333333"}},
    left: {style:'thin', color:{argb:"FF333333"}},
    bottom: {style:'thin', color:{argb:"FF333333"}},
    right: {style:'thin', color:{argb:"FF333333"}},
    diagonal: {}
};
const lightBorder = (position?:string):Borders => {
    return ({
        top: {style:'thin', color:{argb:position?.includes("top") ? fullBorder.top.color!.argb : "FF999999"}},
        left: {style:'thin', color:{argb:position?.includes("left") ? fullBorder.left.color!.argb : "FF999999"}},
        bottom: {style:'thin', color:{argb:position?.includes("bottom") ? fullBorder.bottom.color!.argb : "FF999999"}},
        right: {style:'thin', color:{argb:position?.includes("right") ? fullBorder.right.color!.argb : "FF999999"}},
        diagonal: {}
    })
};

/**
 * Generating csv file using export-to-csv package
 *
 * @param {KV[]} data data to export
 */
const makeExcel = async (date:Date, resources?:Resource[], data?: CalendarEvent[]) => {
    if (!resources || !data) return false
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("Data");
    sheet.columns = [{ header: date.toLocaleDateString(), key: "timeslotCol"}].concat(resources.map((resource) => ({
        header: resource.id ?? '',
        key: resource.id,
        alignment: { textWrap: false }
    })));
    sheet.addRow(resources.reduce((row, resource) => ({...row, [resource.id]: resource.title ?? ""}), {}))
    sheet.addRow(resources.reduce((row, resource) => ({...row, [resource.id]: (resource.extendedProps.bookingType === INTERNAL_TYPE ? resource.extendedProps.clinicName : DOCK_TYPES.includes(resource.extendedProps.bookingType || 0) ? resource.extendedProps.courseName : "外来") ?? ""}), {}))
    sheet.mergeCells(1,1,3,1)
    sheet.getColumn("timeslotCol").width = 15
    sheet.getColumn("timeslotCol").alignment = { horizontal:'right'}
    resources.forEach(resource => {
        const column = sheet.getColumn(String(resource.id))
        column.width = 15
    })
    // Title row
    const headerRow = sheet.getRow(1)
    headerRow.getCell("timeslotCol").border = fullBorder;
    [1,2,3].forEach(rowIdx => resources.forEach(resource => {
        const cell = sheet.getRow(rowIdx).getCell(String(resource.id))
        cell.border = fullBorder
        cell.alignment = { wrapText:true, horizontal:'center'}
        cell.fill = { type: 'pattern', pattern:'solid', fgColor:{argb:getBackground(resource)} };
    }))
    sheet.addRows(timeLabels.map(timeLabel => ({timeslotCol:timeLabel})))
    minutes.forEach((minute, minuteIndex) => {
        const row = sheet.getRow(FIRST_MINUTES_ROW + minuteIndex)
        row.getCell("timeslotCol").border = lightBorder('leftright' + (minuteIndex === minutes.length - 1 ? 'bottom' : ''))
        resources.forEach((resource, resourceIndex) => {
            const cell = row.getCell(String(resource.id))
            const rowNum = row.number
            const colNum = Number(cell.col)
            cell.border = lightBorder((resourceIndex === 0 ? 'left' : '') +  (resourceIndex === resources.length - 1 ? 'right' : ''))
            const slotTime = date.getTime() + minute * 60000
            data.filter(event => event.resourceId === resource.id && (event.start.getTime() <= slotTime && event.end.getTime() > slotTime))
            .sort((a, b) => (a.start.getTime() - b.start.getTime()) || (a.end.getTime() - b.end.getTime())).forEach(datum => {
                const [ isStart, isEnd ] = [ datum.start.getTime() === slotTime, minuteIndex === minutes.length - 1 || datum.end.getTime() === slotTime + SLOT_MILLISECONDS ]
                if ((minuteIndex === 0 && datum.start.getTime() < slotTime) || isStart) { // start before opening
                    if (cell.isMerged) {
                        cell.unmerge()
                        minutes.slice(minuteIndex + 1).forEach((_, i) => {
                            const cell = sheet.getCell(rowNum + 1 + i, colNum)
                            if (cell.isMerged) cell.unmerge()
                        })
                    }
                    if (datum.extendedProps.modalityID && options.modalityTitle[datum.extendedProps.modalityID]) { // exam
                        cell.value = `${options.modalityTitle[datum.extendedProps.modalityID]} ${datum.extendedProps.doctor || ""}${!isEnd ? (datum.extendedProps.interviewType ?? '') : ''}`
                    } else {
                        cell.value = datum.extendedProps.body2?.includes('待機') ? datum.extendedProps.body2 : `${datum.extendedProps.modalityID}${(datum.extendedProps.body2 && !isEnd) ? `\n` : ''}${datum.extendedProps.body2 ?? ''}${(datum.extendedProps.contrast === "造影" && !isEnd) ? `\n造影あり` : ''}`
                    }
                    cell.fill = { type: 'pattern', pattern:'solid', fgColor:{argb:datum.extendedProps.body2?.includes('待機') ? 'FFDDDDDD' : getBackground(resource)} };
                    cell.border = fullBorder
                    if (!isEnd) cell.alignment = { vertical: 'top', wrapText:true }
                } else if (isEnd) {
                    if (!isStart) { // need merge
                        const rowSpan = Math.min(Math.round((Math.min(datum?.end.getTime(), date.getTime() + END_MILLISECONDS) - Math.max(datum?.start.getTime(), date.getTime() + BEGIN_MILLISECONDS)) / SLOT_MILLISECONDS) ?? 1)
                        try { sheet.mergeCells(rowNum - rowSpan + 1, colNum, rowNum, colNum) } catch (err) {}
                    }
                } else if (minuteIndex === minutes.length - 1 && datum.start.getTime() <= slotTime && datum.end.getTime() > slotTime + SLOT_MILLISECONDS) { // end after close
                    const rowSpan = Math.round((slotTime + SLOT_MILLISECONDS - datum?.start.getTime()) / SLOT_MILLISECONDS) ?? 1
                    if (rowSpan > 1) try { sheet.mergeCells(rowNum - rowSpan + 1, colNum, rowNum, colNum) } catch (err) {}
                }
            })
        })
    })
    const lastRow = sheet.getRow(FIRST_MINUTES_ROW + minutes.length - 1)
    resources.forEach((resource, resourceIndex) => { // set bottom border for last cell
        const cell = lastRow.getCell(String(resource.id))
        cell.border = {...cell.border, bottom: fullBorder.bottom}
    })
    const buff = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buff], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `検査予定表${new Date(date.getTime() + 32400000).toISOString().split('T')[0]}.xlsx`);
  };
  
  export default makeExcel