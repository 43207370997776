import {FetchResult, useMutation} from "@apollo/client";
import gql from "graphql-tag";

const useBookingsMutations = () => {
  return {
    addBooking: useAddBooking(),
    addBookings: useAddBookings(),
    deleteBooking: useDeleteBooking(),
    deleteBookingByDock: useDeleteBookingByDock(),
  };
}
export default useBookingsMutations

const AddBookingMutation = gql`
  mutation AddBooking($booking: BookingInsertInput!) {
    addedBooking: insertOneBooking(data: $booking) {
      bookingStatus
      patientStatus
      walkingStatus
      bookingMethod
      checkin
      patientName
      clinicName
      department
      doctor
      updated
      _id
      end
      due
      patientID
      dockID
      contrast
      body2
      comment
      userID
      modalityID
      start
      body1
      bookingType
      courseName
      patient {
        name
        nameKana
        birthDate
        gender
      }
      report {
        image
        imageSubmission
        reportSubmission
        dueDate
      }
      clinicID
    }
  }
`;

const BookingFieldsFragment = gql`
  fragment BookingFields on Booking {
    _id
    clinicID
    clinicName
    department
    doctor
    patientID
    dockID
    patientName
    modalityID
    body1
    body2
    contrast
    checkin
    start
    end
    due
    userID
    bookingType
    bookingMethod
    walkingStatus
    patientStatus
    courseName
    comment
    patient {
      name
      nameKana
      birthDate
      gender
    }
    report {
      image
      imageSubmission
      reportSubmission
      dueDate
    }
    bookingStatus
    updated
  }
`;

const useAddBooking = () => {
  const [addBookingMutation, { data, loading, error }] = useMutation(AddBookingMutation, {
    update: (cache, { data: { addedBooking } }) => {
      cache.modify({
        fields: {
          bookings: (existingbookings = []) => [
            ...existingbookings,
            cache.writeFragment({
              data: addedBooking,
              fragment: BookingFieldsFragment,
            }),
          ],
        },
      });
    },
  });
  const addBooking = async (booking: any) => {
    // @ts-ignore
    const { addedBooking }: FetchResult<{ addedBooking: any }> = await addBookingMutation({
      variables: {
        booking: {
          bookingMethod: 2,
          updated: new Date(),
          ...booking,
        },
      },
    });
    return addedBooking;
  };
  return addBooking;
}

const AddBookingsMutation = gql`
  mutation AddBookings($bookings: [BookingInsertInput!]!) {
    addedBookings: insertManyBookings(data: $bookings) {
      insertedIds
    }
  }
`;

const useAddBookings = () => {
  const [addBookingsMutation] = useMutation(AddBookingsMutation);
  const addBookings = async (bookings: any) => {
    // @ts-ignore
    const { addedBookings } = await addBookingsMutation({
      variables: { bookings: bookings },
    });
    return addedBookings;
  };
  return addBookings;
}

const DeleteBookingMutation = gql`
mutation DeleteBookingReturnShort($Id: String!) {
    deletedBooking: deleteOneBooking(query: { _id: $Id }) {
      _id
      bookingStatus
    }
  }
`;

const useDeleteBooking = () => {
  const [deleteBookingMutation] = useMutation(DeleteBookingMutation, { 
    update: (cache, { data: { deletedBooking } }) => {
        const normalizedId = cache.identify(deletedBooking);
        cache.evict({ id: normalizedId });
        cache.gc();
    }
});

  const deleteBooking = async (booking: any) => {
    // @ts-ignore
    const { deletedBooking } = await deleteBookingMutation({
      variables: { Id: booking._id },
    });
    return deletedBooking;
  };
  return deleteBooking;
}

const DeleteBookingByDockMutation = gql`
mutation DeleteBookings($dockID: String!) {
    deletedBooking: deleteManyBookings(query: { dockID: $dockID, bookingStatus_nin: [3,4] }) {
      deletedCount
    }
  }
`;

const useDeleteBookingByDock = () => {
  const [deleteBookingMutation] = useMutation(DeleteBookingByDockMutation);
  const deleteBooking = async (booking: any) => {
    // @ts-ignore
    const { deletedBooking } = await deleteBookingMutation({
      variables: { dockID: booking.dockID },
    });
    return deletedBooking;
  };
  return deleteBooking;
}
