import React, { MouseEvent } from 'react'
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import PlusIcon from '@heroicons/react/24/solid/PlusIcon'
import { Loading } from 'components/Loading'
import { FilterInput } from 'components/UIParts'
import { filterStates } from 'graphql/RealmApolloProvider'
import options from "contexts/options.json"

const heads = [
    { field: "clinic_name", text: "施設名" },
    { field: "postal_code", text: "施設郵便番号" },
    { field: "address1", text: "住所1" },
    { field: "address2", text: "住所2" },
    { field: "tel", text: "施設電話番号" },
    { field: "fax", text: "施設FAX" },
    { field: "clinic_type", text: "外注" },
    { field: "order", text: "表示順" },
]
const AddButton = ({ onClick }: { onClick?: () => void }) =>
    <button
        type="button"
        onClick={onClick}
        className="flex justify-center items-center bg-theme-50 hover:bg-theme-100 text-theme-800 w-12 h-12 rounded-3xl transition ease-in duration-200"
    >
        <PlusIcon className="w-8 h-8" />
    </button>

const usersData = gql`query GetClinics($query: ClinicQueryInput!) {
  clinics(query: $query) {
    _id
    clinic_name
    postal_code
    address1
    address2
    tel
    fax
    clinic_type
    order
}
}
`

const getQueryVariables = (names: KV): KV => {
    return Object.keys(names).reduce<KV>((vars, key) => (names[key] ? { ...vars, [key]: names[key] } : vars), {})
}

export const List = () => {
    const navigate = useNavigate()
    const values: KV = useReactiveVar(filterStates)
    const names = values.clinic || { clinic_name: "", department: "", doctor_name: "", name: "" }
    const setValue = (name: string, value: string) => filterStates({ ...filterStates(), clinic: { ...names, [name]: value } })
    const { loading, data } = useQuery(usersData, { variables: { query: getQueryVariables(names) }, fetchPolicy: "cache-first", nextFetchPolicy: "cache-first" })
    const handleClick = (e:MouseEvent<HTMLTableRowElement>) => {
        e.preventDefault()
        e.stopPropagation()
        navigate(`/clinic/edit/${e.currentTarget.dataset.id}`)
    }

    if (loading) return <div className="w-full h-full flex justify-center items-center"><Loading /></div>
    return <>
        <div className="absolute left-0 top-0 w-full h-full z-10 pointer-events-none">
            {<div className='fixed left-0 right-0 top-12 px-2 sm:px-4 bg-theme-100 pointer-events-auto'></div>}
            <Link
                className="fixed right-4 bottom-10 pointer-events-auto"
                to={`/clinic/create`}
            >
                <AddButton />
            </Link>
        </div>
        <div className="relative h-full grid grid-rows-list">
            <div className="bg-theme-200 p-2 relative">
                <div className="flex flex-wrap justify-center items-center gap-2">
                    <FilterInput label="施設名" name="clinic_name" value={names.clinic_name} setValue={setValue} />
                </div>
            </div>
            <div className="bg-white"></div>
            <div className="px-2 pb-2 h-full overflow-scroll flex flex-col z-0">
                <table className="mx-auto border-b border-gray-200 sm:rounded-b-md text-center text-sm text-gray-500">
                    <thead className="bg-theme-50">
                        <tr>
                            <th></th>
                            {heads.map((head, i) => (
                                <th
                                    key={(head.field || "") + i}
                                    data-name={head.field}
                                    data-value={i}
                                    scope="col"
                                    className="p-1 font-medium tracking-wider cursor-pointer"
                                >
                                    {head.text}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {data && [...data.clinics]
                            .map((datum) => (
                                <tr key={datum._id} data-id={datum._id} className={`text-sm text-gray-900 hover:bg-theme-50 cursor-pointer`} onClick={handleClick}>
                                    <td></td>
                                    <td className="text-left">{datum.clinic_name || ''}</td>
                                    <td>{datum.postal_code || ''}</td>
                                    <td className="text-left">{datum.address1 || ''}</td>
                                    <td className="text-left">{datum.address2 || ''}</td>
                                    <td>{datum.tel || ''}</td>
                                    <td>{datum.fax || ''}</td>
                                    <td>{datum.clinic_type ? "" : "◯"}</td>
                                    <td>{datum._id === options.centralClinic.id ? '-' : datum.order || ''}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    </>
}

export default List