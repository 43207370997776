import React from 'react'
import { sendPostRequest } from "./sendEmail"

export const sendCancelToExternal = async (toEmails: string[], params: KV, modalityParam: any) => {

    const modality = `検査時間:　${modalityParam["execTimeRange"]}
　モダリティ: ${modalityParam["id"]}
　部位：${modalityParam["body2"]}
　造影剤：${modalityParam["contrast"]}
        `

    const body = `${params["doctor"]}先生

下記のご予約を取り消しました。

---------------------------------------------------------------
　予約No:　${params["bookNo"]}
　予約日:  ${params["bookDate"]}
　来院受付時間:  ${params["receptTime"]}
　${modality}
---------------------------------------------------------------


予約取消に覚えのない方は、お手数ですが下記番号までご連絡を頂けますようお願いいたします。


医療法人社団NIDC セントラルクリニック世田谷
03-6805-9110（営業時間　平日9:00～18:00）　
https://nidc.or.jp/

本メールは送信専用のため、直接ご返信いただいてもお問い合わせにはお答えできませんので、何かございましたら、お手数ですが、当院までお電話ください。
`
    const today = new Date()
    const month = (today.getMonth() + 1).toString().padStart(2, '0')
    const day = today.getDate().toString().padStart(2, '0')
    const subject = `【セントラルクリニック世田谷】予約取消_${month}月${day}日`
    await sendPostRequest(toEmails, subject, body)
}



export const sendCancelToInternal = async (toemails: string[], params: KV, modalityParam: any, hospitals: any) => {

    const modality = `検査時間:　${modalityParam["execTimeRange"]}
　モダリティ: ${modalityParam["id"]}
　部位：${modalityParam["body2"]}
　造影剤：${modalityParam["contrast"]}
        `

    const body = `セントラルクリニック世田谷　各位

予約が取り消されました。

---------------------------------------------------------------
　予約No:　${params["bookNo"]}
　予約日:  ${params["bookDate"]}
　来院受付時間:  ${params["receptTime"]}
　${modality}
　紹介病院:　${hospitals["clinic_name"]}
　紹介科:　${hospitals["department"]}
　紹介医師:　${hospitals["doctor_name"]}
---------------------------------------------------------------
`
    const today = new Date()
    const month = (today.getMonth() + 1).toString().padStart(2, '0')
    const day = today.getDate().toString().padStart(2, '0')
    const subject = `予約取消（${month}月${day}日/予約No.${params["bookNo"]}）`
    await sendPostRequest(toemails, subject, body)
}