import React, { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react"
import FullCalendar from "@fullcalendar/react"
import { DateSelectArg, DatesSetArg, DayHeaderContentArg, EventClickArg, EventDropArg, EventHoveringArg } from "@fullcalendar/core"
import dayGridPlugin from "@fullcalendar/daygrid"
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid"
import interactionPlugin, { DateClickArg, EventDragStartArg } from '@fullcalendar/interaction'
import scrollGridPlugin from "@fullcalendar/scrollgrid"
import jaLocale from '@fullcalendar/core/locales/ja'
import { CheckClass, getColor, IconClass, InputClass } from "contexts/style"
import { EntryForm } from "components/EntryForm"
import { UpdateForm } from "components/UpdateForm"
import { useMongoDB } from "contexts/MongoDBContext"
import EventContent from "components/EventContent"
import { useUpdateBooking } from "contexts/useCollection"
import { getDateString } from "contexts/dateUtils"
import Tooltip from "components/Tooltip"
import { Loading } from "components/Loading"
import { useFindBookings } from "contexts/useMongoQuery"
import { useRealmApp } from "contexts/RealmApp"
import { useApolloClient, useReactiveVar } from "@apollo/client"
import { filterStates } from "graphql/RealmApolloProvider"
import { handleMoveBooking, OUTER_BOOK_TYPE, PetIDs, ModalityLists } from "contexts/enviroments"
import showMessage from "components/showMessage"
import useDailyNotices from "graphql/useDailyNotices"
import DailyNoticeForm from "components/DailyNoticeForm"

let mongoCollectionWatch: AsyncGenerator<Realm.Services.MongoDB.ChangeEvent<any>> | null = null

const SearchForm = ({
                        dateRange,
                        timeUnit,
                        resources,
                        handleChange,
                        handleDateChange,
                        handleTimeUnitChange,
                        baseResources
                    }: {
    dateRange: { start: Date, end: Date | null };
    timeUnit: string;
    resources: KV[];
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleDateChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleTimeUnitChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    baseResources: { [key: string]: string }[]
}) => {
    return <div className="h-24 bg-theme-200 p-2">
        <div className="flex justify-center items-center">
            <label className="pl-4 pr-1">検査日</label><input className={InputClass} type="date" name="date"
                                                           value={getDateString(dateRange.start)}
                                                           onChange={handleDateChange}/>
            <label className="pl-4 pr-1">目盛</label>
            <select className={InputClass} name="timeUnit" value={timeUnit} onChange={handleTimeUnitChange}>
                <option value="5">5分</option>
                <option value="20">20分</option>
                <option value="60">60分</option>
            </select>
            <label className={`ml-4 mr-1 px-2 text-white bg-${getColor(0)}`}>外来</label>
            <label className={`mx-1 px-2 text-white bg-${getColor(1)}`}>外注</label>
            <label className={`mx-1 px-2 text-white bg-${getColor(2)}`}>通常ドック</label>
            <label className={`mx-1 px-2 text-white bg-${getColor(6)}`}>CMC・VIP</label>
        </div>
        <div className="flex justify-center items-center">{
            baseResources.map(resource => <React.Fragment key={resource.id}><input className={CheckClass}
                                                                                   type="checkbox" name={resource.id}
                                                                                   id={resource.id}
                                                                                   checked={Boolean((resources || []).find(v => v.id === resource.id))}
                                                                                   onChange={handleChange}/><label
                className="pl-1 pr-4">{resource.title}</label></React.Fragment>)
        }</div>
    </div>
}

const getTimeUnit = (value: string) => `${value === "60" ? "01:00" : "00:" + ("0" + value).slice(-2)}:00`

export const List = () => {
    const {db} = useMongoDB()
    const app = useRealmApp()
    const client = useApolloClient()
    const values: KV = useReactiveVar(filterStates)
    const baseResources = app.resources.filter((v: KV) => !v.noslot && !v.exam).sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0)
    const filter = values.calendar || {
        dateRange: {start: new Date(), end: null},
        timeUnit: "5",
        resources: undefined,
        monthView: false
    }
    if (!filter.resources && baseResources?.length) filter.resources = baseResources.filter(v => ["MRI", "PET-CT_1", "PET-CT_2"].includes(v.id))

    const dateRange = filter.dateRange
    const timeUnit = filter.timeUnit
    const resources = filter.resources
    const resourceIds = (resources || []).map((v: any) => v.id)

    const {loading, data: events, refetch} = useFindBookings(db, dateRange)
    const calendarRef = useRef<FullCalendar | null>(null)
    const [selectBookingId, setSelectBookingId] = useState<string>("")
    const [defaultDue, setDefaultDue] = useState("")

    const { loading: dailyNoticeLoading, data: dailyNoticeData, error: dailyNoticeError, updateData: updateDailyNotice} = useDailyNotices({ start: dateRange.start, end: dateRange.end })

    const updateBooking = useUpdateBooking()

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let resources: { [key: string]: string }[] = []
        baseResources.forEach(resource => {
            if ((document.getElementById(resource.id || '') as HTMLInputElement).checked) resources.push(resource)
        })
        filterStates({...filterStates(), calendar: {...filter, resources: resources}})
    }
    const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const calendar = calendarRef.current?.getApi()
        calendar?.gotoDate(e.currentTarget.value)
        calendar?.changeView('resourceTimeGridSevenDay')
    }
    const handleTimeUnitChange = (e: ChangeEvent<HTMLSelectElement>) => filterStates({
        ...filterStates(),
        calendar: {...filter, timeUnit: e.currentTarget.value}
    })
    // get fullcalendar dates and set dateRange state
    const handleDatesSet = (arg: DatesSetArg) => {
        let startDate = new Date(arg.start)
        startDate.setHours(0)
        let endDate = new Date(arg.end)
        endDate.setHours(0)
        filterStates({
            ...filterStates(),
            calendar: {
                ...filter,
                dateRange: {start: startDate, end: endDate},
                monthView: arg.view.type === "dayGridMonth"
            }
        })
    }

    const timeClick = (info: DateClickArg) => {
        //  info.dayEl.style.backgroundColor = 'red';
        setResource(String(info.resource?.id))
        let mainModalityInfo: KV = {}
        for (const idx in baseResources) {
            if (String(baseResources[idx]['id']) === info.resource?.id) {
                setResourceInformation(baseResources[idx])
            }
            if (ModalityLists.includes(String(baseResources[idx]['id']))) {
                mainModalityInfo[String(baseResources[idx]['id'])] = baseResources[idx]
            }
        }
        setSecondResourceInformation(mainModalityInfo)
    }

    const handleDrop = async (eventDrop: EventDropArg) => handleMoveBooking({eventDrop, events, client, updateBooking})

    // read json property data
    useEffect(() => {
        async function handleBeforeUnloadEvent(event: BeforeUnloadEvent) {
            event.preventDefault()
            return event.returnValue = "Are you sure you want to exit?"
        }

        window.addEventListener("beforeunload", handleBeforeUnloadEvent)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnloadEvent)
            try {
                if (mongoCollectionWatch) {
                    mongoCollectionWatch.return(null)
                    mongoCollectionWatch = null
                }
            } catch (err) {
                alert(`Error removing watching stream.  ${(err as Error).message}`)
            }
        }
    }, [])


    // Modal(Entry Form)のOn, Offを制御
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)
    const [dailyModalTimestamp, setDailyModalTimestamp] = useState(0)
    const [starttime, setStarttime] = useState(new Date())
    const [checkin, setCheckin] = React.useState<string>("")
    const [resource, setResource] = useState("")
    const [resourceInformation, setResourceInformation] = useState<{ [key: string]: any }>({})
    const [secondResourceInformation, setSecondResourceInformation] = useState<{ [key: string]: any }>({})

    const convertCheckIn = async (checkinDate: Date) => {
        const hour = String(checkinDate.getHours()).padStart(2, '0')
        const minutes = String(checkinDate.getMinutes()).padStart(2, '0')
        return `${hour}:${minutes}`
    }

    /**
     * 新規でカレンダーの枠をクリックした時に発動
     * @param selectionInfo
     */
    const handleModalSelect = async (selectionInfo: DateSelectArg) => {
        if (!selectionInfo.resource) {
            await showMessage("モダリティ情報が取得できませんでした。\r\nselectionInfo => NULL", {error: true})
            return
        }
        // Entry Modalを出力
        setIsModalOpen(true)
        // 選択した時刻
        selectionInfo.start.setMinutes(selectionInfo.start.getMinutes())
        setStarttime(selectionInfo.start)

        // * 受付時刻
        // PETの場合、９０分前
        // ESの場合、3時間前
        // その他30分前
        const bookDateTime = new Date(selectionInfo.start.toString())
        if (PetIDs.includes(selectionInfo.resource.id)) {
            bookDateTime.setMinutes(bookDateTime.getMinutes() - 90)
        } else if (selectionInfo.resource.id === 'ES') {
            bookDateTime.setMinutes(bookDateTime.getMinutes() - 180)
        } else if (selectionInfo.resource.id === 'MRI') {
            bookDateTime.setMinutes(bookDateTime.getMinutes() - 20)
        } else {
            bookDateTime.setMinutes(bookDateTime.getMinutes() - 30)
        }
        const checkin = await convertCheckIn(bookDateTime)
        setCheckin(checkin)

        // デフォルトの期日(2日後)をセット
        const defaultday = selectionInfo.start.getDate() + 2
        const defaultmonth = selectionInfo.start.getMonth() + 1
        const defaultDue = selectionInfo.start.getFullYear() + "-" + defaultmonth.toString().padStart(2, "0") + "-" + defaultday.toString().padStart(2, "0")
        setDefaultDue(defaultDue)

    }

    /**
     * 予約枠をクリックした時に発動
     * @param e
     */
    const handleEventClick = async (e: EventClickArg) => {
        // カレンダー「月」でイベントクリックした場合、何もしない
        if (filter.monthView) {
            return
        }
        if (!e.event.start) {
            await showMessage("予約開始時刻が存在しません。システム管理者に問い合わせてください。", {error: true})
            return
        }
        // ドックの場合は、詳細を非表示
        if (e.event._def.title === "ドック" || e.event._def.title === "CMC・VIP") {
            await showMessage("ドック予約を修正される場合、健診システムから連携してください。", {error: true})
            return
        }
        setTooltipEvent(undefined)
        setIsUpdateModalOpen(true)
        setSelectBookingId(e.event.extendedProps.id)

        // Modality情報をセット
        setResource(String(e.event.extendedProps.modalityID))
        let mainModalityInfo: KV = {}
        for (const idx in baseResources) {
            if (String(baseResources[idx]['id']) === e.event.extendedProps.modalityID) {
                setResourceInformation(baseResources[idx])
            }
            if (ModalityLists.includes(String(baseResources[idx]['id']))) {
                mainModalityInfo[String(baseResources[idx]['id'])] = baseResources[idx]
            }
        }
        setSecondResourceInformation(mainModalityInfo)

        // * 受付時刻
        // PETの場合、９０分前
        // ESの場合、3時間前
        // その他30分前
        const bookDateTime = new Date(e.event.start.toString())
        if (PetIDs.includes(e.event.extendedProps.modalityID)) {
            bookDateTime.setMinutes(bookDateTime.getMinutes() - 90)
        } else if (e.event.extendedProps.modalityID === 'ES') {
            bookDateTime.setMinutes(bookDateTime.getMinutes() - 120)
        } else {
            bookDateTime.setMinutes(bookDateTime.getMinutes() - 60)
        }
        const checkin = await convertCheckIn(bookDateTime)
        setCheckin(checkin)
    }

    // Handling Tooltip
    const [tooltipEvent, setTooltipEvent] = useState<CalendarEvent>()
    const eventElement = useRef<HTMLElement>()
    const handleMouseEnter = (arg: EventHoveringArg) => {
        if (filter.monthView) return
        if (arg.event.extendedProps.bookingType === OUTER_BOOK_TYPE) return
        eventElement.current = arg.el
        setTooltipEvent(events?.find(event => event.id === arg.event.id))
    }
    const handleDragStart = (arg: EventDragStartArg) => setTooltipEvent(undefined)
    const handleMouseLeave = (arg: EventHoveringArg) => setTooltipEvent(undefined)

    const handleDailyNoticeClick = (e:MouseEvent<HTMLDivElement>) => {
        const timestamp = e.currentTarget.dataset.timestamp
        setDailyModalTimestamp(Number(timestamp))
    }

    const DayHeader = (arg:DayHeaderContentArg) => {
        const timestamp = new Date(arg.date).getTime()
        return <div className="cursor-pointer" data-timestamp={timestamp} onClick={handleDailyNoticeClick}>{arg.text}<div className="px-1 bg-theme-200 max-w-xs">{dailyNoticeData?.find(data => data._id === timestamp)?.notice || ''}</div></div>
    }
    // End handling Tooltip
    return <>
        {(loading || dailyNoticeLoading) && <Loading full/>}
        <Tooltip element={eventElement.current} event={tooltipEvent}/>
        <EntryForm
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            starttime={starttime}
            selectResource={resource}
            selectResourceInformation={resourceInformation}
            secondResourceInformation={secondResourceInformation}
            defaultDue={defaultDue}
            checkin={checkin}
            setCheckin={setCheckin}
            events={events}
            refetch={refetch}
        />
        <UpdateForm
            isUpdateModalOpen={isUpdateModalOpen}
            selectBookingId={selectBookingId}
            selectResourceInformation={resourceInformation}
            secondResourceInformation={secondResourceInformation}
            setIsUpdateModalOpen={setIsUpdateModalOpen}
            events={events}
            refetch={refetch}
        />
        <SearchForm
            dateRange={dateRange} timeUnit={timeUnit} resources={resources}
            handleChange={handleChange} handleDateChange={handleDateChange} handleTimeUnitChange={handleTimeUnitChange}
            baseResources={baseResources}
        />
        <DailyNoticeForm timestamp={dailyModalTimestamp} setTimestamp={setDailyModalTimestamp} data={dailyNoticeData} update={updateDailyNotice} />
        {isModalOpen || isUpdateModalOpen ? <div/> :
            <FullCalendar
                ref={calendarRef}
                locale={jaLocale}
                plugins={[resourceTimeGridPlugin, scrollGridPlugin, dayGridPlugin, interactionPlugin]}
                views={{
                    resourceTimeGridSevenDay: {
                        type: 'resourceTimeGrid',
                        duration: {days: 7},
                        buttonText: '週'
                    }
                }}
                headerToolbar={{
                    start: 'prev,next today',
                    center: 'title',
                    end: 'dayGridMonth,resourceTimeGridSevenDay'
                }}
                editable={filter.monthView ? false : true}
                eventDurationEditable={false}
                eventResourceEditable={true}
                eventOverlap={true}
                eventMouseEnter={handleMouseEnter}
                eventMouseLeave={handleMouseLeave}
                eventDragStart={handleDragStart}
                eventDrop={handleDrop}
                eventColor="lightgray"
                select={handleModalSelect}
                selectable={true}
                dayHeaderContent={DayHeader}
                initialDate={dateRange.start}
                initialView={filter.monthView ? "dayGridMonth" : "resourceTimeGridSevenDay"}
                allDaySlot={false}
                dayMinWidth={96}
                slotMinTime="08:00:00"
                slotMaxTime="19:00:00"
                slotDuration={getTimeUnit(timeUnit)}
                slotLabelFormat={(arg) => arg.date.minute ? String(arg.date.minute) : (String(arg.date.hour) + ":00")}//.toTimeString() : "0000"}
                slotLabelInterval={getTimeUnit(timeUnit)}
                dateClick={timeClick}
                datesSet={handleDatesSet}
                datesAboveResources
                resources={resources}
                events={events ? (filter.monthView ? events.filter(v => resourceIds.includes(v.resourceId)) : events) : undefined}
                eventContent={EventContent}
                eventClick={handleEventClick}
            />
        }
    </>
}
