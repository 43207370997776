import React from 'react'
import { sendPostRequest } from "./sendEmail"

export const sendFixToExternal = async (toEmails: string[], params: KV, modalityParam: any) => {

    const modality = `検査時間:　${modalityParam["execTimeRange"]}
　モダリティ: ${modalityParam["id"]}
　部位：${modalityParam["body2"]}
　造影剤：${modalityParam["contrast"]}
        `

    const body = `${params["doctor"]}先生

下記のご予約を確定しました。


---------------------------------------------------------------
　予約No:　${params["bookNo"]}
　予約日:  ${params["bookDate"]}
　来院受付時間:  ${params["receptTime"]}
　${modality}
---------------------------------------------------------------

内容に相違、もしくは変更がある場合は、検査予約日3営業日前まで下記ページより、予約取消の上、再度予約取得をお願いたします。
https://medical-checkup-booking.pages.dev/external/list

3営業日を過ぎてしまった場合の予約取消・変更は下記番号までご連絡ください。

医療法人社団NIDC セントラルクリニック世田谷
予約受付：03-6805-9110（営業時間　平日9:00～18:00）　https://nidc.or.jp/

本メールは送信専用のため、直接ご返信いただいてもお問い合わせにはお答えできませんので、何かございましたら、お手数ですが、当院まで直接お電話ください。
`
    const today = new Date()
    const month = (today.getMonth() + 1).toString().padStart(2, '0')
    const day = today.getDate().toString().padStart(2, '0')
    const subject = `【セントラルクリニック世田谷】予約確定_${month}月${day}日`
    await sendPostRequest(toEmails, subject, body)
}



export const sendFixToInternal = async (toemails: string[], params: KV, modalityParam: any, hospitals: any) => {

    const url = String(process.env.REACT_APP_HOST)

    const modality = `検査時間:　${modalityParam["execTimeRange"]}
　モダリティ: ${modalityParam["id"]}
　部位：${modalityParam["body2"]}
　造影剤：${modalityParam["contrast"]}
        `
    const link = `${url}/booking/detail/${params["bookNo"]}`

    const body = `セントラルクリニック世田谷　各位

予約確定されました。
前日に予約確認の電話をしてください。
${link}
　　　　　　　
---------------------------------------------------------------
　予約No:　${params["bookNo"]}
　予約日:  ${params["bookDate"]}
　来院受付時間:  ${params["receptTime"]}
　${modality}
　体重: ${modalityParam["weight"]}
　紹介病院:　${hospitals["clinic_name"]}
　紹介科:　${hospitals["department"]}
　紹介医師:　${hospitals["doctor_name"]}
---------------------------------------------------------------
`
    const today = new Date()
    const month = (today.getMonth() + 1).toString().padStart(2, '0')
    const day = today.getDate().toString().padStart(2, '0')
    const subject = `予約確定（${month}月${day}日/予約No.${params["bookNo"]}）`
    await sendPostRequest(toemails, subject, body)
}