import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Loading } from "components/Loading";
import { useRealmApp } from "contexts/RealmApp";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { ButtonClass } from "contexts/style";
import showMessage from "components/showMessage";


const Verify = () => {
    const app = useRealmApp()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [processing, setProcessing] = useState(false)
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [message, setMessage] = useState("")
    const [error, setError] = useState("")
    const mode = searchParams.get('mode') || "";
    const token = searchParams.get('token') || "";
    const tokenId = searchParams.get('tokenId') || "";
    const tempId = searchParams.get('tempId') || "";
    const handleChange = async (e: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>) => {
        setError("")
        if (e.target instanceof HTMLInputElement) {
            if (e.target.name === "password") setPassword(e.target.value)
            if (e.target.name === "repeatPassword") setRepeatPassword(e.target.value)
        } else if (e.target instanceof HTMLButtonElement) {
            if (e.target.name === "change") {
                if (!/^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/.test(password)) {
                    await showMessage("パスワードは6文字以上でアルファベットと数字を両方含んでください", { error: true })
                } else if (password !== repeatPassword) {
                    await showMessage("再入力されたパスワードが一致しません", { error: true })
                    return null
                } else {
                    setProcessing(true)
                    try {
                        app.app.emailPasswordAuth.resetPassword({ token, tokenId, password })
                        setMessage("パスワードを更新しました。新しいパスワードでログインできます")
                    } catch (e) {
                        setError("リンクが無効になっているか、不正なパスワードです。")
                    }
                    setProcessing(false)
                }
            }
            // if (e.target.name === "log") {
            //     if (app.currentUser) app.logOut()
            //     else navigate("/login")
            // }
        }
    }

    useEffect(() => {
        const func = async () => {
            // if (!oobCode) {
            //     setMessage("コードがないか、すでに処理済みです")
            //     return
            // }
            let error: string | null | undefined | void = null
            switch (mode) {
                case 'resetPassword':
                    setMessage("新しいパスワードを入力してください")
                    break;
                case 'verifyEmail':
                    setProcessing(true)
                    error = await app.app.emailPasswordAuth.confirmUser({ token, tokenId }).catch(e => (e as Error)?.message || "");
                    if (!error) {
                        const response = await fetch(`https://eastasia.azure.data.mongodb-api.com/app/${app.app.id}/endpoint/userWelcome`, {
                            method: 'POST',
                            body: JSON.stringify({ id: tempId }),
                            headers: { 'Content-type': 'application/json; charset=UTF-8' },
                            mode: 'no-cors',
                        })
                    }
                    setProcessing(false)
                    setMessage(!error ? "メールアドレスを認証しました。ログインしてシステムを利用できます" : "")
                    setError(error ? error : "")
                    window.history.replaceState({}, document.title, window.location.pathname);
                    if (app.currentUser) app.logOut()
                    break;
                default:
                    setMessage("処理内容がありません")
            }
        }
        func()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])



    return <div className="overflow-y-scroll">
        <div className="mx-auto max-w-md p-2 text-theme-900 text-xl">アカウント認証</div>
        <div className="flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            {processing && <Loading full />}
            <div className="max-w-md w-full space-y-8">
                <div className="rounded-md shadow-sm -space-y-px min-h-44">
                    {error ? <div className="m-2 text-red-700">{error}</div> : <div className="m-2">{message}</div>}
                    {mode === "resetPassword" && <><div>
                        <label htmlFor="password" className="sr-only">
                            パスワード
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                            placeholder="パスワード"
                            value={password}
                            onChange={handleChange}
                        />
                    </div>
                        {/*<PasswordInput mode="top" value={password} onChange={handleChange} />*/}
                        <div>
                            <label htmlFor="repeatPassword" className="sr-only">
                                パスワード（再入力）
                            </label>
                            <input
                                id="repeatPassword"
                                name="repeatPassword"
                                type="password"
                                className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                                placeholder="パスワード（再入力）"
                                value={repeatPassword}
                                onChange={handleChange}
                            />
                        </div></>}
                    <div className="p-2 flex justify-center space-x-2">
                        {mode === "resetPassword" && <button name="change" className={ButtonClass} type="button" onClick={handleChange}><span className="flex items-center pr-1">
                            <CheckCircleIcon className="h-5 w-5 text-theme-100 group-hover:text-theme-400" aria-hidden="true" />
                        </span>確定</button>}
                    </div>
                    <div className="text-sm">※この画面から検査予約システムの画面へは遷移できません。</div>
                </div>
            </div>
        </div>
    </div>
}

export default Verify