import React, { ChangeEvent, FormEvent } from 'react'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import showMessage from './showMessage'


export default function ChangePassword({ reset }: { reset: (password: string) => Promise<void> }) {
  console.log(process.env.PUBLIC_URL)
  const [password, setPassword] = React.useState('')
  const [password2, setPassword2] = React.useState('')
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "password") setPassword(e.target.value)
    if (e.target.name === "password2") setPassword2(e.target.value)
  }
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (password !== password2) await showMessage("再入力パスワードが一致しません。", {error:true})
    else if (password.length < 6) await showMessage("パスワードは６文字以上必要です。", {error:true})
    else {
      try {
        await reset(password)
        await showMessage("パスワードを更新しました")
        window.location.href = window.location.href.split('?')[0]
      } catch (e) {
        if (e instanceof Error) await showMessage("パスワードの更新に失敗しました\n" + e.message, {error:true})
      }
    }
    return false
  }
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img
            className="mx-auto h-12 w-auto"
            src="/images/header_logo.png"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">パスワードの変更</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit} >
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="password" className="sr-only">
                パスワード
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                placeholder="パスワード"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                パスワード（再入力）
              </label>
              <input
                id="password2"
                name="password2"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                placeholder="パスワード（再入力）"
                value={password2}
                onChange={handlePasswordChange}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <LockClosedIcon className="h-5 w-5 text-theme-500 group-hover:text-theme-400" aria-hidden="true" />
              </span>
              確定
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}