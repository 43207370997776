import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { gql, useMutation, useReactiveVar } from '@apollo/client'
import { useNavigate } from 'react-router-dom';
import { ArrowDownTrayIcon, ArrowPathIcon, ChevronLeftIcon, ChevronRightIcon, PencilSquareIcon, PrinterIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/solid';

import { CheckClass, getStatusColor, IconClass, InputClass, SmallButtonClass } from 'contexts/style';
import options from "contexts/options.json"
import { getAge, JSTDate, JSTTime, today } from 'contexts/dateUtils';
import { Loading } from 'components/Loading';
import { useRealmApp } from 'contexts/RealmApp';
import { filterStates } from 'graphql/RealmApolloProvider';
import { DatePicker, FilterInput } from 'components/UIParts';
import makePdf from './sheet';
import showMessage from 'components/showMessage';
import { INTERNAL_TYPE, OUTER_BOOK_TYPE, OUTSIDE_TYPE } from 'contexts/enviroments';
import { useMongoDB } from 'contexts/MongoDBContext';
//        makePdf(data.attendances, yearMonth, `${user?.surname || ""}　${user?.givenName || ""}`)

const heads = [
    { field: "bookingStatus", text: "ステータス" },
    { field: "start", text: "日付" },
    { field: "checkin", text: "受付時間" },
    { field: "ivCheckin", text: "IV時間" },
    { field: "start", text: "予約時間" },
    { field: "patientID", text: "患者ID" },
    { field: "patientName", text: "患者名" },
    { field: "patient.nameKana", text: "患者名カナ" },
    { field: "modalityID", text: "モダリティ" },
    { field: "body2", text: "部位" },
    { field: "contrast", text: "造影剤" },
    { field: "bookingType", text: "種別" },
    { field: "courseName", text: "コース" },
    { field: "patient.gender", text: "性別" },
    { field: "patient.birthDate", text: "生年月日" },
    { field: "patient.birthDate", text: "年齢" },
    { field: "height", text: "身長" },
    { field: "weight", text: "体重" },
    { field: "_id", text: "アクセッション番号" },
    { field: "start", text: "開始時間" },
    { field: "end", text: "終了時間" },
]
const statusSet = [
    { key: 0, value: "仮予約" },
    { key: 1, value: "予約確定" },
    { key: 2, value: "IV実施" },
    { key: 3, value: "検査中" },
    { key: 4, value: "実施済" },
    { key: 5, value: "来院取消" },
]

const deletingData = gql`mutation DeleteBooking($id: String!) {
    deletedData: deleteOneBooking(query: { _id: $id }) {
        _id
        bookingStatus
        start
        checkin
        end
        modalityID
        bookingType
        body1
        body2
        height
        weight
        contrast
        patientID
        patientName
        patient {
            name
            nameKana
            birthDate
            gender
        }
        diagnosis
        clinicName
        department
        doctor
        created
    }
}`

const AddButton = ({ onClick }: { onClick?: () => void }) =>
    <button
        type="button"
        onClick={onClick}
        className="flex justify-center items-center bg-theme-50 hover:bg-theme-100 text-theme-800 w-12 h-12 rounded-3xl transition ease-in duration-200"
    >
        <PlusIcon className="w-8 h-8" />
    </button>

const bookingsData = gql`query GetBookings($query: BookingQueryInput!) {
  bookings(query: $query, sortBy: START_ASC, limit: 1000) {
    _id
    bookingStatus
    start
    checkin
    ivCheckin
    end
    modalityID
    bookingType
    courseName
    body1
    body2
    height
    weight
    contrast
    patientID
    patientName
    patient {
        name
        nameKana
        birthDate
        gender
    }
    diagnosis
    clinicName
    department
    doctor
    created
  }
}
`

const getQueryVariables = (values: KV) => {
    let output: KV = { start : { $gte: values.start }, end: { $lte: values.end } }
    if (values.patientID) output.patientID = values.patientID
    if (values.patientName) output.patient = { nameKana: { $regex: values.patientName } }
    return output
}

export const List = () => {
    const app = useRealmApp()
    const { db } = useMongoDB()
    const navigate = useNavigate()
    const baseResources = app.resources.filter(v => !v.exam)
    const noslotResources = baseResources.filter(v => v.noslot).map(v => v.id as string)
    const values: KV = useReactiveVar(filterStates)
    const names = values.bookingList || { modality: "", modality2: "", statuses: [0, 1, 2, 3, 4], start: today(), end: new Date(today().getTime() + 86399999), oneday: true, patientID: "", patientName: "" }
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<KV[]>([])
    const showingData = data.filter(datum => (names.modality || baseResources.map(v => v.id)).includes(datum.modalityID) || names.modality2 === datum.modalityID).filter(datum => names.statuses.includes(datum.bookingStatus))
    const [deleteDataMutation, { loading: deleting }] = useMutation(deletingData, {
        update: (cache, { data: { deletedData } }) => {
            const normalizedId = cache.identify(deletedData);
            cache.evict({ id: normalizedId });
            cache.gc();
        }
    });

    const handleDateChange = (name: string, value: any) => {
        switch (name) {
            case "startDate":
                const dateStart = new Date(value)
                filterStates({ ...filterStates(), bookingList: { ...names, start: dateStart, end: names.oneday ? new Date(dateStart.getTime() + 86399999) : names.end } })
                break
            case "endDate":
                setValue("end", new Date(value.getTime() + 86399999))
                break
            default:

        }
    }
    const handleDateMove = (e: MouseEvent<HTMLButtonElement>) => {
        let dateStart = names.start
        let dateEnd = names.end
        let oneday = names.oneday
        switch (e.currentTarget.name) {
            case "previousDay":
                dateStart = new Date(dateStart.getTime() - 86400000)
                dateEnd = new Date(dateEnd.getTime() - 86400000)
                break
            case "today":
                dateStart = today()
                dateEnd = new Date(today().getTime() + 86399999)
                break
            case "nextDay":
                dateStart = new Date(dateStart.getTime() + 86400000)
                dateEnd = new Date(dateEnd.getTime() + 86400000)
                break
            case "oneday":
                oneday = !oneday
                if (oneday) {
                    dateEnd = new Date(dateStart.getTime() + 86399999)
                }
                break
            default:

        }
        filterStates({ ...filterStates(), bookingList: { ...names, oneday: oneday, start: dateStart, end: dateEnd } })
    }

    const setValue = (name: string, value: any) => {
        filterStates({ ...filterStates(), bookingList: { ...names, [name]: value } })
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (e.currentTarget.name.startsWith("status_")) {
            let statuses: number[] = [];
            [0, 1, 2, 3, 4, 5].forEach(key => {
                if ((document.getElementById(`status_${key}`) as HTMLInputElement).checked) statuses.push(key)
            })
            setValue("statuses", statuses)
        } else {
            setValue(e.currentTarget.name, e.currentTarget.value)
        }
    }

    const handleClick = (e: MouseEvent<HTMLButtonElement | HTMLTableRowElement>) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.currentTarget.dataset.detail) navigate(`/booking/detail/${e.currentTarget.dataset.id}`)
        else if (e.currentTarget.dataset.print) makePdf(data.find((v: KV) => v._id === e.currentTarget.dataset.id) || {})
        else navigate(`/booking/edit/${e.currentTarget.dataset.id}`)
    }

    const handleDelete = async (e: MouseEvent<HTMLButtonElement>) => {
        const id = e.currentTarget.value
        e.preventDefault()
        e.stopPropagation()
        if (!await showMessage("予約を完全に削除します。よろしいですか？", { confirm: true })) return null
        try {
            await deleteDataMutation({ variables: { id: id } })
            //            await refresh()
        } catch (e) {
            if (e instanceof Error) await showMessage(`エラー：予約を削除中にエラーが発生しました。\r\n${e.message}`, { error: true })
        }
    }

    const handleDownload = async (e: MouseEvent<HTMLButtonElement>) => {
        const csvHeader = [...heads.map(v => v.text), "臨床診断名", "予約依頼日", "施設", "診療科", "医師"].join(',') + '\n';
        const csvData = showingData.map(datum => [
            options.bookingStatus[datum.bookingStatus],
            JSTDate(new Date(datum.start)),
            datum.checkin,
            noslotResources.includes(datum.modalityID) ? "" : JSTTime(new Date(datum.start)),
            datum.begin,
            datum.checkout,
            datum._id,
            datum.modalityID,
            options.bookingType[datum.bookingType],
            datum.courseName,
            datum.body2 || "",
            (datum.contrast === true || datum.contrast === "造影") ? "◯" : "",
            datum.patientID,
            datum.patientName,
            datum.patient?.nameKana || '',
            datum.patient?.gender ? options.genderType[datum.patient.gender] : "",
            datum.patient?.birthDate && JSTDate(new Date(datum.patient?.birthDate)),
            datum.patient?.birthDate && getAge(new Date(datum.patient?.birthDate)),
            datum.height,
            datum.weight,
            datum.diagnosis,
            datum.created && JSTDate(new Date(datum.created)),
            datum.clinicName,
            datum.department,
            datum.doctor
        ].join(',')).join('\n');
        const csvContent = csvHeader + csvData;
        const csvFilePath = 'bookingdata.csv';
        const url = window.URL.createObjectURL(new Blob([csvContent])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', csvFilePath)
        document.body.appendChild(link)
        link.click()
        link.remove()
    }

    const getBookings = async () => {
        if (!db) {
            setData([])
            return
        }
        setLoading(true)
        const query = getQueryVariables(names)
        console.log(query)
        const patientQuery = query.patient ? {...query.patient} : undefined
        delete query.patient
        let stages: KV[] = [
            { $match: query },
            {
                $lookup: {
                  from: "patients",
                  localField: "patient",
                  foreignField: "_id",
                  as: "patient"
                }
            },
            { $unwind: "$patient" }
        ]
        if (patientQuery) stages.push({ $match: { "patient.nameKana": patientQuery.nameKana } })
        const data = await db.collection("bookings").aggregate(stages)
        console.log(data)
        setData(data)
        setLoading(false)
    }
    
    useEffect(() => {
        getBookings()        
    }, [db, values])

    return <>
        <div className="absolute left-0 top-0 w-full h-full z-10 pointer-events-none">
            <div className="fixed right-4 bottom-10 pointer-events-auto"><AddButton onClick={() => navigate("/booking/create")} /></div>
        </div>
        <div className="relative h-full grid grid-rows-list">
            <div className="bg-theme-200 p-2 relative">
                <div className="flex flex-wrap justify-center items-center gap-2">
                    <button className={IconClass} type="button" onClick={() => getBookings()}><ArrowPathIcon /></button>
                    <button className={IconClass} type="button" onClick={handleDownload}><ArrowDownTrayIcon /></button>
                    <select className={InputClass} name="modality" value={names.modality} onChange={handleChange}><option value="">全モダリティ</option>{
                        baseResources.map(resource => <option key={resource.id} value={resource.id}>{resource.title}</option>)
                    }</select>
                    <select className={InputClass} name="modality2" value={names.modality2} onChange={handleChange}><option value="">- 未選択</option>{
                        baseResources.map(resource => <option key={resource.id} value={resource.id}>{resource.title}</option>)
                    }</select>
                    <button type="button" className={SmallButtonClass} name="previousDay" onClick={handleDateMove}><ChevronLeftIcon className="w-5 h-5" /></button>
                    <DatePicker name="startDate" value={names.start} setValue={handleDateChange} />
                    {!names.oneday && <>
                        <span className="">〜</span>
                        <DatePicker name="endDate" value={names.end} setValue={handleDateChange} />
                    </>}
                    <button type="button" className={SmallButtonClass} name="nextDay" onClick={handleDateMove}><ChevronRightIcon className="w-5 h-5" /></button>
                    <button type="button" className={SmallButtonClass} name="oneday" onClick={handleDateMove}>{names.oneday ? "複数日" : "単日"}</button>
                    <button type="button" className={SmallButtonClass} name="today" onClick={handleDateMove}>本日</button>
                    <FilterInput label="患者ID" name="patientID" value={names.patientID} setValue={setValue} />
                    <FilterInput label="患者名カナ" name="patientName" value={names.patientName} setValue={setValue} />
                    <div className="flex items-center gap-2">{statusSet.map(status => <React.Fragment key={status.key}><input className={CheckClass} type="checkbox" name={`status_${status.key}`} id={`status_${status.key}`} checked={Boolean(names.statuses.includes(status.key))} onChange={handleChange} /><label className="pr-4 whitespace-nowrap">{status.value}</label></React.Fragment>)}</div>
                </div>
                <div className="absolute bottom-2 right-2">{data && `${showingData.length} 件`}</div>
            </div>
            <div className="bg-white"></div>
            <div className="px-2 pb-2 h-full overflow-scroll flex flex-col z-0">
                {(loading || deleting) ? <Loading full /> :
                    <table className="mx-auto border-b border-gray-200 sm:rounded-b-md text-center text-sm text-gray-500">
                        <thead>
                            <tr>
                                <th></th>
                                {heads.map((head, i) => (
                                    <th
                                        key={(head.field || "") + i}
                                        data-name={head.field}
                                        data-value={i}
                                        scope="col"
                                    >
                                        {head.text}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {data && showingData.map((datum) => (
                                <tr key={datum._id} data-id={datum._id} data-detail="detail" className={`text-sm text-gray-900 cursor-pointer hover:bg-theme-50`} onClick={handleClick}>
                                    <td className="flex gap-1">
                                        {[OUTSIDE_TYPE, INTERNAL_TYPE, OUTER_BOOK_TYPE].includes(datum.bookingType) && <button className={IconClass} type="button" onClick={handleClick} data-id={datum._id}><PencilSquareIcon /></button>}
                                        {[OUTSIDE_TYPE, INTERNAL_TYPE].includes(datum.bookingType) && <button className={IconClass} type="button" onClick={handleDelete} value={datum._id}><TrashIcon /></button>}
                                        {datum.bookingType === OUTSIDE_TYPE && <button className={IconClass} type="button" data-print="print" onClick={handleClick} data-id={datum._id}><PrinterIcon /></button>}
                                    </td>
                                    <td className={`text-white bg-${getStatusColor(datum.bookingStatus)}`}>{options.bookingStatus[datum.bookingStatus]}</td>
                                    <td>{JSTDate(new Date(datum.start))}</td>
                                    <td>{datum.checkin}</td>
                                    <td>{datum.ivCheckin}</td>
                                    <td>{noslotResources.includes(datum.modalityID) ? "" : JSTTime(new Date(datum.start))}</td>
                                    <td>{datum.patientID}</td>
                                    <td>{datum.patientName}</td>
                                    <td>{datum.patient?.nameKana || ''}</td>
                                    <td>{datum.modalityID}</td>
                                    <td>{datum.body2 || ""}</td>
                                    <td>{(datum.contrast === true || datum.contrast === "造影") ? "◯" : ""}</td>
                                    <td>{options.bookingType[datum.bookingType]}</td>
                                    <td>{datum.courseName}</td>
                                    <td>{datum.patient?.gender ? options.genderType[datum.patient.gender] : ""}</td>
                                    <td>{datum.patient?.birthDate && JSTDate(new Date(datum.patient?.birthDate))}</td>
                                    <td>{datum.patient?.birthDate && getAge(new Date(datum.patient?.birthDate))}</td>
                                    <td>{datum.height?.toString()}</td>
                                    <td>{datum.weight?.toString()}</td>
                                    <td>{datum._id}</td>
                                    <td>{datum.begin}</td>
                                    <td>{datum.checkout}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    </>
}

export default List