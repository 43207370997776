import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { Loading } from 'components/Loading';
import { ButtonClass, getStatusColor, statusButtonClass } from 'contexts/style';
import { getAge, getSamedayArea, JSTDate, JSTTime } from 'contexts/dateUtils';
import options from 'contexts/options.json';
import { useRealmApp } from 'contexts/RealmApp';
import { ErrorMessage } from 'components/UIParts';
import { BEGIN_STATUS, BOOKED_STATUS, CANCEL_STATUS, CHECKOUT_STATUS, isPet, IV_STATUS, PetIDs } from 'contexts/enviroments';
import showMessage from 'components/showMessage';
import { FileList } from 'components/FileChooser';
import { useUpdateBooking, useUpdateRelatedBooking, useUpdateVisit } from 'contexts/useCollection';

const HeaderClass = "p-2 bg-theme-50"
const CellClass = "p-2 col-span-3 bg-white"

const fields = gql`{
    _id
    bookingStatus
    start
    checkin
    ivTime
    begin
    end
    checkout
    modalityID
    clinicName
    department
    doctor
    bookingType
    courseName
    body1
    body2
    contrast
    patientID
    patientName
    height
    weight
    glucose
    patient {
        name
        nameKana
        birthDate
        gender
    }
    operator
    visitID {
        _id
        files {
            fileID
            name
        }
        comment
    }
    diagnosis
    walkingStatus
    due
    report {
        image
        imageSubmission
        reportSubmission
    }
    delay
    updated
}`

const bookingData = gql`query($id: String!) {
    booking(query: { _id: $id }) 
        ${fields}
}`
// add id_ne
const bookingsData = gql`query($id: String!, $patientID: String!, $start: DateTime!, $end: DateTime!) {
    bookings(query: { _id_ne: $id, patientID: $patientID, start_gte: $start, end_lt: $end }) 
        ${fields}
}`

const updatingData = gql`mutation($id: String!, $set: BookingUpdateInput!) {
    updateOneBooking(query: { _id: $id }, set: $set)
    ${fields}
}`


const getAvailableTime = (ivTime:string) => {
    if (!ivTime) return ""
    const times = ivTime.split(":")
    return `${Number(times[0])+1}:${times[1] || "00"}`
}

const Edit = () => {
    const { id } = useParams<KV>()
    const app = useRealmApp()
    const noslotResources = app.resources.filter(v => v.noslot).map(v => v.id as string)
    const [comment, setComment] = useState<string>("")
    const [operator, setOperator] = useState<string>("")
    const { loading, data, error, refetch } = useQuery(bookingData, { variables: { id: id }, notifyOnNetworkStatusChange: true,})
    const [startDate, endDate] = getSamedayArea(data?.booking?.start)
    const { loading: loadingOtherBooking, data: otherBookingData, error:otherBookingError } = useQuery(bookingsData, { skip: !data?.booking, variables: { id: data?.booking?._id, patientID: data?.booking?.patientID, start: startDate, end: endDate } })
    const [updateDataMutation, { loading:updating, error:updateError }] = useMutation(updatingData)
    const updateVisit = useUpdateVisit()
    const handleChange = (e:ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        switch (e.currentTarget.name) {
            case "comment": 
                setComment(e.currentTarget.value)
                break
            case "operator":
                setOperator(e.currentTarget.value)
                break
            default:
        }
    }
    const handleClick = async (e:MouseEvent<HTMLButtonElement>) => {
        const name = e.currentTarget.name
        if (!data.booking) {
            await showMessage(`予約情報が読み込まれていません。`, {error:true})
            return
        }
        const statusBefore = data.booking.bookingStatus
        let set = { bookingStatus: statusBefore, statusBefore: statusBefore, start: data.booking.start, ivTime: data.booking.ivTime, begin: data.booking.begin, checkout: data.booking.checkout, operator: operator, comment: comment, updated: new Date()  }
        let message = "コメントの保存"
        switch (name) {
            case "IV": 
                set.ivTime = JSTTime()
                set.bookingStatus = IV_STATUS
                message = "IV実施"
                break
            case "start": 
                set.begin = JSTTime()
                set.bookingStatus = BEGIN_STATUS
                message = "検査開始"
                break
            case "checkout": 
                set.checkout = JSTTime()
                set.bookingStatus = CHECKOUT_STATUS
                if (!set.operator) { set.operator = app.currentUser?.customData.name as string }
                message = "完了"
                break
            case "cancel":
                set.bookingStatus = CANCEL_STATUS
                message = "取消"
                break
            case "reverse":
                set.bookingStatus = statusBefore - ((statusBefore === BEGIN_STATUS && !isPet(data.booking)) ? 2 : 1)
                switch (set.bookingStatus) {
                    case BOOKED_STATUS:
                        if (isPet(data.booking)) set.ivTime = ""
                        else set.begin = ""
                        break;
                    case IV_STATUS:
                        set.begin = ""
                        break;
                    case BEGIN_STATUS:
                        set.checkout = ""
                        break;
                    default:
                }
                message = "戻す"
                break
            default:
        }
        if (!await showMessage(`${message}処理を行います。よろしいですか？`, {confirm:true})) return null
        try {
            if (name === "comment") {
                await updateVisit(data.booking.visitID._id, { comment })
            } else {
                await updateDataMutation({ variables: { id: id, set: set } })
                // for IV, change bookingStatus of other pet booking 
                if ((set.bookingStatus > statusBefore && set.bookingStatus === IV_STATUS) || (set.bookingStatus < statusBefore && statusBefore === IV_STATUS)) {
                    otherBookingData.bookings?.filter((booking:any) => isPet(booking) && booking.bookingStatus === statusBefore).forEach(async (booking:any) => {
                        let set2 = { bookingStatus: set.bookingStatus, statusBefore: statusBefore, ivTime: set.ivTime, updated: new Date()  }
                        await updateDataMutation({ variables: { id: booking._id, set: set2 } })
                    })
                }
            }
            await showMessage(`${message}処理を行いました`)
        } catch (e) {
            await showMessage(`${message}処理中にエラーが発生しました。\r\n${(e instanceof Error) ? e.message : ""}`, {error:true})
        }
    }
    useEffect(() => {
        setComment(data?.booking?.visitID?.comment || "")
        setOperator(data?.booking?.operator || "")
    }, [data])

    if (loading || loadingOtherBooking) return <Loading full/>
    if (error || otherBookingError || updateError) return <ErrorMessage>{error || otherBookingError || updateError || ""}</ErrorMessage>
    if (!data?.booking) return <ErrorMessage>このIDの予約は存在しません</ErrorMessage>
    const datum = data.booking
    return <>
        {updating && <Loading full />}
        <div className="px-4 py-2 flex justify-between items-center bg-theme-100">
            <span>
                <span className="px-6 py-2 text-lg font-bold text-theme-800 bg-theme-300">{datum.modalityID}</span>
                <span className={`p-2 whitespace-nowrap text-lg text-white bg-${getStatusColor(datum.bookingStatus)}`}>{options.bookingStatus[datum.bookingStatus]}</span>
                <button className="w-8 h-8 text-white bg-theme-600 hover:bg-theme-700 rounded-md p-2 ml-4" type="button" onClick={() => refetch()}><ArrowPathIcon /></button>
            </span>
            <span>
                {datum.bookingStatus === BOOKED_STATUS && isPet(datum) && <button type="button" className={statusButtonClass(2)} name="IV" onClick={handleClick}>IV実施</button>}
                {datum.bookingStatus === (isPet(datum) ? IV_STATUS : BOOKED_STATUS) && <button type="button" className={statusButtonClass(3)} name="start" onClick={handleClick}>開始</button>}
                {datum.bookingStatus === BEGIN_STATUS && <button type="button" className={statusButtonClass(4)} name="checkout" onClick={handleClick}>完了</button>}
                {datum.bookingStatus > BOOKED_STATUS && <button type="button" className={statusButtonClass(6)} name="reverse" onClick={handleClick}>戻す</button>}
            </span>
            <Link to="/booking" className={ButtonClass}>検査一覧</Link>
        </div>
        <div className="m-4 grid grid-cols-2 gap-px bg-theme-800 border border-theme-800">
            <div className="flex flex-col gap-px">
                <div className="p-2 text-center bg-theme-200">予約</div>    
                <div className="flex-auto grid grid-cols-4 gap-px bg-theme-800">
                    <span className={HeaderClass}>患者ID</span><span className={CellClass}>{datum.patientID}</span>
                    <span className={HeaderClass}>カナ</span><span className={CellClass}>{datum.patient?.nameKana || ''}</span>
                    <span className={HeaderClass}>患者名</span><span className={CellClass}>{datum.patientName}</span>
                    <span className={HeaderClass}>生年月日</span><span className="p-2 bg-white">{datum.patient?.birthDate && JSTDate(new Date(datum.patient?.birthDate))}</span>
                    <span className="p-2 text-center bg-theme-50">歩行状況</span><span className="p-2 bg-white">{datum.walkingStatus || ""}</span>
                    <span className={HeaderClass}>年齢</span><span className="p-2 bg-white">{datum.patient?.birthDate && getAge(new Date(datum.patient?.birthDate))}</span>
                    <span className="p-2 text-center bg-theme-50">性別</span><span className="p-2 bg-white">{datum.patient?.gender ? options.genderType[datum.patient.gender] : ""}</span>
                    <span className={HeaderClass}>身長</span><span className="p-2 bg-white">{datum.height || ""}</span>
                    <span className="p-2 text-center bg-theme-50">体重</span><span className="p-2 bg-white">{datum.weight || ""}</span>
                </div>
                <div className="p-2 text-center bg-theme-200">基本情報</div>
                    <div className="row-span-2 grid grid-cols-4 gap-px bg-theme-800">
                    <span className={HeaderClass}>アクセッション</span><span className={CellClass}>{datum._id}</span>
                    <span className={HeaderClass}>臨床診断名</span><span className={CellClass}>{datum.diagnosis}</span>
                    <span className={HeaderClass}>検査</span><span className={CellClass}>{datum.modalityID}</span>
                    <span className={HeaderClass}>予約日</span><span className={CellClass}>{JSTDate(new Date(datum.start))}</span>
                    <span className={HeaderClass}>受付時間</span><span className={CellClass}>{datum.checkin}</span>
                    {!noslotResources.includes(datum.modalityID) && <><span className={HeaderClass}>予約時間</span><span className={CellClass}>{JSTTime(new Date(datum.start))}　〜　{JSTTime(new Date(datum.end))}</span></>}
                    <span className={HeaderClass}>開始時間</span><span className={CellClass}>{datum.begin}</span>
                    <span className={HeaderClass}>完了時間</span><span className={CellClass}>{datum.checkout}</span>
                    <span className={HeaderClass}>種別</span><span className={CellClass}>{options.bookingType[datum.bookingType]}{datum.courseName ? ` - ${datum.courseName}` : ""}</span>
                    <span className={HeaderClass}>紹介病院</span><span className={CellClass}>{datum.clinicName}</span>
                    <span className={HeaderClass}>紹介科</span><span className={CellClass}>{datum.department}</span>
                    <span className={HeaderClass}>紹介医師</span><span className={CellClass}>{datum.doctor}</span>
                    <span className={HeaderClass}>報告期日</span><span className={CellClass}>{datum.due ? JSTDate(new Date(datum.due)) : ""}</span>
                    <span className={HeaderClass}>画像媒体</span><span className={CellClass}>{datum.report?.image || ''}</span>
                    <span className={HeaderClass}>画像提出</span><span className={CellClass}>{datum.report?.imageSubmission || ''}</span>
                    <span className={HeaderClass}>報告書</span><span className={CellClass}>{datum.report?.reportSubmission || ''}</span>
                </div>
            </div>
            <div className="flex flex-col gap-px">
                <div className="bg-white flex flex-col">
                    <div className="p-2 text-center bg-theme-200 border-b border-theme-800">部位・造影剤</div>
                    <div className="p-2 grow flex justify-evenly items-center"><span>{datum.body2}</span><span>{datum.contrast}</span>{datum.delay && <span>Delayあり</span>}</div>
                </div>
                <div className="px-2 py-1 text-center bg-theme-50">コメント
                    <button type="button" className={ButtonClass} name="comment" onClick={handleClick}>保存</button>
                    <button type="button" className={ButtonClass}>定型</button>
                </div>
                <textarea className="grow border-white" name="comment" value={comment} placeholder="ここにコメントを入力できます" onChange={handleChange}></textarea>
                {PetIDs.includes(datum.modalityID) && <>
                        <div className="p-2 text-center bg-theme-200">PET-CT項目</div>
                        <div className="grid grid-cols-4 gap-px bg-theme-800">
                            <span className={HeaderClass}>IV時刻</span><span className="p-2 bg-white">{datum.ivTime || ""}</span>
                            <span className="p-2 text-center bg-theme-50">血糖値</span><span className="p-2 bg-white">{datum.glucose || ""}</span>
                        </div>
                    </>}
                <div className="p-2 text-center bg-theme-200">本日他検査</div>
                <div className="flex items-start bg-white overflow-x-scroll"><table className="border-collapse border border-gray-100 text-center"><tbody>
                    <tr>{["ステータス", "開始時間", "完了時間", "モダリティ", "部位", "造影剤"].map(v => <th key={v} className="px-4 font-normal bg-theme-100 border border-gray-200">{v}</th>)}</tr>
                    {otherBookingData && otherBookingData.bookings.map((datum:KV) => <tr key={datum._id}>
                        <td className={`text-white border border-gray-200 bg-${getStatusColor(datum.bookingStatus)}`}>{options.bookingStatus[datum.bookingStatus]}</td>
                        <td className="border border-gray-200">{datum.begin || JSTTime(new Date(datum.start))}</td>
                        <td className="border border-gray-200">{datum.checkout || JSTTime(new Date(datum.end))}</td>
                        <td className="border border-gray-200">{datum.modalityID}</td>
                        <td className="border border-gray-200">{datum.body2}</td>
                        <td className="border border-gray-200">{(datum.contrast === true || datum.contrast === "造影") ? "◯" : ""}</td>
                    </tr>)}
                </tbody></table></div>
                <div className="flex flex-col">
                    <div className="grid grid-cols-4 gap-px bg-theme-800 border-b border-theme-800">
                        <span className="p-2 bg-theme-50">実施者</span>
                        <input className={CellClass+" border-white"} name="operator" value={operator} placeholder="ログインユーザー以外の場合は入力してください" onChange={handleChange} />
                    </div>
                    <div className="grid grid-cols-4 gap-px bg-theme-800 border-b border-theme-800">
                        <span className="p-2 bg-theme-50">診療情報提供書</span>
                        <div className='col-span-3 flex items-center bg-white'><FileList files={datum.visitID?.files || datum.files} /></div>
                    </div>
                </div>
            </div>
        </div>
    </>

}

export default Edit