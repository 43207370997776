import React, { useContext, useState } from "react"

const ExternalBookDateContext = React.createContext<{
    externalBookDate: Date;
    setExternalBookDate: React.Dispatch<React.SetStateAction<Date>>;
} | null>(null)

export const useExternalBookDate = () => {
    const ebdContext = useContext(ExternalBookDateContext)
    if (ebdContext == null) {
        throw new Error("ExternalBookDateContext undefined")
    }
    return ebdContext
}

const ExternalBookDate = ({children}: { children: JSX.Element }) => {
    const [externalBookDate, setExternalBookDate] = useState<Date>(new Date())

    return (
        <ExternalBookDateContext.Provider value={{externalBookDate, setExternalBookDate}}>
            {children}
        </ExternalBookDateContext.Provider>
    )
}

export default ExternalBookDate