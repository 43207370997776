import React, { ReactNode, useEffect, useReducer, useState } from "react";
import * as Realm from "realm-web";
import SignIn from 'components/signin'
import ChangePassword from 'components/changePassword'
import showMessage from "components/showMessage";

interface RealmAppContextInterface {
  app: Realm.App,
  currentUser: Realm.User | null,
  logIn: ((credentials: Realm.Credentials) => Promise<void>),
  logOut:() => Promise<void>,
  createId: () => string,
  /** States of application.  Keys are "branch" and each collection. */
  state: KV,
  setState: (key: string | undefined | null, data: KV) => void,
  /** Dispatcher to change state */
  dispatch: React.Dispatch<KV>,
  resources: KV[]
}

export const RealmAppContext = React.createContext<RealmAppContextInterface>({} as RealmAppContextInterface);

export const useRealmApp = () => {
  const appContext = React.useContext(RealmAppContext);
  if (!appContext) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return appContext;
};

export const isExternal = (app: any) => (!app.currentUser?.customData.internal)

export const RequireLoggedInUser = ({ children }: {children: JSX.Element}): JSX.Element => {
  const realmAppContext = useRealmApp();
  const reset = async (email:string, password:string) => {
    await realmAppContext.app.emailPasswordAuth.callResetPasswordFunction({email: email, password: password})
  }
  
  const search = new URLSearchParams(window.location.search);
  const token = search.get('token')
  const tokenId = search.get('tokenId')
  return (realmAppContext.currentUser ? children :
    ((token && tokenId) ? <ChangePassword reset={async (password: string) => { await realmAppContext.app.emailPasswordAuth.resetPassword({token: token, tokenId: tokenId, password: password})}} /> : 
    <SignIn 
      signin={(email:string, password:string) => realmAppContext.logIn(Realm.Credentials.emailPassword(email, password))}
      reset={reset}
    />)
  )
};

export const RealmAppProvider = ({ appId, children }: {appId: string, children: ReactNode}) => {
  const firstRun = React.useRef<boolean>(true);
  const [app, setApp] = React.useState<Realm.App>(new Realm.App(appId));
  React.useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    setApp(new Realm.App(appId));
  }, [appId]);
  const [currentUser, setCurrentUser] = useState(app.currentUser);
  const logIn = async (credentials: Realm.Credentials) => {
    try {
      await app.logIn(credentials);
    } catch (e) {
      await showMessage("エラー：メールアドレスまたはパスワードが違います", {error:true})
      return
    }
    setCurrentUser(app.currentUser);
  }
  const logOut = async () => {
    await app.currentUser?.logOut();
    setCurrentUser(app.currentUser);
  }
  const createId = () => new Realm.BSON.ObjectId().toString()
  const [state, dispatch] = useReducer(
    (state: KV, action: KV) => ({
      ...state,
      ...action,
    }),
    {}
  );
  const setState = (key: string | undefined | null, data: KV): void => {
    if (!key) {
      dispatch(data)
      return
    }
    let newState = { ...state }
    key.split(".").reduce((a: any, r: string, i: number, array: string[]) => {
      if (i === array.length - 1) a[r] = data
      if (!a[r]) a[r] = {}
      return a[r]
    }, newState)
    dispatch(newState)
  }
  const [resources, setResources] = useState<KV[]>([])

  useEffect(() => {
    async function fetchProps() {
        let response = await fetch(process.env.PUBLIC_URL + '/contexts/modality.json', { cache: "reload" })
        const data = await response.json()
        setResources(data)
    }
    fetchProps()
}, [])

  const realmAppContext = { app, currentUser, logIn, logOut, createId, state, setState, dispatch, resources };


  
  return (
    <RealmAppContext.Provider value={realmAppContext}>
      {children}
    </RealmAppContext.Provider>
  );
};
