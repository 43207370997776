import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import { RealmAppContext, RealmAppProvider, RequireLoggedInUser, isExternal } from 'contexts/RealmApp'
import Header from 'components/Header'
import { List as BookingList } from 'pages/booking/list'
import { Create as BookingCreate, Edit as BookingEdit } from 'pages/booking/edit'
import BookingDetail from 'pages/booking/detail'
import { List as Schedule } from 'pages/outpatient/list'
import BookSchedule from 'pages/booking/schedule'
import PatientSchedule from 'pages/outpatient/schedule'
import ExamSchedule from 'pages/exam/schedule'
import { Dock as BookingDock } from 'pages/dock/list'
import { List as PatientList } from 'pages/patient/list'
import { Edit as PatientEdit } from 'pages/patient/edit'
import { List as UserList } from 'pages/user/list'
import Verify from 'pages/user/verify'
import { Create as UserCreate, Edit as UserEdit } from 'pages/user/edit'
import { List as ClinicList } from 'pages/clinic/list'
import { Create as ClinicCreate, Edit as ClinicEdit } from 'pages/clinic/edit'
import { List as ExternalList } from 'pages/external/list';
import ExternalSchedule from 'pages/external/schedule'
import { ExternalOpening } from 'pages/external/opening'
import { ExternalDailyOpening } from 'pages/external/daily'
import { List as MessageList } from 'pages/message/list';

import { RecoilRoot } from 'recoil'

import RealmApolloProvider from 'graphql/RealmApolloProvider'
import MongoDB from 'contexts/MongoDBContext'
import ExternalBookDate from 'contexts/ExternalBookDateContext'
import UnreadMessage from 'contexts/UnreadMessageContext'
import showMessage from 'components/showMessage'

const wrapRequest = (request:IDBRequest):Promise<any> => {
    return new Promise((resolve, reject) => {
        request.onsuccess = (event: Event) => {
            const target = event.target as IDBRequest;
            resolve(target ? target.result : null);
        };
        request.onerror = (event: Event) => {
            const target = event.target as IDBRequest;
            reject(target ? target.error : new Error("Unknown IDBRequest error"));
        };
    });
}
  
const ErrorPage = ({error}:{error:any}) => <div className="p-4 flex flex-col gap-4 items-center">
  <span className="p-2 w-full sm:max-w-4xl text-center text-xl font-bold text-red-700 bg-red-300">エラー</span>
  <span className="text-red-700">内部エラーが発生しました。</span>
  <span className="sm:max-w-4xl">{error?.message || ''}</span>
  <span className="sm:max-w-4xl">{error?.stack || ''}</span>
</div>


const App = ({ error }: { error?: any }) => {
    const APP_ID = process.env.REACT_APP_APP_ID ? process.env.REACT_APP_APP_ID : "stgmogalitybooking-upekk"

    const [mobile, setMobile] = useState<boolean | null>(null)
    useEffect(() => {
        const handleResize = () => setMobile(!Boolean(document.getElementById('breakpoint-sm')?.offsetParent))
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    useEffect(() => { // Check service worker and subscription every hour
        const intervalId = setInterval(async () => {
            const someAsync = async (regs:ServiceWorkerRegistration[]) => {
                for (const reg of regs) {
                  const result = await reg.pushManager.getSubscription();
                  if (result) {
                    return true;
                  }
                }
                return false;
            }
            const regs = await navigator.serviceWorker.getRegistrations()
            if (!regs.some(reg => reg.active)) {
                // showMessage("サービスワーカーが起動していません。新規メッセージは即時反映されません。", { error: true })
                return
            } else {
                const hasSubscription = await someAsync(regs.filter(reg => reg.active ))
                if (!hasSubscription) {
                    const channel = new BroadcastChannel("sw-messages");
                    channel.postMessage('check-subscription')
                    return
                }
            }
            const db:IDBDatabase = await wrapRequest(indexedDB.open('bookingDB')).catch(e => { console.error(e); return; });
            const store = db.transaction("service", 'readwrite').objectStore("service");
            const serviceData:ServiceData = await wrapRequest(store.get(0)).catch(e => { console.error(e); return; })
            const time = serviceData.subscriptionTime
            if (time < new Date().getTime() - 2592000000) { // If expired, update expiry and renew subscription(fire check-subscription message)
                await wrapRequest(store.put({...serviceData, subscriptionTime:new Date().getTime()})).catch(e => { console.error(e); return; });
                if (time) { // fire only on update, not on activate
                    const channel = new BroadcastChannel("sw-messages");
                    channel.postMessage('check-subscription')
                }

            }
        }, 3600000);
        return () => clearInterval(intervalId);
    }, [])

    if (error) return <ErrorPage error={error} />
    return window.location.pathname === "/verify" ? <RealmAppProvider appId={APP_ID}>
        <div id="breakpoint-sm" className="hidden sm:block w-0 h-0"></div>
        <div className="App grid h-full grid-rows-frame">
            <BrowserRouter>
                <Header nouser />
                <div className="relative h-full overflow-y-scroll">
                    <Routes>
                        <Route path="/verify" element={<Verify />} />
                    </Routes>
                </div>
            </BrowserRouter>
        </div>
    </RealmAppProvider>
        :
        <RealmAppProvider appId={APP_ID}>
            <RecoilRoot>
                <RequireLoggedInUser>
                    <MongoDB>
                        <UnreadMessage>
                            <ExternalBookDate>
                                <RealmApolloProvider>
                                    <div id="breakpoint-sm" className="hidden sm:block w-0 h-0"></div>
                                    <div className="App grid h-full grid-rows-frame">
                                        <BrowserRouter>
                                            <Header />
                                            <div className="relative h-full overflow-y-scroll">
                                                <RealmAppContext.Consumer>{app =>
                                                    <Routes>
                                                        {isExternal(app.app) ? <>
                                                            <Route path="/" element={<Navigate replace to="/external/schedule" />} />
                                                            <Route path="/external/schedule" element={<ExternalSchedule />} />
                                                            <Route path="/external/list" element={<ExternalList />} />
                                                        </> : <>
                                                            <Route path="/" element={<Navigate replace to="/outpatient" />} />
                                                            <Route path="/booking" element={<BookingList />} />
                                                            <Route path="/booking/create" element={<BookingCreate />} />
                                                            <Route path="/booking/edit/:id" element={<BookingEdit />} />
                                                            <Route path="/booking/detail/:id" element={<BookingDetail />} />
                                                            <Route path="/bookschedule/" element={<BookSchedule />} />
                                                            <Route path="/outpatient/" element={<Schedule />} />
                                                            <Route path="/exam/" element={<ExamSchedule />} />
                                                            <Route path="/schedule/" element={<PatientSchedule />} />
                                                            <Route path="/dock/" element={<BookingDock />} />
                                                            <Route path="/patient" element={<PatientList />} />
                                                            <Route path="/patient/edit/:id" element={<PatientEdit />} />
                                                            <Route path="/user" element={<UserList />} />
                                                            <Route path="/user/create" element={<UserCreate />} />
                                                            <Route path="/user/edit/:id" element={<UserEdit />} />
                                                            <Route path="/clinic" element={<ClinicList />} />
                                                            <Route path="/clinic/create" element={<ClinicCreate />} />
                                                            <Route path="/clinic/edit/:id" element={<ClinicEdit />} />
                                                            <Route path="/message" element={<MessageList />} />
                                                            {/*<Route path='/send/' element={<Send />} />*/}
                                                            <Route path="/external/opening" element={<ExternalOpening />} />
                                                            <Route path="/external/daily" element={<ExternalDailyOpening />} />
                                                            <Route path="/external/schedule" element={<ExternalSchedule />} />
                                                        </>}
                                                    </Routes>
                                                }</RealmAppContext.Consumer>
                                            </div>
                                        </BrowserRouter>
                                        <footer className="p-1 text-center text-sm bg-theme-800 text-white">CENTRAL CLINIC
                                            SETAGAYA
                                        </footer>
                                    </div>
                                </RealmApolloProvider>
                            </ExternalBookDate>
                        </UnreadMessage>
                    </MongoDB>
                </RequireLoggedInUser>
            </RecoilRoot>
        </RealmAppProvider>

}

export default App
