import React, { ChangeEvent, useState, useEffect, FormEvent, FormEventHandler } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "components/Loading";
import { gql, useMutation, useQuery } from "@apollo/client";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { ButtonClass } from "contexts/style";
import showMessage from "components/showMessage";
import options from "contexts/options.json";
import { JSTDate } from "contexts/dateUtils";

const PatientFieldsFragment = gql`
  fragment PatientFieldsShort on Patient {
        _id
        patientId
        isTemp
        name
        nameKana
        gender
        birthDate
        tel
        mobileTel
        email
    }
`;

export const patientDataQuery = gql`
    ${PatientFieldsFragment}
    query GetPatientData($query: PatientQueryInput!) {
        patient(query: $query)  {
            ...PatientFieldsShort
        }
    }
`

const patientDataUpdate = gql`
    ${PatientFieldsFragment}
    mutation UpdatePatientReturnShort($set: PatientUpdateInput!, $id: String!) {
        updateOnePatient(set: $set, query: { _id: $id}) {
            ...PatientFieldsShort
        }
    }
`

export const FieldPlain: React.FunctionComponent<{ label: string; name: string; type?: string; value: any; }> = ({ label, name, type = "text", value }) => {
    return <>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">{label}</label>
        <input
            type={type}
            name={name}
            className="mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500  border-gray-300"
            defaultValue={value || ""}
            disabled
        />
    </>
}

const FieldInput: React.FunctionComponent<{ label: string; name: string; type?: string; values: KV; setValues: React.Dispatch<React.SetStateAction<KV>> }> = ({ label, name, type = "text", values, setValues }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [name]: e.currentTarget.value });
    }
    return <>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">{label}</label>
        <input
            type={type}
            name={name}
            className="mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500  border-gray-300"
            value={values[name]}
            onChange={handleChange}
        />
    </>
}


const FormButton = ({ submitting, back = null, }: { submitting: boolean, back: (() => void) | null; }) => {
    return <>
        <button className={ButtonClass} disabled={submitting}>
            <CheckCircleIcon className="w-5 h-5" />確定
        </button>
        <button type="button" className={ButtonClass} disabled={submitting} onClick={(e) => {
            e.preventDefault();
            back && back();
        }}
        >
            <XCircleIcon className="w-5 h-5" />戻る
        </button>
    </>
}

const Form = ({ label, data, values, setValues, handleSubmit, submitting, back }: { label: string; data: Patient; values: KV; setValues: React.Dispatch<React.SetStateAction<KV>>; handleSubmit: FormEventHandler<HTMLFormElement>; submitting: boolean; back: () => void }) => <div className="flex justify-center">
    <div className="pt-5 2xl:pt-0 2xl:col-span-4 w-full max-w-4xl">
        <div className="shadow sm:rounded-md">
            <form onSubmit={handleSubmit}>
                <div className="p-4 text-theme-800 bg-theme-50 text-xl sm:px-6">{label}</div>
                <div className="px-4 py-5 bg-white space-y-4 sm:p-6">
                    <FieldPlain label="登録ステータス" name="isTemp" value={data.isTemp ? "仮登録" : "本登録"} />
                    <FieldPlain label="患者ID（カルテ連携）" name="patientId" value={data.patientId} />
                    <FieldInput label="氏名（カルテ連携）" name="name" values={values}  setValues={setValues} />
                    <FieldInput label="氏名カナ（カルテ連携）" name="nameKana" values={values}  setValues={setValues} />
                    <FieldPlain label="性別（カルテ連携）" name="gender" value={data.gender ? options.genderType[data.gender] : ""} />
                    <FieldPlain label="生年月日（カルテ連携）" name="birthDate" value={data.birthDate && JSTDate(new Date(data.birthDate))} />
                    <FieldInput label="電話番号" name="tel" values={values} setValues={setValues} />
                    <FieldInput label="携帯番号" name="mobileTel" values={values} setValues={setValues} />
                    <FieldInput label="メールアドレス" name="email" values={values} setValues={setValues} />
                </div>
                <div className="px-4 bg-theme-50 text-right sm:px-6">
                    <FormButton submitting={submitting} back={back} />
                </div>
            </form>
        </div>
    </div>
</div>

export const Edit = () => {
    let { id } = useParams<KV>();
    const navigate = useNavigate();
    const back = () => navigate(`/patient`);
    const [updateData, { loading: mutationLoading }] = useMutation(patientDataUpdate, { onCompleted: back })

    const { loading, data, error } = useQuery(patientDataQuery, { variables: { query: { _id: id } } });
    const [patientData, setPatientData] = useState<KV>({ tel: "", mobileTel: "", email: ""})
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async (e: FormEvent) => {
        setSubmitting(true)
        e.preventDefault()
        let updatingData = { ...patientData }
//        Object.keys(updatingData).forEach(key => { if (updatingData[key] === "") updatingData[key] = null })
        try {
            await updateData({ variables: { id: id, set: updatingData } });
        } catch (e) {
            if (e instanceof Error) await showMessage(`患者データの更新時にエラーが発生しました。\r\r${e.message}`, { error: true })
        }
        setSubmitting(false)
    }

    useEffect(() => {
        let newData: KV = {
            name: data?.patient?.name || "",
            nameKana: data?.patient?.nameKana || "",
            tel: data?.patient?.tel || "",
            mobileTel: data?.patient?.mobileTel || "",
            email: data?.patient?.email || "",
        }
        setPatientData(newData)
    }, [data])


    if (error) return <div className="w-full h-full flex justify-center items-center text-red-600 font-bold">{`データ読込エラー\r\n${error}`}</div>
    if (loading || mutationLoading) return <div className="w-full h-full flex justify-center items-center"><Loading /></div>

    return <Form label="患者編集" data={data?.patient || {}} values={patientData} setValues={setPatientData} handleSubmit={handleSubmit} submitting={submitting} back={back} />
};


export default Edit