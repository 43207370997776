import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<>
    <App />
    <div id="modal-container" />
</>);


// const request = indexedDB.open('bookingDB');
// request.onupgradeneeded = (e) => { // write initial data to indexedDB(time is zero)
//     const db:IDBDatabase = (e.target as IDBRequest).result;
//     const store = db.createObjectStore("service", {keyPath : 'id'})
//     const putReq = store.put({
//         id: 0,
//         appID: crypto.randomUUID(),
//         subscriptionTime: 0,
//         serverUrl: process.env.REACT_APP_API_URL || "",
//         serverKey: process.env.REACT_APP_API_KEY || "",        
//     })
//     putReq.onerror = (e) => {
//         const error = (e.target as IDBRequest).error
//         root.render(<App error={error} />);
//     }
// }
// request.onsuccess = (e) => {
//     const db:IDBDatabase = (e.target as IDBRequest).result;
//     const store = db.transaction("service", 'readwrite').objectStore("service");
//     const getReq = store.get(0)
//     getReq.onsuccess = (e) => {
//         const service:ServiceData = (e.target as IDBRequest).result
//         const time = service.subscriptionTime
//         if (time < new Date().getTime() - 2592000000) { // If expired or initial, update expiry and renew subscription(fire check-subscription message)
//             const putReq = store.put({...service, subscriptionTime:new Date().getTime()})
//             putReq.onsuccess = async (e) => {
//                 root.render(<>
//                     <App />
//                     <div id="modal-container" />
//                 </>);
//                 await serviceWorkerRegistration.register();
//                 if (time) { // fire only on update, not on activate
//                     const channel = new BroadcastChannel("sw-messages");
//                     channel.postMessage('check-subscription')
//                 }
//             }
//             putReq.onerror = (e) => {
//                 const error = (e.target as IDBRequest).error
//                 root.render(<App error={error} />);
//             }
//         } else { // still valid(no message)
//             root.render(<>
//                 <App />
//                 <div id="modal-container" />
//             </>);
//             serviceWorkerRegistration.register();
//     }
//     }
//     getReq.onerror = (e) => {
//         const error = (e.target as IDBRequest).error
//         root.render(<App error={error} />);
//     }
// }
