/**
 * イベントの開始・終了をセットするobjectを返す
 * @param time 開始時刻
 * @param modalityTime モダリティの所要期間
 */
export const setStartAndEnd = (time: Date, modalityTime: string) => {
  const start = new Date(time)
  const end = new Date(time)
  end.setMinutes(time.getMinutes() + Number(modalityTime))
  return {"start": start, "end": end}
}

/**
 * 別の予約と重複しないよう、最適な予約イベントの時刻を返す
 * 全ての引数は、同一のモダリティを対象とする
 * @param eventSchedule 別の予約を考慮しない時の予約スケジュール
 * @param modalityEvent モダリティの既存予約イベント
 * @param modalityTime モダリティの所要時間
 */
export const setOptimizeSchedule = (eventSchedule: KV, modalityEvent: KV, modalityTime: string) => {
  let overlap: Boolean = false

  // Eventが重複した場合、重複しないように、後続に配置
  for (const i in modalityEvent) {
    const start = modalityEvent[i]["start"]
    const end = modalityEvent[i]["end"]
    // 予定の予約枠がeventと重複していないかチェック
    if ((start <= eventSchedule.start && eventSchedule.start <= end) ||
        (start <= eventSchedule.end && eventSchedule.end <= end) ||
        (eventSchedule.start <= start && end <= eventSchedule.end)
    ) {
      overlap = true
    }
  }
  // 重複していた場合、最適な位置を探す
  if (overlap) {
    let prevEnd = new Date()
    for (const i in modalityEvent) {
      const start = modalityEvent[i]["start"]
      const end = modalityEvent[i]["end"]

      // eventのendが、dockModalityStartに達していないと、スキップ
      if (end <= eventSchedule.start) {
        prevEnd = end
        continue
      }
      if (Number(i) === 0) {
        // Eventが最後の場合
        if (Number(i) === (modalityEvent.length - 1)) {
          eventSchedule = setStartAndEnd(end, modalityTime)
          break
        }
        prevEnd = end
        continue
      }

      // prevEndとeventSchedule.startの大きい方で比較
      let diff = (start.getTime() - prevEnd.getTime()) / (60*1000)  // minute
      if (prevEnd <= eventSchedule.start) {
        diff = (start.getTime() - eventSchedule.start.getTime()) / (60*1000)
      }
      // 差分が検査所要時間以上あれば、prevEndから予約を確保
      if (Number(diff) >= Number(modalityTime)) {
        if (prevEnd > eventSchedule.start) {
          eventSchedule = setStartAndEnd(prevEnd, modalityTime)
        }
        break
      }
      // Eventが最後の場合
      if (Number(i) === (modalityEvent.length - 1)) {
        if (end > eventSchedule.start) {
          eventSchedule = setStartAndEnd(end, modalityTime)
        }
      }
      prevEnd = end
    }
  }
  return eventSchedule
}

/**
 * 対象モダリティの予約情報を、startでsortしたobjectを返す
 * @param modality 対象モダリティ
 * @param events 予約イベント全体
 */
export const getModalityEvents = (modality: string, events: KV) => {
  const modalityEvents: KV = {}
  const modalityDuration: any = []
  for (const key in events) {
    const event = events[Number(key)]
    if (modality === event["resourceId"]) {
      const duration = {"start" : event["start"], "end" : event["end"]}
      modalityDuration.push(duration)
      modalityEvents[modality] = modalityDuration
    }
  }
  // モダリティの時間ごとにソート
  if (Object.keys(modalityEvents).length) {
    const eventArray: any[] = []
    const eventHash = Object.entries(modalityEvents[modality])
    eventHash.sort((p1: any, p2: any) => {
      return p1[1].start - p2[1].start
    })
    for (const i in eventHash) {
      eventArray.push(eventHash[i][1])
    }
    modalityEvents[modality] = eventArray
  }
  return modalityEvents
}

/**
 * ドック予約のイベントが重複していないかチェック
 * @param dockEvents そのドックで予約したイベント
 */
export const duplicateCheck = (dockEvents: KV) => {

  let prevEnd = new Date()
  // 要素のstartでソート
  let sortStarts = Object.keys(dockEvents).map(function(key) {
    return dockEvents[key]
  }).sort(function(a, b) {
    return (a.start < b.start) ? -1 : 1
  })

  for (const i in sortStarts) {
    // スケジュールなしのモダリティを除く
    if (sortStarts[i]["no_schedule"]) {
      continue
    }
    if (i === "0") {
      prevEnd = sortStarts[i]["end"]
      continue
    }
    if (sortStarts[i]["start"] < prevEnd) {
      return true
    }
    prevEnd = sortStarts[i]["end"]
  }

  return false
}