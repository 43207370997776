import { ApolloClient, gql } from "@apollo/client"
import { EventDropArg } from "@fullcalendar/core"
import showMessage from "components/showMessage"
import { getSamedayArea, JSTDate, JSTTime } from "./dateUtils"

export const ModalityLists = ["MRI", "PET-CT_1", "PET-CT_2", "ABUS", "ES", "MMG", "US", "US(L)"]
export const SpecialCourses = ["CMC", "PETチケット", "VIP"]
export const PetIDs = ["PET-CT_1", "PET-CT_2"]

export const petBodyTime = 70;
export const petHeadTime = 40;
export const RECEPTION_DUR = 20;
export const AMIROID_DUR = 60;


export const isPet = (booking: Booking) => PetIDs.includes(booking?.modalityID || "") && !booking?.body1?.startsWith("CT") && !booking?.body2?.startsWith("CT")
export const isPetHead = (booking: Booking) => isPet(booking) && (booking?.body1?.startsWith("頭") || (booking?.body2 === "頭部"))
export const getLatestIvTime = (booking: Booking) => JSTTime(new Date(new Date(booking.start || 0).getTime() - (isPetHead(booking) ? petHeadTime * 60 * 1000 : petBodyTime * 60 * 1000)))
export const getEarliestIvTime = (booking: Booking) => JSTTime(new Date(new Date(booking.end || 0).getTime() - petBodyTime * 60 * 1000))
export const getAmiroidIvTime = (booking: Booking) => JSTTime(new Date(new Date(booking.start || 0).getTime() - AMIROID_DUR * 60 * 1000))
/**
 * 
 * @param time hh:mm string
 * @returns minutes from midnight
 */
const getMinutesFromMidnight = (time?: string) => {
    if (!time) return 0
    const times = time.split(':')
    return parseInt(times[0] || "0") * 60 + parseInt(times[1] || "0")
}
/**
 * 
 * @param time minutes from midnight
 * @returns hh:mm string
 */
const getTimeFromMinutes = (time: number) => `0${Math.floor(time / 60)}`.slice(-2) + ':' + `0${time % 60}`.slice(-2)

export const PRE_BOOK_STATUS = 0
export const BOOKED_STATUS = 1
export const IV_STATUS = 2
export const BEGIN_STATUS = 3
export const CHECKOUT_STATUS = 4
export const CANCEL_STATUS = 5
export const CLOSE_STATUS = 6
export const UPDATEONLY = 7

export const OUTSIDE_TYPE = 0
export const INTERNAL_TYPE = 1
export const DOCK_TYPE = 2
export const OUTER_BOOK_TYPE = 3
export const CLOSE_TYPE = 4
export const EXAM_TYPE = 5
export const CMC_TYPE = 6
export const DOCK_TYPES = [2, 6]

const otherBookingQuery = gql`query GetOtherBookings($query: BookingQueryInput!) {
    bookings(query: $query) {
        _id
        modalityID
        body1
        body2
        patientID
        ivCheckin
        start
        end
    }
}`



/**
 * Check whether PET booking is acceptable or not
 * @param {Object} object description
 * @param {Booking} object.booking Booking
 * @param {CalendarEvent[] | null} object.events Events on Calendar
 * @param {ApolloClient<any>} object.client Apollo Client
 * @returns Undefined if not acceptable. ValidationResult if acceptable.  ValidationResult.otherPet is set if ivCheckin should be updated.
 */
export const checkPetTime = async ({ booking, events, client }: { booking: Booking, events?: CalendarEvent[] | null, client: ApolloClient<any> }): Promise<ValidationResult | undefined> => {
    const getTimeStamp = (v: any) => {
        if (typeof(v) === 'string') return Date.parse(v)
        return v.getTime()
    }
    if (!booking.start || !booking.end) {
        await showMessage(`開始または終了時間が未入力です。`, { error: true })
        return
    }
    const [startDate, endDate] = getSamedayArea(booking.start)
    let ivCheckin: string | undefined
    let otherPet: Booking | undefined
    let data: (Booking)[] = []
    let bookings: Booking[] = events?.filter(v => (startDate.getTime() <= getTimeStamp(v.start) && endDate.getTime() > getTimeStamp(v.start))).map(({ id, extendedProps, ...v }) => ({ ...v, ...extendedProps, _id: id })) || []
    // Get all bookings for specific date if not provided
    if (!events) {
        try {
            const result = await client.query({ query: otherBookingQuery, variables: { query: { start_gte: startDate, end_lt: endDate } }, fetchPolicy: "network-only" })
            bookings = result.data.bookings
        } catch (e) {
            if (e instanceof Error) {
                await showMessage(`他の予約の存在確認中にエラーが発生しました。\r\n${e.message}`, { error: true })
                return
            }
        }
    }
    // Check other booking of same patient during execution.
    if ((data = bookings.filter((v: Booking) => v._id !== booking._id && v.patientID === booking.patientID && (getTimeStamp(v.start) || 0) < (booking.end?.getTime() || 0) && (getTimeStamp(v.end) || 0) > (booking.start?.getTime() || 0))).length) {
        await showMessage(`同一検査時間内に他の予約が存在しています。\r\n${data.map(v => `${v.modalityID} : ${JSTTime(v.start)} ~ ${JSTTime(v.end)}`).join('\r\n')}`, { error: true })
        return undefined
    }
    if ((data = bookings.filter((v: Booking) => v._id !== booking._id && v.patientID === booking.patientID && isPet(v))).length) { // other pet exist for same patient
        // Check whether there are multiple PET booking of same patient.
        if (data.length > 1) {
            await showMessage(`すでに同一患者で２つのPET検査が予約されています。`, { error: true })
            return undefined
        }
        // Check head->body order
        const isFirst = new Date(data[0].start || 0).getTime() > new Date(booking.start || 0).getTime()
        if ((isFirst && !isPetHead(booking)) || (!isFirst && !isPetHead(data[0]))) {
            await showMessage(`頭部のPET検査が先になっていません。`, { error: true })
            return undefined
        }
        if (!data[0].ivCheckin) {
            await showMessage(`他のPET予約でIV開始予定時刻が設定されていません。\r\nアクセッション：${data[0]._id}`, { error: true })
            return undefined
        }
        const latestIvTimes = { booking: getMinutesFromMidnight(getLatestIvTime(booking)), data: getMinutesFromMidnight(getLatestIvTime(data[0])) }
        const latestIvTime = Math.min(latestIvTimes.booking, latestIvTimes.data)
        const earliestIvTimes = { booking: getMinutesFromMidnight(getEarliestIvTime(booking)), data: getMinutesFromMidnight(getEarliestIvTime(data[0])) }
        const earliestIvTime = Math.max(earliestIvTimes.booking, earliestIvTimes.data)
        // Check whether time range is acceptable
        if (latestIvTime < earliestIvTime) {
            await showMessage("設定可能なIV開始時間では規定時間内に検査を終了できません。", { error: true })
            return undefined
        }
        if (getMinutesFromMidnight(data[0].ivCheckin) >= earliestIvTime && getMinutesFromMidnight(data[0].ivCheckin) <= latestIvTime) {
            const confirmed = await showMessage(`他のPET予約で設定されているIV開始予定時刻(${data[0].ivCheckin})をそのまま使用しますか？`, { confirm: true })
            if (!confirmed) {
                otherPet = data[0] // need to change other pet
                ivCheckin = getTimeFromMinutes(latestIvTimes.booking <= latestIvTime ? latestIvTimes.booking : latestIvTime)
                const confirmed = await showMessage(`IV開始予定時刻を${ivCheckin}に設定して続けますか？`, { confirm: true })
                if (!confirmed) return undefined
            } else {
                ivCheckin = data[0].ivCheckin
            }
        } else {
            ivCheckin = getTimeFromMinutes((latestIvTimes.booking <= latestIvTime && latestIvTimes.booking >= earliestIvTime) ? latestIvTimes.booking : latestIvTime)
            const confirmed = await showMessage(`他のPET予約で設定されているIV開始予定時刻では規定範囲外となります。IV開始予定時刻を${ivCheckin}に設定して続けますか?`, { confirm: true })
            if (!confirmed) return undefined
            otherPet = data[0] // need to change other pet
        }
    } else if (booking.body1 === 'PET-CT_頭' && booking.body2 === '頭部(アミロイド)') {
        ivCheckin = booking.ivCheckin || getAmiroidIvTime(booking)
    } else {  // other pet does not exist for same patient
        ivCheckin = booking.ivCheckin || getLatestIvTime(booking)
    }
    // Check other patient's PET's ivCheckin
    if ((data = bookings.filter((v) => isPet(v) && v._id !== booking._id && v.patientID !== booking.patientID && Math.abs(getMinutesFromMidnight(v.ivCheckin) - getMinutesFromMidnight(ivCheckin)) < 10)).length) {
        await showMessage(`IV時間の差が10分未満の他の患者のPET-CTの予約が存在しています。(IVは${ivCheckin})\r\n${data.map(v => `${v.modalityID} : ${v.ivCheckin || ''}`).join('\r\n')}`, { error: true })
        return undefined
    }
    // Check other non-PET booking of same patient during IV waiting.
    const ivStart = new Date(startDate.getTime() + getMinutesFromMidnight(ivCheckin) * 60000)
    if ((data = bookings.filter((v: Booking) => !isPet(v) && v.patientID === booking.patientID && (getTimeStamp(v.start) || 0) < (booking.start?.getTime() || 0) && (getTimeStamp(v.end) || 0) > ivStart.getTime())).length) {
        await showMessage(`IV待機中(${ivCheckin}から)に同一患者のPET以外の予約が存在しています。\r\n${data.map(v => `${v.modalityID} : ${JSTTime(v.start)} ~ ${JSTTime(v.end)}`).join('\r\n')}`, { error: true })
        return undefined
    }
    return { passed: true, ivCheckin, otherPet }
}

/**
 * Check whether PET booking is acceptable or not for dock course
 * @param {Object} object description
 * @param {Booking} object.booking Booking
 * @param {CalendarEvent[] | null} object.events Events on Calendar
 * @param {ApolloClient<any>} object.client Apollo Client
 * @param {addFlag}
 * @returns Undefined if not acceptable. ValidationResult if acceptable.  ValidationResult.otherPet is set if ivCheckin should be updated.
 */
export const checkPetTimeForDock = async ({ booking, events, client, addFlag }: { booking: Booking, events?: CalendarEvent[] | null, client: ApolloClient<any>, addFlag: Boolean }): Promise<ValidationResult | undefined> => {
    const getTimeStamp = (v: any) => {
        if (typeof(v) === 'string') return Date.parse(v)
        return v.getTime()
    }
    if (!booking.start || !booking.end) {
        await showMessage(`開始または終了時間が未入力です。`, { error: true })
        return
    }
    const [startDate, endDate] = getSamedayArea(booking.start)
    let ivCheckin: string | undefined
    let otherPet: Booking | undefined
    let data: (Booking)[] = []
    let bookings: Booking[] = events?.filter(v => (startDate.getTime() <= getTimeStamp(v.start) && endDate.getTime() > getTimeStamp(v.start))).map(({ id, extendedProps, ...v }) => ({ ...v, ...extendedProps, _id: id })) || []
    // Get all bookings for specific date if not provided
    if (!events) {
        try {
            const result = await client.query({ query: otherBookingQuery, variables: { query: { start_gte: startDate, end_lt: endDate } }, fetchPolicy: "network-only" })
            bookings = result.data.bookings
        } catch (e) {
            if (e instanceof Error) {
                await showMessage(`他の予約の存在確認中にエラーが発生しました。\r\n${e.message}`, { error: true })
                return
            }
        }
    }
    // Check other booking of same patient during execution.
    if (!addFlag && (data = bookings.filter((v: Booking) => v._id !== booking._id && v.patientID === booking.patientID && (getTimeStamp(v.start) || 0) < (booking.end?.getTime() || 0) && (getTimeStamp(v.end) || 0) > (booking.start?.getTime() || 0))).length) {
        await showMessage(`同一検査時間内に他の予約が存在しています。\r\n${data.map(v => `${v.modalityID} : ${JSTTime(v.start)} ~ ${JSTTime(v.end)}`).join('\r\n')}`, { error: true })
        return undefined
    }
    if (!addFlag && (data = bookings.filter((v: Booking) => v._id !== booking._id && v.patientID === booking.patientID && isPet(v))).length) { // other pet exist for same patient
        // Check whether there are multiple PET booking of same patient.
        if (data.length > 1) {
            await showMessage(`すでに同一患者で２つのPET検査が予約されています。`, { error: true })
            return undefined
        }
        // Check head->body order
        const isFirst = new Date(data[0].start || 0).getTime() > new Date(booking.start || 0).getTime()
        if ((isFirst && !isPetHead(booking)) || (!isFirst && !isPetHead(data[0]))) {
            await showMessage(`頭部のPET検査が先になっていません。`, { error: true })
            return undefined
        }
        if (!data[0].ivCheckin) {
            await showMessage(`他のPET予約でIV開始予定時刻が設定されていません。\r\nアクセッション：${data[0]._id}`, { error: true })
            return undefined
        }
        const latestIvTimes = { booking: getMinutesFromMidnight(getLatestIvTime(booking)), data: getMinutesFromMidnight(getLatestIvTime(data[0])) }
        const latestIvTime = Math.min(latestIvTimes.booking, latestIvTimes.data)
        const earliestIvTimes = { booking: getMinutesFromMidnight(getEarliestIvTime(booking)), data: getMinutesFromMidnight(getEarliestIvTime(data[0])) }
        const earliestIvTime = Math.max(earliestIvTimes.booking, earliestIvTimes.data)
        // Check whether time range is acceptable
        if (latestIvTime < earliestIvTime) {
            await showMessage("設定可能なIV開始時間では規定時間内に検査を終了できません。", { error: true })
            return undefined
        }
        if (getMinutesFromMidnight(data[0].ivCheckin) >= earliestIvTime && getMinutesFromMidnight(data[0].ivCheckin) <= latestIvTime) {
            const confirmed = await showMessage(`他のPET予約で設定されているIV開始予定時刻(${data[0].ivCheckin})をそのまま使用しますか？`, { confirm: true })
            if (!confirmed) {
                otherPet = data[0] // need to change other pet
                ivCheckin = getTimeFromMinutes(latestIvTimes.booking <= latestIvTime ? latestIvTimes.booking : latestIvTime)
                const confirmed = await showMessage(`IV開始予定時刻を${ivCheckin}に設定して続けますか？`, { confirm: true })
                if (!confirmed) return undefined
            } else {
                ivCheckin = data[0].ivCheckin
            }
        } else {
            ivCheckin = getTimeFromMinutes((latestIvTimes.booking <= latestIvTime && latestIvTimes.booking >= earliestIvTime) ? latestIvTimes.booking : latestIvTime)
            const confirmed = await showMessage(`他のPET予約で設定されているIV開始予定時刻では規定範囲外となります。IV開始予定時刻を${ivCheckin}に設定して続けますか?`, { confirm: true })
            if (!confirmed) return undefined
            otherPet = data[0] // need to change other pet
        }
    } else {  // other pet does not exist for same patient
        ivCheckin = booking.ivCheckin || getLatestIvTime(booking)
    }
    // Check other patient's PET's ivCheckin
    if ((data = bookings.filter((v) => isPet(v) && v._id !== booking._id && v.patientID !== booking.patientID && Math.abs(getMinutesFromMidnight(v.ivCheckin) - getMinutesFromMidnight(ivCheckin)) < 10)).length) {
        await showMessage(`IV時間の差が10分未満の他の患者のPET-CTの予約が存在しています。(IVは${ivCheckin})\r\n${data.map(v => `${v.modalityID} : ${v.ivCheckin || ''}`).join('\r\n')}`, { error: true })
        return undefined
    }
    // Check other non-PET booking of same patient during IV waiting.
    const ivStart = new Date(startDate.getTime() + getMinutesFromMidnight(ivCheckin) * 60000)
    if (!addFlag && (data = bookings.filter((v: Booking) => !isPet(v) && v.patientID === booking.patientID && (getTimeStamp(v.start) || 0) < (booking.start?.getTime() || 0) && (getTimeStamp(v.end) || 0) > ivStart.getTime())).length) {
        await showMessage(`IV待機中(${ivCheckin}から)に同一患者のPET以外の予約が存在しています。\r\n${data.map(v => `${v.modalityID} : ${JSTTime(v.start)} ~ ${JSTTime(v.end)}`).join('\r\n')}`, { error: true })
        return undefined
    }
    return { passed: true, ivCheckin, otherPet }
}


export const checkTime = async ({ booking, events, client }: { booking: Booking, events?: CalendarEvent[] | null, client: ApolloClient<any> }) => {
    const getTimeStamp = (v: any) => {
        if (typeof(v) === 'string') return Date.parse(v)
        return v.getTime()
    }

    if (!booking.start || !booking.end) {
        await showMessage(`開始または終了時間が未入力です。`, { error: true })
        return false
    }
    const [startDate, endDate] = getSamedayArea(booking.start)
    let data: Booking[] | undefined
    let bookings: Booking[] = events?.filter(v => (startDate.getTime() <= getTimeStamp(v.start) && endDate.getTime() > getTimeStamp(v.end))).map(({ id, extendedProps, ...v }) => ({ ...v, ...extendedProps, _id: id, start: v.start, end: v.end })) || []// date object is converted to string
    if (!events) {
        const result = await client.query({ query: otherBookingQuery, variables: { query: { start_gte: startDate, end_lt: endDate } }, fetchPolicy: "network-only" })
        bookings = result.data.bookings.map((v:KV) => ({...v, start:new Date(v.start), end: new Date(v.end)}))
    }
    if ((data = bookings.filter((v: Booking) => v._id !== booking._id && v.patientID === booking.patientID && (getTimeStamp(v.start) || 0) < (booking.end?.getTime() || 0) && (getTimeStamp(v.end) || 0) > (booking.start?.getTime() || 0))).length) {
        await showMessage(`同一検査時間内に同一患者の他の予約が存在しています。\r\n${data.map(v => `${v.modalityID} : ${JSTTime(v.start)} ~ ${JSTTime(v.end)}`).join('\r\n')}`, { error: true })
        return false
    }
    if ((data = bookings.filter((v: Booking) => isPet(v) && v._id !== booking._id && v.patientID === booking.patientID && (new Date(startDate.getTime() + getMinutesFromMidnight(v.ivCheckin) * 60000)?.getTime() || 0) < (booking.end?.getTime() || 0) && (getTimeStamp(v.start) || 0) > (booking.start?.getTime() || 0))).length) {
        await showMessage(`IV後のPET待機時間中です。\r\n${data.map(v => `${v.modalityID} : ${v.ivCheckin || ''} ~ ${JSTTime(v.start)}`).join('\r\n')}`, { error: true })
        return false
    }
    return true
}


export const handleMoveBooking = async ({ eventDrop, events, client, updateBooking }: { eventDrop: EventDropArg, events?: CalendarEvent[] | null, client: ApolloClient<any>, updateBooking: (id: string | number, set: { [key: string]: any; }) => Promise<any> }) => {
    if ((!eventDrop.event.start || !eventDrop.oldEvent.start)) {
        await showMessage("予約データがないか、不完全な予約データです。リロードしてください。", { error: true })
        return false
    }
    const oldBooking = {
        _id: eventDrop.oldEvent.id,
        modalityID: eventDrop.oldEvent.getResources()[0]?.id,
        body1: eventDrop.oldEvent.extendedProps?.body1,
        body2: eventDrop.oldEvent.extendedProps?.body2,
        patientID: eventDrop.oldEvent.extendedProps?.patientID,
        start: new Date(eventDrop.oldEvent.start || 0),
        end: new Date(eventDrop.oldEvent.end || 0),
    }
    const newBooking = {
        _id: eventDrop.event.id,
        modalityID: eventDrop.event.getResources()[0]?.id,
        body1: eventDrop.event.extendedProps?.body1,
        body2: eventDrop.event.extendedProps?.body2,
        patientID: eventDrop.event.extendedProps?.patientID,
        start: new Date(eventDrop.event.start || 0),
        end: new Date(eventDrop.event.end || 0),
    }

    const isNewPet = isPet(newBooking)
    const isOldPet = isPet(oldBooking)
    let validationResult: ValidationResult | undefined
    //    const otherEvent = events?.find(event => (!oldBooking || event.id !== oldBooking.id) && event.patientID === newBooking.patientID
    //      && (event.start.getTime() - (!isNewPet ? 0 : getLeadtime(event.extendedProps)) < (newBooking.end?.getTime() || 0) /* Other event is pet and moving event is not pet, block 60 min. */
    //      && event.end.getTime() > ((newBooking.start?.getTime() || 0) - ((!isPet(newBooking) && isNewPet) ? 4200000 : 0))))
    const restoreEvent = () => {
        if (!eventDrop) return
        eventDrop.event.setStart(eventDrop.oldEvent.start || new Date())
        eventDrop.event.setEnd(eventDrop.oldEvent.end)
        eventDrop.event.setResources(eventDrop.oldEvent.getResources())
    }
    if (newBooking.modalityID !== oldBooking.modalityID && !(isNewPet && isOldPet)) {
        await showMessage("PET-CT同士以外のモダリティは変更できません。", { error: true })
        restoreEvent()
        return
    } else if (isNewPet) {
        if (!(validationResult = await checkPetTime({ booking: newBooking, events, client }))) {
            restoreEvent()
            return
        }
    } else {
        if (!(await checkTime({ booking: newBooking, events, client }))) {
            restoreEvent()
            return
        }
    }
    if (oldBooking) {
        let message = ""
        if (oldBooking.modalityID !== newBooking.modalityID) message = `モダリティを ${newBooking.modalityID} に`
        if (oldBooking.start.getTime() !== newBooking.start.getTime()) message = `${message}${message ? '、 ' : ''}予約日時を ${JSTDate(newBooking.start)} ${JSTTime(newBooking.start)} に`
        if (!await showMessage(`${message}変更してよろしいですか？`, { confirm: true })) {
            restoreEvent()
            return
        }
    }
    try {
        if (validationResult?.otherPet?._id) await updateBooking(validationResult.otherPet._id, { ivCheckin: validationResult.ivCheckin })
    } catch (e) {
        if (e instanceof Error) await showMessage(`既存予約のIV開始時刻の書込処理エラーが発生しました。\r\nアクセッション：${validationResult?.otherPet?._id}\r\n${e.message}`, { error: true })
    }
    try {
        if (oldBooking.modalityID !== newBooking.modalityID) {
            await updateBooking(newBooking._id, { start: newBooking.start, end: newBooking.end, modalityID: newBooking.modalityID, ...((isNewPet && validationResult) ? { ivCheckin: validationResult.ivCheckin } : {}) })
        } else {
            await updateBooking(newBooking._id, { start: newBooking.start, end: newBooking.end, ...((isNewPet && validationResult) ? { ivCheckin: validationResult.ivCheckin } : {}) })
        }
    } catch (e) {
        if (e instanceof Error) await showMessage(`予約の書込処理エラーが発生しました。\r\n${e.message}`, { error: true })
    }
}

/**
 * [外注・外来専用] IVの所要時間を算出する
 * CTはIV不要
 * PET-CT, 頭 -> 検査開始30分前
 * PET-CT, 全身 -> 検査開始70分前
 */
export const getIvTime = async (modality: string, body1: string, body2: string): Promise<number> => {
    if (PetIDs.includes(modality)) {
        if (!body1?.startsWith("CT") && !body2?.startsWith("CT")) {
            // 頭は40分まえにIV, それ以外は60分まえにIV
            if (body1?.startsWith("頭") || (body2 === "頭部")) {
                return petHeadTime
            }
            return petBodyTime
        }
        // CTはIV不要
        return 0
    } else {
        // modalityはPetIDsに含まれていません
        return 0
    }
}
