import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react"
import { getColor, GrayButtonClass, modalStyles, modalStylesMobile, YellowLeftButtonClass } from "contexts/style"
import { useMongoDB } from "contexts/MongoDBContext"
import { JSTDate, JSTTime } from "contexts/dateUtils"
import { Loading } from "components/Loading"
import { gql, useMutation, useQuery } from "@apollo/client"
import { XCircleIcon } from "@heroicons/react/24/solid"
import ReactModal from "react-modal"
import { FileList, showFile } from "components/FileChooser"
import options from "contexts/options.json"
import showMessage from "components/showMessage"
import { MultipleListPicker } from "components/UIParts"
import { customAlphabet } from "nanoid"
import { useRealmApp } from "contexts/RealmApp"

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 12)


const userQuery = gql`query {
    users(query: { internal: true }, sortBy: NAME_ASC) {
        _id
        name
    }
}`

const messageQuery = gql`query($query: MessageQueryInput!) {
    messages(query: $query, sortBy: SENTAT_DESC) {
        _id
        targetUser {
            name
            userID
            read
        }
        sentBy {
            name
            userID
        }
        message
        sentAt
    }
}`

const visitUpdateMutation = gql`
  mutation UpdateVisit($id: String!, $set: VisitUpdateInput!) {
    updatedData: updateOneVisit(query: { _id: $id }, set: $set) {
        _id
    }
  }
`;




const messageInsertMutation = gql`mutation($data:MessageInsertInput!) {
    insertOneMessage(data:$data) {
        _id
    }
}`



const VisitForm = ({ data, isModalOpen, setIsModalOpen, refetch, fileMax = 3, size = 4 }:{ data?: KV|null, isModalOpen: boolean, setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>, refetch?: () => Promise<void>, fileMax?:number, size?: number }) => {
    const { db } = useMongoDB()
    const app = useRealmApp()
    const { loading: userLoading, data: usersData } = useQuery(userQuery)
    const { loading: messageLoading, data: messageData } = useQuery(messageQuery, { variables: { query: { visitID: { _id: data?._id || data?.id }}}, fetchPolicy: "cache-and-network"})
    const users = (usersData?.users || []).map((v:KV) => ({ id: v._id, name: v.name }))
    const [updateVisitMutation, { error:visitError }] = useMutation(visitUpdateMutation);

    const [insertMessage, { error:messageError }] = useMutation(messageInsertMutation)
    const keys = Object.keys(data?.extendedProps || {})
    const modifiedData:KV = { ...data }
    for (const key of keys) {
        modifiedData[key] = (data?.extendedProps as KV)[key] 
    }
    const [processing, setProcessing] = useState(false)
    const [comment, setComment] = useState(modifiedData?.comment || "")
    const [message, setMessage] = useState("")
//    const [selectedFiles, setSelectedFiles] = useState<FileData[]>((data?.extendedProps?.files || []).map((v:any) => ({ id: v.fileID, name: v.name })));
//    useEffect(() => setSelectedFiles((data?.extendedProps?.files || []).map((v:any) => ({ id: v.fileID, name: v.name }))), [data?.extendedProps?.files])
    const [selectedUser, setSelectedUser] = useState<{id:string,name:string}[]>([])
    
//const sampleItems = new Array(100).fill(undefined).map((v, i) => ({id:String(1000+i), name:`Andrew${i}`}))
//const sampleMessages = [{ _id: nanoid(), targetUser: [{ userID: "1111", name: "生駒 清文" }, { userID: "1111", name: "上條 哲也"}], message:"サンプルメッセージ", sentAt: new Date() }]

    const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (e.currentTarget.dataset.fileid) {
            setProcessing(true)
            await showFile(db, { id: e.currentTarget.dataset.fileid })
            setProcessing(false)
        } else if (e.currentTarget.name === "fix" && data) {
            setProcessing(true)
            await updateVisitMutation({variables: { id: data.id, set: { comment } }})
            if (visitError) { 
                setProcessing(false)
                await showMessage("コメントの更新中にエラーが派生しました", { error: true })
            } else {
                refetch && await refetch()
                setProcessing(false)
                await showMessage("コメントを更新しました", { keep: true })
                setIsModalOpen(false)
            }
        } else if (e.currentTarget.name === "send" && message) {
            setProcessing(true)
            await insertMessage({ variables: { data: {_id: nanoid(), visitID: { link: data?.id }, targetUser: selectedUser.map(v => ({ userID: v.id, name: v.name })), sentBy: { userID: app.currentUser?.id, name: app.currentUser?.customData.name }, message, sentAt: new Date() }}})
            await app.currentUser?.callFunction("sendNotification")
            setProcessing(false)
            if (messageError) await showMessage("メッセージの送信中にエラーが派生しました", { error: true })
            else {
                await showMessage("メッセージを送信しました", { keep: true })
                setIsModalOpen(false)
            }        
        } else if (e.currentTarget.name === "close" || e.currentTarget.dataset.name === "close") {
            setIsModalOpen(false)
        }
    }

    const handleChange = (e:ChangeEvent<HTMLTextAreaElement>) => {
        switch (e.currentTarget.name){
            case 'comment': setComment(e.currentTarget.value);break;
            case 'message': setMessage(e.currentTarget.value);break;
        }
        
    }

//    useEffect(() => { if (data?.comment || data?.extendedProps?.comment) setComment(data?.comment || data?.extendedProps.comment) }, [data?.extendedProps?.comment, data?.comment])
    useEffect(() => { setComment(modifiedData?.comment || "") }, [data?.extendedProps?.comment, modifiedData?.comment])

    const nl2br = (text: string) => {
      return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br/>
        </React.Fragment>
      ));
    };

    return <ReactModal
        isOpen={isModalOpen}
        ariaHideApp={false}
//        onRequestClose={}
        style={modalStylesMobile}
        shouldCloseOnOverlayClick={false}
    >
        { processing ? <Loading full /> : <>
        <span data-name="close" className="text-white items-center absolute top-2 right-2" onClick={handleClick}><XCircleIcon className="w-8 h-8 cursor-pointer hover:text-theme-200" />️</span>
        {(userLoading || messageLoading || !data?.start) ? <div className="flex justify-center items-center text-white w-full h-full"><div>データ取得中...</div></div> : <div className="w-full overflow-scroll">
            <div className="flex flex-col sm:grid sm:grid-cols-[max-content_auto] gap-2 sm:gap-4 text-white">
                <div>日時：</div>
                <div className="flex bg-theme-700 sm:bg-transparent p-1 sm:p-0 gap-4"><span>{JSTDate(new Date(modifiedData.start))}</span><span>{JSTTime(new Date(modifiedData.start))}</span><span>〜</span><span>{JSTTime(new Date(modifiedData.end))}</span></div>
                <div>予定：</div>
                <div className="flex flex-col sm:flex-row bg-theme-700 sm:bg-transparent p-1 sm:p-0 gap-1 sm:gap-4"><span className={`px-2 bg-${getColor(modifiedData.bookingType)}`}>{options.bookingType[modifiedData.bookingType || 0]}</span><span>{modifiedData.patientName}</span><span>{modifiedData.courseName}</span></div>
                <div>コメント</div>
                <div className="flex-1">
                    <textarea name="comment" className="w-full text-black text-left rounded-md" rows={15} value={comment} onChange={handleChange} />
                    <button name="fix" className={YellowLeftButtonClass} onClick={handleClick}>保存</button>
                </div>
                <div>添付ファイル</div>
                <div>
                    <FileList files={modifiedData.files || []} />
                </div>
                <div>メッセージ</div>
                <div className="flex gap-4 text-white">
                    <div className="flex-grow">
                        <MultipleListPicker listItems={users} pickedItems={selectedUser} setPickedItems={setSelectedUser}/>
                        <textarea name="message" rows={5} className="w-full text-black text-left rounded-md" placeholder="メッセージ" value={message} onChange={handleChange} />
                        <button name="send" className={YellowLeftButtonClass} onClick={handleClick}>送信</button>
                        <ul className="py-2 w-full text-black space-y-0.5 rounded-md">
                        {messageData?.messages.map((message:KV) => <li key={message._id} className="p-1 bg-white rounded-md">
                            <div className="flex justify-between">
                                <div><span>宛先：</span>{message.targetUser.map((user:KV) => user.name).join(', ')}</div>
                                <div className="font-sm text-gray-500">送信：{message.sentBy?.name || ""} {JSTDate(new Date(message.sentAt))} {JSTTime(new Date(message.sentAt))}</div>
                            </div>
                            <div className="border"></div>
                            <div>{nl2br(message.message)}</div>
                        </li>)}
                        </ul>
                    </div>
                </div>
                <div className="col-span-2 flex justify-center">
                    <button type="button" name="close" className={GrayButtonClass} onClick={handleClick}>閉じる</button>
                </div>
            </div>
        </div>}</>}
    </ReactModal>
}

export default VisitForm