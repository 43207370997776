import React, { ChangeEvent, MouseEvent, useState, useEffect, FormEvent, FormEventHandler } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Loading } from "components/Loading";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Switch } from "@headlessui/react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { ButtonClass, SmallButtonClass } from "contexts/style";
import {getObjectDeepValue, modifyObjectDeepValue} from "contexts/functions";
import showMessage from "components/showMessage";

const ClinicFieldsFragment = gql`
  fragment ClinicFields on Clinic {
        _id
        clinic_name
        postal_code
        address1
        address2
        tel
        fax
        order
        clinic_type
        departments {
            name
            doctors
        }
    }
`;

const clinicDataQuery = gql`
    ${ClinicFieldsFragment}
    query GetClinic($query: ClinicQueryInput!) {
        clinic(query: $query)  {
            ...ClinicFields
        }
    }
`

const clinicDataInsert = gql`
    ${ClinicFieldsFragment}
    mutation AddClinic($data: ClinicInsertInput!) {
        addedClinic: insertOneClinic(data: $data) {
            ...ClinicFields
        }
    }
`

const clinicDataUpdate = gql`
    ${ClinicFieldsFragment}
    mutation UpdateClinic($set: ClinicUpdateInput!, $id: ObjectId!) {
        updateOneClinic(set: $set, query: { _id: $id}) {
            ...ClinicFields
        }
    }
`

const clinicInitialValues = {
    clinic_name: "",
    postal_code: "",
    address1: "",
    address2: "",
    tel: "",
    fax: "",
    order: "",
    clinic_type: 0,
    departments: [],
}

export const FieldInput: React.FunctionComponent<{ label: string; name: string; type?: string; values: KV; setValues: React.Dispatch<React.SetStateAction<KV>> }> = ({ label, name, type = "text", values, setValues }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [name]: e.currentTarget.value });
    }
    return <>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">{label}</label>
        <input
            type={type}
            name={name}
            className="mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500  border-gray-300"
            value={values[name]}
            onChange={handleChange}
        />
    </>
}

export const FieldSelect: React.FunctionComponent<{ label: string; name: string; name2: string; options: KV[]; values: KV; setValues: React.Dispatch<React.SetStateAction<KV>> }> = ({ label, name, name2, options, values, setValues }) => {
    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setValues({ ...values, [name]: { [name2]: e.currentTarget.value } });
    }
    return <div className={`col-span-6 sm:col-span-6`}>
        <label htmlFor="country" className="block text-sm font-medium text-gray-700">{label}</label>
        <select
            id={name}
            name={name}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={values[name]?.[name2] || ""}
            onChange={handleChange}
        >
            {options.map(v => <option key={v._id || "empty"} value={v._id}>{v.clinic_name}</option>)}
        </select>
    </div>
}


export const FieldCheck: React.FunctionComponent<{ label: string; name: string; values: KV; setValues: React.Dispatch<React.SetStateAction<KV>>; }> = ({ label, name, values, setValues }) => {
    const handleChange = () => {
        setValues({ ...values, [name]: values[name] ? 0 : 1 });
    }
    return (
        <div className={`col-span-6 sm:col-span-2`}>
            <div className="mr-3 text-sm">
                <label htmlFor={name} className="font-medium text-gray-700">{label}</label>
            </div>
            <div className="mt-1 block w-full">
                <Switch
                    checked={Boolean(values[name])}
                    onChange={handleChange}
                    name={name}
                    className={`${values[name] ? 'bg-theme-600' : 'bg-gray-200'
                        } relative inline-flex items-center h-8 rounded-full w-14 focus:outline-none focus:ring-2 ring-indigo-500 ring-offset-1`}
                >
                    <span
                        className={`${values[name] ? 'translate-x-7' : 'translate-x-1'
                            } inline-block w-6 h-6 transform bg-white rounded-full`}
                    />
                </Switch>
            </div>
        </div>
    )
}

export const FormButton = ({ submitting, back = null, }: { submitting: boolean, back: (() => void) | null; }) => {
    return <>
        <button className={ButtonClass} disabled={submitting}>
            <CheckCircleIcon className="w-5 h-5" />確定
        </button>
        <button type="button" className={ButtonClass} disabled={submitting} onClick={(e) => {
            e.preventDefault();
            back && back();
        }}
        >
            <XCircleIcon className="w-5 h-5" />戻る
        </button>
    </>
}

const FormList = ({ label, name, field, values, setValues, broadcast }: { label: string; name: string, field?: string, values: KV, setValues: React.Dispatch<React.SetStateAction<KV>>, broadcast?: (selected?: string) => void }) => {
    const [addingValue, setAddingValue] = useState("")
    const [selectedValue, setSelectedValue] = useState<string[]>([])
    const items: any[] = getObjectDeepValue(name, values) || []
    const addItem = async (e: MouseEvent<HTMLButtonElement>) => {
        if ((field ? items.map(v => v[field]) : items).includes(addingValue)) {
            await showMessage(`この名称の${label}はすでに存在します`, { error: true })
            return null
        }
        setValues(modifyObjectDeepValue(name, values, [...items, (field ? { [field]:addingValue } : addingValue)]))
        setAddingValue("")
        setSelectedValue([addingValue])
    }
    const deleteItem = async (e: MouseEvent<HTMLButtonElement>) => {
        if (items.find(v => field ? v[field] : v)?.doctors?.length) {
            await showMessage(`所属医師のいる${label}は削除できません`, { error: true })
            return null
        }
        setValues(modifyObjectDeepValue(name, values, items.filter(v => !selectedValue.includes(field ? v[field] : v))))
        setSelectedValue([])
    }
    const handleSelectChange = ({ target }: ChangeEvent<HTMLSelectElement>) => {
        const values = Array.from(target.selectedOptions).map(o => o.value)
        setSelectedValue(values)
        broadcast && broadcast(values.length === 1 ? values[0] : undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { broadcast && broadcast(selectedValue.length === 1 ? selectedValue[0] : undefined) }, [selectedValue])
    return <div className="grid grid-cols-[min-content_min-content] auto-rows-min">
        <span className="p-1 text-sm col-span-2">{label}</span>
        <input
            className="m-1 block shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500  border-gray-300"
            type="text"
            value={addingValue}
            onChange={e => setAddingValue(e.currentTarget.value)}
        />
        <button type="button" className={SmallButtonClass.replace("rounded-sm", "rounded-md") + " items-center m-1 w-12 h-8"} onClick={addItem}>追加</button>
        <select className="m-1 text-sm border-gray-300 rounded-md" size={3} multiple value={selectedValue} onChange={handleSelectChange}>
            {(field ? items.map(v => v[field]) : items).map(item => <option key={item} value={item}>{item}</option>)}
        </select>
        <button type="button" className={SmallButtonClass.replace("rounded-sm", "rounded-md") + " items-center m-1 w-12 h-8"} onClick={deleteItem}>削除</button>
    </div>

}

const FormListAccount = ({ label, name, field, values, setValues, broadcast }: { label: string; name: string, field?: string, values: KV, setValues: React.Dispatch<React.SetStateAction<KV>>, broadcast?: (selected?: string) => void }) => {
    const navigate = useNavigate()
    const client = useApolloClient()
    const [addingValue, setAddingValue] = useState("")
    const [selectedValue, setSelectedValue] = useState<string[]>([])
    const items: any[] = getObjectDeepValue(name, values) || []
    const addItem = async (e: MouseEvent<HTMLButtonElement>) => {
        if (addingValue[0].includes('*')) {
            await showMessage(`名前に*を含むことはできません`, { error: true })
            return null
        }
        if ((field ? items.map(v => v[field]) : items).includes(addingValue)) {
            await showMessage(`この名前の${label}はすでに存在します`, { error: true })
            return null
        }
        setValues(modifyObjectDeepValue(name, values, [...items, (field ? { [field]:addingValue } : addingValue)]))
        setAddingValue("")
        setSelectedValue([addingValue])
    }
    const editItem = async (e: MouseEvent<HTMLButtonElement>) => {
        if (!selectedValue[0].includes("*")) {
            await showMessage(`予約可能ユーザーではないため編集できません`, { error: true })
            return null
        }
        const user = await client.query({ query: gql`query GetuserID {
            user(query:{ clinic: { _id:"${values._id}" }, department: "${label.split('：')[0]}", name: "${selectedValue[0]?.replace('*', '') || ''}"}) {
                _id
            }
        }` })
        if (user?.data?.user?._id) navigate(`/user/edit/${user.data.user._id}`)
        else showMessage(`ユーザーが登録されていません`, { error: true })
    }
    const deleteItem = async (e: MouseEvent<HTMLButtonElement>) => {
        if (selectedValue[0].includes("*")) {
            await showMessage(`予約可能ユーザーとして登録されているためここからは削除できません`, { error: true })
            return null
        }
        setValues(modifyObjectDeepValue(name, values, items.filter(v => !selectedValue.includes(field ? v[field] : v))))
        setSelectedValue([])
    }
    const handleSelectChange = ({ target }: ChangeEvent<HTMLSelectElement>) => {
        const values = Array.from(target.selectedOptions).map(o => o.value)
        setSelectedValue(values)
        broadcast && broadcast(values.length === 1 ? values[0] : undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { broadcast && broadcast(selectedValue.length === 1 ? selectedValue[0] : undefined) }, [selectedValue])
    return <div className="grid grid-cols-[min-content_min-content] auto-rows-min">
        <span className="p-1 text-sm col-span-2">{label}</span>
        <div className="flex justify-between items-center">
        <input
            className="m-1 block shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500  border-gray-300"
            type="text"
            value={addingValue}
            onChange={e => setAddingValue(e.currentTarget.value)}
        />
        </div>
        <button type="button" className={SmallButtonClass.replace("rounded-sm", "rounded-md") + " items-center m-1 w-12 h-8"} onClick={addItem}>追加</button>
        <select className="m-1 text-sm border-gray-300 rounded-md" size={3} multiple value={selectedValue} onChange={handleSelectChange}>
            {(field ? items.map(v => v[field]) : items).map(item => <option key={item} value={item}>{item}</option>)}
        </select>
        <div>
        {selectedValue[0]?.includes('*') ? <button type="button" className={SmallButtonClass.replace("rounded-sm", "rounded-md") + " items-center m-1 w-12 h-8"} onClick={editItem}>{selectedValue[0]?.includes('*') ? '編集' : '追加'}</button> : <button type="button" className={SmallButtonClass.replace("rounded-sm", "rounded-md") + " items-center m-1 w-12 h-8"} onClick={deleteItem}>削除</button>}
        </div>
    </div>

}
const Form = ({ label, values, setValues, handleSubmit, submitting, back }: { label: string; values: KV; setValues: React.Dispatch<React.SetStateAction<KV>>; handleSubmit: FormEventHandler<HTMLFormElement>; submitting: boolean; back: () => void }) => {
    const [selectedDepartment, setSelectedDepartment] = useState(-1)
    const broadcast = (selected?: string) =>{
        setSelectedDepartment((values?.departments || []).findIndex((v:any) => v.name === selected))

    }
    return <div className="flex justify-center">
        <div className="pt-5 2xl:pt-0 2xl:col-span-4 w-full max-w-4xl">
            <div className="shadow sm:rounded-md">
                <form onSubmit={handleSubmit}>
                    <div className="p-4 text-theme-800 bg-theme-50 text-xl sm:px-6">{label}</div>
                    <div className="px-4 py-5 bg-white space-y-4 sm:p-6">
                        <div>施設区分：{values.clinic_type ? "外来" : "外注"}</div>
                        <FormList label="診療科" name="departments" field='name' values={values} setValues={setValues} broadcast={broadcast}/>
                        {selectedDepartment > -1 && <FormListAccount label={`${values.departments[selectedDepartment]?.name}：医師`} name={`departments[${selectedDepartment}].doctors`} values={values} setValues={setValues} />}
                        <FieldInput label="施設名" name="clinic_name" values={values} setValues={setValues} />
                        <FieldInput label="施設郵便番号" name="postal_code" values={values} setValues={setValues} />
                        <FieldInput label="住所1(都道府県市区町村)" name="address1" values={values} setValues={setValues} />
                        <FieldInput label="住所2(その他)" name="address2" values={values} setValues={setValues} />
                        <FieldInput label="施設電話番号" name="tel" values={values} setValues={setValues} />
                        <FieldInput label="施設FAX" name="fax" values={values} setValues={setValues} />
                        <FieldInput label="施設メールアドレス" name="email" values={values} setValues={setValues} />
                        <FieldInput type="number" label="表示順" name="order" values={values} setValues={setValues} />
                    </div>
                    <div className="px-4 bg-theme-50 text-right sm:px-6">
                        <FormButton submitting={submitting} back={back} />
                    </div>
                </form>
            </div>
        </div>
    </div>
}

export const Edit = () => {
    let { id } = useParams<KV>();
    const navigate = useNavigate();
    const back = () => navigate(`/clinic`);
    const [updateData, { loading: mutationLoading }] = useMutation(clinicDataUpdate, { onCompleted: back })

    const { loading, data } = useQuery(clinicDataQuery, { variables: { query: { _id: id } } });
    const [clinicData, setClinicData] = useState<KV>(clinicInitialValues)
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async (e: FormEvent) => {
        setSubmitting(true)
        e.preventDefault()
        let updatingData = { ...clinicData }
        delete updatingData._id
        Object.keys(updatingData).forEach(key => { if (updatingData[key] === "") updatingData[key] = null })
        await updateData({
            variables: { id: id, set: updatingData },
        });
        setSubmitting(false)
    }

    useEffect(() => {
        let departments = (data?.clinic?.departments || []).map((department:KV) => {
            const { __typename, ...object } = department
            return object
        })
        let newData: KV = {
            _id: data?.clinic?._id || "",
            clinic_name: data?.clinic?.clinic_name || "",
            postal_code: data?.clinic?.postal_code || "",
            address1: data?.clinic?.address1 || "",
            address2: data?.clinic?.address2 || "",
            tel: data?.clinic?.tel || "",
            fax: data?.clinic?.fax || "",
            order: data?.clinic?.order || "",
            clinic_type: data?.clinic?.clinic_type || 0,
            departments: departments,
        }
        setClinicData(newData)
    }, [data])


    if (loading || mutationLoading) return <div className="w-full h-full flex justify-center items-center"><Loading /></div>

    return <Form label="施設編集" values={clinicData} setValues={setClinicData} handleSubmit={handleSubmit} submitting={submitting} back={back} />
};

export const Create = () => {
    const navigate = useNavigate();
    const back = () => navigate(`/clinic`);
    const [addData, { loading }] = useMutation(clinicDataInsert, {
        onCompleted: back,
        update: (cache, { data: { addedClinic } }) => {
            cache.modify({
                fields: {
                    clinics: (existingclinics = []) => [
                        ...existingclinics,
                        cache.writeFragment({
                            data: addedClinic,
                            fragment: ClinicFieldsFragment,
                        }),
                    ],
                },
            });
        },
    })

    const [clinicData, setClinicData] = useState<KV>(clinicInitialValues)
    const [submitting, setSubmitting] = useState(false)

    const handleSubmit = async (e: FormEvent) => {
        setSubmitting(true)
        e.preventDefault()
        let addingData = { ...clinicData }
        Object.keys(addingData).forEach(key => { if (addingData[key] === "") addingData[key] = null })
        await addData({
            variables: { data: addingData },
        });
        setSubmitting(false)
    }

    if (loading) return <div className="w-full h-full flex justify-center items-center"><Loading /></div>
    return <Form label="施設新規作成" values={clinicData} setValues={setClinicData} handleSubmit={handleSubmit} submitting={submitting} back={back} />
};


export default Edit