import { CheckClass, InputClass } from "contexts/style"
import React, { ChangeEvent, useEffect, useState } from "react"
import { useRealmApp } from "contexts/RealmApp"
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline"
import { ModalityLists, PetIDs } from "contexts/enviroments"

/**
 * FormModality
 * 検査情報
 */
export const FormModality = ({
                                 selectResourceInformation,
                                 secondResourceInformation,
                                 modalities, modality, setModality,
                                 body1, setBody1,
                                 body2, setBody2,
                                 contrast, setContrast,
                                 setSecondModalityData,
                                 delay, setDelay,
                                 starttime, endtime, setEnd, isUpdated, checkin, setCheckin
                             }:
                                 {
                                     selectResourceInformation: { [key: string]: any },
                                     secondResourceInformation: KV,
                                     modalities: string | string[], modality: string, setModality?: React.Dispatch<React.SetStateAction<string>>,
                                     body1: string, setBody1: React.Dispatch<React.SetStateAction<string>>,
                                     body2: string, setBody2: React.Dispatch<React.SetStateAction<string>>,
                                     contrast: string, setContrast: React.Dispatch<React.SetStateAction<string>>,
                                     setSecondModalityData: React.Dispatch<React.SetStateAction<KV>>,
                                     delay: boolean, setDelay: React.Dispatch<React.SetStateAction<boolean>>,
        starttime: Date, endtime: Date, setEnd: React.Dispatch<React.SetStateAction<Date>>, isUpdated: boolean,
        checkin: string,
        setCheckin: React.Dispatch<React.SetStateAction<string>>
                                 }
) => {

    const app = useRealmApp()
    const noslotResources = app.resources.filter(v => v.noslot).map(v => v.id as string)

    const [timeStr, setTimeStr] = useState<string>("")
    const [openDelay, setOpenDelay] = useState<boolean>(false)
    const [secondMod, setSecondMod] = useState<boolean>(false)

    useEffect(() => {
        if (isUpdated && body1 !== '' && body2 !== "") {
            const minute = (endtime.getTime() - starttime.getTime()) / (1000 * 60)
            setTimeStr(minute.toString() + "分")
        }
    }, [isUpdated, body1, body2, endtime, starttime])


    useEffect(() => {
        if (!isUpdated) {
            // デフォルト値があれば、セット
            if (['ABUS', 'MMG'].includes(modality)) {
                setBody1('なし')
                setBody2('乳房')
                setContrast('なし')
                setTimeStr('10分')
                const endTmp = new Date(starttime.getTime())
                endTmp.setMinutes(starttime.getMinutes() + 10)
                setEnd(endTmp)
            } else if (['ES', 'US', 'US(L)'].includes(modality)) {
                setBody1('なし')
            } else {
                setBody1('')
                setBody2('')
                setContrast('')
                setTimeStr('5分')
            }
        }
        if (delay) {
            setOpenDelay(true)
        } else {
            setOpenDelay(false)
        }
    }, [modality])

    /**
     * 部位1選択時に動く
     * 部位2, 造影剤、所要時間は初期化
     * @param e
     */
    const updateBody1 = (e: any) => {
        setBody1(e.target.value)

        if (body2 !== "") {
            setBody2("")
        }
        if (contrast !== "") {
            setContrast("")
        }
        if (timeStr !== "") {
            setTimeStr("")
        }
    }

    /**
     * 部位2選択時に動く
     * 造影剤、所要時間は初期化
     * @param e
     */
    const updateBody2 = (e: any) => {
        setBody2(e.target.value)
        if (contrast !== "") {
            setContrast("")
        }
        if (timeStr !== "") {
            setTimeStr("")
        }
    }

    /**
     * 造影剤選択時に動く
     * 造影剤、所要時間を変更
     * @param e
     */
    const updateContrast = (e: any) => {
        setContrast(e.target.value)
        setTimeStr(selectResourceInformation["body"][body1][body2][e.target.value] + '分')

        if (PetIDs.includes(modality) && body1 === '全身' && body2 === "全身") {
            setOpenDelay(true)
        } else {
            setOpenDelay(false)
        }

        // 終了時刻計算
        const endTmp = new Date(starttime.getTime())
        endTmp.setMinutes(starttime.getMinutes() + Number(selectResourceInformation["body"][body1][body2][e.target.value]))
        setEnd(endTmp)

        // PET-CT_頭、アミロイドの場合、スタートの100分前にする
        if (body1 === 'PET-CT_頭' && body2 === '頭部(アミロイド)') {
            console.log("受付時間を100分前にする");
            const checkinTime = new Date(starttime.getTime() - 100 * 60 * 1000);
            const hours = checkinTime.getHours().toString().padStart(2, '0');
            const minutes = checkinTime.getMinutes().toString().padStart(2, '0');
            const updateCheckin = `${hours}:${minutes}`;
            setCheckin(updateCheckin);
        }
    }

    const updateModal = (e: ChangeEvent<HTMLSelectElement>) => {
        setModality && setModality(e.currentTarget.value)
    }

    const openSecondMod = () => {
        setSecondMod(true)
    }
    const closeSecondMod = () => {
        setSecondMod(false)
        setSecondModalityData({ ID: "MRI", body1: "", body2: "", contrast: "", delay: false, duration: 0 })
    }

    /**
     * モダリティ用のSelect Box
     * @constructor
     */
    const Modality = () => {

        return <span>
    <select id="modality" name="modality" value={modality} onChange={updateModal} className={InputClass}>
      {(Array.isArray(modalities) ? modalities : [modalities]).map(v => <option key={v} value={v}>{v}</option>)}
    </select>
  </span>
    }

    /**
     * 部位1用のSelect Box
     * @constructor
     */
    const ModalityBody1 = () => {
        return <span className="ml-3">
        <select id="modality_body1" name="modality_body1" value={body1} className={InputClass}
                onChange={(e) => updateBody1(e)}>
          <option key="0" value=""> - 部位1 - </option>
            {Object.keys(selectResourceInformation).length === 0 ? <></> :
                Object.keys(selectResourceInformation["body"]).map((key) => {
                    return <option key={key} value={key}>{key}</option>
                })
            }
        </select>
      </span>
    }

    /**
     * 部位2用のSelect Box
     * @constructor
     */
    const ModalityBody2 = () => {
        if (Object.keys(selectResourceInformation).length !== 0) {
            if (body1 in selectResourceInformation["body"]) {
                return <span className="ml-3">
           <select id="modality_body2" name="modality_body2" value={body2} className={InputClass}
                   onChange={(e) => updateBody2(e)}>
             <option key="0" value=""> - 部位2 - </option>
               {
                   Object.keys(selectResourceInformation["body"][body1]).map((key) => {
                       return <option key={key} value={key}>{key}</option>
                   })
               }
           </select>
         </span>
            }
        }

        return <span className="ml-3">
           <select id="modality_body2" name="modality_body2" value={body2} className={InputClass}
                   onChange={(e) => updateBody2(e)}>
             <option key="0" value=""> - 部位2 - </option>
           </select>
         </span>
    }

    const updateTime = (e: any) => {
        setTimeStr(e.target.value)
        const minute = e.target.value.replace("分", "")
        const endTmp = new Date(starttime.getTime())
        endTmp.setMinutes(starttime.getMinutes() + Number(minute))
        setEnd(endTmp)
    }

    /**
     * 造影剤指示用のSelect Box
     * @constructor
     */
    const Contrast = () => {
        if (Object.keys(selectResourceInformation).length !== 0) {
            if (body1 in selectResourceInformation["body"]) {
                if (body2 in selectResourceInformation["body"][body1]) {
                    return <span className="ml-3">
           <select id="contrast_agent" name="contrast_agent" value={contrast} className={InputClass}
                   onChange={(e) => updateContrast(e)}>
             <option key="0" value=""> - 造影剤指示 -  </option>
               {
                   Object.keys(selectResourceInformation["body"][body1][body2]).map((key) => {
                       return <option key={key} value={key}>{key}</option>
                   })
               }
           </select>
         </span>
                }
            }
        }
        return <span className="ml-3">
           <select id="contrast_agent" name="contrast_agent" value={contrast} className={InputClass}
                   onChange={(e) => updateContrast(e)}>
             <option key="0" value=""> - 造影剤指示 -  </option>
           </select>
         </span>

    }

    // セット予約のパラメータ定義
    const [modality2, setModality2] = useState<string>("MRI")
    const [body1_2, setBody1_2] = useState<string>("")
    const [body2_2, setBody2_2] = useState<string>("")
    const [contrast2, setContrast2] = useState<string>("")
    const [timeStr2, setTimeStr2] = useState<string>("5分")

    const updateModality2 = async (e: any) => {
        setModality2(e.target.value)
    }
    const SecondModality = () => {
        return <span>
    <select value={modality2} onChange={updateModality2} className={InputClass}>
      {ModalityLists.map(v => <option key={v} value={v}>{v}</option>)}
    </select>
  </span>
    }
    const updateBody1_2 = async (e: any) => {
        setBody1_2(e.target.value)
    }
    const SecondBody1 = () => {
        return <span className="ml-3">
        <select value={body1_2} className={InputClass}
                onChange={(e) => updateBody1_2(e)}>
          <option key="0" value=""> - 部位1 - </option>
            {Object.keys(secondResourceInformation[modality2]["body"]).map((key) => {
                return <option key={key} value={key}>{key}</option>
            })}
        </select>
      </span>
    }
    const updateBody2_2 = async (e: any) => {
        setBody2_2(e.target.value)
    }
    const SecondBody2 = () => {
        return <span className="ml-3">
        <select value={body2_2} className={InputClass}
                onChange={(e) => updateBody2_2(e)}>
          <option key="0" value=""> - 部位2 - </option>
            {body1_2 in secondResourceInformation[modality2]["body"] ?
                Object.keys(secondResourceInformation[modality2]["body"][body1_2]).map((key) => {
                    return <option key={key} value={key}>{key}</option>
                })
                : <></>}

        </select>
      </span>
    }
    const updateContrast2 = async (e: any) => {
        setContrast2(e.target.value)
        setTimeStr2(String(secondResourceInformation[modality2]["body"][body1_2][body2_2][e.target.value]) + '分')

    }
    useEffect(() => {
        const matchResult = timeStr2.match(/\d+/)
        if (matchResult) {
            setSecondModalityData({
                ID: modality2,
                body1: body1_2,
                body2: body2_2,
                contrast: contrast2,
                delay: false,
                duration: Number(matchResult[0]),
            })
        }
    }, [timeStr2])
    const Contrast2 = () => {
        return <span className="ml-3">
           <select value={contrast2} className={InputClass}
                   onChange={(e) => updateContrast2(e)}>
             <option key="0" value=""> - 造影剤指示 -  </option>
               {body1_2 in secondResourceInformation[modality2]["body"] ?
                   body2_2 in secondResourceInformation[modality2]["body"][body1_2] ?
                       Object.keys(secondResourceInformation[modality2]["body"][body1_2][body2_2]).map((key) => {
                           return <option key={key} value={key}>{key}</option>
                       })
                       : <></> : <></>
               }
           </select>
         </span>

    }
    const updateTime2 = async (e: any) => {
        setTimeStr2(e.target.value)
        const minute = e.target.value.replace("分", "")
        const endTmp = new Date(starttime.getTime())
        endTmp.setMinutes(starttime.getMinutes() + Number(minute))
        setEnd(endTmp)
    }

    const updateDelay = (e: any) => {
        // チェックが入った場合
        if (e.target.checked) {
            setDelay(true)
            // 20分加算
            const minute = timeStr.replace("分", "")
            const endTmp = new Date(starttime.getTime())
            endTmp.setMinutes(starttime.getMinutes() + Number(minute) + 20)
            setEnd(endTmp)
            setTimeStr(String(Number(minute) + 20) + '分')

        } else {
            setDelay(false)
            // 20分減算
            const minute = timeStr.replace("分", "")
            const endTmp = new Date(starttime.getTime())
            endTmp.setMinutes(starttime.getMinutes() + Number(minute) - 20)
            setEnd(endTmp)
            setTimeStr(String(Number(minute) - 20) + '分')
        }
    }

    return <div className="p-3">
        <div className="flex">
            <div className={`mt-2 mr-2 ${secondMod ? "invisible" : ""}`}>
                <button className={`ml-4 mr-1 border-2 border-blue-100 `} onClick={openSecondMod}>
                    <PlusIcon className="w-4 h-4 text-blue-100"/>
                </button>
            </div>

            <Modality/>
            {!noslotResources.includes(modality) && <>
                <ModalityBody1/>
                <ModalityBody2/>
                <Contrast/>
                {openDelay ?
                    <div className="ml-5 mt-1">
                        <input type="checkbox" id="scales" name="scales" checked={delay}
                               onChange={(e) => updateDelay(e)} className={CheckClass} value="FAX"/>
                        <label className="ml-1 text-white" htmlFor="scales">Delay</label>
                    </div>
                    : <></>
                }
                <div className="ml-5 flex ">
                    <span className="text-white mr-6 mt-1">所要時間</span>
                    <span className="">
            <select className={InputClass} value={timeStr} onChange={(e) => updateTime(e)}>
              <option value="5分"> 5分 </option>
              <option value="10分"> 10分 </option>
              <option value="15分"> 15分 </option>
              <option value="20分"> 20分 </option>
              <option value="25分"> 25分 </option>
              <option value="30分"> 30分 </option>
              <option value="30分"> 30分 </option>
              <option value="35分"> 35分 </option>
              <option value="40分"> 40分 </option>
              <option value="45分"> 45分 </option>
              <option value="50分"> 50分 </option>
              <option value="55分"> 55分 </option>
              <option value="60分"> 60分 </option>
            </select>
          </span>
                </div>
            </>}
        </div>
        {/* セット予約の場合 */}
        {secondMod && !isUpdated ?
            <div className="flex mt-2">
                <div className={`mt-2 mr-2`}>
                    <button className={`ml-4 mr-1 border-2 border-blue-100 `} onClick={closeSecondMod}>
                        <MinusIcon className="w-4 h-4 text-blue-100"/>
                    </button>
                </div>
                <SecondModality/>
                <SecondBody1/>
                <SecondBody2/>
                <Contrast2/>
                {openDelay ?
                    <div className="ml-5 mt-1">
                        <input type="checkbox" id="scales" name="scales" checked={delay}
                               onChange={(e) => updateDelay(e)} className={CheckClass} value="FAX"/>
                        <label className="ml-1 text-white" htmlFor="scales">Delay</label>
                    </div>
                    : <></>
                }
                <div className="ml-5 flex ">
                    <span className="text-white mr-6 mt-1">所要時間</span>
                    <span className="">
            <select className={InputClass} value={timeStr2} onChange={(e) => updateTime2(e)}>
              <option value="5分"> 5分 </option>
              <option value="10分"> 10分 </option>
              <option value="15分"> 15分 </option>
              <option value="20分"> 20分 </option>
              <option value="25分"> 25分 </option>
              <option value="30分"> 30分 </option>
              <option value="30分"> 30分 </option>
              <option value="35分"> 35分 </option>
              <option value="40分"> 40分 </option>
              <option value="45分"> 45分 </option>
              <option value="50分"> 50分 </option>
              <option value="55分"> 55分 </option>
              <option value="60分"> 60分 </option>
            </select>
          </span>
                </div>
            </div>
            : <></>}

    </div>
}