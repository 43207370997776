import React, { ChangeEvent, useState, useEffect, FormEvent, MouseEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { customAlphabet } from "nanoid"

import { Loading } from "components/Loading";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { Switch } from "@headlessui/react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { ButtonClass, RedButtonClass } from "contexts/style";
import { useRealmApp } from "contexts/RealmApp";
import { TrashIcon } from "@heroicons/react/24/outline";
import showMessage from "components/showMessage";
import { emailRegex } from "contexts/functions";
import options from "contexts/options.json";

const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 12)


const UserFieldsFragment = gql`
    fragment UserFields on User {
        _id
        email
        internal
        booking
        notice
        clinic {
            _id
            clinic_name
        }
        department
        name
        role
        status
    }
`;


const clinicsData = gql`
    query GetClinics {
        clinics {
            _id
            clinic_name
            departments {
                name
            }
        }
    }
`

const userDataQuery = gql`
    ${UserFieldsFragment}
    query GetUser($query: UserQueryInput!) {
        user(query: $query)  {
            ...UserFields
        }
    }
`

const userDataInsert = gql`
    ${UserFieldsFragment}
    mutation AddUser($data: UserInsertInput!) {
        addedUser: insertOneUser(data: $data) {
            ...UserFields
        }
    }
`

const userDataUpdate = gql`
    ${UserFieldsFragment}
    mutation UpdateUser($set: UserUpdateInput!, $id: String!) {
        updateOneUser(set: $set, query: { _id: $id}) {
            ...UserFields
        }
    }
`

export const FieldLocked: React.FunctionComponent<{ label: string; name: string; values: KV; }> = ({ label, name, values }) => {
    return <div className={`col-span-6 sm:col-span-6`}>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">{label}</label>
        <span className="mt-1 p-2 block w-full shadow-sm sm:text-sm rounded-md bg-gray-100 border-gray-300">{values[name]}</span>
        <input
            type="hidden"
            name={name}
            value={values[name]}
        />
    </div>
}


export const FieldInput: React.FunctionComponent<{ label: string; name: string; type?: string; values: KV; setValues: React.Dispatch<React.SetStateAction<KV>> }> = ({ label, name, type = "text", values, setValues }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [name]: e.currentTarget.value });
    }
    return <>
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">{label}</label>
        <input
            type={type}
            name={name}
            className="mt-1 block w-full shadow-sm sm:text-sm rounded-md disabled:bg-gray-100 focus:ring-indigo-500 focus:border-indigo-500  border-gray-300"
            value={values[name]}
            onChange={handleChange}
        />
    </>
}

export const FieldSelect: React.FunctionComponent<{ label: string; name: string; name2?: string; options: KV; values: KV; setValues: React.Dispatch<React.SetStateAction<KV>> }> = ({ label, name, name2, options, values, setValues }) => {
    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (name === 'clinic' && name2) setValues({ ...values, [name]: { [name2]: e.currentTarget.value }, department: '' });
        else if (name2) setValues({ ...values, [name]: { [name2]: e.currentTarget.value } });
        else setValues({ ...values, [name]: e.currentTarget.value });
    }
    return <div className={`col-span-6 sm:col-span-6`}>
        <label htmlFor="country" className="block text-sm font-medium text-gray-700">{label}</label>
        <select
            id={name}
            name={name}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={(name2 ? values[name]?.[name2] : values[name]) || ""}
            onChange={handleChange}
        >
            {Object.keys(options).map(key => <option key={key} value={key}>{options[key]}</option>)}

        </select>
    </div>
}


export const FieldCheck: React.FunctionComponent<{ label: string; name: string; values: KV; setValues?: ((value: KV) => void) | React.Dispatch<React.SetStateAction<KV>>; }> = ({ label, name, values, setValues }) => {
    const handleChange = () => {
        if (!setValues) showMessage("この項目は変更できません") 
        else setValues({ ...values, [name]: !Boolean(values[name]) });
    }
    return (
        <div className={`col-span-6 sm:col-span-2`}>
            <div className="mr-3 text-sm">
                <label htmlFor={name} className="font-medium text-gray-700">{label}</label>
            </div>
            <div className="mt-1 block w-full">
                <Switch
                    checked={values[name]}
                    onChange={handleChange}
                    name={name}
                    className={`${values[name] ? 'bg-theme-600' : 'bg-gray-200'
                        } relative inline-flex items-center h-8 rounded-full w-14 focus:outline-none focus:ring-2 ring-indigo-500 ring-offset-1`}
                >
                    <span
                        className={`${values[name] ? 'translate-x-7' : 'translate-x-1'
                            } inline-block w-6 h-6 transform bg-white rounded-full`}
                    />
                </Switch>
            </div>
        </div>
    )
}

export const FormButton = ({ submitting, resend, back, deleteData }: { submitting: boolean, resend?: (() => void), back?: (() => void), deleteData?: (() => Promise<void>) }) => {
    const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        switch (e.currentTarget.name) {
            case "resend": resend && resend(); break;
            case "back": back && back(); break;
            case "delete": deleteData && deleteData(); break;
            default:
        }
    }
    return <>
        <button className={ButtonClass} disabled={submitting}>
            <CheckCircleIcon className="w-5 h-5" />確定
        </button>
        {resend && <button type="button" name="resend" className={ButtonClass} disabled={submitting} onClick={handleClick} >
            <XCircleIcon className="w-5 h-5" />認証再送
        </button>}
        {back && <button type="button" name="back" className={ButtonClass} disabled={submitting} onClick={handleClick} >
            <XCircleIcon className="w-5 h-5" />戻る
        </button>}
        {deleteData && <button type="button" name="delete" className={RedButtonClass} disabled={submitting} onClick={handleClick} >
            <TrashIcon className="w-5 h-5" />削除
        </button>}
    </>
}

export const Edit = () => {
    const app = useRealmApp()
    let { id } = useParams<KV>()
    const client = useApolloClient()
    const navigate = useNavigate()
    const back = () => navigate(-1)
    const [updateData, { loading: mutationLoading }] = useMutation(userDataUpdate, { onCompleted: back })

    const { loading: clinicsLoading, data: clinicData } = useQuery(clinicsData);
    const clinics = clinicData?.clinics.reduce((a: KV, r: KV) => ({ ...a, [r._id]: r.clinic_name }), { "": "" }) || { "": "" }
    const { loading, data } = useQuery(userDataQuery, { variables: { query: { _id: id } } });
    const [userData, setUserData] = useState<KV>({ _id: "", email: "", internal: false, booking: false, notice: false, clinic: { _id: "" }, department: "", name: "", role: "", status: "" })
    const departments = (clinicData?.clinics.find((v: KV) => v._id === userData?.clinic?._id)?.departments || []).reduce((a: KV, r: KV) => ({ ...a, [r.name]: r.name }), { "": "" })
    const [submitting, setSubmitting] = useState(false)

    const deleteUser = async () => {
        if (await showMessage(`ユーザーを削除します。この操作は元に戻せません。\r\nこのユーザーはログインできなくなります。\r\n削除してよろしいですか？`, { confirm: true })) {
            if (app.currentUser) {
                setSubmitting(true)
                //                if (app.currentUser.id === id) await app.app.removeUser(app.currentUser)
                try {
                    const error = await app.currentUser.functions.deleteUser(id, userData?.email)
                    if (error) {
                        showMessage(error, { error: true })
                        setSubmitting(false)
                        return
                    }
                    client.cache.evict({ id: `User:${id}` });
                    showMessage("ユーザーを削除しました")
                    setSubmitting(false)
                    navigate(-1)
                } catch {
                    showMessage("ユーザーの削除中にエラーが発生しました", { error: true })
                    setSubmitting(false)
                }
            }
        }
    }

    const resend = async () => {
        setSubmitting(true)
        try {
            await app.app.emailPasswordAuth.retryCustomConfirmation({ email: userData.email })
            setSubmitting(false)
            await showMessage("認証メールを再送しました")
        } catch (e) {
            setSubmitting(false)
            if (e instanceof Error && e.message.includes("already confirmed")) {
                await showMessage("このアカウントはすでに認証されています", { error: true })
            } else {
                await showMessage("認証メールの再送が失敗しました", { error: true })
            }
        }
    }

    const handleSubmit = async (e: FormEvent) => {
        setSubmitting(true)
        e.preventDefault()
        let updatingData = { ...userData }
        delete updatingData._id
        updatingData.clinic = updatingData.clinic._id ? { link: updatingData.clinic._id } : null
        Object.keys(updatingData).forEach(key => { if (updatingData[key] === "") updatingData[key] = null })
        try {
            await updateData({
                variables: { id: id, set: updatingData },
            });
        } catch (e) {
            if (e instanceof Error) await showMessage(`エラー：\r\n${e.message}`, { error: true })
        }
        setSubmitting(false)
    }

    useEffect(() => {
        let newData: KV = { _id: data?.user?._id || "", email: data?.user?.email || "", internal: data?.user?.internal || false, booking: data?.user?.booking || false, notice: data?.user?.notice || false, clinic: { _id: "" }, department: data?.user?.department || "", name: data?.user?.name || "", role: data?.user?.role || "", status: data?.user?.status || "", }
        if (data?.user?.clinic) newData.clinic = data.user.clinic
        setUserData(newData)
    }, [data])
    if (loading || clinicsLoading || mutationLoading || submitting) return <div className="w-full h-full flex justify-center items-center"><Loading /></div>
    return <div className="flex justify-center">
        <div className="pt-5 2xl:pt-0 2xl:col-span-4 w-full max-w-4xl">
            {!data?.user ? <div className="py-8 text-lg">このユーザーIDのユーザーは存在しないか、すでに削除されています</div> : <div className="shadow sm:rounded-md">
                <form onSubmit={handleSubmit}>
                    <div className="p-4 text-theme-800 bg-theme-50 text-xl sm:px-6">ユーザー情報編集</div>
                    <div className="px-4 py-5 bg-white space-y-4 sm:p-6">
                        <div className="flex gap-16">
                            <FieldCheck label="院内" name="internal" values={userData} />
                            {!userData.internal && <FieldCheck label="予約可能" name="booking" values={userData} />}
                            {userData.internal && <FieldCheck label="予約通知メールを送付" name="notice" values={userData} setValues={setUserData} />}
                        </div>
                        <FieldLocked label="メールアドレス" name="email" values={userData} />
                        <FieldSelect label="施設" name="clinic" name2="_id" options={clinics} values={userData} setValues={setUserData} />
                        <FieldSelect label="診療科" name="department" options={departments} values={userData} setValues={setUserData} />
                        <FieldInput label="担当者名" name="name" values={userData} setValues={setUserData} />
                        <FieldInput label="役割" name="role" values={userData} setValues={setUserData} />
                        <FieldLocked label="ステータス" name="status" values={{ status: options.userStatus[userData.status] || "" }} />
                    </div>
                    <div className="px-4 bg-theme-50 text-right sm:px-6">
                        <FormButton submitting={submitting} resend={userData.status === "pending" ? resend : undefined} back={back} deleteData={deleteUser} />
                    </div>
                </form>
            </div>
            }
        </div>
    </div>
};

export const Create = () => {
    const navigate = useNavigate();
    const back = () => navigate(-1);
    const app = useRealmApp()
    const client = useApolloClient()
    const { loading: clinicsLoading, data: clinicData } = useQuery(clinicsData);
    const clinics = clinicData?.clinics.reduce((a: KV, r: KV) => ({ ...a, [r._id]: r.clinic_name }), { "": "" }) || { "": "" }
    const [userData, setUserData] = useState<KV>({ email: "", password: "", password2: "", internal: false, booking: true, notice: false, clinic: "", department: "", name: "", role: "" })
    const departments = (clinicData?.clinics.find((v: KV) => v._id === userData?.clinic?._id)?.departments || []).reduce((a: KV, r: KV) => ({ ...a, [r.name]: r.name }), { "": "" })

    const [submitting, setSubmitting] = useState(false)
    const [addData, { loading }] = useMutation(userDataInsert, {
        onCompleted: back,
        update: (cache, { data: { addedUser } }) => {
            cache.modify({
                fields: {
                    users: (existingusers = []) => [
                        ...existingusers,
                        cache.writeFragment({
                            data: addedUser,
                            fragment: UserFieldsFragment,
                        }),
                    ],
                },
            });
        },
    })

    const handleUserData = (value: KV) => {
        value.booking = true
        if (value.internal) {
        } else {
            value.notice = true
        }
        setUserData(value)
    }

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        if (userData.booking && !userData.email.match(emailRegex)) {
            await showMessage("不正なメールアドレスです", { error: true })
            return
        }
        if (userData.booking && !/^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/.test(userData.password)) {
            await showMessage("パスワードは6文字以上でアルファベットと数字を両方含んでください", { error: true })
            return
        }
        if (userData.booking && userData.password !== userData.password2) {
            await showMessage("パスワードが一致しません", { error: true })
            return
        }
        if (!userData.name) {
            await showMessage("氏名を入力してください", { error: true })
            return
        }
        setSubmitting(true)
        const addingData = { ...userData }
        addingData._id = userData.email || nanoid()
        addingData.clinic = { link: userData.clinic._id }
        addingData.status = userData.booking ? "pending" : "active"
        delete addingData.password
        delete addingData.password2
        const query = {
            OR: [{ email: addingData.email },
            {
                ...(userData.clinic._id ? { clinic: { _id: userData.clinic._id } } : { clinic_exists: false }),
                ...(userData.department ? { department: userData.department } : { department_exists: false }), name: userData.name
            }
            ]
        }
        const existingUser = await client.query({
            query: gql`query GetExistingUser($query:UserQueryInput!){
                user(query: $query) {
                    _id
                    email
                }
            }`, variables: { query: query }
        },)
        if (existingUser?.data?.user?._id) {
            await showMessage(existingUser?.data?.user?.email === addingData.email ? "このメールアドレスはすでに使用されています" : "この名前のユーザーはすでに存在します", { error: true })
            setSubmitting(false)
            return null
        }
        try {
            // Ad  user data whose _id is email 
            addingData.tempId = app.createId()
            await addData({ variables: { data: addingData } })
        } catch (e) {
            if (e instanceof Error) await showMessage(`エラー：\r\nユーザーデータのデータベースへの登録が失敗しました`, { error: true })
            setSubmitting(false)
            return
        }
        if (userData.booking) {
            try {
                await app.app.emailPasswordAuth.registerUser({ email: userData.email, password: userData.password })
                await showMessage('ユーザーを作成しました。認証メール内のリンクをクリックすることで有効化されます。')
            } catch (e) {
                if (e instanceof Error) await showMessage(`エラー：\r\nユーザーの作成に失敗しました。\r\nユーザーデータは手動で消去してください`, { error: true })
            }
        }
        setSubmitting(false)
    }

    if (loading || clinicsLoading || submitting) return <div className="w-full h-full flex justify-center items-center"><Loading /></div>

    return <div className="flex justify-center">
        <div className="pt-5 2xl:pt-0 2xl:col-span-4 w-full max-w-4xl">
            <div className="shadow sm:rounded-md">
                <form onSubmit={handleSubmit}>
                    <div className="p-4 text-theme-800 bg-theme-50 text-xl sm:px-6">新規ユーザー作成</div>
                    <div className="px-4 py-5 bg-white space-y-4 sm:p-6">
                        <FieldCheck label="院内" name="internal" values={userData} setValues={handleUserData} />
                        {userData.internal && <FieldCheck label="予約通知メールを送付" name="notice" values={userData} setValues={handleUserData} />}
                        <FieldInput label="メールアドレス" name="email" values={userData} setValues={setUserData} />
                        {userData.booking && <>
                            <FieldInput label="パスワード" name="password" type="password" values={userData} setValues={setUserData} />
                            <FieldInput label="パスワード(再入力)" name="password2" type="password" values={userData} setValues={setUserData} />
                        </>}
                        <FieldSelect label="施設" name="clinic" name2="_id" options={clinics} values={userData} setValues={setUserData} />
                        <FieldSelect label="診療科" name="department" options={departments} values={userData} setValues={setUserData} />
                        <FieldInput label="氏名" name="name" values={userData} setValues={setUserData} />
                        <FieldInput label="役割" name="role" values={userData} setValues={setUserData} />
                    </div>
                    <div className="px-4 bg-theme-50 text-right sm:px-6">
                        <FormButton submitting={submitting} back={back} />
                    </div>
                </form>
            </div>
        </div>
    </div>
};


export default Edit