import React, { Fragment } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import { isExternal, useRealmApp } from 'contexts/RealmApp'
import { Cog8ToothIcon, EnvelopeIcon } from '@heroicons/react/24/solid'
import { useUnreadMessage } from 'contexts/UnreadMessageContext'

const classNames = (...classes: string[]): string => {
  return classes.filter(Boolean).join(' ')
}

export default function Header({nouser}:{nouser?:boolean}) {
  const location = useLocation()
  const path: string = location.pathname
  const app = useRealmApp()
  const external = isExternal(app)
  const signout = () => app.logOut()
  const unread = useUnreadMessage()

  const navigation: { name: string, href: string, current: boolean }[] = external ? [
    { name: '予約一覧', href: '/external/list', current: path.startsWith('/external/list') },
    { name: '予約可能枠', href: '/external/schedule', current: path.startsWith('/external/schedule') },
  ] : [
    { name: '予約一覧', href: '/booking', current: path.startsWith('/booking') },
    { name: '外注・外来予約', href: '/outpatient', current: path.startsWith('/outpatient') },
    { name: '診療予約', href: '/exam', current: path.startsWith('/exam') },
    { name: '患者別スケジュール', href: '/schedule', current: path.startsWith('/schedule') },
    { name: '予約スケジュール', href: '/bookschedule', current: path.startsWith('/bookschedule') },
    { name: '外部予約公開枠', href: '/external/opening', current: path.startsWith('/external/opening') },
  ]
  return (
    <nav className="bg-theme-800">
      <div className="mx-auto px-2 sm:px-6">
        <div className="relative flex items-center justify-between h-12">
          <div className="flex-1 flex justify-start sm:items-stretch">
            <div className="flex-shrink-0 flex items-center">
              <img
                className="hidden ml-12 h-8 w-auto"
                src="/images/header_logo.gif"
                alt="Workflow"
              />
              <span className='text-theme-100 text-xl font-bold hidden sm:inline'>検査予約システム</span>
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                {!nouser && navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current ? 'bg-theme-600 text-white' : 'text-theme-100 hover:bg-theme-700 hover:text-white',
                      'px-3 py-2 rounded-md text-sm font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {!nouser && <Menu as="div" className="relative">
              {({ open }) => <>
                {/* Mobile menu button*/}
                <Menu.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Menu.Button>
                <Menu.Items className="origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
                  {navigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {item.current ? <div className="block px-4 py-2 text-sm text-gray-300 bg-gray-100">{item.name}</div> :
                        <Link
                          to={item.href}
                          className='block px-4 py-2 text-sm text-gray-700'
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      }
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </>}
            </Menu>
            }
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center gap-3 pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <span className="text-white">{`${(!nouser && app.currentUser?.customData.name) || ""}`}</span>
            <Link to="/message" className="relative bg-theme-600 p-1 flex text-white hover:text-theme-400 text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-theme-700 focus:ring-white">
              {Boolean(unread) && <div className='absolute w-3 h-3 right-0 top-0 bg-red-500 rounded-md'></div>}
              <EnvelopeIcon className='w-6 h-6' aria-hidden="true" />
            </Link>
            {/* Profile dropdown */}
            {!nouser && <Menu as="div" className="relative">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="bg-theme-600 p-1 flex text-white hover:text-theme-400 text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-theme-700 focus:ring-white">
                      <span className="sr-only">ユーザーメニュー</span>
                      <Cog8ToothIcon className="h-6 w-6" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    {!external ?
                        <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
                        >
                          <Menu.Item>
                            {({ active }) => (
                                <Link
                                    to={`/user/edit/${app.currentUser?.id}`}
                                    className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                                >
                                  利用者設定
                                </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                                <Link
                                    to="/clinic"
                                    className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                                >
                                  施設一覧・登録・編集
                                </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                                <Link
                                    to="/user"
                                    className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                                >
                                  利用者一覧・登録
                                </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                                <Link
                                    to="/patient"
                                    className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
                                >
                                  患者一覧・編集
                                </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 w-full text-left text-sm text-gray-700`}
                                    onClick={signout}
                                >
                                  ログアウト
                                </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                    : <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20"
                        >
                          <Menu.Item>
                            {({ active }) => (
                                <button
                                    className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 w-full text-left text-sm text-gray-700`}
                                    onClick={signout}
                                >
                                  ログアウト
                                </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>}
                  </Transition>
                </>
              )}
            </Menu>
            }
          </div>
        </div>
      </div>
    </nav>
  )
}
