import {useLazyQuery, useQuery} from "@apollo/client";
import gql from "graphql-tag";

export const useSearchPatient = () => {
    const [searchPatient, {called, loading, data}] = useLazyQuery(
        gql`
      query GetPatinet($patientId: String!) {
        patient(query: { patientId: $patientId }) {
          _id
          patientId
          isTemp
          name
          nameKana
          birthDate
          tel
          email
          gender
          created
          updated
        }
      }
    `)
    const patient = data?.patient ?? {}
    return {
        searchPatient,
        loading,
        called,
        patient
    }
}

export const usePatients = (patient_id: string) => {
    const { data, loading, error } = useQuery(
        gql`
      query GetPatinet($patientId: String!) {
        patient(query: { patientId: $patientId }) {
          _id
          patientId
          isTemp
          name
          nameKana
          birthDate
          tel
          email
          gender
          created
          updated
        }
      }
    `,
        { variables: { patientId: patient_id } }
    )
    const patient = data?.patient ?? []
    return {
        loading,
        patient,
    }
}