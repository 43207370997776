import showMessage from "components/showMessage";
import options from "contexts/options.json";
import { useEffect, useRef, useState } from "react";
import { CANCEL_STATUS } from "contexts/enviroments";

let mongoCollectionWatch: AsyncGenerator<Realm.Services.MongoDB.ChangeEvent<any>> | null = null

export const translateEvent = (v: KV): CalendarEvent => {
    const { _id: id, start, end, resourceId, clinicID, modalityID, body1, body2, contrast, checkin, ivCheckin, clinicName, patientID, patientName, userID, bookingType, bookingMethod, files, comment, walkingStatus, bookingStatus, appointmentMethod, doctor, doctorID, interviewType, dockID, visitID, delay, updated } = v
    return ({
            id, title: options.bookingType[bookingType], start, end, resourceId: v.modalityID, extendedProps: {
            id, clinicID, modalityID, body1, body2, contrast, checkin, ivCheckin, clinicName, patientID, patientName, userID,
            bookingType, bookingMethod, appointmentMethod, files, comment, walkingStatus, bookingStatus, doctor, doctorID, interviewType, dockID, visitID, delay, updated
        }
    })
}

export const translateEventMonth = (v: KV): CalendarEvent => {
    return ({
        id: v._id.date + v._id.modality, title: v._id.modality, start: v._id.date, end: v._id.date, resourceId: v._id.modality, extendedProps: {
            id: v._id, count: v.count, duration: v.duration
        }
    })
}



export const useFindBookings = (db?: Realm.Services.MongoDBDatabase | null, dateRange?: { start?: Date | null, end?: Date | null }, modalities?: KV, noSummarize?: boolean) => {
    const startTime = dateRange?.start?.getTime();
    const endTime = dateRange?.end?.getTime();
    const [result, setResult] = useState<{ data?: CalendarEvent[] | null, loading?: boolean, error?: Error | null, refetch?: () => Promise<void> }>({
        data: null,
        loading: false,
        error: null
    })
    const eventsReference = useRef<CalendarEvent[] | null | undefined>([])
    eventsReference.current = result.data

    const updateEvent = (change: Realm.Services.MongoDB.ChangeEvent<any>) => {
        const monthView = (dateRange?.end?.getTime() || 0) - (dateRange?.start?.getTime() || 0) > 12096e5
        if (monthView) return;
        switch (change.operationType) {
            case "insert": {
                const { documentKey, fullDocument } = change;
                const index = (eventsReference.current || []).findIndex(v => v.id === documentKey._id)
                if (index > -1) setResult({ loading: false, data: eventsReference.current?.map((v, i) => i === index ? translateEvent(fullDocument) : v), error: null })
                else setResult({ loading: false, data: [...(eventsReference.current || []), translateEvent(fullDocument)], error: null })
                break;
            }
            case "update":
            case "replace": {
                const { documentKey, fullDocument } = change;
                const index = (eventsReference.current || []).findIndex(v => v.id === documentKey._id)
                if (index > -1) {
                    if (fullDocument.canceledAt) setResult({ loading: false, data: eventsReference.current?.filter((v, i) => i !== index), error: null })
                    else setResult({ loading: false, data: eventsReference.current?.map((v, i) => i === index ? translateEvent(fullDocument) : v), error: null })
                } else { setResult({ loading: false, data: [...(eventsReference.current || []), translateEvent(fullDocument)], error: null }) }
                break;
            }
            case "delete": {
                const { documentKey } = change;
                const index = (eventsReference.current || []).findIndex(v => v.id === documentKey._id)
                if (index > -1) { setResult({ loading: false, data: eventsReference.current?.filter((v, i) => i !== index), error: null }) }
                break;
            }
            default:
        }
    }

    const fetchData = async () => {
        if (!db || !dateRange || !dateRange.start || !dateRange.end || (modalities?.length === 0)) {
            setResult({
                loading: false,
                data: null,
                error: null,
            });
            return
        }
        setResult({
            loading: true,
            data: null,
            error: null,
        });
        const monthView = noSummarize ? false : (dateRange.end.getTime() - dateRange.start.getTime() > 12096e5)
        try {
            const data: KV = monthView ?
                await db.collection("bookings").aggregate([
                    { $match: { start: { $gte: dateRange.start, $lt: dateRange.end }, bookingStatus: { $ne: CANCEL_STATUS }, ...(Array.isArray(modalities) ? { modalityID: { $in: modalities } } : {}) } },
                    {
                        $group: {
                            _id: { date: { $dateTrunc: { date: "$start", unit: "day", timezone: "+09:00" } }, modality: "$modalityID" },
                            duration: { $sum: { $dateDiff: { startDate: "$start", endDate: "$end", unit: "minute", timezone: "+09:00" } } },
                            count: { $sum: 1 }
                        }
                    },
                ])
                :
                await db.collection("bookings").find({ start: { $gte: dateRange.start, $lt: dateRange.end }, bookingStatus: { $ne: CANCEL_STATUS }, ...(Array.isArray(modalities) ? { modalityID: { $in: modalities } } : {}) })
            setResult({
                data: data.map((v: KV) => monthView ? translateEventMonth(v) : translateEvent(v)),
                loading: false,
                error: null,
                refetch: fetchData
            });
        } catch (err) {
            setResult({
                data: null,
                loading: false,
                error: (err instanceof Error) ? err : null,
            });
        }
        try {
            if (mongoCollectionWatch) {
                mongoCollectionWatch.return(null)
                mongoCollectionWatch = null
            }
            if (monthView) return; // no watching for month view
            // mongoCollectionWatch = db.collection("bookings").watch({ filter: { $or: [{ operationType: 'delete' }, { 'fullDocument.start': { $gte: dateRange.start, $lt: dateRange.end } }] } });
            // try {
            //     for await (const change of mongoCollectionWatch) {
            //         try {
            //             updateEvent(change)
            //         } catch (err) {
            //             if (err instanceof Error) await showMessage(`予約データ読み込み中にエラーが発生しました。\r\n ${(err as Error).message}`, { error: true })
            //             console.log(`Error updating changed booking data.  ${(err as Error)?.message}`)
            //         }
            //     }
            // } catch (err) {
            //     //error happens on reload.  ignore
            // }
        } catch (err) {
            if (err instanceof Error) await showMessage(`予約データのウオッチ設定中にエラーが発生しました。\r\n ${(err as Error).message}`, { error: true })
        }

    }

    useEffect(() => {
        fetchData();
        return () => {
            try {
                if (mongoCollectionWatch) {
                    mongoCollectionWatch.return(null)
                    mongoCollectionWatch = null
                }
            } catch (err) {
                alert(`Error removing watching stream.  ${(err as Error).message}`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db, startTime, endTime, modalities]) // initially db is null
    return result
}

export const translateVisitEvent = (v: KV): CalendarEvent => {
    return ({
        id: v._id, title: options.bookingType[v.bookingType], start: v.start, end: v.end, extendedProps: {
            id: v._id, clinicID: v.clinicID, ivCheckin: v.ivCheckin, clinicName: v.clinicName, patientID: v.patientID, patientName: v.patientName,
            bookingType: v.bookingType, files: v.files, comment: v.comment, bookingStatus: v.bookingStatus,
            doctor: v.doctor, doctorID: v.doctorID, 
            dockID: v.dockID, courseName: v.courseName, updated: v.updated
        }
    })
}


export const useFindVisitsNoStream = (db?: Realm.Services.MongoDBDatabase | null, dateRange?: { start?: Date | null, end?: Date | null }, modalities?: KV) => {
    const startTime = dateRange?.start?.getTime();
    const endTime = dateRange?.end?.getTime();
    const [result, setResult] = useState<{ data?: CalendarEvent[] | null, loading?: boolean, error?: Error | null }>({
        data: null,
        loading: false,
        error: null,
    })
    const eventsReference = useRef<CalendarEvent[] | null | undefined>([])
    eventsReference.current = result.data

    const updateEvent = (change: Realm.Services.MongoDB.ChangeEvent<any>) => {
        const monthView = (dateRange?.end?.getTime() || 0) - (dateRange?.start?.getTime() || 0) > 12096e5
        if (monthView) return;
        switch (change.operationType) {
            case "insert": {
                const { documentKey, fullDocument } = change;
                const index = (eventsReference.current || []).findIndex(v => v.id === documentKey._id)
                if (index > -1) setResult({ loading: false, data: eventsReference.current?.map((v, i) => i === index ? translateVisitEvent(fullDocument) : v), error: null })
                else setResult({ loading: false, data: [...(eventsReference.current || []), translateVisitEvent(fullDocument)], error: null })
                break;
            }
            case "update":
            case "replace": {
                const { documentKey, fullDocument } = change;
                const index = (eventsReference.current || []).findIndex(v => v.id === documentKey._id)
                if (index > -1) {
                    if (fullDocument.canceledAt) setResult({ loading: false, data: eventsReference.current?.filter((v, i) => i !== index), error: null })
                    else setResult({ loading: false, data: eventsReference.current?.map((v, i) => i === index ? translateVisitEvent(fullDocument) : v), error: null })
                } else { setResult({ loading: false, data: [...(eventsReference.current || []), translateVisitEvent(fullDocument)], error: null }) }
                break;
            }
            case "delete": {
                const { documentKey } = change;
                const index = (eventsReference.current || []).findIndex(v => v.id === documentKey._id)
                if (index > -1) { setResult({ loading: false, data: eventsReference.current?.filter((v, i) => i !== index), error: null }) }
                break;
            }
            default:
        }
    }

    useEffect(() => {
        async function fetchData() {
            if (!db || !dateRange || !dateRange.start || !dateRange.end || (modalities?.length === 0)) {
                setResult({
                    loading: false,
                    data: null,
                    error: null,
                });
                return
            }
            setResult({
                loading: true,
                data: null,
                error: null,
            });
            const monthView = dateRange.end.getTime() - dateRange.start.getTime() > 12096e5
            try {
                const data: KV = monthView ?
                    await db.collection("visits").aggregate([
                        { $match: { start: { $gte: dateRange.start, $lt: dateRange.end }, bookingStatus: { $ne: CANCEL_STATUS }, ...(Array.isArray(modalities) ? { modalityID: { $in: modalities } } : {}) } },
                        {
                            $group: {
                                _id: { date: { $dateTrunc: { date: "$start", unit: "day", timezone: "+09:00" } }, modality: "$modalityID" },
                                duration: { $sum: { $dateDiff: { startDate: "$start", endDate: "$end", unit: "minute", timezone: "+09:00" } } },
                                count: { $sum: 1 }
                            }
                        },
                    ])
                    :
                    await db.collection("visits").find({ start: { $gte: dateRange.start, $lt: dateRange.end }, bookingStatus: { $ne: CANCEL_STATUS }, ...(Array.isArray(modalities) ? { modalityID: { $in: modalities } } : {}) })
                setResult({
                    data: data.map((v: KV) => translateVisitEvent(v)),
                    loading: false,
                    error: null,
                });
            } catch (err) {
                setResult({
                    data: null,
                    loading: false,
                    error: (err instanceof Error) ? err : null,
                });
            }
            try {
                if (mongoCollectionWatch) {
                    mongoCollectionWatch.return(null)
                    mongoCollectionWatch = null
                }
                if (monthView) return; // no watching for month view
                // mongoCollectionWatch = db.collection("visits").watch({ filter: { $or: [{ operationType: 'delete' }, { 'fullDocument.start': { $gte: dateRange.start, $lt: dateRange.end } }] } });
                // try {
                //     for await (const change of mongoCollectionWatch) {
                //         try {
                //             updateEvent(change)
                //         } catch (err) {
                //             if (err instanceof Error) await showMessage(`予約データ読み込み中にエラーが発生しました。\r\n ${(err as Error).message}`, { error: true })
                //             console.log(`Error updating changed visit data.  ${(err as Error)?.message}`)
                //         }
                //     }
                // } catch (err) {
                //     //error happens on reload.  ignore
                // }
            } catch (err) {
                if (err instanceof Error) await showMessage(`予約データのウオッチ設定中にエラーが発生しました。\r\n ${(err as Error).message}`, { error: true })
            }

        }
        fetchData();
        return () => {
            try {
                if (mongoCollectionWatch) {
                    mongoCollectionWatch.return(null)
                    mongoCollectionWatch = null
                }
            } catch (err) {
                alert(`Error removing watching stream.  ${(err as Error).message}`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db, startTime, endTime, modalities]) // initially db is null
    return result
}

export const useFindVisits = (db?: Realm.Services.MongoDBDatabase | null, dateRange?: { start?: Date | null, end?: Date | null }, modalities?: KV) => {
    const startTime = dateRange?.start?.getTime();
    const endTime = dateRange?.end?.getTime();
    const eventsReference = useRef<CalendarEvent[] | null | undefined>([])

    // Put currentModalities null to cancel modalities setting and select all modalities.  If undefined, modalities setting is used.
    const fetchData = async (currentDb?: Realm.Services.MongoDBDatabase | null, currentDateRange?: { start?: Date | null, end?: Date | null }, currentModalities?: KV) => {
        const actualDb = currentDb || db
        const actualDateRange = currentDateRange || dateRange
        const actualModalities = currentModalities === undefined ? modalities : currentModalities
        if (!actualDb || !actualDateRange || !actualDateRange.start || !actualDateRange.end || (actualModalities?.length === 0)) {
            setResult(current => ({ ...current,
                loading: false,
                data: null,
                error: null,
            }));
            return
        }
        setResult(current => ({ ...current,
            loading: true,
            data: null,
            error: null,
        }));
        const monthView = actualDateRange.end.getTime() - actualDateRange.start.getTime() > 12096e5
        try {
            const data: KV = monthView ?
                await actualDb.collection("visits").aggregate([
                    { $match: { start: { $gte: actualDateRange.start, $lt: actualDateRange.end }, bookingStatus: { $ne: CANCEL_STATUS }, ...(Array.isArray(actualModalities) ? { modalityID: { $in: actualModalities } } : {}) } },
                    {
                        $group: {
                            _id: { date: { $dateTrunc: { date: "$start", unit: "day", timezone: "+09:00" } }, modality: "$modalityID" },
                            duration: { $sum: { $dateDiff: { startDate: "$start", endDate: "$end", unit: "minute", timezone: "+09:00" } } },
                            count: { $sum: 1 }
                        }
                    },
                ])
                :
                await actualDb.collection("visits").find({ start: { $gte: actualDateRange.start, $lt: actualDateRange.end }, bookingStatus: { $ne: CANCEL_STATUS }, ...(Array.isArray(actualModalities) ? { modalityID: { $in: actualModalities } } : {}) })
            setResult(current => ({ ...current,
                data: data.map((v: KV) => translateVisitEvent(v)),
                loading: false,
                error: null,
            }));
        } catch (err) {
            setResult(current => ({ ...current,
                data: null,
                loading: false,
                error: (err instanceof Error) ? err : null,
            }));
        }
    }

    const [result, setResult] = useState<{ data?: CalendarEvent[] | null, loading?: boolean, error?: Error | null, refetch: (db?: Realm.Services.MongoDBDatabase | null, dateRange?: { start?: Date | null, end?: Date | null }, modalities?: KV) => Promise<void> }>({
        data: null,
        loading: false,
        error: null,
        refetch: fetchData
    })
    eventsReference.current = result.data


    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [db, startTime, endTime, modalities]) // initially db is null
    return result
}