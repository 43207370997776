import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { ButtonClass, IconClass, InputClass, classNames } from "contexts/style";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { useRealmApp } from "contexts/RealmApp";
import { Loading } from "components/Loading";
import options from "contexts/options.json";
import { JSTDate, JSTTime } from "contexts/dateUtils";
import VisitForm from "components/FormVisit";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useMongoDB } from "contexts/MongoDBContext";

const mutation = gql`
    mutation UpdateMessageRead($query: MessageQueryInput!, $set: MessageUpdateInput!) {
        updateOneMessage(query: $query, set: $set) {
            _id
        }
    }
`;

const getPaginationArray = (page: number, total: number) => {
  const max = Math.ceil((total ?? 0) / 50)
  const around = new Array(7).fill(0).map((_, i) => page + i - 3).filter(v => v > 1 && v < max)
  const numbers = [1, ...(around[0] > 2 ? [0] : []), ...around, ...(around[around.length - 1] < max - 1 ? [0] : []), ...(max > 1 ? [max] : [])]
  return numbers
}

export const List = () => {
  const app = useRealmApp();
  const { db } = useMongoDB()
  const channel = new BroadcastChannel('sw-messages');
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [modalvisitID, setModalVisitID] = useState("")
  const [searchSender, setSearchSender] = useState("")
  const [searchContent, setsearchContent] = useState("")
  const [filter, setFilter] = useState<KV>()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ messages: KV[] }>({ messages: [] })
  const [total, setTotal] = useState<number>(0)
  const [page, setPage] = useState(1)

  const getData = async (appliedFilter?: KV) => {
    setLoading(true)

    const messages = await db?.collection("messages").aggregate([
      { $match: appliedFilter || { "targetUser.userID": app.currentUser?.id } },
      { $sort: { sentAt: -1 } },
      {
        $lookup: {
          from: "visits",
          localField: "visitID",
          foreignField: "_id",
          as: "visit"
        }
      },
      { $unwind: "$visit" },
      {
        $facet: {
          metadata: [{ $count: 'totalCount' }],
          data: [{ $skip: (page - 1) * 50 }, { $limit: 50 }],
        },
      },
    ])
    if (!messages) return
    setTotal(messages[0].metadata[0]?.totalCount || 0)
    setData({ messages: messages[0]?.data || [] })
    setLoading(false)
  }

  const Panel = ({ read }: { read?: boolean }) => {
    const filter = (read === undefined) ? (message: KV) => true : (message: KV) => Boolean(message.targetUser.find((user: KV) => user.userID === app.currentUser?.id).read) === read
    return <>
      {data.messages.filter(filter).map((message: KV, i: number) => {
        const unread = !message.targetUser.find((user: KV) => user.userID === app.currentUser?.id).read
        return <div
          key={message._id}
          className="mx-4 border border-theme-700 rounded-md"
        >
          <div data-visit={message.visitID} className={`p-2 text-theme-800 text-xl ${unread ? "bg-red-50" : "bg-theme-100"} rounded-md cursor-pointer`} onClick={handleClickMessage}>
            <div className="flex gap-4">
              {unread && <span className="text-lg text-red-400">未読</span>}
              <span>{JSTDate(new Date(message.visit.start))}</span>
              <span>{options.bookingType[message.visit.bookingType]}</span>
              <span>{message.visit.clinicName} {message.visit.patientName}</span>
            </div>
          </div>
          <div className="p-2 text-right text-gray-500">
            {message.sentBy.name} {JSTDate(new Date(message.sentAt))} {JSTTime(new Date(message.sentAt))}
          </div>
          <div className="p-2">{message.message}</div>
          {unread && <div>
            <button
              data-index={message._id}
              className={ButtonClass}
              onClick={handleReadClick}
            >
              既読
            </button>
          </div>}
        </div>
      })}
    </>
  }

  useEffect(() => {
    getData(filter)
  }, [db, page])

  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (e.data !== "meessage updated") getData(filter)
    }
    channel.addEventListener('message', handleMessage);
    return () => {
      channel.removeEventListener('message', handleMessage);
    }
  }, [])

  // const { loading, data, error, refetch } = useQuery(query, {
  //   variables: { query: { targetUser: { userID: app.currentUser?.id } } },
  // });
  const [updateDataMutation, { loading: updateLoading, error: updatingError }] = useMutation(mutation, {})
  const handleReadClick = async (e: MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.index;
    const message = data.messages.find((message: KV) => message._id === id) || { targetUser: [] };
    const index = message.targetUser.findIndex((user: KV) => user.userID === app.currentUser?.id)
    const targetUser = message.targetUser.map((v: KV) => {
      let value = { ...v }
      delete value.__typename
      return value
    })
    targetUser[index]["read"] = true
    const { data: updatedData } = await updateDataMutation({
      variables: { query: { _id: id }, set: { targetUser: targetUser } }
    });
    channel.postMessage("message updated")
    getData(filter)
  };
  const handleClickMessage = (e: MouseEvent<HTMLElement>) => {
    const id = e.currentTarget.dataset.visit || ""
    setModalVisitID(id)
  }
  const handleSearchClick = () => {
    const filter: KV = { "targetUser.userID": app.currentUser?.id }
    if (searchContent) filter.message = { "$regex": searchContent }
    if (searchSender) filter["sentBy.name"] = { "$regex": searchSender }
    setFilter(filter)
    getData(filter)
  }
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    switch (e.currentTarget.name) {
      case "content": setsearchContent(e.currentTarget.value); break;
      case "sender": setSearchSender(e.currentTarget.value); break;
      default:
    }
  }
  return (loading || updateLoading) ? (
    <Loading full />
  ) : (<>
    <div className="flex flex-col gap-2">
      <div className="p-4 flex justify-between bg-theme-100">
        <div className="flex items-center gap-2">
          内容<input className={InputClass} name="content" value={searchContent} onChange={handleChange} />
          送信者<input className={InputClass} name="sender" value={searchSender} onChange={handleChange} />
          <button type="button" className={IconClass.replace("w-6", "w-8").replace("h-6", "h-8")} onClick={handleSearchClick} ><MagnifyingGlassIcon className="w-6 h-6" /></button>
        </div>
        <div className="flex items-center gap-2">
          <div>{total}件　</div>
          <button type="button" className="font-bold text-green-600 disabled:text-gray-300" name="backward" onClick={() => setPage(v => v - 1)} disabled={page < 2}>{"<"}</button>
          {getPaginationArray(page, total).map(v => <button key={`page${v}`} type="button" className={v === page ? "font-bold text-green-800" : "text-green-600"} name="page" onClick={() => setPage(v)} disabled={v === page || !v}>{v || "..."}</button>)}
          <button type="button" className="font-bold text-green-600 disabled:text-gray-300" name="forward" onClick={() => setPage(v => v + 1)} disabled={page > Math.ceil(total / 50) - 1}>{">"}</button>
        </div>
      </div>
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="mx-4 p-1 w-48 flex space-x-1 rounded-xl bg-theme-300">
          {["全て", "未読", "既読"].map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-theme-800",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-theme-400 focus:outline-none focus:ring-2",
                  selected
                    ? "bg-theme-50 shadow"
                    : "text-theme-100 hover:bg-theme-500 hover:text-white"
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel key="all-panel">
            <Panel />
          </Tab.Panel>
          <Tab.Panel key="unread-panel">
            <Panel read={false} />
          </Tab.Panel>
          <Tab.Panel key="read-panel">
            <Panel read={true} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
    <VisitForm data={data?.messages?.find(v => v.visit._id === modalvisitID)?.visit || {}} isModalOpen={Boolean(modalvisitID)} setIsModalOpen={() => setModalVisitID("")} />
  </>);
};

export default List;
