import {FetchResult, useMutation} from "@apollo/client";
import gql from "graphql-tag";
import { ApolloError } from "@apollo/client/core";

const useVisitsMutations = () => {
  return {
    addVisit: useAddVisit(),
    updateVisit: useUpdateVisit(),
    deleteVisit: useDeleteVisit(),
  };
}
export default useVisitsMutations;

const AddVisitMutation = gql`
  mutation AddVisit($visit: VisitInsertInput!) {
    addedVisit: insertOneVisit(data: $visit) {
      _id
      bookingType
      bookingIDs
      dockID
      courseName
      clinicID
      clinicName
      comment
      messages {
        _id
        message
      }  
      department
      diagnosis
      doctor
      doctorID
      start
      end
      files {
        fileID
        name
      }
      ivCheckin
      patient {
        name
        nameKana
        birthDate
        gender
      }
      patientName
    }
  }
`;

const VisitFieldsFragment = gql`
  fragment VisitFields on Visit {
    _id
    bookingType
    bookingIDs
    dockID
    courseName
    clinicID
    clinicName
    comment
    messages {
      _id
      message
    }
    department
    diagnosis
    doctor
    doctorID
    start
    end
    files {
      fileID
      name
    }
    ivCheckin
    patient {
      name
      nameKana
      birthDate
      gender
    }
    patientName
  }
`;

const UpdateVisitMutation = gql`
  mutation UpdateVisit($id: String!, $set: VisitUpdateInput!) {
    updatedData: updateOneVisit(query: { _id: $id }, set: $set) {
      _id
      bookingType
      bookingIDs
      dockID
      courseName
      clinicID
      clinicName
      comment
      messages {
        _id
        message
      }
      department
      diagnosis
      doctor
      doctorID
      start
      end
      files {
        fileID
        name
      }
      ivCheckin
      patient {
        name
        nameKana
        birthDate
        gender
      }
      patientName
    }
  }
`;

const useAddVisit = () => {
  const [addVisitMutation, { data, loading, error }] = useMutation(AddVisitMutation, {
    update: (cache, { data: { addedVisit } }) => {
      cache.modify({
        fields: {
          visits: (existingvisits = []) => [
            ...existingvisits,
            cache.writeFragment({
              data: addedVisit,
              fragment: VisitFieldsFragment,
            }),
          ],
        },
      });
    },
  });
  const addVisit = async (visit: any) => {
    // @ts-ignore
    const { addedVisit }: FetchResult<{ addedVisit: any }> = await addVisitMutation({
      variables: {
        visit: {
          created: new Date(),
          updated: new Date(),
          ...visit,
        },
      },
    });
    return addedVisit;
  };
  return addVisit;
}

const DeleteVisitMutation = gql`
mutation DeleteVisit($Id: String!) {
    deletedVisit: deleteOneVisit(query: { _id: $Id }) {
      _id
    }
  }
`;

const useDeleteVisit = () => {
  const [deleteVisitMutation] = useMutation(DeleteVisitMutation, { 
    update: (cache, { data: { deletedVisit } }) => {
        const normalizedId = cache.identify(deletedVisit);
        cache.evict({ id: normalizedId });
        cache.gc();
    }
});

const deleteVisit = async (visit: Visit) => {
    // @ts-ignore
    const { deletedVisit } = await deleteVisitMutation({
      variables: { Id: visit._id },
    });
    return deletedVisit;
  };
  return deleteVisit;
}

export const useUpdateVisit = (completed?: () => void) => {
    const [updateDataMutation, { error }] = useMutation(UpdateVisitMutation, { onCompleted: completed });
    const updateData = async (queryValue: string | number | KV, set: { [key: string]: any }): Promise<{ updatedData: { [key: string]: any }, error: ApolloError | undefined }> => {
        const { data } = await updateDataMutation({
            variables: { id: queryValue, set: set },
        });
        const updatedData: { [key: string]: any } = data?.updatedData || {}
        return { updatedData, error };
    };
    return updateData;
}