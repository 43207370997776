import {
  ButtonClass,
  ButtonClassLong, errorMessageClass,
  InputClass,
  InputClassBox,
  modalBasicText,
  modalBasicTextMini, modalBlackText, modalBlackTextMini
} from "contexts/style"
import React, { useEffect } from "react"
import { Loading } from "./Loading"

import { useSearchPatient, usePatients } from "graphql/useSearchPatient"
import usePatientsMutations from "graphql/usePatientsMutations"
import { useUpdateBooking } from "contexts/useCollection"
import { useUpdateVisit } from "graphql/useVisitsMutations"
import showMessage from "./showMessage"


const toJapaneseEra = (year: number) => {
  // 西暦から和暦の年数に変換するための定数
  const gannen = 2019;
  const reiwaStartYear = 2019;
  const heiseiStartYear = 1989;
  const showaStartYear = 1926;
  const taishoStartYear = 1912;
  const meijiStartYear = 1868;

  let eraName: string = '';
  let eraYear: number = 0;

  if (year >= gannen) {
    eraName = '令和';
    eraYear = year - reiwaStartYear + 1;
  } else if (year >= heiseiStartYear) {
    eraName = '平成';
    eraYear = year - heiseiStartYear + 1;
  } else if (year >= showaStartYear) {
    eraName = '昭和';
    eraYear = year - showaStartYear + 1;
  } else if (year >= taishoStartYear) {
    eraName = '大正';
    eraYear = year - taishoStartYear + 1;
  } else if (year >= meijiStartYear) {
    eraName = '明治';
    eraYear = year - meijiStartYear + 1;
  }

  return eraName + eraYear + '年';
}


const Patient = ({ patient, bookingId, visitId, setPatientId, setPatientName, setIsTemp }: { patient: any, bookingId: string, visitId: string, setPatientId: any, setPatientName: any, setIsTemp: any }) => {
  const [tmpPatientId, setTmpPatientId] = React.useState<string>("")
  const [findPatient, setFindPatient] = React.useState<boolean>(false)
  const updateBooking = useUpdateBooking()
  const updateVisit = useUpdateVisit()
  const { deletePatient } = usePatientsMutations()
  const [isClick, setIsClick] = React.useState<boolean>(false)
  const [isReplace, setIsReplace] = React.useState<boolean>(false)
  const { searchPatient, loading, called, patient: searched } = useSearchPatient()

  const replacePatient = async () => {
    setPatientName(searched["name"]);
    await updateBooking(bookingId, { patientID: tmpPatientId.toString(), patientName: searched["name"] }).then(r => console.log(r))
    if (visitId) {
      await updateVisit(visitId, { patient: { link: tmpPatientId.toString() }, patientName: searched["name"] }).then(r => console.log(r))
    }
    await deletePatient(patient)
    setIsReplace(true)
    await showMessage("患者IDを登録しました。\n患者ID: " + tmpPatientId)
  }
  useEffect(() => {
    if (isReplace) {
      setPatientId(tmpPatientId)
      setIsTemp(true)
    }
  }, [isReplace])

  const updateTmpPatientId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTmpPatientId(e.target.value)
  }

  useEffect(() => {
    const func = async () => {
      if (Object.keys(searched).length !== 0 && !searched["isTemp"]) {
        setFindPatient(true)
      } else if (called && !loading && isClick) {
        await showMessage("患者IDが見つかりません。患者IDを確認してください。\n患者ID: " + tmpPatientId, { error: true })
        setIsClick(false)
      }
    }
    func()
  }, [searched])

  const searchQuery = () => {
    setIsClick(true)
    searchPatient({ variables: { patientId: tmpPatientId } })
  }

  return <>
    {
      findPatient ? <>
        <div className={errorMessageClass}>
          <span className={modalBlackText}>患者IDが見つかりました。誤りがなければ、更新してください。</span>
          <span className={modalBlackTextMini}>患者ID: {searched["patientId"]}, 患者名: {searched["name"]}</span>
          <button className={ButtonClassLong} onClick={replacePatient}>患者IDを付け替える</button>
        </div>
      </> : <>
        <input type="text" className={InputClassBox} onChange={(e) => updateTmpPatientId(e)} placeholder="患者IDを入力してください" />
        <button className={ButtonClassLong} onClick={searchQuery}>患者IDを検索</button>
      </>
    }
  </>
}

const ViewPatient = ({ bookingId, visitId, patientId, setPatientId, setPatientName, setIsTemp }: { bookingId: string, visitId: string, patientId: string, setPatientId: any, setPatientName: any, setIsTemp: any }) => {
  const handleUndefined = (v: any) => {
    return (v === undefined) ? '' : v.toString()
  }
  const { loading: patient_loading, patient } = usePatients(handleUndefined(patientId))
  useEffect(() => {
    setIsTemp(patient["isTemp"])
  }, [patient])

  const showBirthDate = (birthdate: string) => {
    if (birthdate) {
      return birthdate.split('T')[0]
    }
    return ''
  }

  return <>
    {
      patient_loading ? <Loading /> : <>
        {
          patient["isTemp"] ? <>
            <span className={modalBasicText}>患者名: {patient["name"]}</span>
            <span className={modalBasicText}>生年月日: {showBirthDate(patient["birthDate"])}</span>
            <span className={modalBasicTextMini}>※ 仮登録の状態です。患者IDを検索し、患者情報を登録してください。</span>
            <Patient patient={patient} visitId={visitId} bookingId={bookingId} setPatientId={setPatientId} setPatientName={setPatientName} setIsTemp={setIsTemp} />
          </> : <>
            <span className={modalBasicText}>患者ID: {patient["patientId"]}</span>
            <span className={modalBasicText}>患者名: {patient["name"]}</span>
            <span className={modalBasicText}>生年月日: {showBirthDate(patient["birthDate"])}</span>
          </>
        }
      </>
    }
  </>
}

export const FormPatients = ({ bookingId, visitId, patientId, setPatientId,
  patientName, setPatientName,
  patientNameKana, setPatientKana,
  setBirthDate,
  gender, setGender,
  tel, setTel,
  isUpdated, setIsTemp }:
  {
    bookingId: string, visitId: string, patientId: string, setPatientId: any,
    patientName: string, setPatientName: any,
    patientNameKana: string, setPatientKana: any,
    setBirthDate: any,
    gender: string, setGender: any,
    tel: string, setTel: any,
    isUpdated: boolean, setIsTemp: any
  }) => {
  const [patientIdLocal, setPatientIdLocal] = React.useState(patientId)
  const [birthyear, setBirthYear] = React.useState(1960)
  const [birthmonth, setBirthMonth] = React.useState(1)
  const [birthday, setBirthDay] = React.useState(1)
  const [isClick, setIsClick] = React.useState<boolean>(false)
  const { searchPatient, loading, called, patient } = useSearchPatient()
  const [isPatientSearch, setIsPatientSearch] = React.useState<boolean>(false)

  useEffect(() => {
    setBirthDate(birthyear.toString() + '/' + birthmonth.toString().padStart(2, "0") + '/' + birthday.toString().padStart(2, "0"))
  }, [birthyear, birthmonth, birthday])

  /**
   * 生年月日(年) Select Box
   */
  const year = () => {
    const rows = [];
    for (let i = 1930; i <= 2022; i++) {
      const wareki = toJapaneseEra(i)
      rows.push(<option key={i} value={i}>{i}年({wareki})</option>)
    }
    return rows
  }

  /**
   * 生年月日(月) Select Box
   */
  const month = () => {
    const rows = [];
    for (let i = 1; i <= 12; i++) {
      rows.push(<option key={i} value={i}>{i}月</option>)
    }
    return rows
  }

  /**
   * 生年月日(日) Select Box
   */
  const day = () => {
    const rows = [];
    for (let i = 1; i < 31; i++) {
      rows.push(<option key={i} value={i}>{i}日</option>)
    }
    return rows
  }

  /**
   * useEffect
   * patient更新時に起動
   */
  useEffect(() => {
    const func = async () => {
      if (Object.keys(patient).length !== 0) {
        setPatientId(patient.patientId)
        setPatientName(patient.name)
        setPatientKana(patient.nameKana)
        setGender(patient.gender)
        setTel(patient.tel)
        setBirthYear(patient.birthDate.substr(0, 4))
        setBirthMonth(Number(patient.birthDate.substr(5, 2)))
        setBirthDay(Number(patient.birthDate.substr(8, 2)))
        await showMessage("患者情報が見つかりました。\n患者ID: " + patient.patientId)
        setIsPatientSearch(true)
      } else if (called && !loading && isClick) {
        await showMessage("患者情報が見つかりません。", { error: true })
        setPatientId("")
        setPatientName("")
        setPatientKana("")
        setIsClick(false)
      }
    }
    func()
  }, [patient])

  /**
   * GraphQLのPatient検索を起動
   */
  const search = (e: any) => {
    searchPatient({ variables: { patientId: patientIdLocal } })
    setIsClick(true)
  }

  /**
   * 患者ID変更時に起動
   * PatientIDをセット
   * @param e
   */
  const updatePatientID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPatientIdLocal(e.target.value)
  }

  const updateTel = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTel(e.target.value)
  }
  /**
   * 患者名変更時に起動
   * Nameをセット
   * @param e
   */
  const updateName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPatientName(e.target.value)
  }

  /**
   * 患者名カナ変更時に起動
   * Kanaをセット
   * @param e
   */
  const updateKana = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPatientKana(e.target.value)
  }

  /**
   * 生年月日(年)変更時に起動
   * Yearをセット
   * @param e
   */
  const updateYear = (e: any) => {
    setBirthYear(e.target.value)
  }

  /**
   * 生年月日(月)変更時に起動
   * Monthをセット
   * @param e
   */
  const updateMonth = (e: any) => {
    setBirthMonth(e.target.value)
  }

  /**
   * 生年月日(日)変更時に起動
   * Dayをセット
   * @param e
   */
  const updateDay = (e: any) => {
    setBirthDay(e.target.value)
  }

  /**
   * 性別変更時に起動
   * Genderをセット
   * @param e
   */
  const updateGender = (e: any) => {
    setGender(e.target.value)
  }

  return <div className="p-3">
    {
      isUpdated ? <ViewPatient bookingId={bookingId} visitId={visitId} patientId={patientId} setPatientId={setPatientId} setPatientName={setPatientName} setIsTemp={setIsTemp} /> :
        <>
          <div className="flex items-center">
            <span><input type="text" id="patientTmp" name="patientTmp" className={InputClass} value={patientIdLocal} placeholder="患者ID"
              onChange={(e) => updatePatientID(e)} /></span>
            <button className={ButtonClass} onClick={search}>検索</button>
          </div>
          <div className="flex items-center">
            <span className="text-white">{patientId && "患者ID："}{patientId}</span>
            <span className="ml-3"><input id="patient_name" name="patient_name" className={InputClass} type="text" placeholder="患者名" disabled={isPatientSearch} value={patientName}
              onChange={(e) => updateName(e)} /></span>
            <span className="ml-3"><input id="patient_name_kana" name="patient_name_kana" className={InputClass} type="text" placeholder="患者名カナ" disabled={isPatientSearch} value={patientNameKana}
              onChange={(e) => updateKana(e)} /></span>
          </div>
          <div className="flex items-center mt-2">
            <span className="text-white">生年月日</span>
            <div className="ml-2">
              <span>
                <select id="birth_year" name="birth_year" disabled={isPatientSearch} className={InputClass} value={birthyear} onChange={(e) => updateYear(e)}>
                  {year()}
                </select>
              </span>
            </div>
            <div className="ml-2">
              <span>
                <select id="birth_month" name="birth_month" disabled={isPatientSearch} className={InputClass} value={birthmonth} onChange={(e) => updateMonth(e)}>
                  {month()}
                </select>
              </span>
            </div>
            <div className="ml-2">
              <span>
                <select id="birth_day" name="birth_day" className={InputClass} disabled={isPatientSearch} value={birthday} onChange={(e) => updateDay(e)}>
                  {day()}
                </select>
              </span>
            </div>
            <div className="ml-6 flex items-center">
              <span className="text-white">性別</span>
              <span className="ml-2">
                <select id="gender" name="gender" className={InputClass} disabled={isPatientSearch} value={gender} onChange={(e) => updateGender(e)}>
                  <option value="M">男性</option>
                  <option value="F">女性</option>
                  <option value="O">その他</option>
                </select>
              </span>
            </div>
            <div className="ml-6 flex">
              <span><input type="text" id="tel" name="tel" className={InputClass} value={tel} placeholder="電話番号"
                onChange={(e) => updateTel(e)} /></span>
            </div>
          </div>
        </>
    }

  </div>
}