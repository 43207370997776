import Modal from "react-modal"
import React, { MouseEvent, useEffect, useRef, useState } from "react"
import options from "contexts/options.json"

import { BsFillXCircleFill } from "react-icons/bs"
import { FcApproval } from "react-icons/fc"
import {
    YellowButtonClass,
    ButtonClass,
    errorMessageClass,
    modalStyles,
    GrayButtonClass, RedButtonClass, CheckClass
} from "contexts/style"
import { FormClinics } from "./FormClinics"
import { FormPatients } from "./FormPatients"
import { FormModality } from "./FormModality"
import { FormBookInformation } from "./FormBookInformation"
import useBookingsMutations from "graphql/useBookingsMutations"
import useVisitsMutations from "graphql/useVisitsMutations"
import { FormReport } from "./FormReport"
import { useRealmApp } from "contexts/RealmApp"
import { useUpdateBooking, useUpdateRelatedBooking } from "contexts/useCollection"
import { useApolloClient, useLazyQuery } from "@apollo/client"
import gql from "graphql-tag"
import { useMongoDB } from "contexts/MongoDBContext"
import { Loading } from "./Loading"
import showMessage from "./showMessage"
import { BOOKED_STATUS, checkPetTime, checkTime, DOCK_TYPES, isPet, OUTER_BOOK_TYPE, INTERNAL_TYPE, PRE_BOOK_STATUS } from "contexts/enviroments"
import { sendFixToExternal, sendFixToInternal } from "mail/sendFix"
import { sendCancelToExternal, sendCancelToInternal } from "mail/sendCancel"
import { convertDate, getCheckinTime } from "contexts/functions"
import { FileError, FileHandler } from "./FormFile"

export const FIND_EXTERNAL_DOCTOR_USER = gql`
  query GetExternalDoctorUser ($id: String!) {
    user(query: {_id: $id}) {
      _id
      email
      name
    }
  }
`

export const FIND_NOTIFICATION_USERS = gql`
  query GetNotificationUsers {
    users(query: {internal:true, notice:true}) {
      _id
      email
      name
    }
  }
`

const FIND_VISIT = gql`
  query GetVisit($id: String!) {
    visit(query: {_id: $id}) {
      _id
      bookingIDs
    }
  }
`

export interface FIND_EXTERNAL_DOCTOR_USER_RESULT {
    user: User;
}

export interface FIND_VISIT_RESULT {
    visit: Visit;
}

export const viewDate = (date: Date) => {
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日 ${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`
}


const BookButton = ({ db, selectBookingId, booking, updated, isTemp, bookingStatus, setIsUpdateModalOpen, events, saveFiles, deleteFiles }:
    { db: any, selectBookingId: string, booking: Booking, updated: Date, isTemp: boolean, bookingStatus: number, setIsUpdateModalOpen: React.Dispatch<React.SetStateAction<boolean>>, events?: CalendarEvent[] | null, saveFiles: (id?: string) => Promise<FileItem[]>, deleteFiles: (id?: string) => Promise<void> }) => {

    const { deleteBooking } = useBookingsMutations()
    const updateBooking = useUpdateBooking()
    const { deleteVisit, updateVisit } = useVisitsMutations()
    const [loading, setLoading] = useState<boolean>(false)
    const client = useApolloClient()
    const [fix, setFix] = useState<boolean>(false)
    const updateBookings = useUpdateRelatedBooking()

    const validation = async (status: Number) => {
        let flag = false
        let message = ""
        // status:1(予約確定)の場合、患者ID必須
        if (status === BOOKED_STATUS && !booking.patientID) {
            message = " - 患者IDを入力してください"
            flag = true
        }
        if (!booking.patientName) {
            message = " - 患者名を入力してください"
            flag = true
        }
        if (!booking.body1) {
            message = " - 部位1を選択してください"
            flag = true
        }
        if (!booking.body2) {
            message = " - 部位2を選択してください"
            flag = true
        }
        if (!booking.contrast) {
            message = " - 造影剤を選択してください"
            flag = true
        }
        if (flag) {
            await showMessage(message, { error: true })
            return undefined
        }
        if (isPet(booking)) {
            return await checkPetTime({ booking, events, client })
        } else {
            if (!await checkTime({ booking, events, client })) return undefined
        }
        return { ivCheckin: undefined, otherPet: undefined }
    }

    /**
     * 予約を物理削除する
     * @param event
     */
    const handleBookDelete = async (event: any) => {
        event.preventDefault()
        if (!await showMessage(`検査機器：${booking.modalityID}\n患者ID：${booking.patientID}\n患者名：${booking.patientName}\n予約開始：${viewDate(booking.start || new Date(0))}\n予約終了：${viewDate(booking.end || new Date(0))}\n予約を取消してよろしいですか？`, { confirm: true })) {
            return
        }
        try {
            setLoading(true)
            // 外部予約の場合、予約したユーザーにメールを通知する
            if (booking.bookingType === OUTER_BOOK_TYPE) {
                // メールパラメータ設定
                let emailModality: any = {}
                emailModality['execTimeRange'] = `${convertDate2Time(booking.start as Date)} ~ ${convertDate2Time(booking.end as Date)}`
                emailModality['id'] = booking.modalityID
                emailModality['body2'] = booking.body2
                emailModality['contrast'] = booking.contrast

                let hospitals: any = {}
                hospitals["clinic_name"] = booking.clinicName
                hospitals["doctor_name"] = booking.doctor
                hospitals["department"] = booking.department

                const toExternals: string[] = []
                const toInternals: string[] = []

                const external = await client.query<FIND_EXTERNAL_DOCTOR_USER_RESULT>({
                    query: FIND_EXTERNAL_DOCTOR_USER,
                    variables: { id: booking.doctorID },
                    fetchPolicy: 'network-only',
                })
                if (external?.data.user) {
                    toExternals.push(external?.data.user.email)
                }
                // 院内(通知設定しているユーザー)
                const internal = await client.query({ query: FIND_NOTIFICATION_USERS, fetchPolicy: 'network-only', })
                if (internal?.data.users && Array.isArray(internal.data.users)) {
                    for (const user of internal?.data.users) {
                        toInternals.push(user.email)
                    }
                }

                const params: KV = {
                    doctor: booking.doctor as string,
                    bookNo: booking._id as string,
                    bookDate: convertDate(new Date(booking.start || new Date()) as Date),
                    receptTime: booking.checkin as string,
                    weight: booking.weight,
                }

                await sendCancelToExternal(toExternals, params, emailModality)
                await sendCancelToInternal(toInternals, params, emailModality, hospitals)
            }
            // visitsのbookingIDが3つ以上の場合、該当するbooking._idのみを削除する。２つの場合、visitsを削除する。
            const current_visits = await db.collection("visits").find({ bookingIDs: booking._id })
            if (current_visits.length > 0) {
                const firstVisit = current_visits[0];

                // 予約が3つ以上かつINTERVIEW以外の場合
                if (firstVisit.bookingIDs.length > 2 && booking.modalityID !== 'INTERVIEW') {
                    // firstVisit.bookingIDsに該当するbooking._idを削除
                    await db.collection("visits").updateOne(
                        { _id: firstVisit._id },
                        { $pull: { bookingIDs: booking._id } }
                    )
                    // PET-CTの場合、ivCheckinを削除
                    if (booking.modalityID === 'PET-CT_1' || booking.modalityID === 'PET-CT_2') {
                        await db.collection("visits").updateOne(
                            { _id: firstVisit._id },
                            { $unset: { ivCheckin: "" } }
                        )
                    }


                // 予約が2つかつINTERVIEWの場合、visitを残す
                } else if (firstVisit.bookingIDs.length === 2 && booking.modalityID === 'INTERVIEW') {
                    // firstVisit.bookingIDsに該当するbooking._idを削除
                    await db.collection("visits").updateOne(
                        { _id: firstVisit._id },
                        { $pull: { bookingIDs: booking._id } }
                    )
                } else {
                    // visitsおよびbookingsを削除
                    await db.collection("visits").deleteOne({ _id: firstVisit._id })
                    for (const bookingID of firstVisit.bookingIDs) {
                        await db.collection("bookings").deleteOne({ _id: bookingID })
                    }
                }
            }

            booking.files = []
            await deleteFiles(booking._id)
            await deleteBooking(booking)
            await showMessage(`予約を取り消しました。\r\n`, { keep: true })
            setIsUpdateModalOpen(false)
            setLoading(false)
        } catch (e) {
            if (e instanceof Error) await showMessage(`予約の書込処理エラーが発生しました。\r\n${e.message}`, { error: true, keep: true })
        }
    }

    const onClose = async () => {
        setIsUpdateModalOpen(false)
        return
    }

    const updateFix = async (e: any) => {
        setFix(e.target.checked)
        return
    }

    // Date型をhour:minuteに変換
    const convertDate2Time = (_date: Date) => {
        const hour = String(_date.getHours()).padStart(2, '0')
        const minute = String(_date.getMinutes()).padStart(2, '0')

        return `${hour}:${minute}`
    }

    const handleUpdateClick = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const status = (fix || booking.bookingStatus === BOOKED_STATUS) ? BOOKED_STATUS : PRE_BOOK_STATUS
        if (status === BOOKED_STATUS && isTemp) {
            await showMessage("仮患者登録は予約確定できません。", { error: true })
            return
        }
        booking.bookingStatus = status
        if (!await showMessage(`検査機器：${booking.modalityID}\n患者ID：${booking.patientID}\n患者名：${booking.patientName}\n予約開始：${viewDate(booking.start || new Date(0))}\n予約終了：${viewDate(booking.end || new Date(0))}\n予約を${fix ? "確定" : "更新"}してよろしいですか？`, { confirm: true })) {
            return
        }
        try {
            setLoading(true)
            let validationResult: ValidationResult | undefined
            if ((validationResult = await validation(status))) {
                if (!validationResult) {
                    setLoading(false)
                    return
                } else {

                }
            } else {

            }
            booking.updated = new Date()

            // 外部予約の場合、予約確定メールを外部ユーザーに送る
            if (booking.bookingType === OUTER_BOOK_TYPE) {
                // メールパラメータ設定
                let emailModality: any = {}
                emailModality['execTimeRange'] = `${convertDate2Time(booking.start as Date)} ~ ${convertDate2Time(booking.end as Date)}`
                emailModality['id'] = booking.modalityID
                emailModality['body2'] = booking.body2
                emailModality['contrast'] = booking.contrast
                emailModality['weight'] = booking.weight

                let hospitals: any = {}
                hospitals["clinic_name"] = booking.clinicName
                hospitals["doctor_name"] = booking.doctor
                hospitals["department"] = booking.department

                const toExternals: string[] = []
                const toInternals: string[] = []

                const external = await client.query<FIND_EXTERNAL_DOCTOR_USER_RESULT>({
                    query: FIND_EXTERNAL_DOCTOR_USER,
                    variables: { id: booking.doctorID },
                    fetchPolicy: 'network-only',
                })
                if (external?.data.user) {
                    toExternals.push(external?.data.user.email)
                }
                // 院内(通知設定しているユーザー)
                const internal = await client.query({ query: FIND_NOTIFICATION_USERS, fetchPolicy: 'network-only', })
                if (internal?.data.users && Array.isArray(internal.data.users)) {
                    for (const user of internal?.data.users) {
                        toInternals.push(user.email)
                    }
                }

                const params: KV = {
                    doctor: booking.doctor as string,
                    bookNo: booking._id as string,
                    bookDate: convertDate(new Date(booking.start || new Date()) as Date),
                    receptTime: booking.checkin as string,
                }

                await sendFixToExternal(toExternals, params, emailModality)
                await sendFixToInternal(toInternals, params, emailModality, hospitals)

                // 種別を「予約確定」から「外注」に変更
                booking.bookingType = INTERNAL_TYPE

            }
            if (booking.files) {
                const files = await saveFiles(booking._id)
                booking.files = files
            }
            await updateBooking(selectBookingId, booking)

            // Visitも更新
            if (booking.visitID) {
                const visitID = typeof booking.visitID === "string"
                    ? booking.visitID
                    : (booking.visitID as { link: string }).link

                const updateVisitData: Visit = {
                    bookingType: booking.bookingType,
                    clinicID: booking.clinicID,
                    clinicName: booking.clinicName,
                    comment: booking.comment,
                    department: booking.department,
                    doctor: booking.doctor,
                    start: booking.start && booking.checkin ? getCheckinTime(booking.start, booking.checkin) : undefined,
                    end: booking.end ? new Date(booking.end) : undefined,
                    diagnosis: booking.diagnosis,
                    files: booking.files,
                    updated: new Date(),
                }
                const visitdata = await db.collection("visits").updateOne(
                    { _id: visitID },
                    { $set: updateVisitData }
                )
                console.log(visitdata)
            }

            // コメントの共通化: 同じ日程で別モダリティの予約がある場合、コメントを統一する
            const comment = booking.comment
            if (comment && booking.start && booking.patientID) {
                await updateBookings(new Date(booking.start), booking.patientID, { comment })
            }

            await showMessage(`予約を更新しました。\r\n`, { keep: true })
            setIsUpdateModalOpen(false)
            setLoading(false)
        } catch (e) {
            if (e instanceof Error) await showMessage(`予約の書込処理エラーが発生しました。\r\n${e.message}`, { error: true, keep: true })
        }
    }

    if (loading) return <Loading />
    return <>
        <button name="close" className={GrayButtonClass} onClick={onClose}>閉じる</button>
        <button name="cancel" className={RedButtonClass} onClick={handleBookDelete}>予約取消</button>
        <button name="fix" className={ButtonClass} onClick={handleUpdateClick}>予約更新</button>
        {bookingStatus === 0 ? <div className="place-self-center">
            <input type="checkbox" className={CheckClass} value="予約確定" onChange={(e) => updateFix(e)} />
            <label className="ml-1 text-white" htmlFor="scales">予約を確定する</label>
        </div> : null}
    </>
}


/**
 * UpdateForm
 * 予約フォーム用モーダル
 * @param isModalOpen
 * @param setModalClose
 * @param starttime
 * @param selectResource
 * @param selectResourceInformation
 * @param receptTimeRange
 * @constructor
 */
export const UpdateForm = ({
    isUpdateModalOpen,
    selectBookingId,
    selectResourceInformation,
    secondResourceInformation,
    setIsUpdateModalOpen,
    events,
    refetch
}
    : { isUpdateModalOpen: boolean, selectBookingId: string, selectResourceInformation: { [key: string]: any }, secondResourceInformation: KV, setIsUpdateModalOpen: any, events?: CalendarEvent[] | null, refetch?: () => Promise<void> }) => {

    const appContext = useRealmApp()
    const client = useApolloClient()
    const { db } = useMongoDB()

    // Formパラメータ
    const [accessionNo, setAccessionNo] = useState<number>(0)
    const [modalityId, setModalityId] = useState<string>("")
    const [clinicId, setClinicId] = useState<string>(options.firstExternalClinic.id)
    const [clinicName, setClinicName] = useState<string>(options.firstExternalClinic.name)
    const [bookType, setBookType] = useState<number>(0)
    const [department, setDepartment] = useState<string>("")
    const [doctor, setDoctor] = useState<string>("")
    const [patientId, setPatientId] = useState<string>("")
    const [patientName, setPatientName] = useState<string>("")
    const [patientNameKana, setPatientKana] = useState<string>("")
    const [birthDate, setBirthDate] = useState<string>("")
    const [gender, setGender] = useState<string>("M")
    const [tel, setTel] = useState<string>("")
    const [body1, setBody1] = useState<string>("")
    const [body2, setBody2] = useState<string>("")
    const [contrast, setContrast] = useState<string>("")
    const [weight, setWeight] = useState<number>(0)
    const [created, setCreated] = useState<Date>(new Date())
    const [username, setUsername] = useState<string>("")
    const [visitID, setVisitID] = useState<string>("")

    const [doctorID, setDoctorID] = useState<string>("")
    const [userID, setUserID] = useState<string>("")
    const [delay, setDelay] = useState<boolean>(false)
    const [start, setStart] = useState<Date>(new Date())
    const [end, setEnd] = useState<Date>(new Date())
    const [updated, setUpdated] = useState<Date>(new Date())
    const [checkin, setCheckin] = useState<string>("")
    const [walkingStatus, setWalkingStatus] = useState<string>("独歩")
    const [comment, setComment] = useState<string>("")
    const [diagnosis, setDiagnosis] = useState<string>("")
    const [due, setDue] = useState<Date>(new Date())
    const [image, setImage] = useState<string[]>([])
    const [bookingStatus, setBookingStatus] = useState<number>(0)
    const [reportSubmission, setReportSubmission] = useState<string[]>([])
    const [isTemp, setIsTemp] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState("")
    const [secondModalityData, setSecondModalityData] = useState<KV>({
        ID: "MRI",
        body1: "",
        body2: "",
        contrast: "",
        delay: false,
        duration: 0
    })
    const [files, setFiles] = useState<FileItem[]>([])


    const [loadFindBooking, { called, loading, data }] = useLazyQuery(
        gql`
      query GetBookingForUpdate($Id: String!) {
        booking(query: { _id: $Id }) {
          _id
          clinicID
          clinicName
          department
          doctor
          patientID
          patientName
          modalityID
          body1
          body2
          contrast
          delay
          checkin
          start
          end
          due
          userID
          doctorID
          bookingType
          bookingMethod
          walkingStatus
          visitID {
            _id
          }
          files {
            fileID
            name
          }
          comment
          diagnosis
          weight
          report {
            image
            imageSubmission
            reportSubmission
            dueDate
          }
          bookingStatus
          updated
          created
        }
      }
    `,
        {
            onCompleted: result => {
                const booking = result?.booking ?? []
                setAccessionNo(booking._id)
                setPatientId(booking.patientID)
                setPatientName(booking.patientName)
                setClinicId(booking.clinicID)
                setClinicName(booking.clinicName)
                setBookType(booking.bookingType)
                setDepartment(booking.department)
                setDoctor(booking.doctor)
                setModalityId(booking.modalityID)
                setBody1(booking.body1)
                setBody2(booking.body2)
                setContrast(booking.contrast)
                setDoctorID(booking.doctorID)
                setUserID(booking.userID)
                setWeight(booking.weight)
                setDelay(booking.delay)
                setCheckin(booking.checkin)
                setWalkingStatus(booking.walkingStatus)
                setFiles(booking.files)
                setComment(booking.comment)
                setDiagnosis(booking.diagnosis)
                setStart(new Date(booking.start))
                setEnd(new Date(booking.end))
                setDue(new Date(booking.due))
                setImage(booking.report?.image)
                setReportSubmission(booking.report?.reportSubmission)
                setBookingStatus(booking.bookingStatus)
                setVisitID(booking.visitID._id)
                setUpdated(booking.updated)
                setCreated(booking.created)
            }
        }
    )

    const [updateLoading, setUpdateLoading] = React.useState<boolean>(false)
    const { deleteBooking } = useBookingsMutations()

    /**
     * useEffect
     * 初回レンダリング終了時に一度だけ起動
     */
    useEffect(() => {
        async function fetchProps() {
            setBody1("")
            setBody2("")
            setContrast("")
        }

        fetchProps()
    }, [])

    useEffect(() => {
        async function fetchUser() {
            const updated_user = await client.query<FIND_EXTERNAL_DOCTOR_USER_RESULT>({
                query: FIND_EXTERNAL_DOCTOR_USER,
                variables: { id: userID },
                fetchPolicy: 'network-only',
            })
            if (updated_user?.data.user) {
                setUsername(updated_user.data.user.name)
            }
        }
        if (userID) {
            fetchUser()
        }
    }, [userID])

    /**
     * 予約セルをクリックし、モーダルが表示された時に発動
     */
    useEffect(() => {
        if (selectBookingId !== "") {
            loadFindBooking({ variables: { Id: selectBookingId } })
        }
    }, [selectBookingId])

    useEffect(() => {
        if (refetch && !isUpdateModalOpen) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateModalOpen])

    const BookingReport = {
        dueDate: due,
        image: image,
        reportSubmission: reportSubmission,
    }

    const fileChooserRef = useRef({} as FileHandler)

    const booking: Booking = {
        _id: selectBookingId,
        body1: body1,
        body2: body2,
        bookingType: bookType,
        checkin: checkin,
        clinicID: clinicId,
        clinicName: clinicName,
        department: department,
        doctor: doctor,
        comment: comment,
        diagnosis: diagnosis,
        contrast: contrast,
        doctorID: doctorID,
        end: end,
        files: files,
        due: due,
        delay: delay,
        modalityID: modalityId,
        patientID: patientId,
        patientName: patientName,
        patient: { link: patientId },
        report: BookingReport,
        start: start,
        weight: weight,
        userID: appContext.currentUser?.id,
        visitID: { link: visitID },
        walkingStatus: walkingStatus,
        bookingStatus: bookingStatus,
        updated: new Date(),
        created: created
    }

    /**
     * モーダル終了時に起動
     */
    const closeModal = () => {
        setErrorMessage("")
        setPatientName("")
        setPatientKana("")
        setBody1("")
        setBody2("")
        setContrast("")
        setDepartment("")
        setDiagnosis("")
        setDoctor("")
        setFiles([])
        setIsUpdateModalOpen(false)
    }

    const handleBookDelete = async (event: any) => {
        event.preventDefault()
        if (!await showMessage(`検査機器：${booking.modalityID}\n予約開始：${viewDate(booking.start || new Date(0))}\n予約終了：${viewDate(booking.end || new Date(0))}\n予約を取消してよろしいですか？`, { confirm: true })) {
            return
        }
        try {
            setUpdateLoading(true)
            if (booking.files?.length) {
            }
            await deleteBooking(booking).then(r => console.log(r))
            await showMessage(`予約を取り消しました。\r\n`, { keep: true })

            setIsUpdateModalOpen(false)
            setUpdateLoading(false)
        } catch (e) {
            if (e instanceof Error) await showMessage(`予約の書込処理エラーが発生しました。\r\n${e.message}`, { error: true, keep: true })
        } finally {
            setUpdateLoading(false)
        }
    }

    const saveFiles = async (bookingID?: string) => {
        const files = await fileChooserRef.current.saveFiles(bookingID)
        return files
    }

    const deleteFiles = async (bookingID?: string) => {
        await fileChooserRef.current.deleteFiles(bookingID)
    }

    const handleAfterFileSave = (files: FileItem[], error: FileError[]) => {
        setFiles(files)
    }

    return <>
        {(loading || updateLoading) && <Loading full />}
        {booking.bookingStatus === 6 &&
            <Modal
                isOpen={isUpdateModalOpen}
                ariaHideApp={false}
                onRequestClose={closeModal}
                style={modalStyles}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
            >
                <div className="flex">
                    <span className="text-white">アクセッション番号：{accessionNo}</span>
                    <span className="text-white items-center absolute right-8" onClick={closeModal}><BsFillXCircleFill />️</span>
                </div>
                <div className="mt-5 text-white underline flex items-center"><FcApproval />この予約は、クローズ枠です。</div>
                <p className="text-white">下記の時間帯は、モダリティの予約ができません。</p>
                <p className="p-5 text-white">開始時刻 : {booking.start ? viewDate(booking.start) : ''}<br />開始終了
                    : {booking.end ? viewDate(booking.end) : ''}
                    <br />
                    目的 : {booking.comment}<br />
                    更新者：{username ? username : ''}<br />
                    更新時刻 : {booking.updated ? viewDate(booking.updated) : ''}
                </p>
                <p className="text-white">時間枠を解放する場合は、予約取消ボタンを押し、クローズ枠を取消してください。</p>
                <div className="flex my-4 justify-center">
                    <button name="cancel" className={YellowButtonClass} onClick={handleBookDelete}>予約取消</button>
                </div>
            </Modal>
        }
        {booking.bookingStatus !== 6 &&
            <Modal
                isOpen={isUpdateModalOpen}
                ariaHideApp={false}
                onRequestClose={closeModal}
                style={modalStyles}
                contentLabel="Example Modal"
                shouldCloseOnOverlayClick={false}
            >
                <div className="flex">
                    <span className="text-white">アクセッション番号：{accessionNo}</span>
                    <span className="text-white ml-5">最終更新者：{username ? username : ''}</span>
                    <span className="text-white ml-5">最終更新日時： {booking.updated ? viewDate(booking.updated) : ''}</span>
                    <span className="text-white items-center absolute right-8" onClick={closeModal}><BsFillXCircleFill />️</span>
                </div>
                <div className="text-white underline flex items-center"><FcApproval />患者情報</div>
                <FormPatients
                    bookingId={selectBookingId}
                    patientId={patientId}
                    visitId={visitID}
                    setPatientId={setPatientId}
                    patientName={patientName}
                    setPatientName={setPatientName}
                    patientNameKana={patientNameKana}
                    setPatientKana={setPatientKana}
                    setBirthDate={setBirthDate}
                    gender={gender}
                    setGender={setGender}
                    tel={tel}
                    setTel={setTel}
                    isUpdated={true}
                    setIsTemp={setIsTemp}
                />
                <hr className="ml-2" />
                {!DOCK_TYPES.includes(booking.bookingType || 0) ? <>
                    <div className="text-white mt-6 underline flex items-center"><FcApproval />紹介先情報</div>
                    <FormClinics
                        clinicId={clinicId}
                        setClinicId={setClinicId}
                        setClinicName={setClinicName}
                        bookType={bookType}
                        setBookType={setBookType}
                        department={department}
                        setDepartment={setDepartment}
                        doctor={doctor}
                        setDoctor={setDoctor}
                    />
                </>
                    : <></>
                }
                <hr className="ml-2" />
                <div className="text-white mt-6 underline flex items-center"><FcApproval />検査情報</div>
                <FormModality
                    selectResourceInformation={selectResourceInformation}
                    secondResourceInformation={secondResourceInformation}
                    modalities={modalityId}
                    modality={modalityId}
                    body1={body1}
                    setBody1={setBody1}
                    body2={body2}
                    setBody2={setBody2}
                    contrast={contrast}
                    setContrast={setContrast}
                    setSecondModalityData={setSecondModalityData}
                    delay={delay}
                    setDelay={setDelay}
                    starttime={start}
                    endtime={end}
                    setEnd={setEnd}
                    isUpdated={true}
                    checkin={checkin}
                    setCheckin={setCheckin}
                />
                <hr className="ml-2" />
                <FormBookInformation
                    starttime={start}
                    walkingStatus={walkingStatus}
                    setWalkingStatus={setWalkingStatus}
                    checkin={checkin}
                    setCheckin={setCheckin}
                    files={files}
                    fileChooserRef={fileChooserRef}
                    fileSaveCallback={handleAfterFileSave}
                    comment={comment}
                    setComment={setComment}
                    diagnosis={diagnosis}
                    setDiagnosis={setDiagnosis}
                    bookingType={booking.bookingType}
                    modality={modalityId}
                />
                <hr className="ml-2" />
                {!DOCK_TYPES.includes(booking.bookingType || 0) ? <>
                    <div className="text-white mt-6 underline flex items-center"><FcApproval />報告情報</div>
                    <FormReport
                        due={due}
                        setDue={setDue}
                        image={image}
                        setImage={setImage}
                        reportSubmission={reportSubmission}
                        setReportSubmission={setReportSubmission}
                    />
                </> : <></>
                }
                <hr className="ml-2" />
                {errorMessage !== "" ? <div className={errorMessageClass}>入力項目が不足しています。<br /> {errorMessage}</div> :
                    <span />}
                <div className="flex my-4 justify-center">
                    <BookButton
                        db={db}
                        selectBookingId={selectBookingId}
                        booking={booking}
                        updated={updated}
                        isTemp={isTemp}
                        bookingStatus={bookingStatus}
                        setIsUpdateModalOpen={setIsUpdateModalOpen}
                        saveFiles={saveFiles}
                        deleteFiles={deleteFiles}
                    />
                </div>
            </Modal>
        }
    </>
}