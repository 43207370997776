import React from "react";
import { ApolloClient, HttpLink, from, InMemoryCache, ApolloProvider, makeVar } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
//import { InvalidationPolicyCache, RenewalPolicy } from '@nerdwallet/apollo-cache-policies';
import { useRealmApp } from "contexts/RealmApp";


const createRealmApolloClient = (appContext) => {
    const httpLink = new HttpLink({
        uri: `${process.env.REACT_APP_GRAPHQL_URL || "https://eastasia.azure.realm.mongodb.com"}/api/client/v2.0/app/${appContext.app.id}/graphql`,
        fetch: async (uri, options) => {
            if (!appContext.currentUser) {
                throw new Error(`Must be logged in to use the GraphQL API`);
            }
            await appContext.currentUser.refreshCustomData();
            options.headers.Authorization = `Bearer ${appContext.currentUser.accessToken}`;
            return fetch(uri, options);
        },
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                ),
            );

        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    // get single data from cache if exist
    /*  const fields = Object.keys(properties).reduce((a, key) => ({ ...a, 
        [key]: {
          read(_, { args, toReference }) {
            if (args.query?._id) return toReference({
              __typename: capitalize(key),
              _id: args.query._id,
            });
          }
        }
      }), {}) */


/*    const cacheTTL = new InvalidationPolicyCache({
        invalidationPolicies: {
            timeToLive: 3600 * 1000, // 1hr TTL on all types in the cache
            renewalPolicy: RenewalPolicy.WriteOnly,
            types: {
                Clinic: {
                    timeToLive: 3600 * 1000 * 24 // 24hr TTL specifically for the Employee type in the cache
                }
            }
        }
    });
*/    const cache = new InMemoryCache()//{ typePolicies: { Query: { fields: fields } } })
    const connectToDevTools = true
    return new ApolloClient({ link: from([errorLink, httpLink]), cache, connectToDevTools });
};

export const filterStates = makeVar({});

export default function RealmApolloProvider({ children }) {
    const appContext = useRealmApp();
    const [client, setClient] = React.useState(createRealmApolloClient(appContext));
    React.useEffect(() => {
        setClient(createRealmApolloClient(appContext));
        return (() => { client.clearStore(); })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appContext]);
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}