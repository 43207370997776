import Modal from "react-modal"
import React, { MouseEvent, useEffect, useRef, useState, ChangeEvent } from "react"
import options from "contexts/options.json"

import { FcApproval } from "react-icons/fc"
import {
    YellowButtonClass,
    ButtonClass,
    errorMessageClass,
    modalBasicIndent,
    modalStyles,
    FixButtonClass,
    classNames,
    InputGrayClass, GrayButtonClass
} from "contexts/style"
import useBookingsMutations from "graphql/useBookingsMutations"
import useVisitsMutations from "graphql/useVisitsMutations"
import { FormClinics } from "./FormClinics"
import { FormPatients } from "./FormPatients"
import { FormModality } from "./FormModality"
import { FormBookInformation } from "./FormBookInformation"
import { FormReport } from "./FormReport"
import { Loading } from "./Loading"
import { useRealmApp } from "contexts/RealmApp"
import { useApolloClient } from "@apollo/client"
import gql from "graphql-tag"
import usePatientsMutations from "graphql/usePatientsMutations"
import { getSequenceNo } from "graphql/useSequenceFindOneAndUpdate"
import { useMongoDB } from "contexts/MongoDBContext"
import {
    BOOKED_STATUS,
    isPet,
    checkPetTime,
    PRE_BOOK_STATUS,
    checkTime,
    CLOSE_STATUS,
    CLOSE_TYPE, isPetHead, OUTSIDE_TYPE, petBodyTime, petHeadTime
} from "contexts/enviroments"
import showMessage from "./showMessage"
import { useUpdateBooking, useUpdateRelatedBooking } from "contexts/useCollection"
import { Tab } from "@headlessui/react"
import { JSTDate, JSTTime } from "contexts/dateUtils"
import { XCircleIcon } from "@heroicons/react/24/solid"
import { getTmpPatientId, getCheckinTime } from "contexts/functions"
import { FileError, FileHandler } from "./FormFile"

const viewDate = (date: Date) => {
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日 ${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`
}

const FIND_BOOKING = gql`
  query GetOtherBookingsForModality($modalityId: String!, $start: DateTime!, $end: DateTime!) {
    bookings(query: {modalityID: $modalityId, start_lt: $end, end_gt: $start}) {
      _id
      modalityID
      start
      end
      updated
    }
  }
`

const BookButton = ({
    modality,
    starttime,
    end,
    booking,
    tmpPatient,
    setErrorMessage,
    setIsModalOpen,
    events,
    resetStates,
    secondModalityData,
    saveFiles
}
    : { modality: string, starttime: Date, end?: Date, booking: any, tmpPatient: any, setErrorMessage: any, setIsModalOpen: any, events?: CalendarEvent[] | null, resetStates: any, secondModalityData: KV, saveFiles: (id?: string) => Promise<FileItem[]> }) => {
    const { addBooking } = useBookingsMutations()
    const { addVisit } = useVisitsMutations()
    const updateBooking = useUpdateBooking()
    const { addPatient } = usePatientsMutations()
    const { db } = useMongoDB()
    const client = useApolloClient()
    const [loading, setLoading] = useState(false)
    const updateBookings = useUpdateRelatedBooking()

    const validation = async (findBookings: boolean, status: Number) => {
        let flag = false
        let message = ""
        // status:1(予約確定)の場合、患者ID必須
        if (status === BOOKED_STATUS && !booking.patientID) {
            message = " - 患者IDを入力してください"
            flag = true
        }
        if (!booking.patientName) {
            message = " - 患者名を入力してください"
            flag = true
        }
        //    if (!booking.patientNameKana) {
        //      message = " - 患者名カナを入力してください"
        //      flag = true
        //    }
        if (!booking.body1) {
            message = " - 部位1を選択してください"
            flag = true
        }
        if (!booking.body2) {
            message = " - 部位2を選択してください"
            flag = true
        }
        if (!booking.contrast) {
            message = " - 造影剤を選択してください"
            flag = true
        }
        if (findBookings) {
            message = " - 対象の時間は予約が重複しています"
            flag = true
        }
        if (flag) {
            setErrorMessage(message)
            await showMessage(message, { error: true })
            return undefined
        }
        if (isPet(booking)) {
            return await checkPetTime({ booking, events, client })
        } else {
            if (!await checkTime({ booking, events, client })) return undefined
        }
        return { ivCheckin: undefined, otherPet: undefined }
    }

    const saveBookings = async (status: number, validationResult: ValidationResult) => {
        const visit: Visit = {
            bookingType: booking.bookingType,
            clinicID: booking.clinicID,
            clinicName: booking.clinicName,
            comment: booking.comment,
            department: booking.department,
            diagnosis: booking.diagnosis,
            doctor: booking.doctor,
            start: getCheckinTime(booking.start, booking.checkin),
            end: booking.end,
        }
        // 仮予約かつ患者IDが存在しない場合、Patientsのドキュメントを作成
        if (status === PRE_BOOK_STATUS && !booking.patientID) {
            try {
                const tmpPatientID = getTmpPatientId(1000000000, 9000000000).toString()

                booking.patientID = tmpPatientID
                tmpPatient.patientId = tmpPatientID
                await addPatient(tmpPatient)
            } catch (e) {
                if (e instanceof Error) await showMessage(`仮患者登録の書込処理エラーが発生しました。\r\n${e.message}`, { error: true })
                return false
            }
        }
        // AccessionIDを取得
        const seqNo = await getSequenceNo(db)
        if (!seqNo) return false
        // bookingsのドキュメント作成
        booking._id = seqNo
        booking.bookingStatus = status
        visit._id = String(seqNo)
        visit.bookingIDs = [String(seqNo)]
        booking.visitID = { link: seqNo }
        if (validationResult.ivCheckin) booking.ivCheckin = validationResult.ivCheckin
        try {
            if (validationResult?.otherPet?._id) await updateBooking(validationResult.otherPet._id, { ivCheckin: validationResult.ivCheckin })
        } catch (e) {
            if (e instanceof Error) await showMessage(`既存予約のIV開始時刻の書込処理エラーが発生しました。\r\nアクセッション：${validationResult?.otherPet?._id}\r\n${e.message}`, { error: true })
            return false
        }
        try {
            booking.updated = new Date()
            if (booking.files) {
                const files = await saveFiles(booking._id)
                booking.files = files
                visit.files = files
            }
            await addBooking(booking)
        } catch (e) {
            if (e instanceof Error) await showMessage(`予約の書込処理エラーが発生しました。\r\n${e.message}`, { error: true })
            return false
        }
        await showMessage(`アクセッション番号(${seqNo})で予約しました。\r\n予約モダリティ：${booking.modalityID}\r\n患者ID：${booking.patientID}`)

        // セットで予約する場合
        if (secondModalityData.body1 !== '' && secondModalityData.body2 !== '' && secondModalityData.contrast !== '') {
            const seqNo2 = await getSequenceNo(db)
            if (!seqNo2) return false
            visit.bookingIDs.push(String(seqNo2))
            booking._id = seqNo2
            booking.visitID = { link: seqNo2 }
            booking.modalityID = secondModalityData.ID
            booking.body1 = secondModalityData.body1
            booking.body2 = secondModalityData.body2
            booking.contrast = secondModalityData.contrast
            const range = await getEmptyRange(secondModalityData.ID, booking.end, secondModalityData.duration, 3)
            if (range) {
                booking.start = range.start
                booking.end = range.end
                // visitはendのみ更新
                visit.end = range.end
                if (isPet(booking)) {
                    const ivCheck = await checkPetTime({ booking, events, client })
                    if (ivCheck && ivCheck.ivCheckin) {
                        booking.ivCheckin = ivCheck.ivCheckin
                        visit.ivCheckin = ivCheck.ivCheckin
                    } else {
                        await showMessage("２つ目の予約でIVが取得できませんでした。", { error: true })
                        return
                    }
                }
                try {
                    await showMessage(`アクセッション番号(${seqNo2})で予約しました。\r\n予約モダリティ：${booking.modalityID}\r\n患者ID：${booking.patientID}`)
                    await addBooking(booking)
                } catch (e) {
                    if (e instanceof Error) await showMessage(`予約の書込処理エラーが発生しました。\r\n${e.message}`, { error: true })
                    return false
                }
            }
        }

        // 患者情報のコピー
        if ( booking.patientID && booking.patientName ) {
            visit.patient = { link: booking.patientID }
            visit.patientName = booking.patientName
        }


        // コメントの共通化: 同じ日程で別モダリティの予約がある場合、コメントを統一する
        const comment = booking.comment
        if (comment && booking.start && booking.patientID) {
            await updateBookings(new Date(booking.start), booking.patientID, { comment })
        }

        // 診療予約も登録
        const [hours, minutes] = booking.checkin.split(':').map(Number);
        const seqNo3 = await getSequenceNo(db)
        if (!seqNo3) return false

        visit.bookingIDs.push(String(seqNo3))
        // Visitを保存
        await addVisit(visit)

        // 問診予約
        // checkinは15分前から5分間
        // modalityは外来室(1F)
        // interviewType: 問診(外注)
        booking._id = seqNo3
        booking.visitID = { link: visit._id }
        booking.start.setHours(hours);
        booking.start.setMinutes(minutes);
        booking.end.setHours(hours);
        booking.end.setMinutes(minutes);
        // PET-CT_頭、頭部(アミロイド)の場合、受付の10分後に5分間
        if (booking.body1 === 'PET-CT_頭' && booking.body2 === '頭部(アミロイド)') {
            booking.start.setMinutes(booking.start.getMinutes() + 10);
            booking.end.setMinutes(booking.start.getMinutes() + 5);
        } else {
            booking.start.setMinutes(booking.start.getMinutes() + 5);
            booking.end.setMinutes(booking.start.getMinutes() + 5);
        }
        booking.modalityID = 'OUTEXAM'
        booking.bookingType = visit.bookingType // visitに合わせる
        booking.appointmentMethod = '来院'
        booking.interviewType = '問診(外来)'
        if (visit.bookingType === 1) {
            booking.interviewType = '問診(外注)'
        } 
        booking.bookingMethod = 1 // WEB
        booking.bookingStatus = 0 // 仮予約
        booking.body1 = ''
        booking.body2 = ''
        booking.contrast = ''
        // booking.checkin = ''
        booking.updated = new Date()
        try {
            await addBooking(booking)
        } catch (e) {
            if (e instanceof Error) await showMessage(`診療予約の書込処理エラーが発生しました。\r\n${e.message}`, { error: true })
            return false
        }

        return true
    }

    const getEmptyRange = async (modality: string, start: Date, duration: number, repeat: number) => {
        for (let i = 0; i < repeat; i++) {
            let searchStart = new Date()
            let searchEnd = new Date()
            if (isPet(booking)) {
                let iv_duration = petBodyTime
                if (isPetHead(booking)) {
                    iv_duration = petHeadTime
                }
                searchStart = new Date(start.getTime() + (i * 5 + iv_duration) * 60000)
                searchEnd = new Date(searchStart.getTime() + duration * 60000)
            } else {
                searchStart = new Date(start.getTime() + i * duration * 60000)
                searchEnd = new Date(searchStart.getTime() + duration * 60000)
            }
            const result = await client.query({
                query: FIND_BOOKING,
                variables: { modalityId: modality, start: searchStart, end: searchEnd },
                fetchPolicy: 'network-only',
            })
            if (result?.data.bookings.length === 0) {
                return { start: searchStart, end: searchEnd }
            }
        }
        return null
    }

    const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
        if (e.currentTarget.name === "close") {
            setIsModalOpen(false)
            return
        }
        const status = e.currentTarget.name === "prebook" ? PRE_BOOK_STATUS : BOOKED_STATUS
        if ((status && !booking.patientID) || !booking.patientName || !booking.end || !booking.body2 || !booking.contrast) {
            await showMessage("予約情報が不足しています。フォームをご確認ください", { error: true })
            return
        }
        if (!await showMessage(`検査機器：${booking.modalityID}\n${status === BOOKED_STATUS ? `患者ID：${booking.patientID}\n` : ''}患者名：${booking.patientName}\n予約開始：${viewDate(booking.start)}\n予約終了：${viewDate(booking.end)}\n${status === BOOKED_STATUS ? '予約確定' : '仮予約'}してよろしいですか？`, { confirm: true })) {
            return
        }
        let isOtherBookExist = false
        try {
            setLoading(true)
            const result = await client.query({
                query: FIND_BOOKING,
                variables: { modalityId: modality, start: starttime, end: end },
                fetchPolicy: 'network-only',
            })
            setLoading(false)
            isOtherBookExist = Boolean(result?.data?.bookings?.length)
        } catch (e) {
            if (e instanceof Error) {
                await showMessage(`他の予約の存在確認中にエラーが発生しました。\r\n${e.message}`, { error: true })
                setLoading(false)
                return
            }
        }
        // validationチェック
        let validationResult: ValidationResult | undefined
        if ((validationResult = await validation(isOtherBookExist, status))) {
            setLoading(true)
            if (await saveBookings(status, validationResult)) {
                resetStates()
                setLoading(false)
                setIsModalOpen(false)
            } else {
                setLoading(false)
            }
        } else {
            // 7秒間だけ、エラーメッセージを表示
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    setErrorMessage("")
                }, 7000)
            })
        }
    }
    if (loading) return <Loading />
    return <>
        <button name="close" className={GrayButtonClass} onClick={handleClick}>閉じる</button>
        <button name="prebook" className={YellowButtonClass} onClick={handleClick}>仮予約</button>
        <button name="book" className={ButtonClass} onClick={handleClick}>予約確定</button>
    </>
}

/**
 * EntryForm
 * 予約フォーム用モーダル
 */
export const EntryForm = ({
    isModalOpen,
    setIsModalOpen,
    starttime,
    selectResource: modality,
    selectResourceInformation,
    secondResourceInformation,
    defaultDue,
    checkin,
    setCheckin,
    events,
    refetch
}: {
    isModalOpen: boolean, setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
    starttime: Date,
    selectResource: string,
    selectResourceInformation: KV,
    secondResourceInformation: KV,
    defaultDue: string,
    checkin: string,
    setCheckin: React.Dispatch<React.SetStateAction<string>>,
    events?: CalendarEvent[] | null
    refetch?: () => Promise<void>
}) => {

    const appContext = useRealmApp()
    const userId = appContext.currentUser?.id

    // Formパラメータ
    const [clinicId, setClinicId] = useState<string>(options.firstExternalClinic.id)
    const [clinicName, setClinicName] = useState<string>(options.firstExternalClinic.name)
    const [bookType, setBookType] = useState<number>(1)
    const [department, setDepartment] = useState<string>("")
    const [doctor, setDoctor] = useState<string>("")
    const [patientId, setPatientId] = useState<string>("")
    const [patientName, setPatientName] = useState<string>("")
    const [patientNameKana, setPatientKana] = useState<string>("")
    const [birthData, setBirthDate] = useState<string>("")
    const [gender, setGender] = useState<string>("M")
    const [tel, setTel] = useState<string>("")
    const [body1, setBody1] = useState<string>("")
    const [body2, setBody2] = useState<string>("")
    const [contrast, setContrast] = useState<string>("")
    const [end, setEnd] = useState(new Date())
    const [delay, setDelay] = useState<boolean>(false)
    const [walkingStatus, setWalkingStatus] = useState<string>("独歩")
    const [comment, setComment] = useState<string>("")
    const [diagnosis, setDiagnosis] = useState<string>("")
    const [due, setDue] = useState<Date>(new Date())
    const [image, setImage] = useState<string[]>([])
    const [reportSubmission, setReportSubmission] = useState<string[]>([])
    const [files, setFiles] = useState<FileItem[]>([])

    // 種別枠設定用パラメータ
    const [endHour, setEndHour] = useState<number>(8)
    const [endMinute, setEndMinute] = useState<number>(0)
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const client = useApolloClient()
    const { addBooking } = useBookingsMutations()
    const { db } = useMongoDB()

    const [errorMessage, setErrorMessage] = useState<string>("")

    const fileChooserRef = useRef({} as FileHandler)

    const booking: Booking = {
        body1: body1,
        body2: body2,
        bookingType: bookType,
        checkin: checkin,
        clinicID: clinicId,
        clinicName: clinicName,
        department: department,
        doctor: doctor,
        comment: comment,
        diagnosis: diagnosis,
        contrast: contrast,
        end: end,
        due: due,
        delay: delay,
        modalityID: modality,
        patientID: patientId,
        patientName: patientName,
        patient: { link: patientId },
        report: {
            dueDate: due,
            image: image,
            reportSubmission: reportSubmission,
        },
        start: starttime,
        userID: userId,
        walkingStatus: walkingStatus,
        bookingStatus: PRE_BOOK_STATUS,
        files: files
    }

    const tmpPatient: Patient = {
        isTemp: true,
        name: patientName,
        nameKana: patientNameKana,
        birthDate: new Date(birthData),
        gender: gender,
        tel: tel,
    }

    // 初回レンダリング終了時に一度だけ起動
    useEffect(() => {
        async function fetchProps() {
            // 2日後
            const defaultday = starttime.getDate() + 2
            const defaultmonth = starttime.getMonth() + 1
            const defaultDue = starttime.getFullYear() + "-" + defaultmonth.toString().padStart(2, "0") + "-" + defaultday.toString().padStart(2, "0")
            // 期日をセット
            setDue(new Date(defaultDue))
        }

        fetchProps()
    }, [starttime])

    // isModalOpenが更新された場合、リセットする
    useEffect(() => {
        setBody1("")
        setBody2("")
        setContrast("")
        if (refetch && !isModalOpen) refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen])

    // モーダル終了時に起動
    const resetStates = () => {
        setErrorMessage("")
        setPatientName("")
        setPatientKana("")
        setPatientId("")
        setBody1("")
        setBody2("")
        setContrast("")
        setDepartment("")
        setDiagnosis("")
        setDoctor("")
        setIsModalOpen(false)
        setEndHour(8)
        setEndMinute(0)
        setDelay(false)
        setFiles([])
    }

    const selectEndHour = async (e: any) => {
        setEndHour(Number(e.target.value))
    }

    const selectEndMinute = async (e: any) => {
        setEndMinute(Number(e.target.value))
    }

    const handleClose = async (e: MouseEvent<HTMLButtonElement>) => {
        resetStates()
    }

    // 終了時刻を更新した時の挙動
    useEffect(() => {
        const endTmp = new Date(starttime.getTime())
        endTmp.setMinutes(endMinute)
        endTmp.setHours(endHour)
        setEnd(endTmp)
    }, [endMinute, endHour, starttime])

    // Tabを切り替えた時のEnd時刻の初期化
    useEffect(() => {
        // 枠スケジュールタブ
        if (selectedIndex === 1) {
            const endTmp = new Date(starttime.getTime())
            setEnd(endTmp)
        }
    }, [selectedIndex, starttime])

    // クローズ枠と登録する
    const updateRangeBooking = async (e: MouseEvent<HTMLButtonElement>) => {
        if (starttime >= end) {
            await showMessage("終了時刻が不正です。確認してください。", { error: true })
            return
        }
        if (!await showMessage(`検査機器：${booking.modalityID}\n開始時刻：${viewDate(starttime)}\n予約終了：${viewDate(end)}\nクローズ枠を登録してよろしいですか？`, { confirm: true })) {
            return
        }
        let isOtherBookExist = false
        try {
            setIsLoading(true)
            const result = await client.query({
                query: FIND_BOOKING,
                variables: { modalityId: modality, start: starttime, end: end },
                fetchPolicy: 'network-only',
            })
            isOtherBookExist = Boolean(result?.data?.bookings?.length)
        } catch (e) {
            if (e instanceof Error) {
                await showMessage(`他の予約の存在確認中にエラーが発生しました。\r\n${e.message}`, { error: true })
                setIsLoading(false)
                return
            }
        } finally {
            setIsLoading(false)
        }
        if (!isOtherBookExist) {
            setIsLoading(true)

            const seqNo = await getSequenceNo(db)
            if (!seqNo) return false
            booking._id = seqNo.toString()
            booking.patientName = 'クローズ枠'
            booking.bookingStatus = CLOSE_STATUS
            booking.bookingType = CLOSE_TYPE
            await addBooking(booking)
            setIsLoading(false)

            await showMessage("クローズ枠を登録しました。", { error: false })
            resetStates()
        } else {
            await showMessage("クローズ枠の時間帯に別予約が存在します。\n予約を削除して、再度登録してください。", { error: true })
        }
    }

    const [secondModalityData, setSecondModalityData] = useState<KV>({
        ID: "MRI",
        body1: "",
        body2: "",
        contrast: "",
        delay: false,
        duration: 0
    })

    const updatePurpose = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const updatedComment = e.target.value;
        setComment(updatedComment);
    }
    const saveFiles = async (bookingID?: string) => {
        const files = await fileChooserRef.current.saveFiles(bookingID)
        return files
    }

    const handleAfterFileSave = (files: FileItem[], error: FileError[]) => {
        setFiles(files)
    }

    return <>
        {isLoading && <Loading full />}
        <Modal
            isOpen={isModalOpen}
            ariaHideApp={false}
            onRequestClose={resetStates}
            style={modalStyles}
            contentLabel="Example Modal"
            shouldCloseOnOverlayClick={false}
        >
            <span className="text-white items-center absolute top-2 right-2" onClick={resetStates}><XCircleIcon
                className="w-8 h-8 cursor-pointer hover:text-theme-200" />️</span>
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                <Tab.List className="flex space-x-1 rounded-xl bg-theme-500 mr-8 p-1">
                    {["検査予約", "クローズ"].map((category) => (
                        <Tab
                            key={category}
                            className={({ selected }) =>
                                classNames(
                                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-theme-800',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-theme-400 focus:outline-none focus:ring-2',
                                    selected
                                        ? 'bg-theme-50 shadow'
                                        : 'text-theme-100 hover:bg-white/[0.12] hover:text-white'
                                )
                            }
                        >
                            {category}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <div className="flex pt-4">
                            <span className="text-white">アクセッション番号：新規</span>
                        </div>
                        <div className={modalBasicIndent}><FcApproval />患者情報</div>
                        <FormPatients
                            bookingId={""}
                            patientId={patientId}
                            visitId={""}
                            setPatientId={setPatientId}
                            patientName={patientName}
                            setPatientName={setPatientName}
                            patientNameKana={patientNameKana}
                            setPatientKana={setPatientKana}
                            setBirthDate={setBirthDate}
                            gender={gender}
                            setGender={setGender}
                            tel={tel}
                            setTel={setTel}
                            isUpdated={false}
                            setIsTemp={false}
                        />
                        <hr className="ml-2" />
                        <div className={modalBasicIndent}><FcApproval />紹介先情報</div>
                        <FormClinics
                            clinicId={clinicId}
                            setClinicId={setClinicId}
                            setClinicName={setClinicName}
                            bookType={bookType}
                            setBookType={setBookType}
                            department={department}
                            setDepartment={setDepartment}
                            doctor={doctor}
                            setDoctor={setDoctor}
                        />
                        <hr className="ml-2" />
                        <div className={modalBasicIndent}><FcApproval />検査情報</div>
                        <FormModality
                            selectResourceInformation={selectResourceInformation}
                            secondResourceInformation={secondResourceInformation}
                            modalities={[modality]}
                            modality={modality}
                            body1={body1}
                            setBody1={setBody1}
                            body2={body2}
                            setBody2={setBody2}
                            contrast={contrast}
                            setContrast={setContrast}
                            setSecondModalityData={setSecondModalityData}
                            delay={delay}
                            setDelay={setDelay}
                            starttime={starttime}
                            endtime={new Date()}
                            setEnd={setEnd}
                            isUpdated={false}
                            checkin={checkin}
                            setCheckin={setCheckin}
                        />
                        <hr className="ml-2" />
                        <FormBookInformation
                            starttime={starttime}
                            walkingStatus={walkingStatus}
                            setWalkingStatus={setWalkingStatus}
                            checkin={checkin}
                            setCheckin={setCheckin}
                            comment={comment}
                            setComment={setComment}
                            diagnosis={diagnosis}
                            setDiagnosis={setDiagnosis}
                            bookingType={OUTSIDE_TYPE}
                            modality={modality}
                            files={files}
                            fileChooserRef={fileChooserRef}
                            fileSaveCallback={handleAfterFileSave}
                        />
                        <hr className="ml-2" />
                        <div className={modalBasicIndent}><FcApproval />報告情報</div>
                        <FormReport
                            due={due}
                            setDue={setDue}
                            image={image}
                            setImage={setImage}
                            reportSubmission={reportSubmission}
                            setReportSubmission={setReportSubmission}
                        />
                        <hr className="ml-2" />
                        {errorMessage !== "" ? <div className={errorMessageClass}>{errorMessage}</div> : <span />}
                        <div className="flex my-4 justify-center">
                            <BookButton
                                modality={modality}
                                starttime={starttime}
                                end={end}
                                booking={booking}
                                tmpPatient={tmpPatient}
                                setErrorMessage={setErrorMessage}
                                setIsModalOpen={setIsModalOpen}
                                events={events}
                                resetStates={resetStates}
                                secondModalityData={secondModalityData}
                                saveFiles={saveFiles}
                            />
                        </div>

                    </Tab.Panel>
                    <Tab.Panel className="pt-4 text-white grid gap-4">
                        <div><span className="inline-block w-32">枠開始日時</span>{JSTDate(starttime)} {JSTTime(starttime)}
                        </div>
                        <div className="flex items-center"><span className="inline-block w-32">枠終了日時</span>
                            <select
                                className={InputGrayClass}
                                value={endHour}
                                onChange={(e) => selectEndHour(e)}
                            >
                                {Array.from(Array(12)).map((v, i) => <option key={`${i + 8}`}
                                    value={i + 8}>{i + 8}時</option>)}
                            </select>
                            <select
                                className={InputGrayClass}
                                value={endMinute}
                                onChange={(e) => selectEndMinute(e)}
                            >
                                {Array.from(Array(12)).map((v, i) => <option key={`${i + 5}`}
                                    value={i * 5}>{i * 5}分</option>)}
                            </select>
                        </div>
                        <div className="flex items-center"><span className="inline-block w-32">目的</span>
                            <textarea value={comment} rows={4} onChange={(e) => updatePurpose(e)}
                                className={`areaClass text-black w-full m-4`}></textarea>
                        </div>

                        <div className="flex my-4 justify-center">
                            <button name="book" className={FixButtonClass} onClick={updateRangeBooking}>確定</button>
                            <button name="close" className={GrayButtonClass} onClick={handleClose}>閉じる</button>
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </Modal>
    </>
}