import React, { useContext, useEffect, useState } from "react"
import { useRealmApp } from "contexts/RealmApp"
const MongoDBContext = React.createContext<{ db: Realm.Services.MongoDBDatabase | null } | null>(null);
export const useMongoDB = () => {
  const mdbContext = useContext(MongoDBContext)
  if (mdbContext == null) {
      throw new Error("useMongoDB() called outside of a MongoDB?")
  }
  return mdbContext
}

const MongoDB = ({ children }: { children: JSX.Element }) => {
  const { currentUser } = useRealmApp();
  const [db, setDb] = useState<Realm.Services.MongoDBDatabase | null>(null);
  useEffect(() => {
    if (currentUser !== null) {
      const realmService = currentUser.mongoClient("mongodb-atlas");
      setDb(realmService.db("Booking"));
    }
  }, [currentUser]);
  return (
    <MongoDBContext.Provider value={{ db }}>{children}</MongoDBContext.Provider>
  );
};
export default MongoDB