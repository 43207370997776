import { InputClass } from "../contexts/style"
import React, {useEffect, useState} from "react"
import { useExternalClinics, useInternalClinic }  from "../graphql/useClinics"
import { Loading } from "./Loading"
import options from "contexts/options.json"

/**
 * FormClinics
 * 紹介先情報
 * @param clinics
 * @param setClinicId
 * @param setClinicName
 * @param setBookType
 * @param setDepartment
 * @param setDoctor
 * @constructor
 */
export const FormClinics = ({ clinicId, setClinicId,
                              setClinicName,
                              bookType, setBookType,
                              department, setDepartment,
                              doctor, setDoctor }:
                                { clinicId: string, setClinicId: any,
                                  setClinicName: any,
                                  bookType: number, setBookType: any,
                                  department: string, setDepartment: any,
                                  doctor: string, setDoctor: any
                                }) => {

  // clinicのselect boxのvalue
  const [clinicValue, setClinicValue] = useState(0)
  // セントラルクリニック世田谷を選択したらtrue
  const [isSetagayaClinic, setIsSetagayaClinic] = useState(false)
  // Clinic情報（全体）の読み込み
  const { clinics, loading } = useExternalClinics()
  // 院内クリニックの読み込み
  const { internalClinic, loading: internalLoading } = useInternalClinic()

  useEffect(() => {
    async function fetchClinic() {
      if (clinicId === options.centralClinic.id) {
        setIsSetagayaClinic(true)
      }
      if (isSetagayaClinic) {
        setClinicValue(0)
      } else {
        clinics.forEach((clinic, i) => {
          if (clinic._id === clinicId) {
            setClinicValue(i)
          }
        })
      }
    }
    fetchClinic()
  }, [clinicId, clinics, isSetagayaClinic])

  /**
   * 施設選択時に起動
   * @param e
   */
  const chooseClinic = (e: any) => {
    setClinicId(clinics[e.target.value]._id)
    setClinicName(clinics[e.target.value].clinic_name)
    setClinicValue(e.target.value)
  }

  /**
   * 外注・外来選択時に起動
   * @param e
   */
  const chooseBookType = (e: any) => {
    // 外来の場合、クリニック世田谷に選択リストを変更する
    if (e.target.value === "0") {
      setClinicValue(0)
      setClinicId(options.centralClinic.id)
      setClinicName(options.centralClinic.name)
      setIsSetagayaClinic(true)
    } else {
      // 外注の場合、クリニック世田谷の次を自動選択
      setIsSetagayaClinic(false)
      e.target.value = 1
      chooseClinic(e)
    }
    setBookType(e.target.value)
  }

  /**
   * 診療科選択時に起動
   * @param e
   */
  const chooseDepartment = (e: any) => {
    setDepartment(e.target.value)
  }

  /**
   * 医師名選択時に起動
   * @param e
   */
  const chooseDoctor = (e: any) => {
    setDoctor(e.target.value)
  }

  /**
   * 施設 Select Box
   * @constructor
   */
  const Clinics = () => {
    return <select
        id="clinic_name"
        name="clinic_name"
        value={clinicValue}
        className={InputClass}
        disabled={isSetagayaClinic}
        onChange={(e) => chooseClinic(e)}
    >
      {
        (isSetagayaClinic ? internalClinic : clinics).map((clinic, i) => <option key={i} value={i} >{clinic.clinic_name}</option>)
      }
    </select>
  }

  /**
   * 診療科 Select Box
   * @constructor
   */
  const Departments = () => {
    return <select
        className={InputClass}
        onChange={(e) => chooseDepartment(e)}
        value={department}
    >
      <option key="0" value=""> - </option>
      {
        (isSetagayaClinic ? internalClinic[clinicValue] : clinics[clinicValue])?.departments.map((key: any) => <option key={key.name} value={key.name}>{key.name}</option>)
      }
    </select>
  }

  /**
   * 医師名 Select Box
   * @constructor
   */
  const Doctors = () => {

    let doctors: string[] = []
    // eslint-disable-next-line array-callback-return
    if (isSetagayaClinic) {
      internalClinic[clinicValue]?.departments.forEach((deptContents: KV) => {
        if (deptContents.name === department && deptContents.doctors) {
          doctors = deptContents.doctors
        }
      })
    } else {
      clinics[clinicValue]?.departments.forEach((deptContents: KV) => {
        if (deptContents.name === department && deptContents.doctors) {
          doctors = deptContents.doctors
        }
      })
    }

    const removeAsterisk = (doctor_name: string) => {
      // 医師名：山田哲也*の場合、*を削除
      return doctor_name.replaceAll("*", "");
    }

    return <select
        className={InputClass}
        onChange={(e) => chooseDoctor(e)}
        value={removeAsterisk(doctor)}
    >
      <option key="0" value="">-</option>
      {
        doctors.map((doctorOrig: string) => {
          const doctor = doctorOrig.replaceAll("*", "")
          return <option key={doctor} value={doctor}>{doctor}</option>
        })
      }
    </select>
  }

  return <>
    {
      loading && internalLoading && <Loading full/>} <div className="p-3">
    <div className="w-36">
      <span>
        <select id="book_type" name="book_type" className={InputClass} value={bookType} onChange={(e) => chooseBookType(e)}>
          <option value="0">外来</option>
          <option value="1">外注</option>
        </select>
      </span>
    </div>
    <div className="mt-2 flex">
      <span>
        <Clinics/>
      </span>
      <span className="ml-3">
        <Departments/>
      </span>
      <span className="ml-3">
        <Doctors/>
      </span>
    </div>
  </div>
  </>
}