export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/


export const deepCopy = (obj:any) => {
    if(typeof obj !== 'object' || obj === null) return obj;
    if(obj instanceof Date) return new Date(obj.getTime());
    if(obj instanceof Array) {
        return obj.reduce((arr, item, i) => {
            arr[i] = deepCopy(item);
            return arr;
        }, []);
    }
    if(obj instanceof Object) {
        return Object.keys(obj).reduce((newObj:KV, key) => {
            newObj[key] = deepCopy(obj[key]);
            return newObj;
        }, {})
    }
}

export const getObjectDeepValue = (keys:string, values:KV) => {
    let value:any = {...values}
    keys.split(".").forEach(key => {
        if (!value) return value
        const arrayKey = /([^[\]]+)\[(\d+)\]$/.exec(key);
        value = arrayKey ? value[arrayKey[1]][Number(arrayKey[2])] : value[key]
    })
    return value
}

export const removeTypeName = (values: KV[]) => {
    let removedTypeName = []
    for (const num in values) {
        removedTypeName.push({
            name: values[num].name,
            doctors: values[num].doctors,
        })
    }
    return removedTypeName
}

/**
 * 
 * @param keys Key separated by dot
 * @param values current instance of object
 * @param value Value to set to key provided by keys
 * @returns Deep copied new instance
 */
export const modifyObjectDeepValue = (keys:string, values:KV, value:any) => {
    let newValues = deepCopy(values)
    let cursor = newValues
    keys.split(".").forEach((key, index, array) => {
        const arrayKey = /([^[\]]+)\[(\d+)\]/.exec(key);
        if (index === array.length - 1) {
            if (arrayKey) {
                const [, key, idx] = arrayKey
                const i = Number(idx)
                if (!cursor[key]) cursor[key] = new Array(i+1)
                    cursor[key][i] = value
            }
            else cursor[key] = value
        } else  if (arrayKey) {
            const [, key, idx] = arrayKey
            const i = Number(idx)
        if (!cursor[key]) cursor[key] = new Array(i+1)
            cursor = cursor[key][i]
        } else {
            if (!cursor[key]) cursor[key] = {}
            cursor = cursor[key]
        }
    })
    return newValues
}

// Date型を’YYYY年MM月DD日’の文字列に変換する
export const convertDate = (_date: Date) => {
    const year = _date.getFullYear()
    const month = String(_date.getMonth() + 1).padStart(2, '0') // 月は0から始まるため+1する
    const day = String(_date.getDate()).padStart(2, '0')

    return `${year}年${month}月${day}日`
}

export const convertDate2Time = (_date: Date) => {
    const hour = String(_date.getHours()).padStart(2, '0')
    const minute = String(_date.getMinutes()).padStart(2, '0')

    return `${hour}:${minute}`
}

export const getTmpPatientId = (min: number, max: number) => {
    return Math.floor(Math.random() * (max + 1 - min)) + min
}

// 予約の受付時間をDate型で返却する
export const getCheckinTime = (booking_date: Date, booking_time: string) => {
    if (!booking_date) {
        throw new Error('booking_date is undefined');
    }
    const [hours, minutes] = booking_time.split(':').map(Number);
    const newDate = new Date(booking_date);
    newDate.setHours(hours);
    newDate.setMinutes(minutes);

    return newDate;
}