import { FixButtonClass, GrayButtonClass, modalStyles } from "contexts/style";
import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import Modal from "react-modal";
import { Loading } from "./Loading";
import showMessage from "./showMessage";
import { useRealmApp } from "contexts/RealmApp";

const DailyNoticeForm = ({
    timestamp,
    setTimestamp,
    data,
    update,
    refetch,
}: {
    timestamp: number;
    setTimestamp: React.Dispatch<React.SetStateAction<number>>;
    data: KV[];
    update: (time: number, newData: KV) => Promise<void>;
    refetch?: () => Promise<void>;
}) => {
    const app = useRealmApp()
    const datum: KV = data.find(datum => datum._id === timestamp) || {}
    const [notice, setNotice] = useState<string>(datum.notice || "");
    const [isLoading, setIsLoading] = useState(false);
    const resetStates = () => {
        setNotice("");
        setTimestamp(0);
    };

    const updateDailyNotice = async (e: MouseEvent<HTMLButtonElement>) => {
        try {
            setIsLoading(true);
            await update(
                datum._id,
                { notice, updatedBy: app.currentUser?.customData.name || "", updated: new Date() }
            );
            setIsLoading(false);
            await showMessage("日別見出しを登録しました。");
            resetStates();
        } catch (e) {
            if (e instanceof Error) {
                await showMessage(
                    `日別見出しの登録中にエラーが発生しました。\r\n${e.message}`,
                    { error: true }
                );
            }
            setIsLoading(false);
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        console.log(notice)
        setNotice(e.currentTarget.value);
    };

    const handleClose = async (e: MouseEvent<HTMLButtonElement>) => resetStates();

    useEffect(() => {
        setNotice(datum.notice || "")
    }, [datum.notice])

    return (
        <>
            {isLoading && <Loading full />}

            <Modal
                isOpen={Boolean(timestamp)}
                ariaHideApp={false}
                onRequestClose={resetStates}
                style={modalStyles}
                contentLabel="Daily Notice"
                shouldCloseOnOverlayClick={false}
            >
                <div className="flex flex-col gap-4 text-white p-4">
                    <div>日付：{new Date(Number(datum._id)).toLocaleDateString()}</div>
                    <input className="text-black w-full" name="notice" value={notice} onChange={handleChange} />
                    {datum.updated && <div>更新日時：{new Date(Number(datum.updated)).toLocaleString()}</div>}
                    {datum.updatedBy && <div>更新者：{datum.updatedBy}</div>}
                    <div className="flex my-4 justify-center">
                        <button
                            name="book"
                            className={FixButtonClass}
                            onClick={updateDailyNotice}
                        >
                            確定
                        </button>
                        <button
                            name="close"
                            className={GrayButtonClass}
                            onClick={handleClose}
                        >
                            閉じる
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DailyNoticeForm;
