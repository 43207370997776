import { ApolloError, DocumentNode, gql } from "@apollo/client/core";
import { useMutation } from "@apollo/client/react";
import { getSamedayArea } from "./dateUtils";

const getBookingUpdateMutation = (): DocumentNode => gql`
  mutation UpdateBooking($id: String!, $set: BookingUpdateInput!) {
    updatedData: updateOneBooking(query: { _id: $id }, set: $set) {
        _id
        clinicID
        clinicName
        department
        doctor
        patientID
        dockID
        patientName
        modalityID
        body1
        body2
        contrast
        checkin
        start
        end
        due
        userID
        bookingType
        bookingMethod
        walkingStatus
        diagnosis
        files {
            fileID
            name
        }
        comment
        report {
        image
        imageSubmission
        reportSubmission
        dueDate
        }
        bookingStatus
        updated
        patient {
            name
            nameKana
            birthDate
            gender
        }
    }
  }
`;

const getVisitUpdateMutation = (): DocumentNode => gql`
  mutation UpdateVisit($id: String!, $set: VisitUpdateInput!) {
    updatedData: updateOneVisit(query: { _id: $id }, set: $set) {
      _id
      diagnosis
      files {
          fileID
          name
      }
      comment
    }
  }
`;

const getBookingUpdateManyMutation = (): DocumentNode => gql`
  mutation UpdateManyBooking($query: BookingQueryInput!, $set: BookingUpdateInput!) {
    updatedData: updateManyBookings(query: $query, set: $set) {
        matchedCount
        modifiedCount
    }
  }
`;

export const useUpdateBooking = (isMany?:boolean, completed?: () => void) => {
    const [updateDataMutation, { error }] = useMutation(isMany ? getBookingUpdateManyMutation() : getBookingUpdateMutation(), { onCompleted: completed });
    /**
     * Function to update data
     * @param queryValue _id or query(if isMany is true) of document to update
     * @param set New data to set
     * @returns Mutation variables {updateData, error}
     */
    const updateData = async (queryValue: string | number | KV, set: { [key: string]: any }): Promise<{ updatedData: { [key: string]: any }, error: ApolloError | undefined }> => {
        const { data } = await updateDataMutation({
            variables: isMany ?  { query: queryValue, set: set } : { id: queryValue, set: set },
        });
        const updatedData: { [key: string]: any } = data?.updatedData || {}
        return { updatedData, error };
    };
    return updateData;
}

export const useUpdateRelatedBooking = (completed?: () => void) => {
  const [updateDataMutation, { error }] = useMutation(getBookingUpdateManyMutation(), { onCompleted: completed });
    /**
     * Function to update same day same patient data
     * @param date Updating Date 
     * @param patientID Updating patientID
     * @param set Updating field and value
     * @returns Mutation variables {updateData, error}
     */
    const updateData = async (date: Date, patientID: string, set: { [key: string]: any }): Promise<{ updatedData: { [key: string]: any }, error: ApolloError | undefined }> => {
      const [start, end] = getSamedayArea(date)
      const { data } = await updateDataMutation({
          variables: { query: { start_gte: start, start_lt: end, patientID }, set: set },
      });
      const updatedData: { [key: string]: any } = data?.updatedData || {}
      return { updatedData, error };
  };
  return updateData;
}

export const useUpdateVisit = (completed?: () => void) => {
  const [updateDataMutation, { error }] = useMutation(getVisitUpdateMutation(), { onCompleted: completed });
  /**
   * Function to update data
   * @param queryValue _id or query(if isMany is true) of document to update
   * @param set New data to set
   * @returns Mutation variables {updateData, error}
   */
  const updateData = async (queryValue: string, set: { [key: string]: any }): Promise<{ updatedData: { [key: string]: any }, error: ApolloError | undefined }> => {
      const { data } = await updateDataMutation({
          variables: { id: queryValue, set: set },
      });
      const updatedData: { [key: string]: any } = data?.updatedData || {}
      return { updatedData, error };
  };
  return updateData;
}
