import React from 'react'
import { sendPostRequest } from "./sendEmail"

export const sendPreFixToExternal = async (toEmails: string[], params: KV, modalities: any) => {

    // モダリティの個数
    let modality = ''
    if (modalities.length === 1) {
        modality = `検査時間:　${modalities[0]["execTimeRange"]}
　モダリティ: ${modalities[0]["id"]}
　部位：${modalities[0]["body2"]}
　造影剤：${modalities[0]["contrast"]}
        `
    } else if (modalities.length === 2) {
        modality = `検査時間:　${modalities[0]["execTimeRange"]}
　モダリティ: ${modalities[0]["id"]}
　部位：${modalities[0]["body2"]}
　造影剤：${modalities[0]["contrast"]}
　検査時間:　${modalities[1]["execTimeRange"]}
　モダリティ: ${modalities[1]["id"]}
　部位：${modalities[1]["body2"]}
　造影剤：${modalities[1]["contrast"]}
        `
    }

    const body = `${params["doctor"]}先生

ご予約いただきありがとうございました。
現在、仮予約の状態です。
診療情報提供書のFAXを受理後、予約が確定となります。

診療情報提供書
・1枚目　　：当院へFAX（03-6432-1039）をお願い致します
・2枚目以降 ：お控えにつき、患者様ご本人へお渡し下さい　
　　　　　　　
---------------------------------------------------------------
　予約No:　${params["bookNo"]}
　予約日:  ${params["bookDate"]}
　患者名:  ${params["patientNameKana"]}
　来院受付時間:  ${params["receptTime"]}
　${modality}
---------------------------------------------------------------

内容に相違、もしくは変更がある場合は、検査予約日3営業日前まで下記ページより、予約取消の上、再度予約取得をお願いたします。
https://medical-checkup-booking.pages.dev/external/list

3営業日を過ぎてしまった場合の予約取消・変更は下記番号までご連絡ください。

医療法人社団NIDC セントラルクリニック世田谷
予約受付：03-6805-9110（営業時間　平日9：00~18：00）　
https://nidc.or.jp/

本メールは送信専用のため、直接ご返信いただいてもお問い合わせにはお答えできませんので、何かございましたら、お手数ですが、当院まで直接お電話ください。
`
    const today = new Date()
    const month = (today.getMonth() + 1).toString().padStart(2, '0')
    const day = today.getDate().toString().padStart(2, '0')
    const subject = `【セントラルクリニック世田谷】仮予約_${month}月${day}日`
    await sendPostRequest(toEmails, subject, body)
}



export const sendPreFixToInternal = async (toemails: string[], params: KV, modalities: any, hospitals: any) => {

    const url = String(process.env.REACT_APP_HOST)

    // モダリティの個数
    let modality = ''
    let link = ''
    if (modalities.length === 1) {
        modality = `検査時間:　${modalities[0]["execTimeRange"]}
　モダリティ: ${modalities[0]["id"]}
　部位：${modalities[0]["body2"]}
　造影剤：${modalities[0]["contrast"]}
        `
        link = `${url}/booking/detail/${modalities[0]["seqNo"]}`
    } else if (modalities.length === 2) {
        modality = `検査時間:　${modalities[0]["execTimeRange"]}
　モダリティ: ${modalities[0]["id"]}
　部位：${modalities[0]["body2"]}
　造影剤：${modalities[0]["contrast"]}
　検査時間:　${modalities[1]["execTimeRange"]}
　モダリティ: ${modalities[1]["id"]}
　部位：${modalities[1]["body2"]}
　造影剤：${modalities[1]["contrast"]}
        `
        link = `${url}/booking/detail/${modalities[0]["seqNo"]}
${url}/booking/detail/${modalities[1]["seqNo"]}
        `
    }

    const body = `セントラルクリニック世田谷　各位

仮予約が入りました。
FAX届き次第、内容確認の上、予約確定をしてください。
${link}
　　　　　　　
---------------------------------------------------------------
　予約No:　${params["bookNo"]}
　予約日:  ${params["bookDate"]}
　来院受付時間:  ${params["receptTime"]}
　患者名:  ${params["patientNameKana"]}
　${modality}
　紹介病院:　${hospitals["clinic_name"]}
　紹介科:　${hospitals["department"]}
　紹介医師:　${hospitals["doctor_name"]}
---------------------------------------------------------------
`
    const today = new Date()
    const month = (today.getMonth() + 1).toString().padStart(2, '0')
    const day = today.getDate().toString().padStart(2, '0')
    const subject = `仮予約連絡（${month}月${day}日/予約No.${params["bookNo"]}）`
    await sendPostRequest(toemails, subject, body)
}