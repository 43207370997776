import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import options from "contexts/options.json"

export const useExternalClinics = () => {
  const { data, loading, error } = useQuery(
      gql`
      query GetExternalClinics {
        clinics(query:{_id_ne:"${options.centralClinic.id}"}, sortBy: ORDER_ASC) {
          _id
          clinic_name
          postal_code
          address1
          address2
          tel
          fax
          clinic_type
          departments {
            name
            doctors
          }
        }
      }
    `
  );
  if (error) {
    throw new Error(`Failed to fetch useAllClinics`);
  }
  const clinics:KV[] = data?.clinics ?? [];
  return {
    clinics,
    loading,
  }
}

// セントラル世田谷クリニックのみを抽出する
export const useInternalClinic = () => {
  const { data, loading, error } = useQuery(
      gql`
      query GetInternalClinic {
        clinics(query:{_id:"${options.centralClinic.id}"}) {
          _id
          clinic_name
          postal_code
          address1
          address2
          tel
          fax
          clinic_type
          departments {
            name
            doctors
          }
        }
      }
    `
  )
  if (error) {
    throw new Error(`Failed to fetch useAllClinics`)
  }
  const internalClinic:KV[] = data?.clinics ?? []
  return {
    internalClinic,
    loading,
  }
}