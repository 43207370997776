import React from "react";
import { CirclesWithBar } from 'react-loader-spinner'

const Indicator = () => <CirclesWithBar
  height="100"
  width="100"
  color="#4fa94d"
  wrapperStyle={{}}
  wrapperClass="mt-2 mb-5"
  visible={true}
  outerCircleColor=""
  innerCircleColor=""
  barColor=""
  ariaLabel='circles-with-bar-loading'
/>

export const Loading = ({full}:{full?:boolean}) => full ? 
  <div className="fixed z-50 left-0 top-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center"><Indicator /></div>
  : <Indicator />
