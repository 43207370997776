import React, {useEffect, useState} from 'react'
import Modal from "react-modal"
import {
  ButtonClass,
  GrayButtonClass, InputGrayClass, LightGrayButtonClass,
  modalBasicIndent,
  modalStyles
} from "contexts/style"
import {FcApproval} from "react-icons/fc"
import {XCircleIcon} from "@heroicons/react/24/solid"
import { useOpeningTimesMutations, useValidateOpening, useUpdateOpeningTime, useDeleteOpeningTime } from "graphql/useOpeningTimesMutations"
import showMessage from "./showMessage"
import { Loading } from "./Loading"

export const OpeningForm = ({id, updated, setUpdated, isModalOpen, setIsModalOpen, start, end, setEnd, modality, refetch} :
                                {id: string|null, updated: boolean, setUpdated: any, isModalOpen: boolean, setIsModalOpen: any, start: Date|null, end: Date|null, setEnd: any, modality: string|null, refetch?: () => Promise<void>}) => {

  const [end_hour, setEndHour] = useState<number>(9)
  const [end_minutes, setEndMinutes] = useState<number>(0)
  const [week, setWeek] = useState<number>(0)
  const { insertOpeningTime } = useOpeningTimesMutations()
  const [updating, setUpdating] = useState<Boolean>(false)
  const updateOpeningMutation = useUpdateOpeningTime()
  const deleteOpeningMutation = useDeleteOpeningTime()

  const {validateOpeningTime, loading: validate_loading, called: validate_called, opening_times: validate_result} = useValidateOpening()

  // Closeボタン押下後
  const resetStates = () => {
    setIsModalOpen(false)
    setUpdating(false)
    setUpdated(false)
    console.log(updated)
  }

  const date2time = (date: Date|null) => {
    if (date) {
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      return `${hours}:${minutes}`
    }
    return ''
  }

  const date2week = (date: Date|null) => {
    if (date) {
      const daysOfWeek = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日']
      const dayIndex = date.getDay()
      return daysOfWeek[dayIndex]
    }
    return ''
  }

  const opening_time: OpeningTime = {
    _id: Date.now().toString(),
    close: false,
    modality: modality,
    weekday: week,
    start: start,
    starttime: null,
    end: end,
    endtime: null,
  }

  const updateEndHour = (e: any) => {
    setEndHour(e.target.value)
  }

  const updateEndMinutes = (e: any) => {
    setEndMinutes(e.target.value)
  }

  // モーダルの終了時間を変更時に発動
  useEffect(() => {
    const updated = new Date(end || '')
    updated.setHours(end_hour)
    updated.setMinutes(end_minutes)
    setEnd(updated)
  },[end_hour, end_minutes])

  useEffect(() => {
    if (refetch && !isModalOpen) refetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen])

  // 日曜(0)は7に変更する
  const handleSunday = (dayIndex: number) => {
    if (dayIndex === 0) {
      return 7
    } else {
      return dayIndex
    }
  }

  // モーダルがOpenしたときに発動
  useEffect(() => {
    if (end) {
      setEndHour(end.getHours())
      setEndMinutes(end.getMinutes())
      let dayIndex = end.getDay()
      dayIndex = handleSunday(dayIndex)
      setWeek(dayIndex)
    }
  },[isModalOpen, end])

  // 公開枠の登録ボタン押下後のバリデーションチェック後に発動
  useEffect(() => {
    if (modality === '') {
      return
    }
    async function fetchProps() {
      if (validate_result?.length > 0) {
        await showMessage(`公開枠が重複しています。\r\n`)
        resetStates()
      } else if (validate_called && !validate_loading){
        await insertOpeningTime(opening_time)
        await showMessage(`公開枠を登録しました。\r\n`)
        resetStates()
      }
    }
    fetchProps()
  },[validate_result])

  // 公開枠の登録ボタン押下後に発動
  const addOpeningTime = async () => {
    try {
      setUpdating(true)
      // 同一モダリティ、同一の週、時刻が重複していた場合、エラーを吐く。
      await validateOpeningTime({variables: {
          modality: modality,
          weekday: week,
          start: start,
          end: end
        }})
    } catch (e) {
      if (e instanceof Error) await showMessage(`予約公開枠の登録ができません。\r\n${e.message}`, {error:true})
      resetStates()
    }

  }

  // 公開枠を更新する
  const updateOpeningTime = async () => {
    if (!await showMessage(`モダリティ：${modality}\n公開枠の時刻を更新しますか？`, {confirm:true})) {
      return
    }
    try {
      setUpdating(true)
      if (id) {
        const opening_time: KV = {
          _id: id,
          modality: modality,
          weekday: week,
          start: start,
          end: end,
        }
        await updateOpeningMutation(id, opening_time)
        await showMessage(`公開枠を更新しました。\r\n`)
      }
      resetStates()
    } catch (e) {
      if (e instanceof Error) await showMessage(`予約公開枠の登録ができません。\r\n${e.message}`, {error:true})
      resetStates()
    }
  }

  // 公開枠を削除する
  const deleteOpeningTime = async () => {
    if (!await showMessage(`モダリティ：${modality}\n対象の公開枠を削除しますか？`, {confirm:true})) {
      return
    }
    try {
      setUpdating(true)
      await deleteOpeningMutation(id)
      await showMessage(`公開枠を取り消しました。\r\n`)
      resetStates()
    } catch (e) {
      if (e instanceof Error) await showMessage(`予約公開枠の登録ができません。\r\n${e.message}`, {error:true})
      resetStates()
    }
  }

  return <>
    {updating ? <Loading full /> : <></>}
    <Modal
        isOpen={isModalOpen}
        ariaHideApp={false}
        style={modalStyles}
        contentLabel="External Opening Modal"
        shouldCloseOnOverlayClick={false}
    >
      <span className="text-white items-center absolute top-2 right-2" onClick={resetStates}><XCircleIcon className="w-8 h-8 cursor-pointer hover:text-theme-200" />️</span>
      <div className="text-white text-2xl">
        {modality} 外部公開枠({date2week(start)})

      </div>

      <div className={modalBasicIndent}><FcApproval /><span className="mr-5">開始時刻</span>
        <span className="text-2xl">{date2time(start)}</span>
      </div>

      <div className={modalBasicIndent}><FcApproval /><span className="mr-5">終了時刻</span>
        <select
            className={InputGrayClass}
            value={end_hour}
            onChange={(e) => updateEndHour(e)}
        >
          {Array.from(Array(12)).map((v, i) => <option key={`${i+8}`} value={i + 8}>{i + 8}時</option>)}
        </select>
        <select
            className={InputGrayClass}
            value={end_minutes}
            onChange={(e) => updateEndMinutes(e)}
        >
          {Array.from(Array(12)).map((v, i) => <option key={`${i+5}`} value={i * 5}>{i * 5}分</option>)}
        </select>
      </div>
      <div className="mt-5">
        {updated ?
            <>
              <button name="prebook" className={ButtonClass} onClick={updateOpeningTime}>更新</button>
              <button name="prebook" className={GrayButtonClass} onClick={deleteOpeningTime}>削除</button>
              <button name="close" className={LightGrayButtonClass} onClick={resetStates}>閉じる</button>
            </>
            :
            <>
              <button name="prebook" className={ButtonClass} onClick={addOpeningTime}>確定</button>
              <button name="close" className={LightGrayButtonClass} onClick={resetStates}>閉じる</button>
            </>
        }

      </div>
    </Modal>
  </>
}