import React, { MouseEvent, useEffect, useState } from "react"
import { FcApproval } from "react-icons/fc"
import { useNavigate, useParams } from "react-router-dom"
import { gql, useApolloClient, useQuery } from "@apollo/client"

import options from "contexts/options.json"
import { YellowButtonClass, ButtonClass, GrayButtonClass, RedButtonClass, errorMessageClass, modalBasicIndent, FixButtonClass } from "contexts/style"
import useBookingsMutations from "graphql/useBookingsMutations"
import { FormClinics } from "components/FormClinics"
import { FormPatients } from "components/FormPatients"
import { FormModality } from "components/FormModality"
import { FormBookInformation } from "components/FormBookInformation"
import { FormReport } from "components/FormReport"
import { Loading } from "components/Loading"
import { useRealmApp } from "contexts/RealmApp"
import usePatientsMutations from "graphql/usePatientsMutations"
import { getSequenceNo } from "graphql/useSequenceFindOneAndUpdate";
import { useMongoDB } from "contexts/MongoDBContext";
import { today } from "contexts/dateUtils"
import { useUpdateBooking } from "contexts/useCollection"
import showMessage from "components/showMessage"
import { PRE_BOOK_STATUS, BOOKED_STATUS, CANCEL_STATUS, OUTER_BOOK_TYPE, DOCK_TYPES, OUTSIDE_TYPE, INTERNAL_TYPE, UPDATEONLY } from "contexts/enviroments"
import { Link } from "react-router-dom"
import { nonScheduleModality } from "contexts/dockCourses"
import { sendFixToExternal, sendFixToInternal } from "mail/sendFix"
import { convertDate, convertDate2Time, getCheckinTime} from "contexts/functions"
import {
    FIND_EXTERNAL_DOCTOR_USER,
    FIND_EXTERNAL_DOCTOR_USER_RESULT,
    FIND_NOTIFICATION_USERS
} from "../../components/UpdateForm"
import { sendCancelToExternal, sendCancelToInternal } from "../../mail/sendCancel"

const viewDate = (date: Date, notime: boolean = false) => {
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日 ${notime ? "" : `${date.getHours()}:${date.getMinutes()}`}`
}

function BookButton({ modality, booking, tmpPatient, setErrorMessage }
    : { modality: string, booking: any, tmpPatient: any, setErrorMessage: any }) {
    const [processing, setProcessing] = useState(false)
    const { addBooking } = useBookingsMutations()
    const { addPatient } = usePatientsMutations()
    const { db } = useMongoDB()
    const navigate = useNavigate()

    const validation = async () => {
        let flag = 0
        let message = ""
        const status = booking.bookingStatus
        // status:1(予約確定)の場合、患者ID必須
        if (status === BOOKED_STATUS && booking.patientID === "") {
            message = " - 患者IDを入力してください"
            flag = 1
        }
        if (booking.patientName === "") {
            message = " - 患者名を入力してください"
            flag = 1
        }
        if (booking.patientNameKana === "") {
            message = " - 患者名カナを入力してください"
            flag = 1
        }
        if (booking.clinicName === "") {
            message = " - 施設を選択してください"
            flag = 1
        }
        if (flag) {
            // setErrorMessage(message)
            await showMessage(message, { error: true })
            return false
        }
        return true
    }

    const getTmpPatientId = (min: number, max: number) => {
        return Math.floor(Math.random() * (max + 1 - min)) + min
    }

    const saveBookings = async () => {
        if (!await validation()) {
            // 7秒間だけ、エラーメッセージを表示
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    setErrorMessage("")
                }, 7000)
            })
            return null
        }
        if (!await showMessage(`検査機器：${booking.modalityID}\n患者名：${booking.patientName}\n予約日：${viewDate(booking.start, true)}\n${booking.bookingStatus === BOOKED_STATUS ? '予約を確定' : '仮予約'}してよろしいですか？`, { confirm: true })) {
            return null
        }
        setProcessing(true)

        // 仮予約かつ患者IDが存在しない場合、Patientsのドキュメントを作成
        if (booking.bookingStatus === PRE_BOOK_STATUS && booking.patientID === "") {
            try {
                const tmpPatientId = getTmpPatientId(1000000000, 9000000000).toString()
                booking.patientID = tmpPatientId
                tmpPatient["patientId"] = tmpPatientId
                await addPatient(tmpPatient)
            } catch (e) {
                setProcessing(false)
                await showMessage(`仮患者登録の書込処理エラーが発生しました。\r\n${(e instanceof Error) ? e.message : ""}`, { error: true })
                return null
            }
        }
        // AccessionIDを取得
        const seqNo = await getSequenceNo(db)
        // bookingsのドキュメント作成
        booking._id = seqNo
        try {
            await addBooking(booking)
        } catch (e) {
            setProcessing(false)
            await showMessage(`検査予約の書込処理エラーが発生しました。\r\n${(e instanceof Error) ? e.message : ""}`, { error: true })
            return null
        }
        setProcessing(false)
        navigate("/booking")
    }

    const handleBooking = async (e: MouseEvent<HTMLButtonElement>) => {
        booking.bookingStatus = Number(e.currentTarget.dataset.status)
        saveBookings()
    }
    return <>
        {processing && <Loading full />}
        <button data-status={PRE_BOOK_STATUS} className={YellowButtonClass} onClick={handleBooking} disabled={processing}>仮予約</button>
        <button data-status={BOOKED_STATUS} className={ButtonClass} onClick={handleBooking} disabled={processing}>予約確定</button>
        <Link to="/booking" className={ButtonClass}>戻る</Link>
    </>
}

/**
 * Create
 * 予約フォーム
 * @param receptTimeRange
 * @constructor
 */
export const Create = () => {
    const app = useRealmApp()
    const userId = app.currentUser?.id
    const modalities = app.resources.filter(v => v.noslot).map(v => v.id as string)

    // Formパラメータ
    const [clinicId, setClinicId] = useState<string>(options.firstExternalClinic.id)
    const [clinicName, setClinicName] = useState<string>(options.firstExternalClinic.name)
    const [bookType, setBookType] = useState(0)
    const [department, setDepartment] = useState("")
    const [doctor, setDoctor] = useState("")
    const [patientId, setPatientId] = useState("")
    const [patientName, setPatientName] = useState("")
    const [patientNameKana, setPatientKana] = useState("")
    const [birthData, setBirthDate] = useState("")
    const [gender, setGender] = useState("M")
    const [tel, setTel] = useState("")
    const [modalityId, setModalityId] = useState(modalities[0])
    const [body1, setBody1] = useState("")
    const [body2, setBody2] = useState("")
    const [contrast, setContrast] = useState("M")
    const [delay, setDelay] = useState<boolean>(false)
    const [start, setStart] = useState(today())
    const [end, setEnd] = useState(today())
    const [checkin, setCheckin] = useState("09:00")
    const [walkingStatus, setWalkingStatus] = useState("独歩")
    const [comment, setComment] = useState("")
    const [diagnosis, setDiagnosis] = useState("")
    const [due, setDue] = useState(new Date())
    const [image, setImage] = useState<string[]>([])
    const [reportSubmission, setReportSubmission] = useState<string[]>([])
    const [secondModalityData, setSecondModalityData] = useState<KV>({ ID: "MRI", body1: "", body2: "", contrast: "", delay: false, duration: 0 })

    const [errorMessage, setErrorMessage] = useState("")

    const booking = {
        body1: body1,
        body2: body2,
        bookingType: bookType,
        checkin: checkin,
        clinicID: clinicId,
        clinicName: clinicName,
        department: department,
        doctor: doctor,
        comment: comment,
        diagnosis: diagnosis,
        contrast: contrast,
        end: end,
        due: due,
        modalityID: modalityId,
        patientID: patientId,
        patientName: patientName,
        patient: { link: patientId },
        report: {
            dueDate: due,
            image: image,
            reportSubmission: reportSubmission,
        },
        start: start,
        userID: userId,
        walkingStatus: walkingStatus,
        bookingStatus: PRE_BOOK_STATUS,
    }

    const tmpPatient = {
        isTemp: true,
        name: patientName,
        nameKana: patientNameKana,
        birthDate: new Date(birthData),
        gender: gender,
        tel: tel,
    }

    useEffect(() => {
        async function fetchProps() {
            // 2日後
            const defaultday = start.getDate() + 2
            const defaultmonth = start.getMonth() + 1
            const defaultDue = start.getFullYear() + "-" + defaultmonth.toString().padStart(2, "0") + "-" + defaultday.toString().padStart(2, "0")
            // 期日をセット
            setDue(new Date(defaultDue))
        }
        fetchProps()
    }, [])

    useEffect(() => {
        if (modalities && (!modalityId || (modalityId && !modalities.includes(modalityId)))) setModalityId(modalities[0])
    }, [modalities])

    return <div className="p-8 bg-theme-800">
        <div className="flex">
            <span className="text-white">アクセッション番号：新規</span>
        </div>
        <div className={modalBasicIndent}><FcApproval />患者情報</div>
        <FormPatients
            bookingId={""}
            patientId={patientId}
            visitId={""}
            setPatientId={setPatientId}
            patientName={patientName}
            setPatientName={setPatientName}
            patientNameKana={patientNameKana}
            setPatientKana={setPatientKana}
            setBirthDate={setBirthDate}
            gender={gender}
            setGender={setGender}
            tel={tel}
            setTel={setTel}
            isUpdated={false}
            setIsTemp={false}
        />
        <hr className="ml-2" />
        <div className={modalBasicIndent}><FcApproval />紹介先情報</div>
        <FormClinics
            clinicId={clinicId}
            setClinicId={setClinicId}
            setClinicName={setClinicName}
            bookType={bookType}
            setBookType={setBookType}
            department={department}
            setDepartment={setDepartment}
            doctor={doctor}
            setDoctor={setDoctor}
        />
        <hr className="ml-2" />
        <div className={modalBasicIndent}><FcApproval />検査情報</div>
        <FormModality
            selectResourceInformation={{}}
            secondResourceInformation={{}}
            modalities={modalities}
            modality={modalityId}
            setModality={setModalityId}
            body1={body1}
            setBody1={setBody1}
            body2={body2}
            setBody2={setBody2}
            contrast={contrast}
            setContrast={setContrast}
            setSecondModalityData={setSecondModalityData}
            delay={delay}
            setDelay={setDelay}
            starttime={start}
            endtime={new Date()}
            setEnd={setEnd}
            isUpdated={false}
            checkin={checkin}
            setCheckin={setCheckin}
        />
        <hr className="ml-2" />
        <FormBookInformation
            starttime={start}
            setStartTime={modalities.includes(modalityId) ? setStart : undefined}
            setEndTime={modalities.includes(modalityId) ? setEnd : undefined}
            walkingStatus={walkingStatus}
            setWalkingStatus={setWalkingStatus}
            checkin={checkin}
            setCheckin={setCheckin}
            comment={comment}
            diagnosis={diagnosis}
            setDiagnosis={setDiagnosis}
            setComment={setComment}
            bookingType={OUTSIDE_TYPE}
            modality={modalityId}
        />
        <hr className="ml-2" />
        {errorMessage !== "" ? <div className={errorMessageClass}>入力項目が不足しています。<br /> {errorMessage}</div> : <span />}
        <div className="flex my-4 justify-center">
            <BookButton modality={modalityId} booking={booking} tmpPatient={tmpPatient} setErrorMessage={setErrorMessage} />
        </div>
    </div>
}

export const Edit = () => {
    const { id } = useParams<KV>()
    const app = useRealmApp()
    const modalities = app.resources.filter(v => v.noslot).map(v => v.id as string)
    const navigate = useNavigate()
    const { db } = useMongoDB()

    const [accessionNo, setAccessionNo] = useState(0)
    const [modalityId, setModalityId] = useState("")
    const [clinicId, setClinicId] = useState<string>(options.firstExternalClinic.id)
    const [clinicName, setClinicName] = useState<string>(options.firstExternalClinic.name)
    const [bookType, setBookType] = useState(0)
    const [department, setDepartment] = useState("")
    const [doctor, setDoctor] = useState("")
    const [patientId, setPatientId] = useState("")
    const [patientName, setPatientName] = useState("")
    const [patientNameKana, setPatientKana] = useState("")
    const [birthDate, setBirthDate] = useState("")
    const [gender, setGender] = useState("M")
    const [tel, setTel] = useState("")
    const [body1, setBody1] = useState("")
    const [body2, setBody2] = useState("")
    const [contrast, setContrast] = useState("")
    const [delay, setDelay] = useState<boolean>(false)
    const [start, setStart] = useState(new Date())
    const [end, setEnd] = useState(new Date())
    const [created, setCreated] = useState(new Date())
    const [checkin, setCheckin] = useState("")
    const [doctorID, setDoctorID] = useState("")
    const [visitID, setVisitID] = useState("")
    const [walkingStatus, setWalkingStatus] = useState("独歩")
    const [comment, setComment] = useState("")
    const [diagnosis, setDiagnosis] = useState("")
    const [due, setDue] = useState(new Date())
    const [weight, setWeight] = useState<number>(0)
    const [image, setImage] = useState<string[]>([])
    const [bookingStatus, setBookingStatus] = useState(0)
    const [imageSubmission, setImageSubmission] = useState<string[]>([])
    const [reportSubmission, setReportSubmission] = useState<string[]>([])
    const [isTemp, setIsTemp] = useState<boolean>(false)
    const [secondModalityData, setSecondModalityData] = useState<KV>({ ID: "MRI", body1: "", body2: "", contrast: "", delay: false, duration: 0 })

    const [processing, setProcessing] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const updateBooking = useUpdateBooking()
    const client = useApolloClient()


    const [resourceInformation, setResourceInformation] = useState<{ [key: string]: any }>({})
    const baseResources = app.resources.filter((v: KV) => !v.noslot && !v.exam).sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0)

    useEffect(() => {
        async function fetchProps() {
            // 時間枠管理の場合、モダリティ情報をセット
            for (const idx in baseResources) {
                if (String(baseResources[idx]['id']) === modalityId) {
                    setResourceInformation(baseResources[idx])
                }
            }
        }
        fetchProps()
    }, [modalityId, baseResources])

    const { loading, data } = useQuery(
        gql`
      query GetBookingForEdit($id: String!) {
        booking(query: { _id: $id }) {
          _id
          clinicID
          clinicName
          department
          doctor
          doctorID
          patientID
          visitID {
            _id
          }
          patientName
          modalityID
          body1
          body2
          contrast
          checkin
          start
          end
          due
          weight
          userID
          bookingType
          bookingMethod
          walkingStatus
          comment
          diagnosis
          report {
            image
            imageSubmission
            reportSubmission
            dueDate
          }
          bookingStatus
          updated
          created
        }
      }
    `,
        {
            variables: { id: id },
            onCompleted: result => {
                const booking = result?.booking ?? []
                setAccessionNo(booking._id)
                setPatientId(booking.patientID)
                setPatientName(booking.patientName)
                setClinicId(booking.clinicID)
                setBookType(booking.bookingType)
                setDepartment(booking.department)
                setDoctor(booking.doctor)
                setModalityId(booking.modalityID)
                setBody1(booking.body1)
                setBody2(booking.body2)
                setContrast(booking.contrast)
                setCheckin(booking.checkin)
                setWalkingStatus(booking.walkingStatus)
                setComment(booking.comment)
                setDiagnosis(booking.diagnosis)
                setDoctorID(booking.doctorID)
                setVisitID(booking.visitID._id)
                setStart(new Date(booking.start))
                setEnd(new Date(booking.end))
                setDue(new Date(booking.due))
                setImage(booking.report.image)
                setWeight(booking.weight)
                setImageSubmission(booking.report.imageSubmission)
                setReportSubmission(booking.report.reportSubmission)
                setBookingStatus(booking.bookingStatus)
                setCreated(booking.created)
            }
        }
    )


    const BookingReport = {
        dueDate: due,
        image: image,
        imageSubmission: imageSubmission,
        reportSubmission: reportSubmission,
    }

    const booking: Booking = {
        _id: id,
        body1: body1,
        body2: body2,
        bookingType: bookType,
        checkin: checkin,
        clinicID: clinicId,
        clinicName: clinicName,
        visitID: visitID,
        department: department,
        doctor: doctor,
        doctorID: doctorID,
        comment: comment,
        diagnosis: diagnosis,
        contrast: contrast,
        end: end,
        due: due,
        modalityID: modalityId,
        patientID: patientId,
        patientName: patientName,
        patient: { link: patientId },
        report: BookingReport,
        start: start,
        weight: weight,
        userID: app.currentUser?.id,
        walkingStatus: walkingStatus,
        bookingStatus: bookingStatus,
        updated: new Date(),
        created: created
    }

    const { deleteBooking } = useBookingsMutations()
    /**
     * 予約を物理削除する
     * @param event
     */
    const handleClick = async (event: any) => {
        event.preventDefault()

        const status = Number(event.currentTarget.dataset.status)
        if (status === BOOKED_STATUS && isTemp) {
            await showMessage("仮患者登録は予約確定できません。", { error: true })
            return null
        }
        if (!await showMessage(`検査機器：${booking.modalityID}\n患者ID：${booking.patientID}\n患者名：${booking.patientName}\n予約日：${viewDate(booking.start || new Date(), true)}\n予約を${status === CANCEL_STATUS ? "取消" : status === BOOKED_STATUS ? "確定" : "更新"}してよろしいですか？`, { confirm: true })) {
            return null
        }
        try {
            setProcessing(true)
            const updateBookingData: Visit = {
                clinicID: booking.clinicID,
                clinicName: booking.clinicName,
                department: booking.department,
                doctor: booking.doctor,
                start: booking.start && booking.checkin ? getCheckinTime(booking.start, booking.checkin) : undefined,
                end: booking.end ? new Date(booking.end) : undefined,
                body1: booking.body1,
                body2: booking.body2,
                contrast: booking.contrast,
                checkin: booking.checkin,
                comment: booking.comment,
                diagnosis: booking.diagnosis,
                files: booking.files,
                walkingStatus: booking.walkingStatus,
                updated: new Date(),
            }
            let bookingdata = null
            switch (status) {
                case CANCEL_STATUS:
                    // 外部予約の場合、取り消されたらメール送信
                    if (booking.bookingType === OUTER_BOOK_TYPE) {
                        console.log('取消メール')
                        const toExternals: string[] = []
                        const external = await client.query<FIND_EXTERNAL_DOCTOR_USER_RESULT>({
                            query: FIND_EXTERNAL_DOCTOR_USER,
                            variables: { id: booking.doctorID },
                            fetchPolicy: 'network-only',
                        })
                        if (external?.data.user) {
                            toExternals.push(external?.data.user.email)
                        }

                        const toInternals: string[] = []
                        // 院内(通知設定しているユーザー)
                        const internal = await client.query({ query: FIND_NOTIFICATION_USERS, fetchPolicy: 'network-only', })
                        if (internal?.data.users && Array.isArray(internal.data.users)) {
                            for (const user of internal?.data.users) {
                                toInternals.push(user.email)
                            }
                        }

                        // メールパラメータ設定
                        let emailModality: any = {}
                        emailModality['execTimeRange'] = `${convertDate2Time(booking.start as Date)} ~ ${convertDate2Time(booking.end as Date)}`
                        emailModality['id'] = booking.modalityID
                        emailModality['body2'] = booking.body2
                        emailModality['contrast'] = booking.contrast

                        let hospitals: any = {}
                        hospitals["clinic_name"] = booking.clinicName
                        hospitals["doctor_name"] = booking.doctor
                        hospitals["department"] = booking.department

                        const params: KV = {
                            doctor: booking.doctor as string,
                            bookNo: booking._id as string,
                            bookDate: convertDate(new Date(booking.start || new Date()) as Date),
                            receptTime: booking.checkin as string,
                            weight: booking.weight,
                        }

                        await sendCancelToExternal(toExternals, params, emailModality)
                        await sendCancelToInternal(toInternals, params, emailModality, hospitals)

                    }

                    // visitsの削除対応
                    if (db) {
                        const current_visits = await db.collection("visits").find({ bookingIDs: booking._id })
                        if (current_visits.length > 0) {
                            const firstVisit = current_visits[0];

                            // 予約が3つ以上かつINTERVIEW以外の場合
                            if (firstVisit.bookingIDs.length > 2 && booking.modalityID !== 'INTERVIEW') {
                                // firstVisit.bookingIDsに該当するbooking._idを削除
                                await db.collection("visits").updateOne(
                                    { _id: firstVisit._id },
                                    { $pull: { bookingIDs: booking._id } }
                                )
                                // PET-CTの場合、ivCheckinを削除
                                if (booking.modalityID === 'PET-CT_1' || booking.modalityID === 'PET-CT_2') {
                                    await db.collection("visits").updateOne(
                                        { _id: firstVisit._id },
                                        { $unset: { ivCheckin: "" } }
                                    )
                                }

                            // 予約が2つかつINTERVIEWの場合、visitを残す
                            } else if (firstVisit.bookingIDs.length === 2 && booking.modalityID === 'INTERVIEW') {
                                // firstVisit.bookingIDsに該当するbooking._idを削除
                                await db.collection("visits").updateOne(
                                    { _id: firstVisit._id },
                                    { $pull: { bookingIDs: booking._id } }
                                )
                            } else {
                                // visitsおよびbookingsを削除
                                await db.collection("visits").deleteOne({ _id: firstVisit._id })
                                for (const bookingID of firstVisit.bookingIDs) {
                                    await db.collection("bookings").deleteOne({ _id: bookingID })
                                }
                            }
                        }
                    }
                    await deleteBooking(booking)

                    break;
                case BOOKED_STATUS:
                    // bookingsの更新対応
                    updateBookingData.bookingStatus = BOOKED_STATUS
                    
                    bookingdata = await db?.collection("bookings").updateOne(
                        { _id: id },
                        { $set: updateBookingData }
                    )
                    console.log(bookingdata)

                    // 外部予約の場合、確定されたらメール送信
                    if (booking.bookingType === OUTER_BOOK_TYPE) {
                        const toExternals: string[] = []
                        const external = await client.query<FIND_EXTERNAL_DOCTOR_USER_RESULT>({
                            query: FIND_EXTERNAL_DOCTOR_USER,
                            variables: { id: booking.doctorID },
                            fetchPolicy: 'network-only',
                        })
                        if (external?.data.user) {
                            toExternals.push(external?.data.user.email)
                        }

                        const toInternals: string[] = []
                        // 院内(通知設定しているユーザー)
                        const internal = await client.query({ query: FIND_NOTIFICATION_USERS, fetchPolicy: 'network-only', })
                        if (internal?.data.users && Array.isArray(internal.data.users)) {
                            for (const user of internal?.data.users) {
                                toInternals.push(user.email)
                            }
                        }

                        // メールパラメータ設定
                        let emailModality: any = {}
                        emailModality['execTimeRange'] = `${convertDate2Time(booking.start as Date)} ~ ${convertDate2Time(booking.end as Date)}`
                        emailModality['id'] = booking.modalityID
                        emailModality['body2'] = booking.body2
                        emailModality['contrast'] = booking.contrast
                        emailModality['weight'] = booking.weight

                        let hospitals: any = {}
                        hospitals["clinic_name"] = booking.clinicName
                        hospitals["doctor_name"] = booking.doctor
                        hospitals["department"] = booking.department

                        const params: KV = {
                            doctor: booking.doctor as string,
                            bookNo: booking._id as string,
                            bookDate: convertDate(new Date(booking.start || new Date()) as Date),
                            receptTime: booking.checkin as string,
                        }

                        await sendFixToExternal(toExternals, params, emailModality)
                        await sendFixToInternal(toInternals, params, emailModality, hospitals)

                        // 種別を「予約確定」から「外注」に変更
                        booking.bookingType = INTERNAL_TYPE
                    }

                    // visitsの更新対応
                    if (db && booking.visitID) {
                        const updateVisitData: Visit = {
                            clinicID: booking.clinicID,
                            clinicName: booking.clinicName,
                            department: booking.department,
                            doctor: booking.doctor,
                            updated: new Date(),
                        }
                        const visitdata = await db.collection("visits").updateOne(
                            { _id: visitID },
                            { $set: updateVisitData }
                        )
                        console.log(visitdata)
                    }

                    break;
                
                case UPDATEONLY:
                    // bookingsの更新対応
                    bookingdata = await db?.collection("bookings").updateOne(
                        { _id: id },
                        { $set: updateBookingData }
                    )
                    console.log(bookingdata)
                    // visitsの更新対応
                    if (db && booking.visitID) {
                        console.log(visitID);

                        const updateVisitData: Visit = {
                            bookingType: booking.bookingType,
                            clinicID: booking.clinicID,
                            clinicName: booking.clinicName,
                            comment: booking.comment,
                            department: booking.department,
                            doctor: booking.doctor,
                            start: booking.start && booking.checkin ? getCheckinTime(booking.start, booking.checkin) : undefined,
                            end: booking.end ? new Date(booking.end) : undefined,
                            diagnosis: booking.diagnosis,
                            files: booking.files,
                            updated: new Date(),
                        }
                        const visitdata = await db.collection("visits").updateOne(
                            { _id: String(visitID) },
                            { $set: updateVisitData }
                        )
                        console.log(visitdata)
                    }
                    break;
                default:
                    await updateBooking(id, booking)
                    break;
            }
            setProcessing(false)
            await showMessage(`予約を${status === CANCEL_STATUS ? "取り消" : status === BOOKED_STATUS ? "確定" : "更新"}しました。\r\n`)
            navigate("/booking")
        } catch (e) {
            setProcessing(false)
            if (e instanceof Error) await showMessage(`予約の書込処理エラーが発生しました。\r\n${e.message}`, { error: true })
        }
    }

    return <div className="p-8 bg-theme-800">
        {(loading || processing) && <Loading full />}
        <div className="flex">
            <span className="text-white">アクセッション番号：{accessionNo}</span>
        </div>
        <div className="text-white underline flex items-center"><FcApproval />患者情報</div>
        <FormPatients
            bookingId={id}
            patientId={patientId}
            visitId={visitID}
            setPatientId={setPatientId}
            patientName={patientName}
            setPatientName={setPatientName}
            patientNameKana={patientNameKana}
            setPatientKana={setPatientKana}
            setBirthDate={setBirthDate}
            gender={gender}
            setGender={setGender}
            tel={tel}
            setTel={setTel}
            isUpdated={true}
            setIsTemp={setIsTemp}
        />
        <hr className="ml-2" />
        {!DOCK_TYPES.includes(booking.bookingType || 0) ? <>
            <div className="text-white mt-6 underline flex items-center"><FcApproval />紹介先情報</div>
            <FormClinics
                clinicId={clinicId}
                setClinicId={setClinicId}
                setClinicName={setClinicName}
                bookType={bookType}
                setBookType={setBookType}
                department={department}
                setDepartment={setDepartment}
                doctor={doctor}
                setDoctor={setDoctor}
            />
        </>
            : <></>
        }
        <hr className="ml-2" />
        <div className="text-white mt-6 underline flex items-center"><FcApproval />検査情報</div>
        <FormModality
            selectResourceInformation={resourceInformation}
            secondResourceInformation={{}}
            modalities={[modalityId]}
            modality={modalityId}
            setModality={setModalityId}
            body1={body1}
            setBody1={setBody1}
            body2={body2}
            setBody2={setBody2}
            contrast={contrast}
            setContrast={setContrast}
            setSecondModalityData={setSecondModalityData}
            delay={delay}
            setDelay={setDelay}
            starttime={start}
            endtime={end}
            setEnd={setEnd}
            isUpdated={true}
            checkin={checkin}
            setCheckin={setCheckin}
        />
        <hr className="ml-2" />
        <FormBookInformation
            starttime={start}
            setStartTime={modalities.includes(modalityId) ? setStart : undefined}
            setEndTime={modalities.includes(modalityId) ? setEnd : undefined}
            walkingStatus={walkingStatus}
            setWalkingStatus={setWalkingStatus}
            checkin={checkin}
            setCheckin={setCheckin}
            comment={comment}
            setComment={setComment}
            diagnosis={diagnosis}
            setDiagnosis={setDiagnosis}
            bookingType={booking.bookingType}
            modality={modalityId}
        />
        <hr className="ml-2" />
        {!DOCK_TYPES.includes(booking.bookingType || 0) && !nonScheduleModality.includes(modalityId) ? <>
            <div className="text-white mt-6 underline flex items-center"><FcApproval />報告情報</div>
            <FormReport
                due={due}
                setDue={setDue}
                image={image}
                setImage={setImage}
                reportSubmission={reportSubmission}
                setReportSubmission={setReportSubmission}
            />
        </> : <></>
        }
        <hr className="ml-2" />
        {errorMessage !== "" ? <div className={errorMessageClass}>入力項目が不足しています。<br /> {errorMessage}</div> : <span />}
        <div className="flex my-4 justify-center">
            <Link to="/booking" className={GrayButtonClass}>戻る</Link>
            <button data-status={CANCEL_STATUS} className={RedButtonClass} onClick={handleClick}>予約取消</button>
            <button data-status={7} className={ButtonClass} onClick={handleClick}>{bookingStatus === PRE_BOOK_STATUS && "仮"}予約更新</button>
            {bookingStatus === PRE_BOOK_STATUS ? <button data-status={BOOKED_STATUS} className={FixButtonClass} onClick={handleClick}>予約確定</button> : <span />}
        </div>
    </div>
}

export default Edit