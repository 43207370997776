import { EventContentArg } from "@fullcalendar/core"
import React from "react"

const OpeningEventContent = (eventContent: EventContentArg) => {
  const gray = eventContent.event.extendedProps.close || !eventContent.event.extendedProps.week
  return <div className={gray ? `fc-event-main-frame text-white bg-gray-500` : `fc-event-main-frame text-white bg-purple-300 h-full`}>
    <div className="fc-event-title-container">
      <div className="px-2">{eventContent.event.extendedProps.close ? <span>クローズ</span> : <span>{eventContent.event.title}</span>}</div>
    </div>
  </div>
}

export default OpeningEventContent
