import { FcApproval } from "react-icons/fc"
import { areaClass, CheckClass, InputClass, modalBasicIndent } from "contexts/style"
import React, { ChangeEvent, ForwardedRef, useEffect, useState } from "react"
import { getDateString } from "contexts/dateUtils"
import { nonScheduleModality } from "contexts/dockCourses"
import FileChooser, { FileError, FileHandler } from "./FormFile"
import { DOCK_TYPES } from "contexts/enviroments"

export interface FormBookInformationParams {
    starttime: Date, setStartTime?: React.Dispatch<React.SetStateAction<Date>>,
    setEndTime?: React.Dispatch<React.SetStateAction<Date>>,
    walkingStatus: string, setWalkingStatus: React.Dispatch<React.SetStateAction<string>>,
    checkin: string, setCheckin: React.Dispatch<React.SetStateAction<string>>,
    comment: string, setComment: React.Dispatch<React.SetStateAction<string>>,
    diagnosis: string, setDiagnosis: React.Dispatch<React.SetStateAction<string>>,
    bookingType: number | undefined,
    modality: string,
    files?: FileItem[],
    fileChooserRef?: ForwardedRef<FileHandler>,
    fileSaveCallback?: (files: FileItem[], error: FileError[]) => void
}
/**
 * FormBookInformation
 * 予約情報
 */
export const FormBookInformation = ({
    starttime, setStartTime, setEndTime, walkingStatus, setWalkingStatus,
    checkin, setCheckin,
    comment, setComment,
    diagnosis, setDiagnosis,
    bookingType, modality,
    files, fileChooserRef, fileSaveCallback
}: FormBookInformationParams) => {

    const [receptionHour, setReceptionHour] = useState<string>('08')
    const [receptionMin, setReceptionMin] = useState<string>('00')

    useEffect(() => {
        console.log(checkin)
        const parts = checkin.split(':')
        const zeroPadded = parts.map(part => {
            return part.padStart(2, '0')
        })
        setReceptionHour(zeroPadded[0])
        setReceptionMin(zeroPadded[1])

    }, [checkin])

    const updateReceptionHour = (e: any) => {
        setCheckin(`${e.target.value}:${receptionMin}`)
    }

    const updateReceptionMin = (e: any) => {
        setCheckin(`${receptionHour}:${e.target.value}`)
    }

    const updateWalkingStatus = (e: any) => {
        setWalkingStatus(e.target.value)
    }

    const updateComment = (e: any) => {
        setComment(e.target.value)
    }

    const updateDiagnosis = (e: any) => {
        setDiagnosis(e.target.value)
    }

    const FormReceptTimes = () => {
        return <span className="flex">
            <select id="reception_time" name="reception_time" onChange={(e) => updateReceptionHour(e)} value={receptionHour}
                className={InputClass}>
                {Array.from({ length: 12 }, (_, index) => {
                    const hour = index + 6
                    const formattedHour = hour < 10 ? `0${hour}` : `${hour}`
                    return <option key={formattedHour} value={formattedHour}>{formattedHour}</option>
                })}
            </select>
            <select id="reception_time" name="reception_time" onChange={(e) => updateReceptionMin(e)} value={receptionMin}
                className={InputClass}>
                {Array.from({ length: 12 }, (_, index) => {
                    const minute = index * 5
                    const formattedMinute = minute < 10 ? `0${minute}` : `${minute}`
                    return <option key={formattedMinute} value={formattedMinute}>{formattedMinute}</option>
                })}
            </select>
        </span>
    }

    const starttimeString = () => {
        const year = starttime.getFullYear()
        const month = starttime.getMonth() + 1
        const date = starttime.getDate()
        const hour = String(starttime.getHours()).padStart(2, '0')
        const minutes = String(starttime.getMinutes()).padStart(2, '0')
        return `${year}年${month}月${date}日 ${hour}時${minutes}分`
    }

    const updateStart = (e: ChangeEvent<HTMLInputElement>) => {
        let date = new Date(e.currentTarget.value)
        date.setHours(0, 0, 0, 0)
        setStartTime && setStartTime(date)
        setEndTime && setEndTime(date)
    }

    return <>
        <div className={modalBasicIndent}><FcApproval />予約情報</div>
        <div className="p-3">
            <div className="grid grid-cols-9">
                {setStartTime ? <div className="col-span-2">
                    <span className="text-white w-36 block">予約日</span>
                    <span className="w-36">
                        <input className={InputClass} min={getDateString()} type="date" name="date"
                            value={getDateString(starttime)} onChange={(e) => updateStart(e)} />
                    </span>
                </div>
                    :
                    <div className="col-span-2">
                        <div className="col-span-1">
                            <span className="text-white font-bold block">受付時間</span>
                            <FormReceptTimes />
                        </div>
                        <span className="mt-3 text-white font-bold block">モダリティ開始時刻</span>
                        <span className="text-white">{starttimeString()}</span>
                    </div>
                }
                {!DOCK_TYPES.includes(bookingType || 0) && <>

                    {!nonScheduleModality.includes(modality) &&
                        <div className="col-span-2">
                            <span className="text-white block">歩行状況</span>
                            <div>
                                <input type="radio" checked={walkingStatus === '独歩'} id="walkingStatus1"
                                    name="walkingStatus" value="独歩" className={CheckClass}
                                    onChange={(e) => updateWalkingStatus(e)} />
                                <label htmlFor="独歩" className="ml-2 text-white">独歩</label>
                            </div>
                            <div>
                                <input type="radio" checked={walkingStatus === '車椅子'} id="walkingStatus2"
                                    name="walkingStatus" value="車椅子" className={CheckClass}
                                    onChange={(e) => updateWalkingStatus(e)} />
                                <label htmlFor="車椅子" className="ml-2 text-white">車椅子</label>
                            </div>
                            <div>
                                <input type="radio" checked={walkingStatus === '杖'} id="walkingStatus3"
                                    name="walkingStatus" value="杖" className={CheckClass}
                                    onChange={(e) => updateWalkingStatus(e)} />
                                <label htmlFor="杖" className="ml-2 text-white">杖</label>
                            </div>
                            <div>
                                <input type="radio" checked={walkingStatus === '歩行器'} id="walkingStatus4"
                                    name="walkingStatus" value="歩行器" className={CheckClass}
                                    onChange={(e) => updateWalkingStatus(e)} />
                                <label htmlFor="歩行器" className="ml-2 text-white">歩行器</label>
                            </div>
                        </div>
                    }
                </>
                }

                {!nonScheduleModality.includes(modality) &&
                    <div className="col-span-4">
                        <div className="flex items-center mb-4">
                            <span className="text-white ">
                                臨床診断名
                            </span>
                            <span className="ml-5">
                                <input id="diagnosis" name="diagnosis" className={InputClass} type="text" placeholder="臨床診断名" value={diagnosis}
                                    onChange={(e) => updateDiagnosis(e)} />
                            </span>
                        </div>
                        <FileChooser ref={fileChooserRef} files={files} callback={fileSaveCallback} />
                        <span className="text-white ">
                            コメント・メモ欄
                        </span>
                        <textarea value={comment} rows={4} onChange={(e) => updateComment(e)}
                            className={areaClass}></textarea>
                    </div>
                }
            </div>
        </div>
    </>
}