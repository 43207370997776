import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react'
import { ApolloClient, gql, useApolloClient, useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { ArrowPathIcon, ChevronLeftIcon, ChevronRightIcon, TrashIcon } from '@heroicons/react/24/solid';
import { CheckClass, getStatusColor, IconClass, SmallButtonClass } from 'contexts/style';
import options from "contexts/options.json"
import { getAge, JSTDate, JSTTime, nextMonth, today } from 'contexts/dateUtils';
import { Loading } from 'components/Loading';
import { useRealmApp } from 'contexts/RealmApp';
import { filterStates } from 'graphql/RealmApolloProvider';
import { DatePicker, FilterInput } from 'components/UIParts';
import showMessage from 'components/showMessage';
import { useExternalBookDate } from "contexts/ExternalBookDateContext"
import { FIND_EXTERNAL_DOCTOR_USER, FIND_EXTERNAL_DOCTOR_USER_RESULT, FIND_NOTIFICATION_USERS, viewDate } from 'components/UpdateForm';
import { sendCancelToExternal, sendCancelToInternal } from 'mail/sendCancel';
import { convertDate, convertDate2Time } from "contexts/functions"
//        makePdf(data.attendances, yearMonth, `${user?.surname || ""}　${user?.givenName || ""}`)

const heads = [
    { field: "bookingStatus", text: "ステータス" },
    { field: "start", text: "日付" },
    { field: "_id", text: "予約番号" },
    { field: "checkin", text: "受付時間" },
    { field: "start", text: "開始時間" },
    { field: "end", text: "終了時間" },
    { field: "modalityID", text: "モダリティ" },
    { field: "body2", text: "部位" },
    { field: "contrast", text: "造影剤" },
    { field: "patientName", text: "患者名" },
    { field: "patient.nameKana", text: "患者名カナ" },
    { field: "patient.gender", text: "性別" },
    { field: "patient.birthDate", text: "生年月日" },
    { field: "patient.birthDate", text: "年齢" },
    {}
]
const statusSet = [
    { key: 0, value: "仮予約" },
    { key: 1, value: "予約確定" },
    { key: 4, value: "実施済" },
    { key: 5, value: "来院取消" },
]

const bookingsData = gql`query GetBookingsForExrernal($query: BookingQueryInput!) {
    bookings(query: $query, sortBy: START_ASC) {
      _id
      bookingStatus
      start
      checkin
      end
      modalityID
      bookingType
      courseName
      body1
      body2
      height
      weight
      contrast
      patientID
      doctorID
      weight
      created
      patientName
      patient {
          name
          nameKana
          birthDate
          gender
      }
      clinicName
      department
      doctor
    }
  }
`

const updatingData = gql`mutation UpdateBookingStatus($id: String!) {
    updatedData: updateOneBooking(set: { bookingStatus: 5 }, query: { _id: $id }) {
        _id
      bookingStatus
      start
      checkin
      end
      modalityID
      bookingType
      courseName
      body1
      body2
      height
      weight
      contrast
      patientID
      patientName
      patient {
          name
          nameKana
          birthDate
          gender
      }
      clinicName
      department
      doctor
    }
}`

const getQueryVariables = (values: KV, doctorID?:string) => {
    let output: KV ={ query: { start_gte: values.start, end_lte: values.end } }
    if (values.patientName) output.query.patientName = values.patientName
    if (doctorID) output.query.doctorID =doctorID
    return output
}

const sendEmail = async (client: ApolloClient<object>, booking: KV) => {
    try {
            // メールパラメータ設定
        let emailModality: any = {}
        emailModality['execTimeRange'] = `${convertDate2Time(new Date(booking.start || new Date()))} ~ ${convertDate2Time(new Date(booking.end || new Date()))}`
        emailModality['id'] = booking.modalityID
        emailModality['body2'] = booking.body2
        emailModality['contrast'] = booking.contrast

        let hospitals: any = {}
        hospitals["clinic_name"] = booking.clinicName
        hospitals["doctor_name"] = booking.doctor
        hospitals["department"] = booking.department

        const toExternals: string[] = []
        const toInternals: string[] = []

        const external = await client.query<FIND_EXTERNAL_DOCTOR_USER_RESULT>({
            query: FIND_EXTERNAL_DOCTOR_USER,
            variables: {id: booking.doctorID},
            fetchPolicy: 'network-only',
        })
        if (external?.data.user) {
            toExternals.push(external?.data.user.email)
        }
        const internal = await client.query({query: FIND_NOTIFICATION_USERS, fetchPolicy: 'network-only',})
        if (internal?.data.users && Array.isArray(internal.data.users)) {
            for (const user of internal?.data.users) {
                toInternals.push(user.email)
            }
        }

        const params: KV = {
            doctor: booking.doctor as string,
            bookNo: booking._id as string,
            bookDate: convertDate(new Date(booking.start || new Date()) as Date),
            receptTime: booking.checkin as string,
            weight: booking.weight,
        }

        await sendCancelToExternal(toExternals, params, emailModality)
        await sendCancelToInternal(toInternals, params, emailModality, hospitals)
        await showMessage("予約が取り消されました。", {keep:true})

    } catch (e) {
        if (e instanceof Error) await showMessage(`確認メールの送信中にエラーが発生しました。\r\n取消処理は正常に終了しています。`, {error: true})
    }
}



export const List = () => {
    const app = useRealmApp()
    const client = useApolloClient()
    const todayDate = today()
    const userID = app.currentUser?.id
    const noslotResources = app.resources.filter(v => v.noslot).map(v => v.id as string)
    const values: KV = useReactiveVar(filterStates)
    const filter = values.externalBookingList || { start: todayDate, end: nextMonth(todayDate, 1, true), oneday: false, patientName: "", statuses: [0, 1, 2, 3, 4, 5] }
    const { loading, data, refetch } = useQuery(bookingsData, { variables:getQueryVariables(filter, userID), notifyOnNetworkStatusChange: true,})
    const [ processing, setProcessing ] = useState(false)
    const { externalBookDate } = useExternalBookDate()
    const cancelLimit = todayDate.getTime() + (todayDate.getDay() === 0 ? 4 : todayDate.getDay() < 3 ? 3 : 5) * 86400000
    useEffect(() => {
        const fetchBookDate = async () => {
            const defaultDate = new Date(externalBookDate);
            defaultDate.setHours(0, 0, 0, 0);
            filterStates({ ...filterStates(), externalBookingList: { ...filter, start: defaultDate, end: filter.oneday ? new Date(defaultDate.getTime() + 86399999) : filter.end } })
        }
        fetchBookDate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalBookDate])

    const [cancelDataMutation, { loading: deleting }] = useMutation(updatingData);

    const handleDateChange = (name: string, value: any) => {
        switch (name) {
            case "startDate":
                const dateStart = new Date(value)
                filterStates({ ...filterStates(), externalBookingList: { ...filter, start: dateStart, end: filter.oneday ? new Date(dateStart.getTime() + 86399999) : filter.end } })
                break
            case "endDate":
                setValue("end", new Date(value.getTime() + 86399999))
                break
            default:

        }
    }
    const handleDateMove = (e: MouseEvent<HTMLButtonElement>) => {
        let dateStart = filter.start
        let dateEnd = filter.end
        let oneday = filter.oneday
        switch (e.currentTarget.name) {
            case "previousDay":
                dateStart = new Date(dateStart.getTime() - 86400000)
                dateEnd = new Date(dateEnd.getTime() - 86400000)
                break
            case "today":
                dateStart = todayDate
                dateEnd = new Date(todayDate.getTime() + 86399999)
                break
            case "nextDay":
                dateStart = new Date(dateStart.getTime() + 86400000)
                dateEnd = new Date(dateEnd.getTime() + 86400000)
                break
            case "oneday":
                oneday = !oneday
                if (oneday) {
                    dateEnd = new Date(dateStart.getTime() + 86399999)
                }
                break
            default:

        }
        filterStates({ ...filterStates(), externalBookingList: { ...filter, oneday: oneday, start: dateStart, end: dateEnd } })
    }

    const setValue = (name: string, value: any) => {
        filterStates({ ...filterStates(), externalBookingList: { ...filter, [name]: value } })
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (e.currentTarget.name.startsWith("status_")) {
            let statuses: number[] = [];
            [0, 1, 4, 5].forEach(key => {
                if ((document.getElementById(`status_${key}`) as HTMLInputElement).checked) {
                    statuses.push(key)
                    if (key === 1) {
                        statuses.push(2)
                        statuses.push(3)
                    }
                }
            })
            setValue("statuses", statuses)
        } else {
            setValue(e.currentTarget.name, e.currentTarget.value)
        }
    }

    const handleDelete = async (e: MouseEvent<HTMLButtonElement>) => {
        const id = e.currentTarget.value
        const booking = await data?.bookings?.find((datum:KV) => datum._id === id)
        e.preventDefault()
        e.stopPropagation()
        if (todayDate < today()) {
            await showMessage("この予約情報は前日以前に取得されたもののため、このページを再読み込みしてから削除してください", {keep:true})
            return null
        }
        if (!await showMessage(`受付No：${booking._id}\n予約日時：${viewDate(new Date(booking.start || 0))}\nモダリティ：${booking.modalityID}\n部位：${booking.body2}\n\n予約を取消してよろしいですか？`, {confirm: true})) {
            return null
        }
        try {
            setProcessing(true)
            await cancelDataMutation({ variables: { id: id } })
            await sendEmail(client, booking)
            setProcessing(false)
        //            await refresh()
        } catch (e) {
            setProcessing(false)
            if (e instanceof Error) await showMessage(`エラー：予約を削除中にエラーが発生しました。\r\n${e.message}`, {error:true})
        }
    }

    // Filter by bookingStatus and if bookingStatus is 2 or 3, change to 1
    const showingData = [...(data?.bookings || [])].filter(datum => filter.statuses.includes(datum.bookingStatus)).map(datum => ({...datum, bookingStatus: [2,3].includes(Number(datum.bookingStatus)) ? 1 : datum.bookingStatus}))

    return <>
        <div className="relative h-full grid grid-rows-list">
            <div className="bg-theme-200 p-2 relative">
                <div className="flex flex-wrap justify-center items-center gap-2">
                    <button className={IconClass} type="button" onClick={() => refetch()}><ArrowPathIcon /></button>
                    <button type="button" className={SmallButtonClass} name="previousDay" onClick={handleDateMove}><ChevronLeftIcon className="w-5 h-5" /></button>
                    <DatePicker name="startDate" value={filter.start} setValue={handleDateChange} />
                    {!filter.oneday && <>
                        <span className="">〜</span>
                        <DatePicker name="endDate" value={filter.end} setValue={handleDateChange} />
                    </>}
                    <button type="button" className={SmallButtonClass} name="nextDay" onClick={handleDateMove}><ChevronRightIcon className="w-5 h-5" /></button>
                    <button type="button" className={SmallButtonClass} name="oneday" onClick={handleDateMove}>{filter.oneday ? "複数日" : "単日"}</button>
                    <button type="button" className={SmallButtonClass} name="today" onClick={handleDateMove}>本日</button>
                    <FilterInput label="患者名" name="patientName" value={filter.patientName} setValue={setValue} />
                    <div className="flex items-center gap-2">{statusSet.map(status => <React.Fragment key={status.key}><input className={CheckClass} type="checkbox" name={`status_${status.key}`} id={`status_${status.key}`} checked={Boolean(filter.statuses.includes(status.key))} onChange={handleChange} /><label className="pr-4 whitespace-nowrap">{status.value}</label></React.Fragment>)}</div>
                </div>
                <div className="absolute bottom-2 right-2">{data && `${showingData.length} 件` }</div>
            </div>
            <div className="bg-white"></div>
            <div className="px-2 pb-2 h-full overflow-scroll flex flex-col z-0">
                {(loading || deleting || processing) ? <Loading full/> : 
                    <table className="mx-auto border-b border-gray-200 sm:rounded-b-md text-center text-sm text-gray-500">
                        <thead>
                            <tr>
                                {heads.map((head, i) => (
                                    <th
                                        key={(head.field || "") + i}
                                        data-name={head.field}
                                        data-value={i}
                                        scope="col"
                                    >
                                        {head.text}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {data && showingData.map((datum) => (
                                    <tr key={datum._id} data-id={datum._id} data-detail="detail" className={`text-sm text-gray-900`}>
                                        <td className={`text-white bg-${getStatusColor(datum.bookingStatus)}`}>{options.bookingStatus[datum.bookingStatus]}</td>
                                        <td>{JSTDate(new Date(datum.start))}</td>
                                        <td>{datum._id}</td>
                                        <td>{datum.checkin}</td>
                                        <td>{noslotResources.includes(datum.modalityID) ? "" : JSTTime(new Date(datum.start))}</td>
                                        <td>{noslotResources.includes(datum.modalityID) ? "" : JSTTime(new Date(datum.end))}</td>
                                        <td>{datum.modalityID.replace(/_\d*$/, '')}</td>
                                        <td>{datum.body2 || ""}</td>
                                        <td>{(datum.contrast === true || datum.contrast === "造影") ? "◯" : ""}</td>
                                        <td>{datum.patientName}</td>
                                        <td>{datum.patient?.nameKana || ''}</td>
                                        <td>{datum.patient?.gender ? options.genderType[datum.patient.gender] : ""}</td>
                                        <td>{datum.patient?.birthDate && JSTDate(new Date(datum.patient?.birthDate))}</td>
                                        <td>{datum.patient?.birthDate && getAge(new Date(datum.patient?.birthDate))}</td>
                                        <td className='flex items-center'>{ new Date(datum.start).getTime() > cancelLimit && <button className='text-theme-600' type="button" value={datum._id} onClick={handleDelete}><TrashIcon className='w-5 h-5' /></button>}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    </>
}

export default List