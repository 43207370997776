import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import jaLocale from "@fullcalendar/core/locales/ja"
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid"
import scrollGridPlugin from "@fullcalendar/scrollgrid"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import EventContent from "components/EventContent"
import { DatesSetArg, EventDropArg } from "@fullcalendar/core"
import FullCalendar from "@fullcalendar/react"
import { areaClass, getColor, GrayButtonClass, InputClass, LightGrayButtonClass, YellowButtonClass } from "contexts/style"
import { useSearchParams } from 'react-router-dom'
import { Loading } from "components/Loading"
import options from "contexts/options.json"
import useDocks from "graphql/useDocks"
import { compareTimes } from "contexts/dateUtils"
import { parseModalityAll } from "contexts/dockCourses"
import { useFindBookings } from "contexts/useMongoQuery"
import { useMongoDB } from "contexts/MongoDBContext"
import { useRealmApp } from "contexts/RealmApp"
import { nonScheduleModality } from "contexts/dockCourses"
import useBookingsMutations from "graphql/useBookingsMutations"
import useVisitsMutations from "graphql/useVisitsMutations"
import { useNavigate } from "react-router-dom"
import { getSequenceNo } from "graphql/useSequenceFindOneAndUpdate"
import { useUpdateDock } from "graphql/useDocksMutations"
import { PRE_BOOK_STATUS, BEGIN_STATUS, CHECKOUT_STATUS, PetIDs, checkPetTimeForDock, DOCK_TYPE, CMC_TYPE, OUTER_BOOK_TYPE } from "contexts/enviroments"
import { setStartAndEnd, setOptimizeSchedule, getModalityEvents, duplicateCheck } from "components/dockUtils"
import showMessage from "components/showMessage"
import { useApolloClient } from "@apollo/client"

const modifyEmptyIfNull = (data: any) => (data) ? data : ""

let course_contents: KV[] = []
let one_day_modalities: KV[] = []
// ドックコースの優先獣医を制御
const priority = ["MMG", "ABUS", "MRI", "US", "US(L)", "XP", "ES", "DEXA", "PET-CT"]
let convertPet = {
  "PET-CT": "PET-CT_1"
}
let convertPet2 = {
  "PET-CT": "PET-CT_2"
}

const PATIENT = ({ dock, birthdate }: { dock: KV, birthdate: string }) => {
  // 患者情報を表示
  return <div className="p-5">
    <table className="w-full mx-auto border-b border-gray-200 sm:rounded-b-md text-center text-sm text-gray-500">
      <thead>
        <tr>
          <th>患者ID</th>
          <th>患者名</th>
          <th>患者カナ</th>
          <th>性別</th>
          <th>生年月日</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        <tr className={`text-sm text-gray-900 cursor-pointer hover:bg-theme-50`}>
          <td>{dock["patient_id"]}</td>
          <td>{dock["patient_name"]}</td>
          <td>{dock["patient_name_kana"]}</td>
          <td>{options.genderType[dock["gender"]]}</td>
          <td>{birthdate}</td>
        </tr>
      </tbody>
    </table>
  </div>
}

const INFORMATION = ({ dock, memo, setMemo }: { dock: KV, memo: string, setMemo: any }) => {
  // 受信内容を表示
  const updateComment = (e: any) => setMemo(e.target.value)
  return <div className="p-5">
    <div className="grid grid-cols-2 mt-2">
      <span className="border-b-2 grid grid-cols-2">
        <span className="">親予約番号</span>
        <span className="">{dock["book_parent_id"]}</span>
      </span>
      <span className="border-b-2 grid grid-cols-2 ml-10">
        <span className="">子予約番号</span>
        <span className="">{dock["book_sub_id"]}</span>
      </span>
    </div>
    <div className="grid grid-cols-2 mt-2">
      <span className="border-b-2 grid grid-cols-2">
        <span className="">受信希望日</span>
        <span className="">{dock["reception_date"]}</span>
      </span>
      <span className="border-b-2 grid grid-cols-2 ml-10">
        <span className="">受付希望時間</span>
        <span className="">{dock["reception_time"]}</span>
      </span>
    </div>
    <div className="grid grid-cols-2 mt-2">
      <span className="border-b-2 grid grid-cols-2">
        <span className="">種別</span>
        <span className="">{dock["course_type"]}</span>
      </span>
      <span className="border-b-2 grid grid-cols-2 ml-10">
        <span className="">コース名</span>
        <span className="">{dock["course_name"]}</span>
      </span>
    </div>
    <div className="grid grid-cols-2 mt-2">
      <span className="border-b-2 grid grid-cols-2">
        <span className="">予約ステータス</span>
        <span className="">
          {dock["book_status"] === 1 ? <>未確定</> : <></>}
          {dock["book_status"] === 2 ? <>変更中</> : <></>}
          {dock["book_status"] === 3 ? <>仮予約</> : <></>}
          {dock["book_status"] === 4 ? <>本予約</> : <></>}
          {dock["book_status"] === 5 ? <>受付</> : <></>}
        </span>
      </span>
      <span className="border-b-2 grid grid-cols-2 ml-10">
        <span className="">ステータス区分</span>
        <span className="">{dock["update_status"] === 0 ? <>新規登録</> : <>更新</>}</span>
      </span>
    </div>
    <div className="grid grid-cols-2 mt-2">
      <COURSE dock={dock} />
      <COURSE_OPTION dock={dock} />
    </div>
    <div className="grid grid-cols-[1fr_5fr] mt-2">
      <span className="">メモ</span>
      <textarea rows={4} className={areaClass} defaultValue={memo} onChange={(e) => updateComment(e)}></textarea>

    </div>
  </div>
}

const COURSE_OPTION = ({ dock }: { dock: KV }) => {
  // 追加オプションを表示
  return <div className="mt-2 border-b-2 ml-10">
    {!dock["options"] ? <div /> :
      <div>
        <table className="w-full mx-auto border-b border-gray-200 sm:rounded-b-md text-center text-sm text-gray-500">
          <thead>
            <tr>
              <th>オプション名</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">

            <>{dock["options"].map((key: KV) =>
              <tr key={key["option_name"]} className={`text-sm text-gray-900 cursor-pointer hover:bg-theme-50`}>
                <td>{key["option_name"]}</td>
              </tr>
            )}</>
          </tbody>
        </table>
      </div>
    }
  </div>
}

const COURSE = ({ dock }: { dock: KV }) => {
  // コース詳細の箇所を表示
  let courses: string[] = []
  dock["course_contents"].map((key: KV) => {
    let course_content = key["modality"]
    if (key["modality_body1"]) {
      course_content += ":" + key["modality_body1"]
    }
    if (key["modality_body2"]) {
      course_content += ":" + key["modality_body2"]
    }
    if (key["modality_contrast"]) {
      course_content += ":" + key["modality_contrast"]
    }
    courses.push(course_content)
    return courses
  })
  return <div className="grid grid-cols-[1fr_5fr] mt-2 border-b-2">
    <span>コース詳細</span>
    <div>
      <table className="w-full mx-auto border-b border-gray-200 sm:rounded-b-md text-center text-sm text-gray-500">
        <thead>
          <tr>
            <th>モダリティ</th>
            <th>部位詳細</th>
            <th>造影剤</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {!dock["course_contents"] ? <div /> :
            <>{courses.map((key: string) =>
              <tr key={key} className={`text-sm text-gray-900 cursor-pointer hover:bg-theme-50`}>
                <td>{key.split(":")[0]}</td>
                <td>{key.split(":")[1]}</td>
                <td>{key.split(":")[2]}</td>
              </tr>
            )}
            </>
          }
        </tbody>
      </table>
    </div>
  </div>
}


const DOCKCONTENTS = ({ dock, memo, setMemo, resources, birthdate, events, baseResources, updateStatus, receptionDate, setDateRange }:
  {
    dock: any, memo: string, setMemo: any, resources: { [key: string]: string }[], birthdate: string, events: any,
    baseResources: { [key: string]: string }[], updateStatus: Number, receptionDate: Date, setDateRange: any
  }
) => {

  const [timeUnit, setTimeUnit] = useState<string>("5")
  const [optimizeFlag, setOptimizeFlag] = useState<Boolean>(false)
  const [updateFlag, setUpdateFlag] = useState<Boolean>(false)
  const [addFlag, setAddFlag] = useState<Boolean>(false)
  const [dockEvents, setDockEvents] = useState<KV>({})
  const [monthView, setMonthView] = useState<boolean>(false)
  const [additionalModality, setAdditionalModality] = useState<string[]>([])

  const appContext = useRealmApp()
  const calendarRef = useRef<FullCalendar | null>(null)
  let modalityKey: KV = {}

  // カレンダーのイベントを更新
  const refreshCalendarEvent = async () => {
    const api = calendarRef?.current?.getApi()
    api?.removeAllEventSources()
    api?.addEventSource(events)
  }

  // カレンダーのイベントを追加
  const addCalendarEvent = async (event: CalendarEvent) => {
    const api = calendarRef?.current?.getApi()
    api?.addEvent(event)
  }

  // モダリティ、部位、造影剤から時間を取得
  const getModalityTime = (modality: string, body1: string, body2: string, contrast: string) => {
    if (modality === 'PET-CT') modality = convertPet['PET-CT']
    for (const idx in baseResources) {
      if (String(baseResources[idx]['id']) === modality) modalityKey = baseResources[idx]
    }

    if (contrast === "") contrast = "なし"
    if (body1 === "" || body1 === null || body1 === '-') body1 = "なし"
    if (body2 === "" || body2 === null || body2 === '-') body2 = "なし"
    console.log(`${modality} ${body2} ${contrast} Time: ${modalityKey["body"][body1][body2][contrast]}`)
    const modality_time = modalityKey["body"][body1][body2][contrast]

    return modality_time
  }

  // カレンダーイベントが更新される度に、処理を実行
  useEffect(() => {
    // 一旦、カレンダーのイベントを更新する
    // enents: Fullcalendar内のイベント
    // dock: Getパラメータで取得したdocksのドキュメント
    const fetchProps = async () => {
      const duration: KV = {}
      const modalities: string[] = []
      let updated: boolean = false
      const updateEvent = () => {
        for (const key in events) {
          if (events[Number(key)].extendedProps.dockID === String(dock["book_id"])) {
            const event = events[Number(key)]
            let modality = event.extendedProps.modalityID
            if (modality === 'PET-CT') modality = convertPet['PET-CT']
            const body1 = modifyEmptyIfNull(event.extendedProps.body1)
            const body2 = modifyEmptyIfNull(event.extendedProps.body2)
            const contrast = modifyEmptyIfNull(event.extendedProps.contrast)

            // 更新データは、予約枠に変更する
            event.title = "予約枠"
            event.extendedProps.bookingType = OUTER_BOOK_TYPE
            event.extendedProps.patientName += `(${body2})`

            // 更新のケースなので、modalityEventにセット
            duration[modality + body1 + body2 + contrast] = {
              "start": new Date(event.start),
              "end": new Date(event.end),
              "status": event.extendedProps.bookingStatus,
              "no_schedule": nonScheduleModality.includes(modality)
            }

            // TODO: 更新の場合、再計算を押したものとする
            setOptimizeFlag(true)

            // 情報更新
            setUpdateFlag(true)
            updated = true
            modalities.push(modality + body1 + body2 + contrast)
          }
        }
      }

      await updateEvent()

      // 更新の場合、追加されたモダリティを検出
      if (updated) {
        let additionalModality: string[] = []
        await course_contents.forEach((key: KV) => {
          let addModality = key["modality"]
          const body1 = modifyEmptyIfNull(key["modality_body1"])
          const body2 = modifyEmptyIfNull(key["modality_body2"])
          const contrast = modifyEmptyIfNull(key["modality_contrast"])
          let match: boolean = false
          // PETの場合、PET_CT1, PET_CT2を両方チェックする
          if (addModality === 'PET-CT') {
            addModality = convertPet['PET-CT']
            modalities.forEach((modalityKey: string) => {
              if (addModality + body1 + body2 + contrast === modalityKey) match = true
            })

            addModality = convertPet2['PET-CT']
            modalities.forEach((modalityKey: string) => {
              if (addModality + body1 + body2 + contrast === modalityKey) match = true
            })

            // 新規PETが追加されていた場合、初期値はPET1に寄せる
            if (!match) addModality = convertPet['PET-CT']
          } else {
            modalities.forEach((modalityKey: string) => {
              if (addModality + body1 + body2 + contrast === modalityKey) match = true
            })
          }

          if (!match) {
            additionalModality.push(addModality + body1 + body2 + contrast)
          }
        })
        setAdditionalModality(additionalModality)

        // 追加モダリティがなければ、追加ボタンは押された状態にする
        if (additionalModality.length === 0 && !addFlag) {
          await addModalitySchedule()
        }
      }
      setDockEvents(duration)
      await refreshCalendarEvent()
    }
    fetchProps()
  }, [events])

  const handleDatesSet = (arg: DatesSetArg) => {
    let startDate = new Date(arg.start)
    startDate.setHours(0)
    let endDate = new Date(arg.end)
    endDate.setHours(0)
    setMonthView(arg.view.type === "dayGridMonth")
    setDateRange({ start: startDate, end: endDate })
  }

  /**
   * 再計算ボタン、イベント最適化フェーズ
   * 「追加」ボタン押下後の処理
   */
  const addModalitySchedule = async () => {
    setAddFlag(true)
    let included: boolean = false
    let eventSchedule: any = { "start": undefined, "end": undefined }
    for (const key of course_contents) {
      let modality = key["modality"]
      if (modality === 'PET-CT') modality = convertPet['PET-CT']

      const body1 = modifyEmptyIfNull(key["modality_body1"])
      const body2 = modifyEmptyIfNull(key["modality_body2"])
      const contrast = modifyEmptyIfNull(key["modality_contrast"])

      if (additionalModality.includes(modality + body1 + body2 + contrast)) {
        included = true
        await showMessage(`モダリティ(${modality})、部位(${body2})が見つかりました。\r\n受付希望時間(${dock["reception_time"]})の30分後に設定したので、予約時刻を調整してください。`)
        console.log(`${modality} ${body1} ${body2} ${contrast}`)
        console.log(course_contents)
        // モダリティの予約枠を取得
        const modalityEvents = getModalityEvents(modality, events)

        // 必要なモダリティの時間を取得
        const modalityTime = getModalityTime(modality, body1, body2, contrast)

        // 受信時刻の30分後に設定
        const receptionTime = new Date(dock["reception_date"] + " " + dock["reception_time"] + ":00")
        receptionTime?.setMinutes(receptionTime.getMinutes() + 30)
        eventSchedule = setStartAndEnd(receptionTime, modalityTime)

        // Eventと重複している場合、データ修正
        eventSchedule = setOptimizeSchedule(eventSchedule, modalityEvents[modality], modalityTime)

        // 検出したスケジュールの開始時刻、終了時刻をセット
        dockEvents[modality + body1 + body2 + contrast] = {
          "start": eventSchedule.start,
          "end": eventSchedule.end,
          "status": 0,
          "no_schedule": nonScheduleModality.includes(modality)
        }
        setDockEvents(dockEvents)

        const tmpEvent: CalendarEvent = {
          id: modality,
          title: "予約枠",
          start: eventSchedule.start,
          end: eventSchedule.end,
          resourceId: modality,
          extendedProps: {
            id: modality,
            patientName: `${dock["patient_name"]}(${body2})`,
            patientID: dock["patient_id"],
            modalityID: modality,
            body1: body1,
            body2: body2,
            contrast: contrast,
            bookingMethod: 1, // WEB
            bookingStatus: 0,
            bookingType: OUTER_BOOK_TYPE, // 予約枠
            comment: dock["memo"],
            updated: new Date(),
            dockID: dock["book_id"],
            userID: appContext.currentUser?.id
          }
        }
        addCalendarEvent(tmpEvent)
      }

    }
    if (!included) {
      await showMessage(`追加されたモダリティはありません。`)
    }
  }

  // 再計算ボタンの処理
  const optimizeModalitySchedule = async () => {
    const duration: KV = {}
    let overtime: boolean = false
    let wait: boolean = true
    let first: boolean = true
    let prevSchedule: any

    if (!optimizeFlag) {
      for (const key of course_contents) {
        let modality = key["modality"]
        if (modality === 'PET-CT') modality = convertPet['PET-CT']
        const body1 = modifyEmptyIfNull(key["modality_body1"])
        const body2 = modifyEmptyIfNull(key["modality_body2"])
        const contrast = modifyEmptyIfNull(key["modality_contrast"])
        let eventSchedule: any

        // 必要なモダリティの時間を取得
        const modalityTime = getModalityTime(modality, body1, body2, contrast)

        // モダリティの予約枠を取得
        const modalityEvents = getModalityEvents(modality, events)

        // 空き時間を時間を検索
        if (first) {
          first = false
          // 受信時刻の30分後に設定
          const receptionTime = new Date(dock["reception_date"] + " " + dock["reception_time"] + ":00")
          receptionTime.setMinutes(receptionTime.getMinutes() + 30)

          // 初期値を設定
          eventSchedule = setStartAndEnd(receptionTime, modalityTime)
          // 既存イベントを考慮した設置可能な時刻を検索
          eventSchedule = setOptimizeSchedule(eventSchedule, modalityEvents[modality], modalityTime)

          prevSchedule = JSON.stringify(eventSchedule)
          prevSchedule = JSON.parse(prevSchedule)

          // 前回の予約があれば、その次に設定
        } else {
          // PETの場合、1時間加算(待機)する
          let prevEnd: Date = new Date(prevSchedule.end)
          if (key["modality"] === 'PET-CT' && wait) {
            prevEnd.setMinutes(prevEnd.getMinutes() + 60)
            wait = false
          }
          // 前イベントの次を設置
          eventSchedule = setStartAndEnd(prevEnd, modalityTime)
          // 既存イベントを考慮した設置可能な時刻を検索
          eventSchedule = setOptimizeSchedule(eventSchedule, modalityEvents[modality], modalityTime)

          prevSchedule = JSON.stringify(eventSchedule)
          prevSchedule = JSON.parse(prevSchedule)

        }

        // endが19時を過ぎていた場合、エラーメッセージを表示し、手動でスケジュールを決めてもらう。
        if (eventSchedule.end.getHours() > 19) {
          const lastTime = new Date(dock["reception_date"] + " 08:00:00")
          lastTime.setHours(17)
          eventSchedule = setStartAndEnd(lastTime, modalityTime)
          if (!overtime) {
            // アラート通知
            overtime = true
            await showMessage("自動スケジュールでは、就業時刻(19:00)を過ぎました。\r\n健診システムから別の日程を選択するか、モダリティの時刻を調整してください。", { error: true })
          }
        }

        // 検出したスケジュールの開始時刻、終了時刻をセット
        duration[modality + body1 + body2 + contrast] = {
          "start": eventSchedule.start,
          "end": eventSchedule.end,
          "status": 0,
          "no_schedule": nonScheduleModality.includes(modality)
        }
        setDockEvents(duration)

        let course_type = DOCK_TYPE;
        if (dock["course_type"] === "CMC" || dock["course_name"].includes("CMC")) {
            course_type = CMC_TYPE
        } else {
            course_type = DOCK_TYPE
        }

        const tmpEvent: CalendarEvent = {
          id: modality,
          title: "予約枠",
          start: eventSchedule.start,
          end: eventSchedule.end,
          resourceId: modality,
          extendedProps: {
            id: modality,
            patientName: `${dock["patient_name"]}(${body2})`,
            patientID: dock["patient_id"],
            modalityID: modality,
            body1: body1,
            body2: body2,
            contrast: contrast,
            bookingMethod: 1, // WEB
            bookingStatus: 0,
            bookingType: course_type, // 予約枠
            comment: dock["memo"],
            updated: new Date(),
            dockID: dock["book_id"],
            userID: appContext.currentUser?.id
          }
        }
        addCalendarEvent(tmpEvent)
      }
    } else {
      await showMessage("再計算は一度しか実行できません", { error: true })
    }
    setOptimizeFlag(true)
  }

  /**
   * Booking保存フェーズ
   */
  const navigate = useNavigate()
  const { db } = useMongoDB()
  const { addBookings, deleteBookingByDock } = useBookingsMutations()
  const { addVisit } = useVisitsMutations()
  const [updating, setUpdating] = React.useState<boolean>(false)
  const [savedBookings, setSavedBookings] = React.useState<any>([])

  const handleTimeUnitChange = (e: ChangeEvent<HTMLSelectElement>) => setTimeUnit(e.currentTarget.value)
  const getTimeUnit = (value: string) => `${value === "60" ? "01:00" : "00:" + ("0" + value).slice(-2)}:00`

  const updateDock = useUpdateDock()

  const saveDock = async (courses: KV, status: number) => {
    // AccessionIDを取得
    const seqNo = await getSequenceNo(db, Object.keys(courses).length)
    if (seqNo === undefined) {
      await showMessage("アクセッションNoの発番に失敗しました。ブラウザをリロードして予約を取り直してください", { error: true })
      return
    }
    const find_visit = await db?.collection("visits").findOne({ dockID: String(dock["book_id"]) });
    let visitID = '';
    if (find_visit) {
      visitID = find_visit._id;
    } else {
      visitID = String(seqNo + 1);
    }

    // 更新の場合、既存のdockIDは削除する、検査中、完了済みのものは除外
    const booking = { dockID: dock["book_id"] }
    deleteBookingByDock(booking).then(() => {
      let i: number = 0
      let ivTime: string = ''
      dock["course_contents"].forEach(async (key: { [key: string]: string }, idx: number, contents: KV[]) => {
        let modality = key["modality"]
        if (key["modality"] === 'PET-CT') modality = convertPet['PET-CT']
        const body1 = modifyEmptyIfNull(key["modality_body1"])
        const body2 = modifyEmptyIfNull(key["modality_body2"])
        const contrast = modifyEmptyIfNull(key["modality_contrast"])
        let start = new Date(dock["reception_date"])
        let end = new Date(dock["reception_date"])
        let skip = false

        if (key["modality"] === 'PET-CT') {
          if ('PET-CT_1' + body1 + body2 + contrast in dockEvents) {
            modality = 'PET-CT_1'
          }
          if ('PET-CT_2' + body1 + body2 + contrast in dockEvents) {
            modality = 'PET-CT_2'
          }
        }
        // 健診　modalityが"QST", "UCT"の場合、診療予約をする
        // QSTの場合、受付の10分後にデフォルトで問診(健診)10分間
        if (modality === "QST") {
          const [hours, minutes] = dock["reception_time"].split(":")
          start.setHours(hours, minutes)
          start.setMinutes(start.getMinutes() + 10)
          end.setHours(hours, minutes)
          end.setMinutes(end.getMinutes() + 20)
        }
        // UCTの場合、受付の10分後にデフォルトで子宮細胞診10分間
        if (modality === "UCT") {
          const [hours, minutes] = dock["reception_time"].split(":")
          start.setHours(hours, minutes)
          start.setMinutes(start.getMinutes() + 10)
          end.setHours(hours, minutes)
          end.setMinutes(end.getMinutes() + 20)
        }

        if (!nonScheduleModality.includes(modality)) {
          if (dockEvents[modality + body1 + body2 + contrast]) {
            start = dockEvents[modality + body1 + body2 + contrast]["start"];
            end = dockEvents[modality + body1 + body2 + contrast]["end"];
          }
          // スケジュールを管理しないモダリティの場合、
          // startをcheckinの10分後, endをcheckinの20分後と定義する
        } else {
          const [hours, minutes] = dock["reception_time"].split(":")
          start.setHours(hours, minutes)
          start.setMinutes(start.getMinutes() + 10)
          end.setHours(hours, minutes)
          end.setMinutes(end.getMinutes() + 20)
        }
        // 受付済みのモダリティはスキップ
        if (modality + body1 + body2 + contrast in dockEvents) {
          if ("status" in dockEvents[modality + body1 + body2 + contrast]) {
            if ([BEGIN_STATUS, CHECKOUT_STATUS].includes(dockEvents[modality + body1 + body2 + contrast]["status"])) {
              console.log(`Skip ${modality} ${body2} status: ${dockEvents[modality + body1 + body2 + contrast]["status"]}`)
              await showMessage(`${modality}[${body2}] は受付または検査完了のため、変更できません。`)
              skip = true
            }
          }
        }

        // カウントアップ
        i++

        // 検査受付・完了中でなければ、更新する
        if (!skip) {
          console.log(`seqNo:${seqNo + i}`)
          let booking: Booking = {
            _id: String(seqNo + i),
            modalityID: modality,
            visitID: { link: visitID },
            body1: body1,
            body2: body2,
            contrast: contrast,
            patient: { link: Number(dock["patient_id"]).toString() },
            patientID: Number(dock["patient_id"]).toString(),
            dockID: dock["book_id"],
            patientName: dock["patient_name"],
            comment: memo,
            start: start,
            end: end,
            checkin: dock["reception_time"],
            userID: appContext.currentUser?.id,
            bookingStatus: status,
            courseName: dock["course_name"],
            patientStatus: dock["course_type"]
          }
          // course_typeがCMCまたは、course_nameにCMCが含まれる場合、CMC(6)を適用。それ以外はドック(2)
          if (dock["course_type"] === "CMC" || dock["course_name"].includes("CMC")) {
            booking.bookingType = CMC_TYPE
          } else {
            booking.bookingType = DOCK_TYPE
          }
          // 診療予約(QST)の場合、初回を設定
          // 問診(1F)、診療種別、種別はドック、仮ステータスで登録, interviewType(診療種別)は健診
          if (modality === "QST") {
            booking.modalityID = "INTERVIEW" // 問診(1F)
            booking.bookingStatus = PRE_BOOK_STATUS
            booking.appointmentMethod = '来院'
            booking.interviewType = '問診(健診)'
            if (updateFlag) {
              booking.bookingStatus = 1
            }
          }
          // 診療予約(UCT)の場合、初回を設定
          // 子宮細胞診、診療種別、種別はドック、仮ステータスで登録, interviewType(診療種別)は子宮細胞診
          if (modality === "UCT") {
            booking.modalityID = "UTERINE" // 子宮細胞診(2F)
            booking.interviewType = "子宮細胞診"
            booking.bookingStatus = PRE_BOOK_STATUS
            booking.appointmentMethod = '来院'
            if (updateFlag) {
              booking.bookingStatus = 1
            }
          }

          // PET_CTの場合、icCheckinを挿入
          if (isPetCourse(modality + body1 + body2 + contrast)) {
            // ivCheckinを挿入
            booking.ivCheckin = dockEvents[modality + body1 + body2 + contrast].ivCheckin
          }
          savedBookings.push(booking)
          setSavedBookings(savedBookings)
        }

        // IV時刻のチェック
        for (const book in savedBookings) {
          if (savedBookings[book].ivCheckin) {
            if (ivTime !== '') {
              ivTime = savedBookings[book].ivCheckin
            }
            const compareResult = compareTimes(ivTime, savedBookings[book].ivCheckin);
            if (compareResult === -1) {
            } else if (compareResult === 1) {
              ivTime = savedBookings[book].ivCheckin
            } else {
              ivTime = savedBookings[book].ivCheckin
            }
          }
        }

        // モダリティの予約情報が全て挿入されたら、データ挿入
        if (contents.length === i) {
          if (status === 0) {
            await showMessage('ドックコースを仮予約しました。予約一覧ページに遷移します。')
          } else {
            await showMessage('ドックコースを予約しました。予約一覧ページに遷移します。')
          }
          for (const book in savedBookings) {
            if (savedBookings[book].ivCheckin) {
              savedBookings[book].ivCheckin = ivTime
            }
          }

          // visitを登録
          const [hours, minutes] = dock["reception_time"].split(':')
          const receptionDate = new Date(dock["reception_date"])
          receptionDate.setHours(parseInt(hours), parseInt(minutes), 0, 0)
          const latestEndBooking = savedBookings.reduce((latestBooking: any, currentBooking: any) => {
              // currentBookingのendがlatestBookingのendより遅ければ、currentBookingをlatestBookingに置き換える
              if (!latestBooking || currentBooking.end > latestBooking.end) {
                  latestBooking = currentBooking;
              }
              return latestBooking;
          }, null);
          const visit: Visit = {
            bookingIDs: savedBookings.map((booking: Booking) => booking._id),
            courseName: dock["course_name"],
            bookingType: dock["course_type"] === "CMC" ? CMC_TYPE : DOCK_TYPE,
            start: receptionDate,
            end: latestEndBooking ? latestEndBooking.end : null,
            comment: memo
          }

          // visitsを登録
          if (find_visit) {
            // 既存のvisitがあれば更新
            await db?.collection("visits").updateOne({ dockID: String(dock["book_id"]) }, { $set: visit });
          } else {
            // 新規登録
            visit._id = visitID;
            visit.patient = dock["patient_id"].toString();
            visit.dockID = String(dock["book_id"]);
            visit.patientName = dock["patient_name"];
            console.log(visit);
            await db?.collection("visits").insertOne(visit);
          }

          addBookings(savedBookings).then(() => {
            // docksにフラグ追加
            if (status === 0) {
              // 仮予約
              updateDock(dock["_id"], { completed: true, book_status: 3, memo: memo }).then(r => console.log(r))
            } else {

              // 本予約
              updateDock(dock["_id"], { completed: true, book_status: 4, memo: memo }).then(r => console.log(r))
            }
            navigate("/outpatient")
            setUpdating(false)
          })
        }

      })
    })
  }

  const client = useApolloClient()
  // PET-CTかつCTでないかどうか。部位・造影剤を含めたmodality情報も判定可能
  // modalityAll: PET-CT_1頭部単純やPET-CT_1CTFat Scan単純、MRI頭部単純を想定
  const isPetCourse = (modalityAll: string) => {
    return modalityAll.startsWith("PET-CT") && modalityAll.substring(6).search('CT') === -1
  }

  const preRegisterDock = async () => {
    if (optimizeFlag) {
      if (!await showMessage(`予約番号：${dock.book_id}\n患者ID：${dock.patient_id}\n患者名：${dock.patient_name}\nコース名：${dock.course_name}\n予約日時：${dock.reception_date}\n健診コースを仮予約しますか？`, { confirm: true })) {
        return
      }
      // 予約時刻が重複していたら、警告
      if (duplicateCheck(dockEvents)) {
        if (!await showMessage("予約時刻が重複しています。予約してもよろしいですか？", { confirm: true })) {
          return
        }
      }
      // ivCheckinが入るかどうかチェック、ただし、更新時はチェックしない
      for (const modalityAll in dockEvents) {
        // PET-CTの場合、ivCheckin
        if (isPetCourse(modalityAll)) {
          let modality, body2, contrast
          [modality, body2, contrast] = parseModalityAll(modalityAll)
          const booking: Booking = {
            start: dockEvents[modalityAll].start,
            end: dockEvents[modalityAll].end,
            patientID: Number(dock.patient_id).toString(),
            modalityID: modality,
            body2: body2,
            contrast: contrast,
          }
          const resultIvCheck = await checkPetTimeForDock({ booking, events, client, addFlag })
          if (!resultIvCheck?.passed) {
            return
          }

          dockEvents[modalityAll].ivCheckin = resultIvCheck.ivCheckin
        }
      }

      setUpdating(true)

      // bookingを登録する
      saveDock(dock["course_contents"], 0) // 仮予約
    } else {
      await showMessage("再計算ボタンを押してください", { error: true })
    }
  }

  const registerDock = async () => {
    if (optimizeFlag) {
      if (!await showMessage(`予約番号：${dock.book_id}\n患者ID：${dock.patient_id}\n患者名：${dock.patient_name}\nコース名：${dock.course_name}\n予約日時：${dock.reception_date}\n健診コースを予約しますか？`, { confirm: true })) {
        return
      }
      // 予約時刻が重複していたら、警告
      if (duplicateCheck(dockEvents)) {
        if (!await showMessage("予約時刻が重複しています。予約してもよろしいですか？", { confirm: true })) {
          return
        }
      }
      // ivCheckinが入るかどうかチェック
      for (const modalityAll in dockEvents) {
        // PET-CTの場合、ivCheckin
        if (isPetCourse(modalityAll)) {
          let modality, body2, contrast
          [modality, body2, contrast] = parseModalityAll(modalityAll)
          const booking: Booking = {
            start: dockEvents[modalityAll].start,
            end: dockEvents[modalityAll].end,
            patientID: Number(dock.patient_id).toString(),
            modalityID: modality,
            body2: body2,
            contrast: contrast,
          }
          const resultIvCheck = await checkPetTimeForDock({ booking, events, client, addFlag })
          if (!resultIvCheck?.passed) {
            return
          }

          dockEvents[modalityAll].ivCheckin = resultIvCheck.ivCheckin
        }
      }
      setUpdating(true)

      // bookingを登録する
      saveDock(dock["course_contents"], 1) // 本予約
    } else {
      await showMessage("再計算ボタンを押してください", { error: true })
    }
  }

  function dockValidation() {
    // 受信日より前
    const today = new Date();
    today.setDate(today.getDate() - 1)
    if (receptionDate <= today) return true
    if (dock.length === 0) return true
    return false
  }

  const handleDrop = async (target: EventDropArg) => {
    if (!target.event.start || !target.oldEvent.start) {
      await showMessage("ドラッグしている予約の日時が読み取れません。スケジュールをリロードしてください。", { error: true })
      return
    }
    const oldModalityID = target.oldEvent.getResources()[0]?.id
    const newModalityID = target.event.getResources()[0]?.id
    const isNewPet = PetIDs.includes(newModalityID)
    const isOldPet = PetIDs.includes(oldModalityID)
    const restoreEvent = () => {
      target.event.setStart(target.oldEvent.start || new Date())
      target.event.setEnd(target.oldEvent.end)
      target.event.setResources(target.oldEvent.getResources())
    }
    if ((newModalityID !== oldModalityID) && !(isNewPet && isOldPet)) {
      await showMessage("PET-CT同士以外のモダリティは変更できません。", { error: true })
      restoreEvent()
      return
    }
    // 予約枠がずらされた場合、スケジュール変更する
    if (target.event.title === '予約枠') {
      const body1 = modifyEmptyIfNull(target.event.extendedProps.body1)
      const body2 = modifyEmptyIfNull(target.event.extendedProps.body2)
      const contrast = modifyEmptyIfNull(target.event.extendedProps.contrast)
      if (isNewPet && isOldPet) {
        delete dockEvents[oldModalityID + body1 + body2 + contrast]
      }
      dockEvents[newModalityID + body1 + body2 + contrast] = {
        "start": target.event.start,
        "end": target.event.end,
        "status": target.event.extendedProps.bookingStatus,
        "no_schedule": nonScheduleModality.includes(newModalityID)
      }
      setDockEvents(dockEvents)
    }
  }

  return <>
    {updating ? <Loading full /> : <></>}
    {dockValidation() ?
      <div className="ml-10 mt-6 underline flex items-center">ご指定の予約番号が見つかりません。<br />受信日が過去の日付を選択している可能性があります。<br />健診システムをご確認ください。
      </div> :
      <div className="p-10">
        <div className="mt-6 underline flex items-center">受診者情報</div>
        <PATIENT dock={dock} birthdate={birthdate} />
        <div className="mt-6 underline flex items-center">受信内容</div>
        <INFORMATION dock={dock} memo={memo} setMemo={setMemo} />
        <div className="mt-6 underline flex items-center">検査スケジュール</div>
        <div>※ 新規予約の場合、「再計算」ボタンを押し、スケジュールを調整してください。</div>
        {updateFlag && !updating ? <div>※ 健診システムでモダリティ予約を追加した場合、「追加」ボタンを押し、スケジュールを調整してください。</div> : <></>}
        {updateFlag && !updating ? <div>※ 検査受付・検査完了のモダリティ予約情報は更新できません。</div> : <></>}

        <div className="flex my-2 justify-center">
          {!optimizeFlag && !updating ?
            <button className={YellowButtonClass} onClick={optimizeModalitySchedule}>再計算</button> : <></>}
          {updateFlag && !updating && !addFlag ? <button className={YellowButtonClass} onClick={addModalitySchedule}>追加</button> : <></>}
          {dock["book_status"] !== 5 && !updating ?
            <button className={LightGrayButtonClass} onClick={preRegisterDock}>仮予約</button> : <></>}
          {updating ? <></> : <button className={GrayButtonClass} onClick={registerDock}>予約確定</button>}
          <div className="flex justify-center items-center">
            <label className={`ml-4 mr-1 px-2 text-white bg-${getColor(0)}`}>外来</label>
            <label className={`mx-1 px-2 text-white bg-${getColor(1)}`}>外注</label>
            <label className={`mx-1 px-2 text-white bg-${getColor(2)}`}>通常ドック</label>
            <label className={`mx-1 px-2 text-white bg-${getColor(6)}`}>CMC・VIP</label>
            <label className="pl-4 pr-1">目盛</label>
            <select className={InputClass} name="timeUnit" onChange={handleTimeUnitChange}>
              <option value="5">5分</option>
              <option value="20">20分</option>
              <option value="60">60分</option>
            </select>
          </div>

        </div>
        <FullCalendar
          ref={calendarRef}
          locale={jaLocale}
          plugins={[resourceTimeGridPlugin, scrollGridPlugin, dayGridPlugin, interactionPlugin]}
          views={{
            resourceTimeGridSevenDay: {
              type: 'resourceTimeGrid',
              duration: { days: 1 },
            }
          }}
          headerToolbar={{
            start: '',
            center: 'title',
            end: ''
          }}
          initialDate={receptionDate}
          editable={monthView ? false : true}
          eventDurationEditable={false}
          eventResourceEditable={true}
          eventOverlap={false}
          eventColor="lightgray"
          eventDrop={handleDrop}
          initialView="resourceTimeGridSevenDay"
          allDaySlot={false}
          datesSet={handleDatesSet}
          firstDay={20}
          dayMinWidth={96}
          slotMinTime="08:00:00"
          slotMaxTime="19:00:00"
          slotDuration={getTimeUnit(timeUnit)}
          slotLabelFormat={(arg) => arg.date.minute ? String(arg.date.minute) : (String(arg.date.hour) + ":00")}//.toTimeString() : "0000"}
          slotLabelInterval={getTimeUnit(timeUnit)}
          datesAboveResources
          resources={resources}
          events={events || undefined}
          eventContent={EventContent}
        />
      </div>
    }
  </>
}

const DOCKBODY = ({ book_id }: { book_id: string }) => {
  const { db } = useMongoDB()
  const [dateRange, setDateRange] = useState<{ start: Date | null, end: Date | null }>({ start: null, end: null })
  const [resources, setResources] = useState<{ [key: string]: string }[]>([])
  const [birthdate, setBirthdate] = useState<string>("")
  const [updateStatus, setUpdateStatus] = useState<Number>(0)
  const [receptionDate, setReceptionDate] = useState<Date>(new Date())
  const [memo, setMemo] = useState<string>("")
  const { loading: book_loading, data: events } = useFindBookings(db, dateRange)

  // Handling Tooltip
  const [baseResources, setBaseResources] = useState<{ [key: string]: string }[]>([])

  const { loading: dock_loading, dock } = useDocks(book_id)

  useEffect(() => {
    async function fetchProps() {
      let response = await fetch(process.env.PUBLIC_URL + '/contexts/modality_dock.json', { cache: "reload" })
      const data = await response.json()
      setBaseResources(data)

      const resources: { [key: string]: string }[] = []
      let birthdate: string = ""
      if (dock["course_contents"]) {
        setReceptionDate(new Date(dock["reception_date"]))
        setMemo(dock["memo"])
        setUpdateStatus(Number(dock["update_status"]))

        const tmpModalities: string[] = []
        dock["course_contents"].forEach((key: any) => {
          // QUS, FS, ECGの場合、別枠で予約
          if (nonScheduleModality.includes(key["modality"])) {
            return 0
          }

          // モダリティが重複していなければPUSH
          if (!tmpModalities.includes(key["modality"])) {
            // PET-CTの場合、1号機と2号機を追加する
            if (key["modality"] === "PET-CT") {
              resources.push({ "id": "PET-CT_1", "title": "PET-CT_1" })
              resources.push({ "id": "PET-CT_2", "title": "PET-CT_2" })
            } else {
              resources.push({ "id": key["modality"], "title": key["modality"] })
            }
          }
          tmpModalities.push(key["modality"])
        })
        birthdate = dock["birthdate"].substr(0, 10)
        setBirthdate(birthdate)
        setResources(resources)

        // 優先順位付
        for (const i in priority) {
          for (const j in dock["course_contents"]) {
            if (dock["course_contents"][j]["modality"] === priority[i]) {
              course_contents.push(dock["course_contents"][j])
            }
          }
        }
        // TODO: スケジュールのないモダリティ
        for (const j in dock["course_contents"]) {
          if (nonScheduleModality.includes(dock["course_contents"][j]["modality"])) {
            one_day_modalities.push(dock["course_contents"][j])
          }
        }
      }
    }
    fetchProps()
  }, [dock])

  return <>
    {(dock_loading || book_loading) ? <Loading full /> :
      <DOCKCONTENTS
        dock={dock}
        memo={memo}
        setMemo={setMemo}
        resources={resources}
        birthdate={birthdate}
        events={events}
        baseResources={baseResources}
        updateStatus={updateStatus}
        receptionDate={receptionDate}
        setDateRange={setDateRange}
      />
    }
  </>
}

export const Dock = () => {
  const [searchParams] = useSearchParams()
  const book_id = searchParams.get("book_id")

  return <>
    {book_id ? <DOCKBODY book_id={book_id} />
      : <div className="ml-10 mt-6 underline flex items-center">予約番号が見つかりません</div>
    }
  </>
}