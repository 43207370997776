import React, { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import FullCalendar from "@fullcalendar/react";
import { DatesSetArg, EventChangeArg, EventClickArg, EventContentArg, EventHoveringArg } from "@fullcalendar/core"
import dayGridPlugin from "@fullcalendar/daygrid"
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid"
import interactionPlugin from '@fullcalendar/interaction';
import scrollGridPlugin from "@fullcalendar/scrollgrid"
import jaLocale from '@fullcalendar/core/locales/ja';

import Tooltip from "components/Tooltip";
import { Loading } from "components/Loading";
import { getDateString, getTimeUnit, today } from "contexts/dateUtils";
import { filterStates } from "graphql/RealmApolloProvider";
import { CheckClass, getColor, InputClass } from "contexts/style";
import options from "contexts/options.json";
import { useRealmApp } from "contexts/RealmApp";
import { ResourceLabelContentArg } from "@fullcalendar/resource";
import { useUpdateBooking } from "contexts/useCollection";
import showMessage from "components/showMessage";
import makeExcel from "./excel";
import { CMC_TYPE, DOCK_TYPE, DOCK_TYPES, EXAM_TYPE, INTERNAL_TYPE, SpecialCourses } from "contexts/enviroments";
import VisitForm from "components/FormVisit";
import { useFindVisits } from "contexts/useMongoQuery";
import { useMongoDB } from "contexts/MongoDBContext";
import DailyNoticeForm from "components/DailyNoticeForm";
import useDailyNotices from "graphql/useDailyNotices";

const bookingsData = gql`query GetBookingsForSchedule($query: BookingQueryInput!) {
    bookings(query: $query, sortBy: START_ASC) {
      _id
      bookingStatus
      start
      checkin
      ivCheckin
      end
      dockID
      modalityID
      bookingType
      courseName
      clinicName
      body1
      body2
      contrast
      interviewType
      patientID
      patientName
      patient {
          nameKana
      }
      visitID {
        _id
      }
      doctor
      delay
    }
  }
`
const propKeys = [
    "bookingStatus",
    "start",
    "checkin",
    "ivCheckin",
    "end",
    "dockID",
    "modalityID",
    "bookingType",
    "courseName",
    "clinicName",
    "body1",
    "body2",
    "contrast",
    "interviewType",
    "patientID",
    "patientName",
    "patient",
    "visitID",
    "doctor",
    "delay"
]

const getScheduleColor = (props: KV) => getColor(
    SpecialCourses.some(course => props.courseName?.includes(course)) ? CMC_TYPE : props.bookingType,
    props.bookingStatus === 0
)

const bookingType:KV = {
    "0": "外来",
    "1": "外注",
    "2": "通常ドック",
    "6": "CMC・VIP",
    "5": "診療予約",
}

const EventContent = (eventContent: EventContentArg) => {
    const is5min = (eventContent.event.end?.getTime() ?? 0) - (eventContent.event.start?.getTime() ?? 0) < 600000
    const { bookingStatus, modalityID, body2: body, contrast, interviewType, doctor } = eventContent.event.extendedProps
    return <div className={`fc-event-main-frame text-white bg-${getScheduleColor(eventContent.event.extendedProps)}`}>
        <div className="fc-event-title-container">
            {is5min ? <div className="px-2">{bookingStatus === 0 ? "(仮)" : ""}{`${options.modalityTitle[modalityID] || modalityID || ""}${body && body !== '-' ? `/${body}` : ''}`}</div>
                : <><div className="px-2">{bookingStatus === 0 ? "(仮)" : ""}{options.modalityTitle[modalityID] || modalityID || ""}{interviewType ? ` ${interviewType}` : ''}</div>
                    <div className="px-2">{(interviewType ? doctor : body) || ""}</div>
                    {contrast === "造影" && <div className="px-2">造影</div>}
                </>}
        </div>
    </div>
}

const SearchForm = ({ start, timeUnit, types, handleDateChange, handleTimeUnitChange, handleTypeChange }: {
    start: Date;
    timeUnit: string;
    types: number[];
    handleDateChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleTimeUnitChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    handleTypeChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) => {
    return <div className="h-12 bg-theme-200 p-2">
        <div className="flex justify-center items-center gap-2">
            <label>検査日</label><input className={InputClass} type="date" name="date" value={getDateString(start)} onChange={handleDateChange} />
            <label>目盛</label>
            <select className={InputClass} name="timeUnit" value={timeUnit} onChange={handleTimeUnitChange}>
                <option value="5">5分</option>
                <option value="10">10分</option>
                <option value="20">20分</option>
            </select>
            {Object.keys(bookingType).map(key => <React.Fragment key={key}>
                <input className={CheckClass}
                    type="checkbox" name={key}
                    checked={(types || []).findIndex(v => v === Number(key)) > -1}
                    onChange={handleTypeChange}
                />
                <label className={`px-2 text-white bg-${getColor(Number(key))}`}>{bookingType[Number(key)]}</label>
            </React.Fragment>)}
        </div>
    </div>
}

const resourceSort = (a:any, b:any) => ((!a.checkin || !b.checkin) ? 0 : a.checkin < b.checkin ? -1 : a.checkin > b.checkin ? 1 : 0);

const List = () => {
    const {db} = useMongoDB()
    const app = useRealmApp()
    const values: KV = useReactiveVar(filterStates)
    const updateBooking = useUpdateBooking();

    const noslotResources = app.resources.filter(v => v.noslot).map(v => v.id as string)

    const filter = values.schedule || { start: today(), end: new Date(today().getTime() + 86399999), timeUnit: "5", types: Object.keys(bookingType).map(v => Number(v)) }
    const dateNumber = filter.start.getTime()

    const timeUnit = filter.timeUnit

    const { loading, data } = useQuery(bookingsData, { variables: { query: { start_gte: filter.start, end_lte: filter.end, bookingStatus_in: [0, 1, 2, 3, 4] } }, notifyOnNetworkStatusChange: true, })
    const { loading: visitLoading, data: visitEvents, refetch: refetchFull} = useFindVisits(db, { start: filter.start, end: filter.end })
    const refetch = useCallback(async () => refetchFull(db, { start:filter.start, end: filter.end }),[refetchFull, db, filter.start, filter.end])

    const { loading: dailyNoticeLoading, data: dailyNoticeData, error: dailyNoticeError, updateData: updateDailyNotice } = useDailyNotices({ start: filter.start, end: filter.end })

    const [writing, setWriting] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [dailyModalTimestamp, setDailyModalTimestamp] = useState(0)
    const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null | undefined>()

    // patientID is used for resourceId.  For exasm's modalityID, title(or 診療 if not exist) is used instead of id
    const translateEvent = (v: KV): CalendarEvent => ({
        id: v._id, title: options.bookingType[v.bookingType], start: new Date(v.start), end: new Date(v.end), resourceId: v.patientID, extendedProps: propKeys.reduce<CalendarEvent["extendedProps"]>((a, r) => ({
             ...a, [r]: r === "modalityID" && v.bookingType === EXAM_TYPE ? app.resources.find(resource => resource.id === v[r])?.title || "診療" : (r === "visitID" && v[r]) ? v[r]._id : v[r]
        }), { id: v._id })
    })
    
    const ResourceContent = (arg: ResourceLabelContentArg) => {
        const { id, title, extendedProps: props } = arg.resource
        return <div className="flex flex-col text-sm cursor-pointer" data-id={props.visitID} onClick={handleResourceClick}><span>{id}</span><span>{title}</span><span className={`px-1 text-white bg-${getScheduleColor(props)}`}>{props.bookingType === INTERNAL_TYPE ? props.clinicName : DOCK_TYPES.includes(props.bookingType) ? (props.courseName) || (props.bookingType === DOCK_TYPE ? "通常ドック" : "CMC・VIP") : props.bookingType === EXAM_TYPE ? "診療" : "外来"}</span></div>
    }

    const { events, resources }:{ events?:CalendarEvent[], resources?: any[] } = useMemo(() => {
        // patientID is used for resourceId.  For exasm's modalityID, title(or 診療 if not exist) is used instead of id
        const events: CalendarEvent[] | undefined = data?.bookings?.map((v: Booking) => translateEvent(v))
        // Make non-slot booking movable
        events?.filter(event => noslotResources.includes(event.extendedProps.modalityID || "")).forEach((event) => event.startEditable = true)
        // Find first event for each patient excluding exam or only exam and resources data with id as patientID
        const resources = events?.filter((e, i) => (events.findIndex(event => event.resourceId === e.resourceId && (e.extendedProps.bookingType !== EXAM_TYPE || ( e.extendedProps.bookingType === EXAM_TYPE && !events.find(event => event.resourceId === e.resourceId && event.extendedProps.bookingType !== EXAM_TYPE)))) === i))
            .filter(e => (filter.types || []).some((type:number) => type === e.extendedProps.bookingType))
            .map(v => ({ id: v.resourceId, title: v.extendedProps.patient?.nameKana || v.extendedProps.patientName, extendedProps: { bookingType: v.extendedProps.bookingType, courseName: v.extendedProps.courseName, clinicName: v.extendedProps.clinicName, checkin: v.extendedProps.checkin, visitID: v.extendedProps.visitID } }))
            .sort((a, b) => ((!a.extendedProps.checkin || !b.extendedProps.checkin) ? 0 : a.extendedProps.checkin < b.extendedProps.checkin ? -1 : a.extendedProps.checkin > b.extendedProps.checkin ? 1 : 0));
        // Temporary adjust ------
        resources?.forEach(resource => {
            if (resource.extendedProps.bookingType === 1) {
                const event = events?.find(event => event.resourceId === resource.id && event.extendedProps.clinicName !== "セントラルクリニック世田谷")
                if (event) {
                    resource.extendedProps.clinicName = event.extendedProps.clinicName
                    resource.extendedProps.checkin = event.extendedProps.checkin
                }
            }
        })
        resources?.sort((a, b) => ((!a.extendedProps.checkin || !b.extendedProps.checkin) ? 0 : a.extendedProps.checkin < b.extendedProps.checkin ? -1 : a.extendedProps.checkin > b.extendedProps.checkin ? 1 : 0));
        // Temporary adjust end ------    
        resources?.forEach(resource => {
            const start = new Date(`${getDateString(filter.start)}T${resource.extendedProps.checkin?.padStart(5, "0") || "08:30"}:00.000`).getTime()// - JA_TIMEZONE_OFFSET
            // Add reception event
            events?.push({ id: "", title: "受付", resourceId: resource.id, start: new Date(start), end: new Date(start + ((resource.extendedProps.bookingType || 0) < 2 ? 300000 : 300000)), extendedProps: { id: "", modalityID: "受付", bookingType: resource.extendedProps.bookingType, courseName: resource.extendedProps.courseName } })
            // Set temporary start and end time for noslot booking
            events?.filter(event => event.startEditable && (event.start.getTime() === start + 600000 || event.start.getTime() === dateNumber || event.start.getTime() === event.end.getTime())).forEach((event, i) => {
                event.start = new Date(start + 300000 * (i + 2))
                event.end = new Date(start + 300000 * (i + 3))
            })
        })
        // Add IV event
        events?.filter(event => event.extendedProps.ivCheckin).filter((event, i, array) => i === array.findIndex((obj) => obj.resourceId === event.resourceId)).forEach(event => {
            const start = new Date(`${getDateString(filter.start)}T${event.extendedProps.ivCheckin?.padStart(5, "0") || "08:30"}:00.000`).getTime()// - JA_TIMEZONE_OFFSET
            events?.push({ id: "", title: "IV", resourceId: event.resourceId, start: new Date(start), end: new Date(start + 600000), extendedProps: { id: "", modalityID: "IV", bookingType: event.extendedProps.bookingType, courseName: event.extendedProps.courseName } })
        })
        // Divide delay waiting and delay from PET
        events?.filter(event => event.extendedProps.delay).forEach(event => {
            const endOrig = event.end.getTime()
            event.end = new Date(endOrig - 1200000)
            events?.push({ id: "", title: event.title, resourceId: event.resourceId, start: new Date(endOrig - 1200000), end: new Date(endOrig - 600000), extendedProps: { id: "", modalityID: event.extendedProps.modalityID, body2: "Delay待機", bookingType: event.extendedProps.bookingType, courseName: event.extendedProps.courseName } })
            events?.push({ id: "", title: event.title, resourceId: event.resourceId, start: new Date(endOrig - 600000), end: new Date(endOrig), extendedProps: { id: "", modalityID: event.extendedProps.modalityID, body2: "Delay", bookingType: event.extendedProps.bookingType, courseName: event.extendedProps.courseName } })
        })
        return { events, resources }
    }, [data?.bookings, dateNumber, filter.start, noslotResources])// avoid rerender(revert of event at mutate) at mutate
    const calendarRef = useRef<FullCalendar | null>(null)

    // get fullcalendar dates and set dateRange state
    const handleDatesSet = (arg: DatesSetArg) => {
        let startDate = new Date(arg.start)
        startDate.setHours(0)
        filterStates({ ...filterStates(), schedule: { ...filter, start: startDate, end: new Date(startDate.getTime() + 86399999) } })
    }

    const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        let startDate = new Date(e.currentTarget.value)
        startDate.setHours(0)
        filterStates({ ...filterStates(), schedule: { ...filter, start: startDate, end: new Date(startDate.getTime() + 86399999) } })
    }

    const handleTimeUnitChange = (e: ChangeEvent<HTMLSelectElement>) => filterStates({ ...filterStates(), schedule: { ...filter, timeUnit: e.currentTarget.value } })

    const handleTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
        let types = [...filter.types]
        const type = Number(e.currentTarget.name)
        if (!types.some(v => v === type)) types.push(type)
        else types = types.filter(v => v !== type)
        filterStates({ ...filterStates(), schedule: { ...filter, types: types } })
    }

    // Handling Tooltip
    const [tooltipEvent, setTooltipEvent] = useState<CalendarEvent>();
    const eventElement = useRef<HTMLElement>()
    const handleMouseEnter = (arg: EventHoveringArg) => {
        eventElement.current = arg.el
        const event = events?.find(event => event.id === arg.event.id)
        if (event && event.id !== tooltipEvent?.id) setTooltipEvent(events?.find(event => event.id === arg.event.id))
    }
    const handleMouseLeave = (arg: EventHoveringArg) => setTooltipEvent(undefined)
    // End handling Tooltip

    const customButtons = {
        print: {
            text: '印刷',
            icon: 'print',
            click: () => {
//                makeSchedulePdf(filter.start, resources, events)
                makeExcel(filter.start, resources, events)
            }
        },
        notice: {
            text: dailyNoticeData[0]?.notice || "日別見出し",
            click: () => {
                setDailyModalTimestamp(filter.start.getTime())
            }
        }
    }
    const handleChange = async (eventChange: EventChangeArg) => {
        if ((!eventChange.event.start || !eventChange.oldEvent.start)) {
            await showMessage("予約データがないか、不完全な予約データです。リロードしてください。", { error: true })
            return false
        }
        const checkinEnd = events?.find(event => event.extendedProps.modalityID === "受付" && event.resourceId === eventChange.event.getResources()[0]?.id)?.end
        if (checkinEnd && checkinEnd.getTime() > new Date(eventChange.event.start || 0).getTime()) {
            await showMessage("受付時間より前には動かせません。", { error: true })
            eventChange.revert()
            return false
        }
        const newBooking = {
            _id: eventChange.event.id,
            start: new Date(eventChange.event.start || 0),
            end: new Date(eventChange.event.end || 0),
        }
        try {
            setWriting(true)
            await updateBooking(newBooking._id, { start: newBooking.start, end: newBooking.end })
            setWriting(false)
        } catch (e) {
            eventChange.revert()
            setWriting(false)
            if (e instanceof Error) await showMessage(`予約の書込処理エラーが発生しました。\r\n${e.message}`, { error: true })
        }
    }

    const handleResourceClick = async (e: MouseEvent<HTMLDivElement>) => {
        setIsModalOpen(true)
        setSelectedEvent(visitEvents?.find(v => v.id === e.currentTarget.dataset.id))
    }

    useEffect(() => {
        calendarRef.current?.getApi().gotoDate(filter.start)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateNumber])

    return <div className="fc-patient h-full">
        {(loading || dailyNoticeLoading || writing) && <Loading full />}
        <Tooltip element={eventElement.current} event={tooltipEvent} />
        <SearchForm
            start={filter.start} timeUnit={timeUnit} types={filter.types} handleDateChange={handleDateChange} handleTimeUnitChange={handleTimeUnitChange} handleTypeChange={handleTypeChange}
        />
        <DailyNoticeForm timestamp={dailyModalTimestamp} setTimestamp={setDailyModalTimestamp} data={dailyNoticeData} update={updateDailyNotice} />
        <FullCalendar
            ref={calendarRef}
            locale={jaLocale}
            plugins={[resourceTimeGridPlugin, scrollGridPlugin, dayGridPlugin, interactionPlugin]}
            customButtons={customButtons}
            headerToolbar={{
                start: 'prev,next today',
                center: 'title',
                end: 'notice print'
            }}
            eventChange={handleChange}
            eventOverlap={true}
            //            eventMouseEnter={handleMouseEnter}
            //            eventMouseLeave={handleMouseLeave}
            eventColor="lightgray"
            eventResourceEditable={false}
            selectable={true}
            initialDate={filter.start}
            initialView="resourceTimeGridDay"
            dayMinWidth={120}
            allDaySlot={false}
            slotMinTime="08:30:00"
            slotMaxTime="18:00:00"
            slotDuration={getTimeUnit(timeUnit)}
            slotLabelFormat={(arg) => arg.date.minute ? ((arg.date.hour === 8 && arg.date.minute === 30) ? "8:30" : String(arg.date.minute)) : (String(arg.date.hour) + ":00")}
            slotLabelInterval={getTimeUnit(timeUnit)}
            datesSet={handleDatesSet}
            datesAboveResources
            resources={resources}
            resourceLabelContent={ResourceContent}
            resourceOrder={resourceSort}
            events={events || undefined}
            eventContent={EventContent}
        />
        <VisitForm data={selectedEvent} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} refetch={refetch}/>
        </div>
}
export default List