const Locale = 'ja-JP';
const TIME_ZONE = 'Asia/Tokyo';

/** @type {number} JST offset hours */
export const JA_OFFSET_HOURS = (-9);
/** @type {number} JST offset minutes. Same as gotton by getTimezoneOffset at Ja */
export const JA_OFFSET_MINUTES = JA_OFFSET_HOURS * 60
/** @type {number} Local offset minutes. Same as gotton by getTimezoneOffset at local */
export const LOCAL_OFFSET_MINUTES = new Date().getTimezoneOffset();
/** @type {number} Offset milliseconds at local timezone */
export const LOCAL_OFFSET = LOCAL_OFFSET_MINUTES * 60000;
/** @type {number} Milliseconds of one day */
export const ONEDAY_MS = 86400000;
/** @type {number} JST offset as milliseconds */
export const JA_OFFSET = JA_OFFSET_HOURS * 3600000;
/** @type {number} Offset of JST from current as milliseconds */
export const LOCAL_OFFSET_JA = LOCAL_OFFSET - JA_OFFSET;
export const LOCAL_OFFSET_JA_HOURS = LOCAL_OFFSET_JA / 3600000;

/**
 * Date extention to implement ISO string at timezone Asia/Tokyo(JST)
 */
class ExtendedDate extends Date {
  /**
   * Set hours and minutes as HH:mm
   * @param time HH:mm to set.
   */
  public setHMString(time:string) {
    const times = time.split(':')
    return this.setHours(Number(times[0]), Number(times[1]))
  }
  /**
   * Returns a date as a string value in ISO format. Timezone is set as Asia/Tokyo(JST)
   */
  public toJaISOString() {
    return new Date(this.getTime() - JA_OFFSET).toISOString().replace('Z', '+09:00')
  }
  /**
   * Get ISO Date string(yyyy-mm-dd) at JST
   */
  public toJaISODateString() {
    return new Date(this.getTime() - JA_OFFSET).toISOString().slice(0, 10)
  }
  /**
   * Get HH:mm at JST
   */
  public toJaISOTimeString() {
    return new Date(this.getTime() - JA_OFFSET).toISOString().slice(11, 16)
  }
}

/**
 * Date extention to handle setter and getter as JST regardless of local timezone.
 */
class JaDate extends ExtendedDate {

  /**
   * Create Date object whose hours and minutes are provided as JST(UTC + 9h)
   * @param firstAttr Same as first attribute in Date
   * @param month  Same as monthIndex in Date
   * @param date Same as day in Date 
   * @param hours JST hours to set
   * @param minutes JST minutes to set
   * @param seconds Same as seconds in Date 
   * @param ms Same as milliseconds in Date
   */
  constructor(firstAttr?:any, month?:number, date:number = 1, hours:number = 0, minutes:number = 0, seconds:number = 0, ms:number = 0) {
    if (month) super(firstAttr, month, date, hours, minutes + LOCAL_OFFSET_MINUTES - JA_OFFSET_MINUTES, seconds, ms);
    else super(firstAttr)
  }

  /**
   * Gets the year, using JST.
   */
  public getFullYear() {
    return new Date(this.getTime() - JA_OFFSET).getUTCFullYear()
  }

  public setFullYear(year:number, month?:number, date?:number) {
    this.setUTCMinutes(this.getUTCMinutes() - JA_OFFSET_MINUTES)
    month == null ? this.setUTCFullYear(year) : date == null ? this.setUTCFullYear(year, month) : this.setUTCFullYear(year, month, date)
    return this.setUTCMinutes(this.getUTCMinutes() + JA_OFFSET_MINUTES)
  }

  public getMonth() {
    return new Date(this.getTime() - JA_OFFSET).getUTCMonth()
  }

  public setMonth(month:number, date?:number) {
    this.setUTCMinutes(this.getUTCMinutes() - JA_OFFSET_MINUTES)
    date == null ? this.setUTCMonth(month) : this.setUTCMonth(month, date)
    return this.setUTCMinutes(this.getUTCMinutes() + JA_OFFSET_MINUTES)
  }

  /**
   * Gets the day-of-the-month, using JST.
   */
  public getDate() {
    return new Date(this.getTime() - JA_OFFSET).getUTCDate()
  }

  public setDate(date:number) {
    this.setUTCMinutes(this.getUTCMinutes() - JA_OFFSET_MINUTES)
    this.setUTCDate(date)
    return this.setUTCMinutes(this.getUTCMinutes() + JA_OFFSET_MINUTES)
  }

  /**
   * Gets the hours of a JaDate object, using JST.
   */
  public getHours() {
    return new Date(this.getTime() - JA_OFFSET).getUTCHours()
  }

  public setHours(hours: number, min?: number, sec?: number, ms?: number) {
    this.setUTCMinutes(this.getUTCMinutes() - JA_OFFSET_MINUTES)
    min == null ? this.setUTCHours(hours) : sec == null ? this.setUTCHours(hours, min) : ms == null ? this.setUTCHours(hours, min, sec) : this.setUTCHours(hours, min, sec, ms)
    return this.setUTCMinutes(this.getUTCMinutes() + JA_OFFSET_MINUTES)
  }

  /**
   * Gets the minutes of a JaDate object, using JST.
   */
  public getMinutes() {
    return this.getUTCMinutes() // JST has no minute difference from UTC
  }

  public setMinutes(min: number, sec?: number, ms?: number) {
    this.setUTCMinutes(this.getUTCMinutes() - JA_OFFSET_MINUTES)
    sec == null ? this.setUTCMinutes(min) : ms == null ? this.setUTCMinutes(min, sec) :this.setUTCMinutes(min, sec, ms)
    return this.setUTCMinutes(this.getUTCMinutes() + JA_OFFSET_MINUTES)
  }

  public setHMString(time:string) {
    const times = time.split(':')
    return this.setHours(Number(times[0]), Number(times[1]))
  }

  /**
   * Returns a value as a string value appropriate to the locale. Converts a date and time to a string by using the ja-JP or specified locale. Timezone is set as Asia/Tokyo(JST)
   * @param locales A locale string, array of locale strings, Intl.Locale object, or array of Intl.Locale objects that contain one or more language or locale tags. If you include more than one locale string, list them in descending order of priority so that the first entry is the preferred locale. If you omit this parameter, the locale is ja-JP.
   * @param options An object that contains one or more properties that specify comparison options. Timezone is set to Asia/Tokyo(JST) unless specified.
   */
  public toLocaleString(locales: Intl.LocalesArgument = Locale, options?: Intl.DateTimeFormatOptions | undefined) {
    return super.toLocaleString(locales, { timeZone: TIME_ZONE, ...options })
  }

  /**
   * Converts a date to a string by using the ja-JP or specified locale. Timezone is set as Asia/Tokyo(JST)
   * @param locales A locale string, array of locale strings, Intl.Locale object, or array of Intl.Locale objects that contain one or more language or locale tags. If you include more than one locale string, list them in descending order of priority so that the first entry is the preferred locale. If you omit this parameter, the locale is ja-JP.
   * @param options An object that contains one or more properties that specify comparison options. Timezone is set to Asia/Tokyo(JST) unless specified.
   */
  public toLocaleDateString(locales: Intl.LocalesArgument = Locale, options?: Intl.DateTimeFormatOptions | undefined) {
    return super.toLocaleDateString(locales, { timeZone: TIME_ZONE, ...options })
  }

  public toLocaleTimeString(locales: Intl.LocalesArgument = Locale, options?: Intl.DateTimeFormatOptions | undefined) {
    return super.toLocaleTimeString(locales, { timeZone: TIME_ZONE, ...options })
  }

}

export const DateClass = LOCAL_OFFSET_MINUTES === JA_OFFSET_MINUTES ? ExtendedDate : JaDate


export const getJSTHour = (date:Date) => { 
  const hours = date.getUTCHours() - JA_OFFSET_HOURS
  return hours > 24 ? hours - 24 : hours < 0 ? hours + 24 : hours
}

export const setJSTHour = (date:Date, hour:number = 0, minute?:number, second?:number, ms?:number) => date.setUTCHours(hour + JA_OFFSET_HOURS, minute, second, ms)

/**
 *
 * @param {string} locale
 * @param {string} timezone
 * @returns
 */
export const isLocaleValid = (locale = Locale, timezone = TIME_ZONE) => {
  if (!Intl || !Intl.DateTimeFormat().resolvedOptions().timeZone) return false;
  try {
    Intl.DateTimeFormat(locale, { timeZone: timezone });
    return true;
  } catch (ex) {
    return false;
  }
};

/**
 * Add offset hours if UTC date and JST date is different(3pm or later).  Works at any timezone.
 * If local is +12h and by 3am local, local and JST is different date.  If local is -12h and after 3am, local and JST is different date. 
 * @param {Date} date Date to add offset.  Any timezone is OK
 * @returns {Date} New date object which offset hours are added to
 */
const UTCtoJSTDate = (date: Date) =>
  date.getUTCHours() >= 24 + JA_OFFSET_HOURS ? new Date(date.getTime() - JA_OFFSET) : new Date(date); // If local is +12h, 
const weekdays = ['日', '月', '火', '水', '木', '金', '土', '日'];

/**
 * Retuerns day of week at JST.
 * @param {Date?} date Date to get day of week
 * @returns {number} Day of week as number
 */
export const JSTWeekdayNumber = (date: Date = new Date()) => UTCtoJSTDate(date).getUTCDay();
// export const JSTWeekdayHoliday = (date:Date) => JSTPublicHoliday(date) ? '祝' : JSTWeekday(date)

/**
 * Returns day of week at JST.
 * @param {Date?} date Date to get day of week
 * @returns {string} Day of week as Japanese string
 */
export const JSTWeekday = (date: Date = new Date()) => weekdays[JSTWeekdayNumber(date)];

export const JSTDate = (date: Date) => date.toLocaleDateString(Locale, { timeZone: TIME_ZONE });
export const JSTMonthDate = (date: Date) =>
  date.toLocaleDateString(Locale, { timeZone: TIME_ZONE, month: 'short', day: 'numeric', weekday: 'short' });
export const JSTDateTime = (date: Date) => date.toLocaleString(Locale, { timeZone: TIME_ZONE });
export const JSTTime = (date: Date = new Date(), style:"numeric" | "2-digit" = 'numeric') =>
  date.toLocaleTimeString(Locale, { timeZone: TIME_ZONE, hour: style, minute: style });
export const JSTTimeWithSecond = (date: Date = new Date(), style:"numeric" | "2-digit" = 'numeric') =>
  date.toLocaleTimeString(Locale, { timeZone: TIME_ZONE, hour: style, minute: style, second: style });

export const getDateString = (date:Date = new Date()) => new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0]
export const getAge = (date:Date) => Math.abs(new Date(Date.now() - date.getTime()).getUTCFullYear() - 1970);

// 当日日付 yyyy-mm-dd型 0埋め表記
export const todayISO = new Date().getFullYear() + "-" + (new Date().getMonth() + 1).toString().padStart(2, "0") + "-" + new Date().getDate().toString().padStart(2, "0")

export const today = () => {
  let date = new Date()
  date.setHours(0, 0, 0, 0)
  return date
}

export const getDateAfterDays = (days: number) => {
  let date = new Date();
  date.setDate(date.getDate() + days);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const nextDate = (date: Date, days: number = 1) => new Date(date.getTime() + 86400000 * days);
export const nextMonth = (date: Date, months: number = 1, previousDayEnd?: boolean) => {
  let newDate = new Date(date.getTime() - (previousDayEnd ? 1 : 0));
  newDate.setMonth(date.getMonth() + 1)
  return newDate
}

export const getSamedayArea = (date:Date = new Date()) => {
  const start = new Date(date)
  if (start.getUTCHours() < (JA_OFFSET_HOURS < 0 ? 24 + JA_OFFSET_HOURS : JA_OFFSET_HOURS)) {
    start.setUTCHours(JA_OFFSET_HOURS < 0 ? JA_OFFSET_HOURS : JA_OFFSET_HOURS - 24, 0, 0, 0) // If UTC is ~15:00(JST 9:00~ same day), JST 0:00 is previous day 15:00 at UTC
  } else {
    start.setUTCHours(JA_OFFSET_HOURS < 0 ?  24 + JA_OFFSET_HOURS : JA_OFFSET_HOURS, 0, 0, 0) // If UTC > 15:00(JST 0:00~09:00 next day), JST 0:00 is same day 15:00 at UTC
  }
  return [start, new Date(start.getTime() + ONEDAY_MS)]
}

export const getMinutesFromMidnight = (date: Date) => date.getHours() * 60 + date.getMinutes()

export const datesAreOnSameDay = (first:Date, second:Date) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

export const getTimeUnit = (value: string) => `${value === "60" ? "01:00" : "00:" + ("0" + value).slice(-2)}:00`

export const compareTimes = (time1: string, time2: string) => {
  const [hours1, minutes1] = time1.split(':');
  const [hours2, minutes2] = time2.split(':');

  const date1 = new Date(0, 0, 0, parseInt(hours1), parseInt(minutes1)).getTime();
  const date2 = new Date(0, 0, 0, parseInt(hours2), parseInt(minutes2)).getTime();

  if (date1 < date2) {
    return -1; // time1 < time2
  } else if (date1 > date2) {
    return 1; // time1 > time2
  } else {
    return 0; // time1 = time2
  }
}

export const compareDates = (date1:Date, date2:Date, timeZone:string = "Asia/Tokyo") => 
  date1.toLocaleDateString("en-GB", { timeZone: timeZone }).slice(0, 10) === date2.toLocaleDateString("en-GB", { timeZone: timeZone }).slice(0, 10)
