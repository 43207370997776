import React, { useContext, useEffect, useState } from "react"
import { useRealmApp } from "contexts/RealmApp"
import { useMongoDB } from "./MongoDBContext";
const UnreadMessageContext = React.createContext<number>(0);
export const useUnreadMessage = () => {
    const umContext = useContext(UnreadMessageContext)
    if (umContext == null) {
        throw new Error("useUnreadMessage() called outside of a UnreadMessage?")
    }
    return umContext
}

const UnreadMessage = ({ children }: { children: JSX.Element }) => {
    const { currentUser } = useRealmApp();
    const { db } = useMongoDB()
    const [unread, setUnread] = useState(0)
    const [updated, setUpdated] = useState(false)
    const channel = new BroadcastChannel('sw-messages');

    const countNewMessage = async (id:string, db:Realm.Services.MongoDBDatabase) => {
        const count = await db.collection("messages").count({ "targetUser.userID": id, "targetUser.read": { $ne: true } })
        setUnread(count || 0)
    }

    useEffect(() => {
        const handleMessage = (e:MessageEvent) => {
            console.log('Message received in browser page:', e.data);
            if (e.data === "No unread meessage") setUnread(0)
            else setUpdated(true)
        }
        channel.addEventListener('message', handleMessage);
        return () => { 
            channel.removeEventListener('message', handleMessage);
        }
    }, [])

    useEffect(() => {
        if (!currentUser?.id || !db) return
        if (updated) setUpdated(false) // step to trigger counting to avoid db is null(happen when direct call counting from handleMessage)
        else if (currentUser?.id && db) countNewMessage(currentUser?.id, db)
    }, [currentUser?.id, db, updated]);
    return (
        <UnreadMessageContext.Provider value={unread}>{children}</UnreadMessageContext.Provider>
    );
};
export default UnreadMessage