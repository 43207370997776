import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import options from "contexts/options.json"

export const useInternalDoctors = () => {
    const { data, loading, error } = useQuery(
        gql`
          query GetUsers {
            users(query: { internal: true, clinic: { _id: "${options.centralClinic.id}" } }) {
              _id
              email
              name
            }
          }
        `);
    if (error) {
        throw new Error(`Failed to fetch useInternalDoctors`);
    }
    const doctors:KV[] = data?.users ?? [];
    return {
        doctors,
        loading,
    }
}
