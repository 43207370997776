import { useMongoDB } from "contexts/MongoDBContext";
import { useEffect, useState } from "react";

const getTodayMidnight = () => {
    const now = new Date();
    now.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0
    return now;
}

const useDailyNotices = (query: KV) => {
    const db = useMongoDB();
    const [dates, setDates] = useState<number[]>([])
    const [data, setData] = useState<KV[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        const newDates = new Array(7).fill(new Date(query.start || getTodayMidnight()).getTime()).map((time, i) => (time + 86400000 * i))
        setDates(newDates)
    }, [query.start])

    useEffect(() => {
        const fetchData = async () => {
            if (!dates[0]) return
            if (!db?.db) {
                if (dates.length) setData(new Array(7).fill(0).map((_, i) => ({ _id: dates[i] })))
                return
            }
            setLoading(true);
            try {
                const responseData =
                    (await db?.db?.collection("dailynotices").find({ _id: { $gte: dates[0], $lt: dates[0] + 86400000 * 7 } })) || [];
                const organizedData = new Array(7).fill(0).map((_, i) => responseData.find(datum => datum._id === dates[i]) || ({ _id: dates[i] }))
                setData(organizedData);
            } catch (error) {
                if (error instanceof Error) setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [db?.db, dates]);

    const updateData = async (time: number, newData: KV) => {
        setLoading(true);
        try {
            const updatedData = await db?.db
                ?.collection("dailynotices")
                .findOneAndUpdate({ _id: time }, { $set: newData }, { upsert: true, returnNewDocument: true }) || null;
            if (updatedData) setData(data => {
                const newData = [...data]
                const index = newData.findIndex(datum => datum._id === updatedData._id)
                if (index > -1) newData[index] = updatedData
                return newData
            });
        } catch (error) {
            if (error instanceof Error) setError(error);
        } finally {
            setLoading(false);
        }
    };

    return { data, loading, error, updateData };
};

export default useDailyNotices;
