import React, { ChangeEvent, MouseEvent, useEffect, useMemo, useRef, useState } from "react"
import FullCalendar from "@fullcalendar/react"
import { DateSelectArg, DatesSetArg, DayHeaderContentArg, EventClickArg, EventContentArg, EventDropArg, EventHoveringArg } from "@fullcalendar/core"
import dayGridPlugin from "@fullcalendar/daygrid"
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid"
import interactionPlugin, { EventDragStartArg } from '@fullcalendar/interaction'
import scrollGridPlugin from "@fullcalendar/scrollgrid"
import jaLocale from '@fullcalendar/core/locales/ja'
import { CheckClass, InputClass, getColor } from "contexts/style"
import { useMongoDB } from "contexts/MongoDBContext"
import { useUpdateBooking } from "contexts/useCollection"
import { getDateString } from "contexts/dateUtils"
import { TooltipExam as Tooltip } from "components/Tooltip"
import { Loading } from "components/Loading"
import { useFindBookings } from "contexts/useMongoQuery"
import { useRealmApp } from "contexts/RealmApp"
import { useApolloClient, useReactiveVar } from "@apollo/client"
import { filterStates } from "graphql/RealmApolloProvider"
import { handleMoveBooking, OUTER_BOOK_TYPE } from "contexts/enviroments"
import showMessage from "components/showMessage"
import { ExamEntryForm } from "components/ExamEntryForm"
import options from "contexts/options.json"
import useDailyNotices from "graphql/useDailyNotices"
import DailyNoticeForm from "components/DailyNoticeForm"

let mongoCollectionWatch: AsyncGenerator<Realm.Services.MongoDB.ChangeEvent<any>> | null = null

const EventContent = (eventContent: EventContentArg) => <div className={`fc-event-main-frame text-white bg-${getColor(eventContent.event.extendedProps.bookingType, !Boolean(eventContent.event.extendedProps.bookingStatus))}`}>
  <div className="fc-event-title-container">
    <div className="px-2">{!eventContent.event.extendedProps.bookingStatus ? "(仮)" : ""}{eventContent.event.extendedProps.patientName || ""}</div>
    <div className="px-2">{eventContent.event.extendedProps.doctor || ""}</div>
    <div className="px-2">{eventContent.event.extendedProps.interviewType || ""}</div>
    <div className="px-2">{eventContent.event.extendedProps.appointmentMethod || ""}</div>
  </div>
</div>

const EventContentMonth = (eventContent: EventContentArg) => <div className={`fc-event-main-frame text-white bg-${getColor(eventContent.event.extendedProps.bookingType, !Boolean(eventContent.event.extendedProps.bookingStatus))}`}>
  <div className="fc-event-title-container">
    <div className="px-2">{eventContent.timeText}　{options.modalityTitle[eventContent.event.extendedProps.modalityID] || eventContent.event.extendedProps.modalityID}</div>
    <div className="px-2">{!eventContent.event.extendedProps.bookingStatus ? "(仮)" : ""}{eventContent.event.extendedProps.patientName || ""}　{eventContent.event.extendedProps.doctor ? "(医)" : ""}{eventContent.event.extendedProps.doctor || ""}</div>
  </div>
</div>

const SearchForm = ({
    dateRange,
    timeUnit,
    resources,
    handleChange,
    handleDateChange,
    handleTimeUnitChange,
    baseResources
}: {
    dateRange: { start: Date, end: Date | null };
    timeUnit: string;
    resources: KV[];
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleDateChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleTimeUnitChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    baseResources: { [key: string]: string }[]
}) => {
    return <div className="h-24 bg-theme-200 p-2">
        <div className="flex justify-center items-center">
            <label className="pl-4 pr-1">検査日</label><input className={InputClass} type="date" name="date"
                value={getDateString(dateRange.start)}
                onChange={handleDateChange} />
            <label className="pl-4 pr-1">目盛</label>
            <select className={InputClass} name="timeUnit" value={timeUnit} onChange={handleTimeUnitChange}>
                <option value="5">5分</option>
                <option value="20">20分</option>
                <option value="60">60分</option>
            </select>
        </div>
        <div className="flex justify-center items-center">{
            baseResources.map(resource => <React.Fragment key={resource.id}><input className={CheckClass}
                type="checkbox" name={resource.id}
                id={resource.id}
                checked={Boolean((resources || []).find(v => v.id === resource.id))}
                onChange={handleChange} /><label
                    className="pl-1 pr-4">{resource.title}</label></React.Fragment>)
        }</div>
    </div>
}

const getTimeUnit = (value: string) => `${value === "60" ? "01:00" : "00:" + ("0" + value).slice(-2)}:00`

export const Schedule = () => {
    const { db } = useMongoDB()
    const app = useRealmApp()
    const client = useApolloClient()
    const values: KV = useReactiveVar(filterStates)
    const baseResources = app.resources.filter((v: KV) => v.exam)//.sort((a, b) => a.id < b.id ? -1 : a.id > b.id ? 1 : 0)
    const filter = values.exam || {
        dateRange: { start: new Date(), end: null },
        timeUnit: "5",
        resources: undefined,
        monthView: false
    }
    if (!filter.resources && baseResources?.length) filter.resources = baseResources.filter(v => ["INTERVIEW", "RESULT_3", "OUTEXAM"].includes(v.id))


    const dateRange = filter.dateRange
    const timeUnit = filter.timeUnit
    const resources = filter.resources
    const resourceIds = (resources || []).map((v: any) => v.id)
    const baseResourceIds = useMemo(() => baseResources.map(v => v.id), [app.resources])

    const { loading, data: events, refetch } = useFindBookings(db, dateRange, baseResourceIds, true)

    const { loading: dailyNoticeLoading, data: dailyNoticeData, error: dailyNoticeError, updateData: updateDailyNotice} = useDailyNotices({ start: dateRange.start, end: dateRange.end })

    const calendarRef = useRef<FullCalendar | null>(null)

    const [isExamModalOpen, setIsExamModalOpen] = useState<boolean>(false)
    const [dailyModalTimestamp, setDailyModalTimestamp] = useState(0)
    const [selectInfo, setSelectInfo] = useState<any>({})
    const [selectBookingId, setSelectBookingId] = useState<string>("")
    const [isUpdated, setIsUpdated] = useState<boolean>(false)
    const [selectedEvent, setSelectedEvent] = useState<any>({})

    const updateBooking = useUpdateBooking()

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        let resources: { [key: string]: string }[] = []
        baseResources.forEach(resource => {
            if ((document.getElementById(resource.id || '') as HTMLInputElement).checked) resources.push(resource)
        })
        filterStates({ ...filterStates(), exam: { ...filter, resources: resources } })
    }
    const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const calendar = calendarRef.current?.getApi()
        calendar?.gotoDate(e.currentTarget.value)
        calendar?.changeView('resourceTimeGridSevenDay')
    }
    const handleTimeUnitChange = (e: ChangeEvent<HTMLSelectElement>) => filterStates({
        ...filterStates(),
        exam: { ...filter, timeUnit: e.currentTarget.value }
    })
    // get fullcalendar dates and set dateRange state
    const handleDatesSet = (arg: DatesSetArg) => {
        let startDate = new Date(arg.start)
        startDate.setHours(0)
        let endDate = new Date(arg.end)
        endDate.setHours(0)
        filterStates({
            ...filterStates(),
            exam: {
                ...filter,
                dateRange: { start: startDate, end: endDate },
                monthView: arg.view.type === "dayGridMonth"
            }
        })
    }

    const handleDrop = async (eventDrop: EventDropArg) => handleMoveBooking({ eventDrop, events, client, updateBooking })

    // read json property data
    useEffect(() => {
        async function handleBeforeUnloadEvent(event: BeforeUnloadEvent) {
            event.preventDefault()
            return event.returnValue = "Are you sure you want to exit?"
        }

        window.addEventListener("beforeunload", handleBeforeUnloadEvent)
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnloadEvent)
            try {
                if (mongoCollectionWatch) {
                    mongoCollectionWatch.return(null)
                    mongoCollectionWatch = null
                }
            } catch (err) {
                alert(`Error removing watching stream.  ${(err as Error).message}`)
            }
        }
    }, [])

    // Handling Tooltip
    const [tooltipEvent, setTooltipEvent] = useState<CalendarEvent>()
    const eventElement = useRef<HTMLElement>()
    const handleMouseEnter = (arg: EventHoveringArg) => {
        if (filter.monthView) return
        if (arg.event.extendedProps.bookingType === OUTER_BOOK_TYPE) return
        eventElement.current = arg.el
        setTooltipEvent(events?.find(event => event.id === arg.event.id))
    }
    const handleDragStart = (arg: EventDragStartArg) => setTooltipEvent(undefined)
    const handleMouseLeave = (arg: EventHoveringArg) => setTooltipEvent(undefined)
    // End handling Tooltip

    // After Click the Calendar
    const handleModalSelect = async (selectionInfo: DateSelectArg) => {
        if (!selectionInfo.resource) {
            await showMessage("問診情報が取得できませんでした。\r\nselectionInfo => NULL", { error: true })
            return
        }
        // Entry Modalを出力
        setIsExamModalOpen(true)
        setSelectInfo(selectionInfo)
        setTooltipEvent(undefined)

    }

    const handleEventClick = async (e: EventClickArg) => {
        setIsExamModalOpen(true)
        setIsUpdated(true)
        setSelectedEvent(e.event)
        setTooltipEvent(undefined)
    }

    const handleDailyNoticeClick = (e:MouseEvent<HTMLDivElement>) => {
        const timestamp = e.currentTarget.dataset.timestamp
        setDailyModalTimestamp(Number(timestamp))
    }

    const DayHeader = (arg:DayHeaderContentArg) => {
        const timestamp = new Date(arg.date).getTime()
        return <div className="cursor-pointer" data-timestamp={timestamp} onClick={handleDailyNoticeClick}>{arg.text}<div className="px-1 bg-theme-200 max-w-xs">{dailyNoticeData?.find(data => data._id === timestamp)?.notice || ''}</div></div>
    }


    return <>
        {(loading || dailyNoticeLoading) && <Loading full/>}
        <ExamEntryForm
            isExamModalOpen={isExamModalOpen}
            setIsExamModalOpen={setIsExamModalOpen}
            selectBookingId={selectBookingId}
            selectInfo={selectInfo}
            baseResources={baseResources}
            isUpdated={isUpdated}
            setIsUpdated={setIsUpdated}
            event={selectedEvent}
            refetch={refetch}
        />
        <Tooltip element={eventElement.current} event={tooltipEvent} />
        <SearchForm
            dateRange={dateRange} timeUnit={timeUnit} resources={resources}
            handleChange={handleChange} handleDateChange={handleDateChange} handleTimeUnitChange={handleTimeUnitChange}
            baseResources={baseResources}
        />
        <DailyNoticeForm timestamp={dailyModalTimestamp} setTimestamp={setDailyModalTimestamp} data={dailyNoticeData} update={updateDailyNotice} />
        {isExamModalOpen ? <div /> :
            <FullCalendar
                ref={calendarRef}
                locale={jaLocale}
                plugins={[resourceTimeGridPlugin, scrollGridPlugin, dayGridPlugin, interactionPlugin]}
                views={{
                    resourceTimeGridSevenDay: {
                        type: 'resourceTimeGrid',
                        duration: { days: 7 },
                        buttonText: '週'
                    }
                }}
                headerToolbar={{
                    start: 'prev,next today',
                    center: 'title',
                    end: 'dayGridMonth,resourceTimeGridSevenDay'
                }}
                editable={filter.monthView ? false : true}
                eventDurationEditable={false}
                eventResourceEditable={true}
                eventOverlap={false}
                eventMouseEnter={handleMouseEnter}
                eventMouseLeave={handleMouseLeave}
                eventDragStart={handleDragStart}
                eventDrop={handleDrop}
                eventColor="lightgray"
                selectable={true}
                select={handleModalSelect}
                dayHeaderContent={DayHeader}
                initialDate={dateRange.start}
                initialView={filter.monthView ? "dayGridMonth" : "resourceTimeGridSevenDay"}
                allDaySlot={false}
                dayMinWidth={96}
                slotMinTime="08:00:00"
                slotMaxTime="19:00:00"
                slotDuration={getTimeUnit(timeUnit)}
                slotLabelFormat={(arg) => arg.date.minute ? String(arg.date.minute) : (String(arg.date.hour) + ":00")}
                slotLabelInterval={getTimeUnit(timeUnit)}
                datesSet={handleDatesSet}
                datesAboveResources
                resources={resources}
                events={events ? (filter.monthView ? events.filter(v => resourceIds.includes(v.resourceId)) : events) : undefined}
                eventContent={filter.monthView ? EventContentMonth : EventContent}
                eventClick={handleEventClick}
            />
        }
    </>
}

export default Schedule
