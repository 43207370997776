import {useLazyQuery, useMutation} from "@apollo/client"
import gql from "graphql-tag"
import {ApolloError, DocumentNode} from "@apollo/client/core";

export const useOpeningTimesMutations = () => {
  return {
    insertOpeningTime: useInsertOpeningTime(),
  }
}

// 指定された予約時間(開始~終了)が重複していないかどうかを確認するクエリ
const validateOpeningQuery = gql`
query validateOpeningTime1($modality: String, $weekday: Int, $start: DateTime, $end: DateTime){
  opening_times(query: {OR:[{
      modality: $modality,
      weekday: $weekday,
      start_gte : $start,
      start_lt : $end
  },{
      modality: $modality,
      weekday: $weekday,
      end_gt : $start,
      end_lte : $end
  },{
      modality: $modality,
      weekday: $weekday,
      start_lte : $start,
      end_gte : $end
  },{
      modality: $modality,
      weekday: $weekday,
      start_gte : $start,
      end_lt : $end
  }]}) {
    _id
    start
    end
    close
    modality
    weekday
  }
}
`

// opening_timesを追加するクエリ
const InsertOpeningTimesMutation = gql`
mutation AddOpeningTime($opening_time: Opening_timeInsertInput!) {
    addedOpeningTime: insertOneOpening_time(data: $opening_time) {
      _id
      modality
      weekday
      close
      starttime
      endtime
      start
      end
    }
  }
`

// openint_timesにドキュメントを追加する
const useInsertOpeningTime = () => {
  const [insertOpeningTimeMutation] = useMutation(InsertOpeningTimesMutation);
  const insertOpeningTime = async (opening_time: OpeningTime) => {
    // @ts-ignore
    const { insertedOpeningTime } = await insertOpeningTimeMutation({
      variables: { opening_time: opening_time },
    });
    return insertedOpeningTime;
  };
  return insertOpeningTime;
}


// ボタン押下時に処理を動かすのでuseLazyQueryを使う。
// 公開枠の重複チェック
export const useValidateOpening = () => {
  const [validateOpeningTime, {called, loading, data}] = useLazyQuery(validateOpeningQuery)
  const opening_times = data?.opening_times ?? []
  return {
    validateOpeningTime,
    loading,
    called,
    opening_times
  }
}


const updateOpeningTimesMutation = (): DocumentNode => gql`
  mutation UpdateOpeningTime($id: String!, $set: Opening_timeUpdateInput!) {
    updatedData: updateOneOpening_time(query: { _id: $id }, set: $set) {
        _id
        modality
        weekday
        close
        start
        end
    }
  }
`;

export const useUpdateOpeningTime = (completed?: () => void) => {
  const [updateDataMutation, { error }] = useMutation(updateOpeningTimesMutation(), { onCompleted: completed });
  const updateData = async (id: string | number, set: { [key: string]: any }): Promise<{ updatedData: { [key: string]: any }, error: ApolloError | undefined }> => {
    const { data } = await updateDataMutation({
      variables: { id: id, set: set },
    });
    const updatedData: { [key: string]: any } = data?.updatedData || {}
    return { updatedData: updatedData, error };
  };
  return updateData;
}

const deleteOpeningTimeMutation = (): DocumentNode => gql`
  mutation DeleteOpeningTime($Id: String!) {
    deletedOpeningTime: deleteOneOpening_time(query: { _id: $Id }) {
      _id
      modality
      start
      close
      end
      weekday
    }
  }
`;

export const useDeleteOpeningTime = (completed?: () => void) => {
  const [deleteDataMutation, { error }] = useMutation(deleteOpeningTimeMutation(),{onCompleted: completed});
  const deleteData = async (id: string | null) => {
    // @ts-ignore
    const { deleteOpeningTime } = await deleteDataMutation({
      variables: { Id: id },
    });
    return deleteOpeningTime;
  };
  return deleteData;
}


// 日付別用 指定された予約時間(開始~終了)が重複していないかどうかを確認するクエリ
const validateOpeningDailyQuery = gql`
query validateOpeningTime($modality: String, $start: DateTime, $end: DateTime){
  opening_times(query: {
    OR: [
      {
        modality: $modality,
        weekday: 99,
        start_gte: $start,
        start_lt: $end
      },
      {
        modality: $modality,
        weekday: 99,
        end_gt: $start,
        end_lte: $end
      },
      {
        modality: $modality,
        weekday: 99,
        start_lte: $start,
        end_gte: $end
      },
      {
        modality: $modality,
        weekday: 99,
        start_gte: $start,
        end_lt: $end
      }
    ]
  }) {
    _id
    start
    end
    close
    modality
  }
}
`

// 日付別用 ボタン押下時に処理を動かすのでuseLazyQueryを使う。
// 公開枠の重複チェック
export const useValidateDailyOpening = () => {
  const [validateOpeningTime, {called, loading, data}] = useLazyQuery(validateOpeningDailyQuery)
  const opening_times = data?.opening_times ?? []
  return {
    validateOpeningTime,
    loading,
    called,
    opening_times
  }
}