import { InputClass } from "contexts/style"
import React from "react"

interface FormTmpPatientsProps {
    setPatientKana: any,
}

export const FormTmpPatients = ({ setPatientKana}: FormTmpPatientsProps) => {

    /**
     * 患者名カナ変更時に起動
     * Kanaをセット
     * @param e
     */
    const updateKana = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPatientKana(e.target.value)
    }

    return <div className="p-3">
        {
            <>
                <div className="text-white">※ 患者名・カナ、体重、電話番号は必須項目です。</div>
                <div className="flex items-center">
                    <div className="ml-3">
                        <input id="patient_name_kana" name="patient_name_kana" className={InputClass}
                            type="text" placeholder="患者名カナ*"
                            onChange={(e) => updateKana(e)} />
                    </div>
                </div>
            </>
        }

    </div>
}