import pdfMake from "pdfmake";

import options from "contexts/options.json"
import { JSTDate, JSTTime } from 'contexts/dateUtils';
require("components/vfs_fonts.js");

const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
}

pdfMake.fonts = {
    GenShin: {
        normal: 'GenShinGothic-Normal-Sub.ttf',
        bold: 'GenShinGothic-Normal-Sub.ttf',
        italics: 'GenShinGothic-Normal-Sub.ttf',
        bolditalics: 'GenShinGothic-Normal-Sub.ttf'
    }
}

const style = {
    font: 'GenShin',
    fontSize: 8,
    alignment: 'left',
    margin: [0, 2],
    pageMargins: [35, 35],
}
const address = [68, 34, 257, 104]
// address is [left excluding pageMargin, top excluding pageMargin, width, height]
const pageStyle = {
    pageMargins: 35,
    address: { x: address[0] - style.pageMargins[0], y: Math.max(address[1] - style.pageMargins[0], 0), width: address[2] - address[0], height: address[3] - address[1] },
    height: 55,//min 52
    minHeight: 60,
    totalHeight: 515 - 30,
    headerHeight: 150,
    footerHeight: 60,
    fontSizes: { title: 16, subtitle: 14, name: 12, spot: 16 },
    margins: { spot: 16 },
    margin: 4,
    cellPadding: 5,
}

// const columnTitleText = (text) => ({ text: `${text}\n`, fontSize: 6, alignment: 'left' })


//const doc = pdf.getStream(pdf)
const fontSize = 10
/**
 *
 *
 * @param {KV[]} data
 * @return {*} 
 */
const getPdfData = (data) => {
    console.log(data)

    return {
        pageSize: 'A4',
        pageMargins: pageStyle.pageMargins,
        info: { title: '検査予約確認表' },
        content: [
/*            {
                canvas: [
                    {
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 285,
                        h: 130,
                        r: 5,
                        lineColor: 'black',
                    },
                ]
            },
            {
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    body: [
                        [{ text: 'Header 1', style: 'tableHeader' }, { text: 'Header 2', style: 'tableHeader' }, { text: 'Header 3', style: 'tableHeader' }],
                        ['Sample value 1', 'Sample value 2', 'Sample value 3'],
                        ['Sample value 1', 'Sample value 2', 'Sample value 3'],
                        ['Sample value 1', 'Sample value 2', 'Sample value 3'],
                        ['Sample value 1', 'Sample value 2', 'Sample value 3'],
                        ['Sample value 1', 'Sample value 2', 'Sample value 3'],
                    ]
                },
                layout: 'headerLineOnly',
                absolutePosition: { x: 45, y: 50 }
            },
*/
            { text: '検査予約確認表', alignment: 'center', margin: [10, 0], fontSize: pageStyle.fontSizes.title },
            ' ',
            { text: '【患者様情報】', margin: [0, 10] },
            {
                table: {
                    widths: [50, "*", 80, "*"],
                    body: [
                        ["カルテNo : ", data.patientID, "生年月日 : ", new Date(data.patient?.birthDate).toLocaleDateString("ja-JP")],
                        ["氏名 : ", data.patientName, "性別 : ", options.genderType[data.patient?.gender] || ""],
                    ],
                },
                layout: { 
                    hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 2 : 0; },
                    vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 2 : 0; },
                    //hLineColor: function (i, node) { return (i === 0 || i === node.table.body.length) ? 'black' : 'gray'; },
                    //vLineColor: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray'; },
                    // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                    paddingLeft: function(i, node) { return 10; },
                    paddingRight: function(i, node) { return 10; },
                    paddingTop: function(i, node) { return (i === 0) ? 10 : 0; },
                    paddingBottom: function(i, node) { return (i === 1) ? 10 : 0; },
                    // fillColor: function (rowIndex, node, columnIndex) { return null; }
                }
            },
            ' ',
            { text: '【検査内容】', margin: [0, 10] },
            {
                table: {
                    widths: [50, "*"],
                    body: [
                        ["検査日 : ", new Date(data.start).toLocaleDateString("ja-JP")],
                        ["来院時間 : ", JSTTime(new Date(new Date(data.start).getTime() - 20 * 60000))],
                        ["検査時間 : ", JSTTime(new Date(data.start))],
                        ["検査項目 : ", `${data.modalityID}-${data.body1 || ""}`],
                    ],
                },
                layout: { 
                    hLineWidth: function (i, node) { return (i === 0 || i === node.table.body.length) ? 2 : 0; },
                    vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 2 : 0; },
                    //hLineColor: function (i, node) { return (i === 0 || i === node.table.body.length) ? 'black' : 'gray'; },
                    //vLineColor: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray'; },
                    // hLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                    // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                    paddingLeft: function(i, node) { return 10; },
                    paddingRight: function(i, node) { return 10; },
                    paddingTop: function(i, node) { return (i === 0) ? 10 : 0; },
                    paddingBottom: function(i, node) { return (i === 3) ? 10 : 0; },
                    // fillColor: function (rowIndex, node, columnIndex) { return null; }
                }
            },
            ' ',
            { text: '【注意事項】', margin: [0, 10] },
            {
                table: {
                    widths: ["*"],
                    body: [[{
                        ul: [
                            "検査当日は検査時間の20分前までに受付にお越しください。",
                            "検査予約確認表（この用紙）・保険証をご持参ください。",
                            "食事制限等の検査にかかる注意事項は別紙をご参照ください。",
                            "検査状況により時間は前後することがありますので、あらかじめご了承ください。"
                        ]
                }]]
                },
                layout: { 
                    hLineWidth: function (i, node) { return 2 },
                    vLineWidth: function (i, node) { return 2 },
                    paddingLeft: function(i, node) { return 10; },
                    paddingRight: function(i, node) { return 10; },
                    paddingTop: function(i, node) { return 10; },
                    paddingBottom: function(i, node) { return 10; },
                }
            },
            ' ',
            "ご不明点などございましたら、お気軽にお問い合わせください。",
            { text: `予約日:${JSTDate(new Date())}`, alignment: "right" },
            {
                columns: [
                    [
                        " ",
                    ],
                    [
                        " ",
                        "医療法人社団NIDC",
                         { text: "セントラルクリニック世田谷", fontSize: pageStyle.fontSizes.subtitle },
                        "〒158-0093",
                        "東京都世田谷区上野毛3-3-4",
                        "Tel:03-6805-9130 Fax:03-6432-1039"
                    ]
                ]
            }


        ],
        styles: {
        },
        defaultStyle: {
            font: 'GenShin',
            fontSize: fontSize,
            alignment: 'left',
            margin: [0, 2]
        }
    }
}


const makePdf = (data) => pdfMake.createPdf(getPdfData(data)).open()

export default makePdf