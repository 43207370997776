import {CheckClass, InputClass} from "../contexts/style";
import React, {useEffect} from "react";
import { todayISO } from "contexts/dateUtils";

/**
 * FormReport
 * 報告情報
 * @param setDue
 * @param setImage
 * @param setImageSubmission
 * @param setReportSubmission
 * @constructor
 */
export const FormReport = ({ due, setDue,
                             image, setImage,
                             reportSubmission, setReportSubmission,
                           }:
                               { due: Date, setDue: any,
                                 image: string[], setImage: any,
                                 reportSubmission: string[], setReportSubmission: any,
                               }) => {

  const dueMonth = due.getMonth() + 1
  let defaultDue = due.getFullYear() + "-" + dueMonth.toString().padStart(2, "0") + "-" + due.getDate().toString().padStart(2, "0")

  useEffect(() => {
    async function fetchReportInfo() {
      const dueMonth = due.getMonth() + 1
      defaultDue = due.getFullYear() + "-" + dueMonth.toString().padStart(2, "0") + "-" + due.getDate().toString().padStart(2, "0")
    }
    fetchReportInfo()
  }, [due])

  /**
   * 期日の設定
   * @param e
   */
  const updateDue = (e: any) => {
    setDue(new Date(e.target.value))
  }

  /**
   * 画像媒体の配列管理及び更新
   * @param e
   */
  const updateImage = (e: any) => {
    let imageTmp = image.concat()
    // チェックが入った場合
    if (e.target.checked) {
      if (e.target.value === 'CD' && !imageTmp.includes('CD')) {
        imageTmp.push(e.target.value)
      }
      if (e.target.value === 'WEB媒体' && !imageTmp.includes('WEB媒体')) {
        imageTmp.push(e.target.value)
      }
    // チェックを外した場合
    } else {
      if (e.target.value === 'CD' && imageTmp.includes('CD')) {
        imageTmp = imageTmp.filter(item => (item.match(/CD/)) === null)
      }
      if (e.target.value === 'WEB媒体' && imageTmp.includes('WEB媒体')) {
        imageTmp = imageTmp.filter(item => (item.match(/WEB媒体/)) === null)
      }
    }
    setImage(imageTmp)
  }


  /**
   * 報告書提出の配列管理及び更新
   * @param e
   */
  const updateReportSubmission = (e: any) => {
    let reportSubmissionTmp = reportSubmission.concat()
    // チェックが入った場合
    if (e.target.checked) {
      if (e.target.value === 'FAX' && !reportSubmissionTmp.includes('FAX')) {
        reportSubmissionTmp.push(e.target.value)
      }
      if (e.target.value === '郵送' && !reportSubmissionTmp.includes('郵送')) {
        reportSubmissionTmp.push(e.target.value)
      }
      if (e.target.value === 'WEB掲載' && !reportSubmissionTmp.includes('WEB掲載')) {
        reportSubmissionTmp.push(e.target.value)
      }
      // チェックを外した場合
    } else {
      if (e.target.value === 'FAX' && reportSubmissionTmp.includes('FAX')) {
        reportSubmissionTmp = reportSubmissionTmp.filter(item => (item.match(/FAX/)) === null)
      }
      if (e.target.value === '郵送' && reportSubmissionTmp.includes('郵送')) {
        reportSubmissionTmp = reportSubmissionTmp.filter(item => (item.match(/郵送/)) === null)
      }
      if (e.target.value === 'WEB掲載' && reportSubmissionTmp.includes('WEB掲載')) {
        reportSubmissionTmp = reportSubmissionTmp.filter(item => (item.match(/WEB掲載/)) === null)
      }
    }
    setReportSubmission(reportSubmissionTmp)
  }

  const checkIncludes = (arr: any, item: any) => {
    if (arr === undefined || arr === null) arr = []
    return arr.includes(item)
  }

  return <div className="p-3">
    <div className="grid grid-cols-4">
      <div className="col-span-2">
        <span className="text-white w-36 block">期日</span>
        <span className="w-36">
          <input className={InputClass} min={todayISO} type="date" name="date" value={defaultDue} onChange={(e) => updateDue(e)} />
        </span>
      </div>
    </div>
    <div className="mt-2">
      <div className="grid grid-cols-4">
        <span className="text-white w-56 mt-1 block">画像媒体</span>
        <div className="col-span-3 grid grid-cols-4">
          <div>
            <input type="checkbox" checked={checkIncludes(image,'CD')} id="scales" name="scales" className={CheckClass} value="CD" onChange={(e) => updateImage(e)}/>
            <label className="ml-1 text-white" htmlFor="scales">CD</label>
          </div>
          <div>
            <input type="checkbox" checked={checkIncludes(image,'WEB媒体')} id="scales" name="scales" className={CheckClass} value="WEB媒体" onChange={(e) => updateImage(e)}/>
            <label className="ml-1 text-white" htmlFor="scales">WEB媒体</label>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4">
        <span className="text-white w-56 mt-2 block">報告書提出</span>
        <div className="col-span-3 grid grid-cols-4">
          <div>
            <input type="checkbox" checked={checkIncludes(reportSubmission,'FAX')} id="scales" name="scales" className={CheckClass} value="FAX" onChange={(e) => updateReportSubmission(e)}/>
            <label className="ml-1 text-white" htmlFor="scales">FAX</label>
          </div>
          <div>
            <input type="checkbox" checked={checkIncludes(reportSubmission,'郵送')} id="scales" name="scales" className={CheckClass} value="郵送" onChange={(e) => updateReportSubmission(e)}/>
            <label className="ml-1 text-white" htmlFor="scales">郵送</label>
          </div>
          <div>
            <input type="checkbox" checked={checkIncludes(reportSubmission,'WEB掲載')} id="scales" name="scales" className={CheckClass} value="WEB掲載" onChange={(e) => updateReportSubmission(e)}/>
            <label className="ml-1 text-white" htmlFor="scales">WEB掲載</label>
          </div>
        </div>
      </div>
    </div>
  </div>
}