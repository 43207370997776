import Modal from "react-modal"
import {
    ButtonClass,
    LightGrayButtonClass,
    modalStyles
} from "contexts/style"
import React, { useEffect, useState } from "react"
import showMessage from "./showMessage"
import useBookingsMutations from "graphql/useBookingsMutations"
import { checkPetTime, getIvTime, isPet, OUTER_BOOK_TYPE, PRE_BOOK_STATUS } from "contexts/enviroments"
import { FormTmpPatients } from "./FormTmpPatient"
import usePatientsMutations from "graphql/usePatientsMutations"
import { getSequenceNo } from "graphql/useSequenceFindOneAndUpdate"
import { useMongoDB } from "contexts/MongoDBContext"
import { Loading } from "./Loading"
import { useApolloClient } from "@apollo/client"
import { useFindBookings } from "contexts/useMongoQuery"
import { useRealmApp } from "../contexts/RealmApp"
import { useNavigate } from "react-router-dom"
import { useExternalBookDate } from "contexts/ExternalBookDateContext"
import { sendPreFixToExternal, sendPreFixToInternal } from "mail/sendPreFix"
import { convertDate } from "contexts/functions"
import { FIND_NOTIFICATION_USERS } from "./UpdateForm"
import { RECEPTION_DUR } from "contexts/enviroments"

export const ExternalEntryForm = ({ isModalOpen, setIsModalOpen, modality, date, duration }:
    { isModalOpen: boolean, setIsModalOpen: any, modality: KV[], date: Date, duration: number[] }) => {
    // Modalityの開始終了時刻(セット含む)
    const [start, setStart] = useState(new Date())
    const [end, setEnd] = useState(new Date())
    const [start2, setStart2] = useState(new Date())
    const [end2, setEnd2] = useState(new Date())

    const [patientName, setPatientName] = useState<string>("")
    const [patientNameKana, setPatientKana] = useState<string>("")
    const [tel, setTel] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { db } = useMongoDB()
    const { addPatient } = usePatientsMutations()
    const { addBooking } = useBookingsMutations()
    const [hasIv, setHasIv] = useState<boolean>(false)
    const [ivStart, setIvStart] = useState(new Date())
    const [ivEnd, setIvEnd] = useState(new Date())

    const realmAppContext = useRealmApp()
    const navigate = useNavigate()
    const { externalBookDate, setExternalBookDate } = useExternalBookDate()

    const client = useApolloClient()
    const tmpDate = new Date(date)
    tmpDate.setHours(0, 0, 0, 0)
    const dateRange = { start: tmpDate, end: tmpDate }

    const { loading, data: events } = useFindBookings(db, dateRange)

    // Closeボタン
    const resetStates = () => {
        setIsModalOpen(false)
        setPatientName('')
        setPatientKana('')
        setTel('')
        setIsLoading(false)
        setHasIv(false)
    }

    // Date型をhour:minuteに変換
    const convertDate2Time = (_date: Date) => {
        const hour = String(_date.getHours()).padStart(2, '0')
        const minute = String(_date.getMinutes()).padStart(2, '0')

        return `${hour}:${minute}`
    }

    // 受付時刻を設定
    const getReceptionDateTime = () => {
        const hour = String(date.getHours()).padStart(2, '0')
        const minute = String(date.getMinutes()).padStart(2, '0')

        return `${hour}:${minute}`
    }

    // 検査開始時刻
    const getStartTime = (index: number) => {
        let hour = ""
        let minute = ""
        if (index === 0) {
            hour = String(start.getHours()).padStart(2, '0')
            minute = String(start.getMinutes()).padStart(2, '0')
        } else {
            hour = String(start2.getHours()).padStart(2, '0')
            minute = String(start2.getMinutes()).padStart(2, '0')
        }
        return `${hour}:${minute}`
    }

    // 検査終了時刻
    const getEndTime = (index: number) => {
        let hour = ""
        let minute = ""
        if (index === 0) {
            hour = String(end.getHours()).padStart(2, '0')
            minute = String(end.getMinutes()).padStart(2, '0')
        } else {
            hour = String(end2.getHours()).padStart(2, '0')
            minute = String(end2.getMinutes()).padStart(2, '0')
        }
        return `${hour}:${minute}`
    }

    // 予約当日
    const getDate = () => {
        const newDate = new Date(date)
        const year = newDate.getFullYear()
        const month = String(newDate.getMonth() + 1).padStart(2, '0')
        const day = String(newDate.getDate()).padStart(2, '0')
        return `${year}年${month}月${day}日`
    }

    // モーダルオープン時にstart / endを設定
    useEffect(() => {
        const fetchModalOpen = async () => {
            const baseDate = new Date(date)
            const startDate = new Date(date)
            const endDate = new Date(date)
            const startDate2 = new Date(date)
            const endDate2 = new Date(date)
            const ivstart = new Date(date)
            const ivend = new Date(date)

            // 時刻設定
            if (modality[0].ID === 'MRI') {
                startDate.setMinutes(baseDate.getMinutes() + RECEPTION_DUR)
                endDate.setMinutes(baseDate.getMinutes() + RECEPTION_DUR + duration[0])

                // セット予約の場合
                if (modality.length === 2) {
                    if (modality[1].ID === 'MRI') {
                        startDate2.setMinutes(baseDate.getMinutes() + RECEPTION_DUR + duration[0])
                        endDate2.setMinutes(baseDate.getMinutes() + RECEPTION_DUR + duration[0] + duration[1])
                    } else {
                        // IV時間を計算
                        const iv_time = await getIvTime(modality[1].ID, modality[1].body1, modality[1].body2)
                        startDate2.setMinutes(baseDate.getMinutes() + RECEPTION_DUR + duration[0] + iv_time)
                        endDate2.setMinutes(baseDate.getMinutes() + RECEPTION_DUR + duration[0] + iv_time + duration[1])
                        if (iv_time > 0) {
                            setHasIv(true)
                            ivstart.setMinutes(baseDate.getMinutes() + RECEPTION_DUR + duration[0])
                            setIvStart(ivstart)
                            ivend.setMinutes(baseDate.getMinutes() + RECEPTION_DUR + duration[0] + 10)
                            setIvEnd(ivend)
                        } else {
                            setHasIv(false)
                        }
                    }
                }
            } else {
                // IV時間を計算, 準備は20分とする
                const iv_time = await getIvTime(modality[0].ID, modality[0].body1, modality[0].body2)
                startDate.setMinutes(baseDate.getMinutes() + RECEPTION_DUR + iv_time)
                endDate.setMinutes(baseDate.getMinutes() + RECEPTION_DUR + iv_time + duration[0])

                if (iv_time > 0) {
                    setHasIv(true)
                    ivstart.setMinutes(baseDate.getMinutes() + RECEPTION_DUR)
                    setIvStart(ivstart)
                    ivend.setMinutes(baseDate.getMinutes() + RECEPTION_DUR + 10)
                    setIvEnd(ivend)
                } else {
                    setHasIv(false)
                }


                // セット予約の場合 IVは一回のみ
                if (modality.length === 2) {
                    startDate2.setMinutes(baseDate.getMinutes() + iv_time + RECEPTION_DUR + duration[0])
                    endDate2.setMinutes(baseDate.getMinutes() + iv_time + RECEPTION_DUR + duration[0] + duration[1])
                }
            }

            setStart(startDate)
            setEnd(endDate)
            setStart2(startDate2)
            setEnd2(endDate2)
        }
        fetchModalOpen()
    }, [isModalOpen])

    const receptMessage = (messages: any[]) => {
        const formattedMessages = messages.map((message) => {
            return `受付No.${message.seqNo} (モダリティ: ${message.modality})`;
        });
        return formattedMessages.join('\n');
    };

    // 予約データ投入
    const insertBooking = async () => {
        if (patientNameKana === '') {
            alert('患者名カナを入力してください')
            return
        }
        if (!await showMessage(`検査を予約しますか？`, { confirm: true })) {
            return
        }
        setIsLoading(true)

        try {
            // 仮患者IDの発番
            const getTmpPatientId = (min: number, max: number) => {
                return Math.floor(Math.random() * (max + 1 - min)) + min
            }
            const patientId = getTmpPatientId(1000000000, 9000000000).toString()

            const tmpPatient: Patient = {
                patientId: patientId,
                isTemp: true,
                name: patientName,
                nameKana: patientNameKana,
                birthDate: new Date(),
                gender: "",
                tel: tel,
            }
            await addPatient(tmpPatient)

            // 予約番号(アクセッション番号)発行
            let seqNos = []
            for (const item of modality) {
                const seqNo = await getSequenceNo(db)
                if (!seqNo) {
                    alert('予約番号の発番に失敗しました。管理者へお問合せください。')
                    return false
                }
                seqNos.push(seqNo)
            }

            // @ts-ignore ログインIDからクリニック情報を取得
            const clinic_data = await db.collection("user").aggregate([
                {
                    $match: { _id: realmAppContext.currentUser?.id }
                },
                {
                    $lookup: {
                        from: "clinics",
                        localField: "clinic",
                        foreignField: "_id",
                        as: "clinic_info"
                    }
                },
                {
                    $unwind: "$clinic_info"
                },
                {
                    $project: {
                        clinic_id: "$clinic_info._id",
                        clinic_name: "$clinic_info.clinic_name",
                        departments: "$clinic_info.departments",
                        user_name: "$name",
                        user_id: "$_id"
                    }
                }
            ])
            // 空白を削除したログインユーザー
            const loginUser = clinic_data[0].user_name.replace(/\s/g, '')
            // nameが空白、doctorsの登録がない場合は削除
            const filteredDepartments = clinic_data[0].departments.filter((department: { name: string; doctors?: string[] }) => department.name !== "" || (department.doctors && department.doctors.length > 0));


            // 所属情報(医師名、診療科を取得)
            const foundEntry = filteredDepartments.map((department: { doctors: string[], name: string }) => {
                const foundDoctor = department.doctors.find(doctor => doctor.replace(/[\s*]/g, '') === loginUser)
                if (foundDoctor) {
                    return { doctor: foundDoctor, department: department.name }
                }
                return null
            }).filter((entry: KV) => entry !== null)[0]

            if (foundEntry) {
                let index = 0
                let emailModalities = []
                let hospitals: any = {}
                let messages: any[] = []
                for (const item of modality) {
                    const seqNo = seqNos[index]
                    messages.push({ "seqNo": seqNo, "modality": item.ID })
                    const booking: Booking = {
                        modalityID: item.ID,
                        body1: item.body1,
                        body2: item.body2,
                        contrast: item.contrast,
                        delay: false, // TODO: どうする？
                        clinicID: String(clinic_data[0].clinic_id),
                        clinicName: clinic_data[0].clinic_name,
                        doctor: foundEntry.doctor,
                        department: foundEntry.department,
                        bookingType: OUTER_BOOK_TYPE, // 外部予約
                        bookingMethod: 1, // WEB
                        checkin: getReceptionDateTime(),
                        patientID: patientId,
                        patient: { link: patientId },
                        patientName: patientNameKana,
                        userID: realmAppContext.currentUser?.id,
                        doctorID: realmAppContext.currentUser?.id,
                        bookingStatus: PRE_BOOK_STATUS, // 仮予約専用
                        comment: '',
                        updated: new Date(),
                        created: new Date(),
                    }

                    // 検査時間セット
                    if (index === 0) {
                        booking.start = start
                        booking.end = end
                    } else {
                        booking.start = start2
                        booking.end = end2
                    }

                    // データ登録
                    booking._id = String(seqNo)
                    // IV対応
                    if (isPet(booking)) {
                        const iv_information = await checkPetTime({ booking, events, client })
                        booking.ivCheckin = iv_information?.ivCheckin
                    }
                    await addBooking(booking)
                    index++

                    const modality: any = {}
                    modality["execTimeRange"] = `${convertDate2Time(booking.start)} ~ ${convertDate2Time(booking.end)}`
                    modality["id"] = item.ID
                    modality["body2"] = item.body2
                    modality["contrast"] = item.contrast
                    modality["seqNo"] = seqNo
                    emailModalities.push(modality)
                    hospitals["clinic_name"] = clinic_data[0].clinic_name
                    hospitals["doctor_name"] = foundEntry.doctor
                    hospitals["department"] = foundEntry.department

                }

                await showMessage(`仮予約完了しました。\r\n${receptMessage(messages)}\r\n診療情報提供書のFAXを受理後、予約が確定となります。`, { keep: true })

                // Mail項目の整理
                const toExternals: string[] = []
                const toInternals: string[] = []
                const seqNosString = seqNos.join(', ')

                // ログインしているユーザーに通知
                if (realmAppContext.currentUser?.profile.email) {
                    toExternals.push(realmAppContext.currentUser?.profile.email)
                }
                // 院内(通知設定しているユーザー)
                const result = await client.query({ query: FIND_NOTIFICATION_USERS, fetchPolicy: 'network-only', })
                if (result?.data.users && Array.isArray(result.data.users)) {
                    for (const user of result?.data.users) {
                        toInternals.push(user.email)
                    }
                }
                const params: KV = {
                    doctor: foundEntry.doctor,
                    bookNo: seqNosString,
                    bookDate: convertDate(start),
                    receptTime: getReceptionDateTime(),
                    patientNameKana: patientNameKana,

                }

                await sendPreFixToExternal(toExternals, params, emailModalities)
                await sendPreFixToInternal(toInternals, params, emailModalities, hospitals)

                // リダイレクト
                setExternalBookDate(start)
                navigate("/external/list")

            } else {
                await showMessage('ログインしたユーザーが不正です。管理者へ問い合わせてください。', { error: true, keep: true })
            }

        } catch (e) {
            if (e instanceof Error) await showMessage(`外部予約の登録ができませんでした。\r\nお手数ですが、もう一度お試しください。`, { error: true })
            return false
        } finally {
            setIsLoading(false)
            resetStates()
        }

    }

    const trimPET = (str: string) => {
        const regex = /_(1|2)$/
        const match = str.match(regex)

        if (match) {
            const convertedStr = str.replace(match[0], "")
            return convertedStr
        } else {
            return str
        }
    }

    return <>
        {isLoading && <Loading full />}
        <Modal
            isOpen={isModalOpen}
            ariaHideApp={false}
            style={modalStyles}
            contentLabel="External Opening Modal"
            shouldCloseOnOverlayClick={false}
        >
            <div>
                <div className="text-white text-3xl">検査日時：{getDate()}</div>
                <div className="w-8/12">
                    <div className="text-white text-2xl mt-5">検査内容</div>
                    <table className="min-w-full table-auto border-collapse border">
                        <thead>
                            <tr className="border">
                                <th className="border px-4 py-2">モダリティ</th>
                                <th className="border px-4 py-2">部位</th>
                                <th className="border px-4 py-2">項目</th>
                                <th className="border px-4 py-2">タイプ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {modality.map((item, index) => (
                                <tr key={index} className="border bg-gray-200 text-center">
                                    <td className="border px-4 py-2 font-bold">{item.ID}</td>
                                    <td className="border px-4 py-2">{item.body1}</td>
                                    <td className="border px-4 py-2">{item.body2}</td>
                                    <td className="border px-4 py-2">{item.contrast}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="text-white text-2xl mt-10">検査スケジュール</div>
                <div className="w-full text-center">
                    <table className="w-8/12 table-auto border-collapse border">
                        <thead>
                            <tr className="border">
                                <th className="border px-4 py-2">時刻</th>
                                <th className="border px-4 py-2">内容</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="border bg-gray-200 text-center">
                                <td className="border px-4 py-2">{getReceptionDateTime()}</td>
                                <td className="border px-4 py-2">受付時刻</td>
                            </tr>
                            {hasIv && ['PET-CT_1', 'PET-CT_2'].includes(modality[0].ID) ?
                                <tr className="border bg-gray-200 text-center">
                                    <td className="border px-4 py-2">{convertDate2Time(ivStart)} ~ {convertDate2Time(ivEnd)}</td>
                                    <td className="border px-4 py-2">IV検査</td>
                                </tr>
                                : <></>}
                            <tr className="border bg-gray-200 text-center">
                                <td className="border px-4 py-2">{getStartTime(0)} ~ {getEndTime(0)}</td>
                                <td className="border px-4 py-2">{trimPET(modality[0].ID)} 検査</td>
                            </tr>
                            {hasIv && modality && modality.length > 1 && modality[1].ID && ['PET-CT_1', 'PET-CT_2'].includes(modality[1].ID) ?
                                <tr className="border bg-gray-200 text-center">
                                    <td className="border px-4 py-2">{convertDate2Time(ivStart)} ~ {convertDate2Time(ivEnd)}</td>
                                    <td className="border px-4 py-2">IV検査</td>
                                </tr>
                                : <></>}
                            {modality.length === 2 ?
                                <tr className="border bg-gray-200 text-center">
                                    <td className="border px-4 py-2">{getStartTime(1)} ~ {getEndTime(1)}</td>
                                    <td className="border px-4 py-2">{trimPET(modality[1].ID)} 検査</td>
                                </tr>
                                : <></>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="text-white text-2xl mt-10">患者情報登録</div>
            <FormTmpPatients
                setPatientKana={setPatientKana}
            />

            <p className="mt-5 text-center text-white text-1xl">予約内容をご確認ください</p>
            <div className="flex justify-center">
                <button name="close" className={ButtonClass} onClick={insertBooking}>予約する</button>
                <button name="close" className={LightGrayButtonClass} onClick={resetStates}>閉じる</button>
            </div>

        </Modal>
    </>

}